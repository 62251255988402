import { LookupId } from '../../lists/lookups.enum';

export enum WebsiteKeys {
  id = 'id',
  url = 'url',
  websiteType = 'website_type',
}

export class Website {
  [WebsiteKeys.id]?: string;
  [WebsiteKeys.url]?: string;
  [WebsiteKeys.websiteType]?: LookupId<'AgencyWebsiteTypes'> | LookupId<'WebsiteTypes'>;
}
