import { Patterns } from '@ag-common-lib/public-api';
import validationEngine from 'devextreme/ui/validation_engine';
import { ValidationResult } from 'devextreme/ui/validation_group';
import { ValidationCallbackData } from 'devextreme/ui/validation_rules';

export const validateUrlWithOrWithoutProtocol = (value?: string) => {
  if (!value) {
    return true;
  }
  const re = new RegExp(Patterns.URL_PATTERN_WITH_OR_WITHOUT_PROTOCOL);
  return re.test(value);
};

export const validateRequiredBoolean = (callbackData?: ValidationCallbackData) => {
  return callbackData?.value === true || callbackData?.value === false;
};

export function resetDxGroupsValidation(groupName: string | string[]): void {
  const groups = Array.isArray(groupName) ? groupName : [groupName];
  setTimeout(async () => {
    await Promise.all(
      groups
        .map(validationGroup => {
          const groupConfig = validationEngine.getGroupConfig(validationGroup);

          if (!groupConfig) {
            return null;
          }

          return validationEngine.validateGroup(validationGroup);
        })
        .filter(Boolean)
        .map(async (validationGroupResults: ValidationResult) => {
          let asyncValidatorValid = !validationGroupResults?.complete;

          if (validationGroupResults?.complete) {
            const asyncValidationResults = await validationGroupResults?.complete;
            asyncValidatorValid = asyncValidationResults?.isValid;
          }

          const isValid = validationGroupResults.isValid && asyncValidatorValid;

          if (isValid) {
            return;
          }

          validationGroupResults?.validators?.forEach(validator => {
            if (!validator) {
              return;
            }

            const editor = validator.option('adapter').editor;

            if (!editor) {
              return;
            }

            const isValid = editor.option('isValid');

            if (isValid) {
              return;
            }

            editor.option('isValid', true);
          });
        }),
    ).catch(validationError => {
      console.error('validationError', validationError);
      return false;
    });
  });
}

export async function validateSingleDxGroup(validationGroup: string) {
  const groupConfig = validationEngine.getGroupConfig(validationGroup);

  if (!groupConfig) {
    return { isValid: true };
  }

  const validationGroupResults = validationEngine.validateGroup(validationGroup);

  let asyncValidatorValid = !validationGroupResults?.complete;

  if (validationGroupResults?.complete) {
    const asyncValidationResults = await validationGroupResults?.complete;
    asyncValidatorValid = asyncValidationResults?.isValid;
  }

  const isValid = validationGroupResults.isValid && asyncValidatorValid;

  if (isValid) {
    return { isValid };
  }

  const invalidEditors = [];

  validationGroupResults?.validators?.forEach(validator => {
    if (!validator) {
      return;
    }

    const adapter = validator.option('adapter');
    const editor = adapter?.editor;

    if (!editor) {
      return;
    }

    const isValid = editor.option('isValid');

    if (isValid) {
      return;
    }

    invalidEditors.push(editor);

    const readOnly = editor.option('readOnly');

    if (!readOnly) {
      return;
    }
    editor.option('readOnly', false);
  });

  return { isValid, invalidEditors };
}

export async function validateDxGroups(groupName: string | string[]): Promise<boolean> {
  const groups = Array.isArray(groupName) ? groupName : [groupName];

  return await Promise.all(
    groups.map(async validationGroup => {
      return validateSingleDxGroup(validationGroup);
    }),
  )
    .then(results =>
      results.every(result => {
        if (result?.isValid) {
          return true;
        }

        const [editor] = result?.invalidEditors ?? [];

        editor?.focus();

        return false;
      }),
    )
    .catch(validationError => {
      console.error('validationError', validationError);
      return false;
    });
}
