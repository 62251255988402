import { LocalDateTimeString } from './local-date-time-string.model';

export enum OTPKeys {
  email = 'email',
  otp = 'otp',
  expirationDate = 'expirationDate',
}

export interface OTP {
  [OTPKeys.otp]: string;
  [OTPKeys.email]: string;
  [OTPKeys.expirationDate]: string;
}
