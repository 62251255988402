import { getYear, subWeeks } from 'date-fns';

export const MIN_DATE: Date = new Date(1900, 0, 1);
export const DATE_NOW: Date = new Date();
export const TIMESTAMP: string = new Date().toJSON().slice(0, 19);

export const MIN_STATISTICS_YEAR = 2020;
export const CONFERENCE_MIN_STATISTICS_YEAR = 2021;
export const DASHBOARD_YEAR = getYear(subWeeks(DATE_NOW, 3));

export const YEARS_LIST: number[] = new Array(DASHBOARD_YEAR - MIN_STATISTICS_YEAR)
  .fill(DASHBOARD_YEAR)
  .map((year, index) => year - index);

export const CONFERENCE_YEARS_LIST: number[] = new Array(DASHBOARD_YEAR - CONFERENCE_MIN_STATISTICS_YEAR)
  .fill(DASHBOARD_YEAR)
  .map((year, index) => year - index);

export const TIME_SUFFIX = 'T00:00:00';
export const DATE_FNS_LOCAL_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT = 'yyyy-MM-ddTHH:mm:ss';
export const TWELVE_HRS_SHORT_TIME_FORMAT: string = 'hh:mm';
export const MILITARY_TIME_FORMAT: string = 'HH:mm';
export const TIME_FORMAT: string = 'HH:mm:ss';
export const SHORT_TIME_AM_PM_FORMAT: string = 'hh:mm a';
export const MDYYYY_DATE_FORMAT: string = 'M/d/yyyy';
export const MMDDYYYY_DATE_FORMAT: string = 'MM/dd/yyyy';
export const YYYYMMDD_DATE_FORMAT: string = 'yyyy/MM/dd';
export const MDYYYY_DATE_TIME_FORMAT: string = `${MDYYYY_DATE_FORMAT}, ${TIME_FORMAT}`;
export const MMDDYYYY_DATE_TIME_FORMAT: string = `${MMDDYYYY_DATE_FORMAT}, ${TIME_FORMAT}`;
export const MMDDYYYY_DATE_SHORT_TIME_FORMAT: string = `${MMDDYYYY_DATE_FORMAT} ${TWELVE_HRS_SHORT_TIME_FORMAT}`;
