<div
  class="html-editor__content"
  [ngClass]="{
    'html-editor__content--focused': isFocused,
    'html-editor__content--read-only': isReadOnly
  }"
>
  <label
    for="htmlEditor"
    class="html-editor__label dx-widget"
    [ngClass]="{
      'html-editor__label--top': !!value || isFocused,
      'html-editor__label--focused': isFocused,
      'html-editor__label--read-only': isReadOnly
    }"
  >
    {{ label }}
  </label>

  <div class="html-editor__extra-content-container">
    <div *ngIf="useFiledEditing && hasChanges" class="html-editor__controls-container">
      <dx-button
        icon="undo"
        type="danger"
        stylingMode="text"
        hint="REVERT ALL"
        [elementAttr]="{
          class: 'inline-editor-control'
        }"
        [disabled]="inProgress"
        (onClick)="handleRevertClick()"
      ></dx-button>

      <dx-button
        icon="save"
        type="success"
        stylingMode="text"
        hint="SAVE ALL"
        [elementAttr]="{
          class: 'inline-editor-control'
        }"
        template="successButtonTemplate"
        [disabled]="inProgress"
        (onClick)="handleSaveChangesClick()"
      >
        <div *dxTemplate="let item of 'successButtonTemplate'" class="editor-progress-indicator">
          <ng-container *ngIf="inProgress; then loadIndicatorTmp; else saveIconTmp"></ng-container>
        </div>
      </dx-button>
    </div>
  </div>

  <div class="html-editor__editor-container" [style.height]="'100%'">
    <dx-scroll-view direction="vertical" width="100%" height="100%" class="html-editor__scroll-view">
      <dx-html-editor
        #editorRef
        id="htmlEditor"
        [value]="value"
        [name]="name"
        [allowSoftLineBreak]="allowSoftLineBreak"
        (onValueChanged)="handleValueChange($event)"
        (onFocusIn)="isFocused = true"
        (onFocusOut)="isFocused = false"
        class="html-editor__editor"
        [readOnly]="isReadOnly"
      >
        <dxo-toolbar
          *ngIf="!isReadOnly"
          [multiline]="false"
          [items]="editorItems"
          [container]="htmlEditorFooter"
        ></dxo-toolbar>

        <dxo-variables *ngIf="variables?.length" [dataSource]="variables" [escapeChar]="['{{', '}}']"> </dxo-variables>

        <dx-validator [validationGroup]="validationGroup">
          <dxi-validation-rule *ngIf="isRequired" type="required" [message]="label + ' ' + Messages.REQUIRED_TEXT">
          </dxi-validation-rule>
        </dx-validator>

        <dxo-media-resizing [enabled]="true"> </dxo-media-resizing>

        <dxo-image-upload
          #testRef
          [tabs]="['file', 'url']"
          fileUploadMode="server"
          [uploadDirectory]="fileDirectory | htmlEditorFileDirectory"
          [fileUploaderOptions]="{
            onInitialized: onInitialized,

            uploadFile: uploadFile
          }"
        >
        </dxo-image-upload>
      </dx-html-editor>
    </dx-scroll-view>
  </div>
  <div
    #htmlEditorFooter
    class="html-editor__footer"
    [ngClass]="{
      'html-editor__footer--visible': isFocused
    }"
  ></div>
</div>

<ng-template #saveIconTmp>
  <i class="dx-icon dx-icon-save"></i>
</ng-template>

<ng-template #loadIndicatorTmp>
  <dx-load-indicator
    class="editor-progress-indicator__indicator"
    [visible]="true"
    height="18"
    width="18"
  ></dx-load-indicator>
</ng-template>
