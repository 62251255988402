import * as uuid from 'uuid';
import { LookupId } from '../../lists/lookups.enum';
import { BaseModel } from '../base.model';
import { RequestOutcome } from '../utils/request-outcome.model';
import { LocalDateTimeString, LocalTimeString } from '../utils/local-date-time-string.model';
import { LookupKeys } from '../crm/lookup.model';

export enum SeatPreference {
  Window = 'Window',
  Middle = 'Middle',
  Aisle = 'Aisle',
}

export const SEAT_PREFERENCE_LOOKUP = [
  { value: SeatPreference.Window, description: 'Window' },
  { value: SeatPreference.Middle, description: 'Middle' },
  { value: SeatPreference.Aisle, description: 'Aisle' },
];

export enum Airlines {
  Alaska = 'Alaska',
  American = 'American',
  Delta = 'Delta',
  JetBlue = 'JetBlue',
  Southwest = 'Southwest',
  United = 'United',
  Other = 'Other',
  None = 'None',
}

export const AIRLINES_LOOKUP = [
  { value: Airlines.Alaska, description: 'Alaska' },
  { value: Airlines.American, description: 'American' },
  { value: Airlines.Delta, description: 'Delta' },
  { value: Airlines.JetBlue, description: 'JetBlue' },
  { value: Airlines.Southwest, description: 'Southwest' },
  { value: Airlines.United, description: 'United' },
  { value: Airlines.Other, description: 'Other' },
  { value: Airlines.None, description: 'None' },
];

export enum FlightBookingStatus {
  initial = 'initial',
  pending = 'pending',
  confirmed = 'confirmed',
}

export const FlightBookingStatusMap = new Map([
  [FlightBookingStatus.initial, 'Initial'],
  [FlightBookingStatus.pending, 'Pending'],
  [FlightBookingStatus.confirmed, 'Confirmed'],
]);

export const FLIGHT_BOOKING_STATUS_LOOKUP = [
  { value: FlightBookingStatus.initial, description: FlightBookingStatusMap.get(FlightBookingStatus.initial) },
  { value: FlightBookingStatus.pending, description: FlightBookingStatusMap.get(FlightBookingStatus.pending) },
  { value: FlightBookingStatus.confirmed, description: FlightBookingStatusMap.get(FlightBookingStatus.confirmed) },
];

export enum FlightDirectionType {
  toConference = 'ToConference',
  fromConference = 'FromConference',
}

export const FLIGHT_DIRECTION_LOOKUP = [
  { value: FlightDirectionType.toConference, description: 'To Conference' },
  { value: FlightDirectionType.fromConference, description: 'From Conference' },
];

export enum TravelMode {
  flying = 'flying',
  driving = 'driving',
}

export const TRAVEL_MODE_LOOKUP = [
  { value: TravelMode.flying, description: 'Flying' },
  { value: TravelMode.driving, description: 'Driving' },
];

export enum FlightBookingKeys {
  id = 'id',
  flightInfoId = 'flightInfoId',
  directionType = 'directionType',
  sequenceNumber = 'sequenceNumber',

  airline = 'airline',
  flightNumber = 'flight_number',
  confirmationNumber = 'confirmation_number',
  passengerName = 'passenger_name',
  seat = 'seat',
  class = 'class',
  frequentFlyerNumber = 'frequent_flyer_number',
  knownTravelerNumber = 'known_traveler_number',
  departureAirport = 'departure_airport',
  flightDepartureDate = 'flight_departure_date',
  flightDepartureTime = 'flight_departure_time',
  arrivalAirport = 'arrival_airport',
  flightArrivalDate = 'flight_arrival_date',
  flightArrivalTime = 'flight_arrival_time',
}

export class FlightBookingModel {
  [FlightBookingKeys.id]: string = uuid.v4();
  [FlightBookingKeys.flightInfoId]?: string;
  [FlightBookingKeys.directionType]?: FlightDirectionType;
  [FlightBookingKeys.sequenceNumber]?: number;
  [FlightBookingKeys.airline]?: Airlines;
  [FlightBookingKeys.flightNumber]?: string;
  [FlightBookingKeys.confirmationNumber]?: string;
  [FlightBookingKeys.passengerName]?: string;
  [FlightBookingKeys.seat]?: string;
  [FlightBookingKeys.class]?: string;
  [FlightBookingKeys.frequentFlyerNumber]?: string;
  [FlightBookingKeys.knownTravelerNumber]?: string;
  [FlightBookingKeys.departureAirport]?: string;
  [FlightBookingKeys.flightDepartureDate]?: LocalDateTimeString;
  [FlightBookingKeys.flightDepartureTime]?: LocalTimeString;
  [FlightBookingKeys.arrivalAirport]?: string;
  [FlightBookingKeys.flightArrivalDate]?: LocalDateTimeString;
  [FlightBookingKeys.flightArrivalTime]?: LocalTimeString;
}

export enum FlightInfoKeys {
  travelMode = 'travel_mode',
  equalTravelMode = 'equal_travel_mode',
  travelDatesDiffer = 'travel_dates_differ',
  travelDatesDifferRequestOutcome = 'travel_dates_differ_outcome',
  sameForAll = 'same_for_all',
  firstName = 'first_name',
  lastName = 'last_name',
  middleName = 'middle_name',
  prefix = 'prefix',
  suffix = 'suffix',
  passportNationality = 'passport_nationality',
  passportNameConfirmation = 'passport_name_confirmation',
  passportConfirmation = 'passport_confirmation',
  preferredAirline = 'preferred_airline',
  preferredAirlineLoyaltyNumber = 'preferred_airline_loyalty_number',
  secondChoiceAirline = 'second_choice_airline',
  secondChoiceAirlineLoyaltyNumber = 'second_choice_airline_loyalty_number',
  knownTravelerNumber = 'known_traveler_number',
  seatPreference = 'seat_preference',
  preferredDepartureAirport = 'preferred_departure_airport',
  additionalRequest = 'additional_request',
  bookingInfo = 'booking_info',
  isBookOwnFlights = 'is_book_own_flights',
  otherSecondChoiceAirline = 'other_second_choice_airline',
  otherPreferredAirline = 'other_preferred_airline',
  status = 'status',
  arrivalDate = 'arrival_date',
  departureDate = 'departure_date',
  dateSent = 'dateSent',
  driverLicenseFirstName = 'driver_license_first_name',
  driverLicenseMiddleName = 'driver_license_middle_name',
  driverLicenseLastName = 'driver_license_last_name',
  driverLicenseSuffix = 'driver_license_suffix',
  driverLicenseNameConfirmation = 'driver_license_name_confirmation',
  vendorFirstName = 'vendor_first_name',
  vendorLastName = 'vendor_last_name',
}

export class FlightInformationModel extends BaseModel {
  [FlightInfoKeys.sameForAll]?: boolean;
  [FlightInfoKeys.travelMode]?: TravelMode;
  [FlightInfoKeys.equalTravelMode]?: boolean;
  [FlightInfoKeys.travelDatesDiffer]?: boolean;
  [FlightInfoKeys.travelDatesDifferRequestOutcome]?: RequestOutcome;
  [FlightInfoKeys.prefix]?: LookupId<'Prefixes'>;
  [FlightInfoKeys.firstName]?: string = '';
  [FlightInfoKeys.middleName]?: string = '';
  [FlightInfoKeys.lastName]?: string = '';
  [FlightInfoKeys.suffix]?: LookupId<'Suffixes'>;
  [FlightInfoKeys.passportNationality]?: string;
  [FlightInfoKeys.passportConfirmation]?: boolean;
  [FlightInfoKeys.passportNameConfirmation]?: boolean;
  [FlightInfoKeys.preferredAirline]?: Airlines;
  [FlightInfoKeys.preferredAirlineLoyaltyNumber]?: string;
  [FlightInfoKeys.secondChoiceAirline]?: Airlines;
  [FlightInfoKeys.secondChoiceAirlineLoyaltyNumber]?: string;
  [FlightInfoKeys.knownTravelerNumber]?: string;
  [FlightInfoKeys.seatPreference]?: SeatPreference;
  [FlightInfoKeys.preferredDepartureAirport]?: string;
  [FlightInfoKeys.additionalRequest]?: string;
  [FlightInfoKeys.bookingInfo]?: FlightBookingModel[];
  [FlightInfoKeys.isBookOwnFlights]?: boolean;
  [FlightInfoKeys.otherSecondChoiceAirline]?: Airlines;
  [FlightInfoKeys.otherPreferredAirline]?: Airlines;
  [FlightInfoKeys.status]?: FlightBookingStatus = FlightBookingStatus.initial;
  [FlightInfoKeys.dateSent]?: Date;
  [FlightInfoKeys.arrivalDate]?: LocalDateTimeString;
  [FlightInfoKeys.departureDate]?: LocalDateTimeString;
  [FlightInfoKeys.driverLicenseFirstName]?: string;
  [FlightInfoKeys.driverLicenseMiddleName]?: string;
  [FlightInfoKeys.driverLicenseLastName]?: string;
  [FlightInfoKeys.driverLicenseSuffix]?: LookupId<'Suffixes'>;
  [FlightInfoKeys.driverLicenseNameConfirmation]?: boolean;
  // to map flight information from the booking company:
  [FlightInfoKeys.vendorFirstName]?: string;
  [FlightInfoKeys.vendorLastName]?: string;
}

export const FLIGHT_INFO_KEYS_LOOKUP = [
  {
    [LookupKeys.value]: FlightInfoKeys.sameForAll,
    [LookupKeys.description]:
      'Is the departure city, state, airport, and preferred airline the same for qualifier and guests?',
  },
  { [LookupKeys.value]: FlightInfoKeys.travelDatesDiffer, [LookupKeys.description]: 'Travel Dates Differ' },
  {
    [LookupKeys.value]: FlightInfoKeys.travelDatesDifferRequestOutcome,
    [LookupKeys.description]: 'Travel Dates Differ Request Outcome',
  },
  { [LookupKeys.value]: FlightInfoKeys.prefix, [LookupKeys.description]: 'Prefix' },
  { [LookupKeys.value]: FlightInfoKeys.firstName, [LookupKeys.description]: 'First Name' },
  { [LookupKeys.value]: FlightInfoKeys.middleName, [LookupKeys.description]: 'Middle Name' },
  { [LookupKeys.value]: FlightInfoKeys.lastName, [LookupKeys.description]: 'Last Name' },
  { [LookupKeys.value]: FlightInfoKeys.suffix, [LookupKeys.description]: 'Suffix' },
  { [LookupKeys.value]: FlightInfoKeys.passportNationality, [LookupKeys.description]: 'Passport Nationality' },
  { [LookupKeys.value]: FlightInfoKeys.passportConfirmation, [LookupKeys.description]: 'Passport Confirmation' },
  {
    [LookupKeys.value]: FlightInfoKeys.passportNameConfirmation,
    [LookupKeys.description]: 'Passport Name Confirmation',
  },
  { [LookupKeys.value]: FlightInfoKeys.preferredAirline, [LookupKeys.description]: 'Preferred Airline' },
  {
    [LookupKeys.value]: FlightInfoKeys.preferredAirlineLoyaltyNumber,
    [LookupKeys.description]: 'Preferred Airline Loyalty #',
  },
  { [LookupKeys.value]: FlightInfoKeys.secondChoiceAirline, [LookupKeys.description]: 'Second Choice Airline' },
  {
    [LookupKeys.value]: FlightInfoKeys.secondChoiceAirlineLoyaltyNumber,
    [LookupKeys.description]: 'Second Choice Airline Loyalty #',
  },
  { [LookupKeys.value]: FlightInfoKeys.knownTravelerNumber, [LookupKeys.description]: 'Known Traveler #' },
  { [LookupKeys.value]: FlightInfoKeys.seatPreference, [LookupKeys.description]: 'Seat Preference' },
  {
    [LookupKeys.value]: FlightInfoKeys.preferredDepartureAirport,
    [LookupKeys.description]: 'Preferred Departure Airport',
  },
  { [LookupKeys.value]: FlightInfoKeys.additionalRequest, [LookupKeys.description]: 'Additional Request' },
  { [LookupKeys.value]: FlightInfoKeys.bookingInfo, [LookupKeys.description]: 'Booking Info' },
  { [LookupKeys.value]: FlightInfoKeys.isBookOwnFlights, [LookupKeys.description]: 'Is Book Own Flights' },
  { [LookupKeys.value]: FlightInfoKeys.otherSecondChoiceAirline, [LookupKeys.description]: 'Other Airline' },
  { [LookupKeys.value]: FlightInfoKeys.otherPreferredAirline, [LookupKeys.description]: 'Other Preferred Airline' },
  { [LookupKeys.value]: FlightInfoKeys.status, [LookupKeys.description]: 'Status' },
  { [LookupKeys.value]: FlightInfoKeys.dateSent, [LookupKeys.description]: 'Date Sent' },
  { [LookupKeys.value]: FlightInfoKeys.arrivalDate, [LookupKeys.description]: 'Arrival Date' },
  { [LookupKeys.value]: FlightInfoKeys.departureDate, [LookupKeys.description]: 'Departure Date' },
  { [LookupKeys.value]: FlightInfoKeys.driverLicenseFirstName, [LookupKeys.description]: 'Driver License First Name' },
  {
    [LookupKeys.value]: FlightInfoKeys.driverLicenseMiddleName,
    [LookupKeys.description]: 'Driver License Middle Name',
  },
  { [LookupKeys.value]: FlightInfoKeys.driverLicenseLastName, [LookupKeys.description]: 'Driver License Last Name' },
  { [LookupKeys.value]: FlightInfoKeys.driverLicenseSuffix, [LookupKeys.description]: 'Driver License Suffix' },
  {
    [LookupKeys.value]: FlightInfoKeys.driverLicenseNameConfirmation,
    [LookupKeys.description]: 'Driver License Name Confirmation',
  },
  {
    [LookupKeys.value]: FlightInfoKeys.travelMode,
    [LookupKeys.description]: 'Flying or Driving',
  },
  {
    [LookupKeys.value]: FlightInfoKeys.equalTravelMode,
    [LookupKeys.description]: 'Is travel mode the same for you and your guests?',
  },
];
