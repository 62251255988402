import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import {
  Messages,
  SocialKeys,
  Patterns,
  Social,
  CampaignSocialMedia,
  SOCIAL_MEDIA_LOOKUP_VALUE,
} from '@ag-common-lib/public-api';
import { LookupsService } from 'ag-common-svc/public-api';
import { Observable } from 'rxjs';
import { AgentCampaignSocialMediaService } from './agent-campaign-social-media.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DxFormComponent } from 'devextreme-angular';
import { validateUrlWithOrWithoutProtocol } from 'ag-common-svc/lib/utils/validation';

@UntilDestroy()
@Component({
  selector: 'ag-shr-agent-campaign-social-media',
  templateUrl: './agent-campaign-social-media.component.html',
  styleUrls: ['./agent-campaign-social-media.component.scss'],
})
export class AgentCampaignSocialMediaComponent {
  @HostBinding('class') className = 'agent-campaign-social-media agent-campaign-wizard__content-container';
  @ViewChild('socialsMediaFormRef') socialsMediaFormComponent: DxFormComponent;

  @Input() hasStepIssues: boolean;
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;

  inProgress$: Observable<boolean>;
  agentSocialsDataSource$: Observable<Social[]>;
  socialMediaFormData: Partial<CampaignSocialMedia>;
  hasFormChanges$: Observable<boolean>;

  protected readonly Messages = Messages;
  protected readonly SocialKeys = SocialKeys;
  protected readonly socialMediaLookupValue = SOCIAL_MEDIA_LOOKUP_VALUE;

  constructor(
    private readonly lookupsService: LookupsService,
    private agentCampaignSocialMediaService: AgentCampaignSocialMediaService,
  ) {
    this.inProgress$ = agentCampaignSocialMediaService.inProgress$;
    this.hasFormChanges$ = agentCampaignSocialMediaService.hasFormChanges$;
    this.agentSocialsDataSource$ = agentCampaignSocialMediaService.agentSocialsDataSource$;

    agentCampaignSocialMediaService.formData$.pipe(untilDestroyed(this)).subscribe(formData => {
      this.socialMediaFormData = formData;
    });
  }

  saveSocials = async (socials: Social[]) => {
    return socials;
  };

  validateUrlWithOrWithoutProtocol = e => validateUrlWithOrWithoutProtocol(e?.value);

  nextStep = () => {
    const validationResults = this.socialsMediaFormComponent.instance.validate();

    if (!validationResults.isValid) {
      return;
    }
    this.agentCampaignSocialMediaService.handleNextStepClick();
  };

  onCustomItemCreating = this.agentCampaignSocialMediaService.handleCustomItemCreating;

  resetChanges = this.agentCampaignSocialMediaService.revertAllChanges;
}
