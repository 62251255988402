import { Pipe, PipeTransform } from '@angular/core';
import { FunctionsNames } from '@ag-common-lib/public-api';
import { CloudFunctionsService } from 'ag-common-svc/lib/services/cloud-functions.service';

@Pipe({
  name: 'htmlEditorFileDirectory',
})
export class HtmlEditorFileDirectoryPipe implements PipeTransform {
  constructor(private cloudFunctionsService: CloudFunctionsService) {}
  transform(fileDirectory: string = 'html-editor'): string {
    const url = [this.cloudFunctionsService.getFunctionUrl(FunctionsNames.getMedia), fileDirectory].join('/'); //`${this.cloudFunctionsService.getFunctionUrl(FunctionsNames.getMedia)}/${path}`;

    const queryParams = new URLSearchParams();

    return [url, queryParams.toString()].filter(Boolean).join('?');
  }
}
