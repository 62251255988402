<p class="editor__data-grid--empty" *ngIf="!isEditable && !carriers?.length; else dataGridTmp">
  {{ emptyMessage }}
</p>

<ng-template #dataGridTmp>
  <dx-data-grid
    #agencyCarriersGridRef
    class="editor editor__data-grid"
    [class.editor__data-grid--view]="!isEditable"
    [dataSource]="carriers"
    [showRowLines]="isEditable"
    [showBorders]="isEditable"
    [showColumnLines]="isEditable"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="isEditable"
    [rowAlternationEnabled]="false"
    [noDataText]="emptyMessage"
    (onRowRemoving)="onRowRemoving($event)"
    height="100%"
  >
    <dxo-toolbar [visible]="isEditable">
      <dxi-item
        *ngIf="Entity.agencyAgencyInfoCarriers | hasPermission : EntityPermissionActivityKeys.create | async"
        location="after"
        widget="dxButton"
        [options]="{ icon: 'plus', onClick: showAddPopup }"
      ></dxi-item>
    </dxo-toolbar>

    <dxo-editing
      mode="popup"
      [useIcons]="true"
      [allowUpdating]="false"
      [allowDeleting]="Entity.agencyAgencyInfoCarriers | hasPermission : EntityPermissionActivityKeys.delete | async"
      [allowAdding]="false"
    >
    </dxo-editing>

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>

    <dxi-column [dataField]="AgencyCarrierModelKeys.carrier" caption="Carrier">
      <dxo-lookup
        [dataSource]="carriersLookup$ | async"
        [displayExpr]="CarrierModelKeys.carrierName"
        [valueExpr]="BaseModelKeys.dbId"
      ></dxo-lookup>

      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_TEXT"></dxi-validation-rule>
    </dxi-column>

    <dxi-column [dataField]="AgencyCarrierModelKeys.name" caption="Name"></dxi-column>

    <dxi-column [dataField]="AgencyCarrierModelKeys.writingNumber" caption="Writing Number"></dxi-column>

    <dxi-column [dataField]="AgencyCarrierModelKeys.emailAddress" caption="Email Address">
      <dxi-validation-rule type="email" [message]="Messages.INVALID_EMAIL_FORMAT_TEXT"></dxi-validation-rule>
    </dxi-column>

    <dxi-column [dataField]="AgencyCarrierModelKeys.individualOrCorp" [caption]="'Individual / Corp'">
      <dxo-lookup
        [dataSource]="carrierOrganizationLookup"
        [displayExpr]="'description'"
        [valueExpr]="'value'"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="AgencyCarrierModelKeys.lineOfBusiness" [caption]="'Line Of Business'">
      <dxo-lookup
        [dataSource]="carrierLineOfBusinessLookup"
        [displayExpr]="'description'"
        [valueExpr]="'value'"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="AgencyCarrierModelKeys.isActive" dataType="boolean" [caption]="'Active'"></dxi-column>

    <dxi-column
      [dataField]="AgencyCarrierModelKeys.firstContracted"
      caption="First Contracted"
      dataType="date"
      [sortOrder]="'desc'"
      [format]="dateFormat"
    ></dxi-column>

    <dxi-column type="buttons" [visible]="isEditable">
      <dxi-button
        [visible]="Entity.agencyAgencyInfoCarriers | hasPermission : EntityPermissionActivityKeys.write | async"
        icon="edit"
        [onClick]="showEditPopup"
      ></dxi-button>
      <dxi-button
        [visible]="Entity.agencyAgencyInfoCarriers | hasPermission : EntityPermissionActivityKeys.delete | async"
        name="delete"
      ></dxi-button>
    </dxi-column>
  </dx-data-grid>
</ng-template>

<ag-shr-agency-carriers-modal
  *ngIf="isEditable"
  #agencyCarrierModalRef
  [carriersLookup]="carriersLookup$ | async"
  (carrierChange)="carrierChange($event)"
></ag-shr-agency-carriers-modal>
