import { LookupId } from '../../lists/lookups.enum';
import { LookupKeys } from '../crm/lookup.model';

export enum TShirtSizesKeys {
  tShirtSize = 'tShirtSize',
  tShirtSizeOther = 'tShirtSizeOther',
  unisexTShirtSize = 'unisexTShirtSize',
  unisexTShirtSizeOther = 'unisexTShirtSizeOther',
}

export const TShirtSizesMap = {
  [TShirtSizesKeys.tShirtSize]: 'T-Shirt Size',
  [TShirtSizesKeys.tShirtSizeOther]: 'T-Shirt Size Other',
  [TShirtSizesKeys.unisexTShirtSize]: 'Unisex T-Shirt Size',
  [TShirtSizesKeys.unisexTShirtSizeOther]: 'Unisex T-Shirt Size Other',
};

export class TShirtSizes {
  [TShirtSizesKeys.tShirtSize]?: LookupId<'TShirtSize'> = null;
  [TShirtSizesKeys.tShirtSizeOther]?: string = null;
  [TShirtSizesKeys.unisexTShirtSize]?: LookupId<'TShirtSize'> = null;
  [TShirtSizesKeys.unisexTShirtSizeOther]?: string = null;
}

export const TSHIRT_SIZES_LOOKUP = [
  TShirtSizesKeys.tShirtSize,
  TShirtSizesKeys.tShirtSizeOther,
  TShirtSizesKeys.unisexTShirtSize,
  TShirtSizesKeys.unisexTShirtSizeOther,
].map(value => {
  return {
    [LookupKeys.value]: value,
    [LookupKeys.description]: TShirtSizesMap[value],
  };
});
