export enum RegistrantFieldKeys {
  id = 'id',
  name = 'name',
  label = 'label',
  dataType = 'datatype',
  options = 'options',
}

// 'name' is using for saving data in 'registrants' db
export class RegistrantField {
  [RegistrantFieldKeys.id]: string;
  [RegistrantFieldKeys.name]: string;
  [RegistrantFieldKeys.label]: string;
  [RegistrantFieldKeys.dataType]: string;
  [RegistrantFieldKeys.options]?: string[] = [];
}
