import { Pipe, PipeTransform } from '@angular/core';
import { Agency } from '@ag-common-lib/public-api';
import { AgentService } from 'ag-common-svc/public-api';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

@Pipe({ name: 'agentNameByDbId' })
export class AgentNameByDbIdePipe implements PipeTransform {
  private _agentId$ = new BehaviorSubject(null);
  agentFullName$ = this._agentId$.pipe(
    filter(Boolean),
    mergeMap(agentDbId => {
      if (!agentDbId) {
        return '';
      }
      return this.agentService.getDocument(agentDbId).pipe(
        map(agentSnapshot => {
          if (agentSnapshot.exists()) {
            const data = agentSnapshot.data();
            return [data?.p_agent_first_name, data?.p_agent_last_name].filter(Boolean).join(' ');
          }
          return '';
        }),
      );
    }),
  );

  constructor(private agentService: AgentService) {}

  transform(agentDbId: string, converter?: (agency: Agency) => string): Observable<string> {
    this._agentId$.next(agentDbId);

    return this.agentFullName$;
  }
}
