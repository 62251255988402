import { AgencyKeys, AGENT_TYPE_LOOKUP, AgentKeys, BaseModelKeys, LookupKeys } from '@ag-common-lib/public-api';
import { Injectable } from '@angular/core';
import { AgencyService } from '../agency.service';
import { Field } from 'devextreme/ui/filter_builder';
import { DxFilterOperators } from '@ag-common-lib/lib';
import { AgentService } from '../agent.service';

@Injectable({ providedIn: 'root' })
export class AgentGridFieldsService {
  fieldsCaptionMap = {
    [AgentKeys.agent_type]: 'Agent Type',
    [AgentKeys.p_agency_id]: 'Agency',
    [AgentKeys.p_mga_id]: 'MGA',
    [AgentKeys.is_manager]: 'Is Manager',
    [AgentKeys.is_rmd]: 'Is RMD',
    [BaseModelKeys.dbId]: 'Agent',
  };
  private _agentTypeField: Field = {
    dataField: AgentKeys.agent_type,
    caption: this.fieldsCaptionMap[AgentKeys.agent_type],
    lookup: {
      dataSource: AGENT_TYPE_LOOKUP,
      valueExpr: LookupKeys.value,
      displayExpr: LookupKeys.description,
    },
  };

  private _agencyField: Field = {
    dataField: AgentKeys.p_agency_id,
    caption: this.fieldsCaptionMap[AgentKeys.p_agency_id],
    lookup: {
      dataSource: this.agencyService.getAgencyCustomStore(),
      valueExpr: BaseModelKeys.dbId,
      displayExpr: AgencyKeys.agencyId,
    },
    editorOptions: {
      searchEnabled: true,
    },
  };

  private _mgaField: Field = {
    dataField: AgentKeys.p_mga_id,
    caption: this.fieldsCaptionMap[AgentKeys.p_mga_id],
    lookup: {
      dataSource: this.agencyService.getMGACustomStore(),
      valueExpr: BaseModelKeys.dbId,
      displayExpr: AgencyKeys.agencyId,
    },
    editorOptions: {
      searchEnabled: true,
    },
  };

  private _isManagerField: Field = {
    dataField: AgentKeys.is_manager,
    caption: this.fieldsCaptionMap[AgentKeys.is_manager],
    dataType: 'boolean',
    trueText: 'Yes',
    falseText: 'No',
  };

  private _isRMDField: Field = {
    dataField: AgentKeys.is_rmd,
    caption: this.fieldsCaptionMap[AgentKeys.is_rmd],
    dataType: 'boolean',
    trueText: 'Yes',
    falseText: 'No',
  };

  private _agentField: Field = {
    dataField: BaseModelKeys.dbId,
    caption: this.fieldsCaptionMap[BaseModelKeys.dbId],
    filterOperations: [DxFilterOperators.doNotEqual],
    lookup: {
      dataSource: this.agentService.getAgentCustomStore(),
      valueExpr: BaseModelKeys.dbId,
      displayExpr: AgentKeys.p_agent_name,
    },
  };

  fields: Field[] = [
    this._agentTypeField,
    this._agencyField,
    this._mgaField,
    this._isManagerField,
    this._isRMDField,
    this._agentField,
  ];

  constructor(
    private agencyService: AgencyService,
    private agentService: AgentService,
  ) {}

  getDisplayValue = async (field: AgentKeys | BaseModelKeys, value: any): Promise<string> => {
    switch (field) {
      case AgentKeys.p_agency_id:
      case AgentKeys.p_mga_id:
        return this.agencyService.getAgencyDisplayValue(value);
      case BaseModelKeys.dbId:
        return this.agentService.getAgentDisplayValue(value);
    }
    return value;
  };
}
