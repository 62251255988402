<ag-shr-agent-campaign-summary-web-site [stepData]="agentCampaignSummary?.[AgentCampaignStepName.webSite]"></ag-shr-agent-campaign-summary-web-site>

<ag-shr-agent-campaign-summary-contact-info
  [stepDataAddress]="agentCampaignSummary?.[AgentCampaignStepName.contactInfoAddress]"
  [stepDataPhoneNumber]="agentCampaignSummary?.[AgentCampaignStepName.contactInfoPhoneNumber]"
  [stepDataEmailAddress]="agentCampaignSummary?.[AgentCampaignStepName.contactInfoEmailAddress]"
></ag-shr-agent-campaign-summary-contact-info>

<ag-shr-agent-campaign-summary-social-media [stepData]="agentCampaignSummary?.[AgentCampaignStepName.socialMedia]"></ag-shr-agent-campaign-summary-social-media>

<ag-shr-agent-campaign-summary-bio
  class="agent-campaign-summary-list__bio"
  [stepData]="agentCampaignSummary?.[AgentCampaignStepName.bio]"
></ag-shr-agent-campaign-summary-bio>

<ag-shr-agent-campaign-summary-logo-headshot
  [stepData]="agentCampaignSummary?.[AgentCampaignStepName.logoHeadshot]"
  [title]="'Logo Image'"
  [imageKey]="'logo'"
  [canEdit]="canEdit"
></ag-shr-agent-campaign-summary-logo-headshot>

<ag-shr-agent-campaign-summary-logo-headshot
  [stepData]="agentCampaignSummary?.[AgentCampaignStepName.logoHeadshot]"
  [title]="'Headshot'"
  [imageKey]="'headshot'"
  [canEdit]="canEdit"
></ag-shr-agent-campaign-summary-logo-headshot>

<ag-shr-agent-campaign-summary-logo-headshot
  [stepData]="agentCampaignSummary?.[AgentCampaignStepName.logoHeadshot]"
  [title]="'Favicon'"
  [imageKey]="'favicon'"
  [canEdit]="canEdit"
></ag-shr-agent-campaign-summary-logo-headshot>

<ag-shr-agent-campaign-summary-logo-headshot
  [stepData]="agentCampaignSummary?.[AgentCampaignStepName.logoHeadshot]"
  [title]="'Social Sharing Image'"
  [imageKey]="'socialSharingImage'"
  [canEdit]="canEdit"
></ag-shr-agent-campaign-summary-logo-headshot>

<ag-shr-agent-campaign-summary-appointment [stepData]="agentCampaignSummary?.[AgentCampaignStepName.appointment]"></ag-shr-agent-campaign-summary-appointment>

<ag-shr-agent-campaign-summary-extra [stepData]="agentCampaignSummary?.[AgentCampaignStepName.extras]"></ag-shr-agent-campaign-summary-extra>
