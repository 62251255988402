import { NgModule } from '@angular/core';
import { AgentCampaignSummaryListComponent } from './agent-campaign-summary-list.component';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ButtonWithIndicatorModule } from '../button-with-indicator/button-with-indicator.module';
import { AgentCampaignSummaryListItemComponent } from './agent-campaign-summary-list-item/agent-campaign-summary-list-item.component';
import { AgentCampaignSummaryListDataComponent } from './agent-campaign-summary-list-data/agent-campaign-summary-list-data.component';
import { AgentCampaignSummaryWebSiteComponent } from './agent-campaign-summary-web-site/agent-campaign-summary-web-site.component';
import { AgentCampaignSummaryContactInfoComponent } from './agent-campaign-summary-contact-info/agent-campaign-summary-contact-info.component';
import { AgentCampaignSummaryExtraComponent } from './agent-campaign-summary-extra/agent-campaign-summary-extra.component';
import { AgentCampaignSummaryAppointmentComponent } from './agent-campaign-summary-appointment/agent-campaign-summary-appointment.component';
import { AgentCampaignSummarySocialMediaComponent } from './agent-campaign-summary-social-media/agent-campaign-summary-social-media.component';
import { ClipboardButtonModule } from './clipboard-button/clipboard-button.module';
import { AgentCampaignSummaryBioComponent } from './agent-campaign-summary-bio/agent-campaign-summary-bio.component';
import { AgentCampaignSummaryContactInfoAddressCopyOptionsPipe } from './agent-campaign-summary-contact-info/agent-campaign-summary-contact-info-address-copy-options.pipe';
import { AgentCampaignSummaryLogoHeadshotComponent } from './agent-campaign-summary-logo-headshot/agent-campaign-summary-logo-headshot.component';
import { ImageWithLoaderModule } from '../image-with-loader/image-with-loader.module';
import { DxDropDownButtonModule } from 'devextreme-angular';

@NgModule({
  declarations: [
    AgentCampaignSummaryListComponent,
    AgentCampaignSummaryListItemComponent,
    AgentCampaignSummaryListDataComponent,
    AgentCampaignSummaryWebSiteComponent,
    AgentCampaignSummaryContactInfoComponent,
    AgentCampaignSummaryExtraComponent,
    AgentCampaignSummaryAppointmentComponent,
    AgentCampaignSummarySocialMediaComponent,
    AgentCampaignSummaryBioComponent,
    AgentCampaignSummaryContactInfoAddressCopyOptionsPipe,
    AgentCampaignSummaryLogoHeadshotComponent,
  ],
  imports: [
    SharedModule,
    ButtonWithIndicatorModule,
    ClipboardButtonModule,
    ImageWithLoaderModule,
    DxDropDownButtonModule,
  ],
  exports: [
    AgentCampaignSummaryListComponent,
    AgentCampaignSummaryWebSiteComponent,
    AgentCampaignSummaryContactInfoComponent,
    AgentCampaignSummarySocialMediaComponent,
    AgentCampaignSummaryAppointmentComponent,
    AgentCampaignSummaryExtraComponent,
    AgentCampaignSummaryListItemComponent,
    AgentCampaignSummaryListDataComponent,
    AgentCampaignSummaryBioComponent,
    AgentCampaignSummaryLogoHeadshotComponent,
  ],
})
export class AgentCampaignSummaryListModule {}
