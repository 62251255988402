import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionsHistoryComponent } from './transactions-history.component';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxTreeListModule,
} from 'devextreme-angular';
import { PipesModule } from '../../../shared/pipes/pipes.module';
import { AttendeeTransactionModalModule } from '../attendee-transaction-modal/attendee-transactions.module';
import { ModalWindowModule } from '../modal-window/modal-window.module';
import { AttendeeRefundPaymentModalModule } from '../attendee-refund-payment-modal/attendee-refund-payment-modal.module';
import { AttendeeTransactionDetailsModalModule } from '../attendee-transaction-details-modal/attendee-transaction-details-modal.module';
import { AttendeeManualRefundPaymentModalModule } from '../attendee-manual-refund-payment-modal/attendee-manual-refund-payment-modal.module';

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    PipesModule,
    DxCheckBoxModule,
    AttendeeTransactionModalModule,
    AttendeeRefundPaymentModalModule,
    AttendeeTransactionDetailsModalModule,
    AttendeeManualRefundPaymentModalModule,
    DxPopupModule,
    DxFormModule,
    DxButtonModule,
    ModalWindowModule,
    DxTreeListModule,
  ],
  declarations: [TransactionsHistoryComponent],
  exports: [TransactionsHistoryComponent],
})
export class TransactionsHistoryModule {}
