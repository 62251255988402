import { Pipe, PipeTransform } from '@angular/core';
import { FunctionsNames } from '@ag-common-lib/public-api';
import { MediaSize } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib';
import { CloudFunctionsService } from '../../lib/services/cloud-functions.service';

@Pipe({
  name: 'wasabiArchivedImages',
})
export class WasabiArchivedImagesPipe implements PipeTransform {
  constructor(private cloudFunctionsService: CloudFunctionsService) {}
  transform(paths: string[], mediaSize: MediaSize = MediaSize.origin, optimized: boolean = false): string {
    if (!paths?.length) {
      return null;
    }

    const queryParams = new URLSearchParams();
    paths.forEach(path => {
      queryParams.append('wasabiPaths', path);
    });

    queryParams.append('mediaSize', mediaSize);

    if (optimized) {
      queryParams.append('optimized', 'true');
    }

    const functionUrl = this.cloudFunctionsService.getFunctionUrl(FunctionsNames.getArchivedImages);
    return `${functionUrl}?${queryParams.toString()}`;
  }
}
