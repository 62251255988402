import { NotifyEmail } from './notify-email.model';

export enum ExcursionConfigurationsEmailKeys {
  excursions = 'excursions',
  travelDates = 'travelDates',
  hotelCheckInDate = 'hotelCheckInDate',
  hotelCheckOutDate = 'hotelCheckOutDate',
}

export enum ExcursionConfigurationsEmailGroupKeys {
  excursionAttendee = 'excursionAttendee',
  excursionName = 'excursionName',
  excursionPreferences = 'excursionPreferences',
}

export enum ExcursionConfigurationsEmailKeys {
  excursionsGroupedDate = 'excursionsGroupedDate',
  excursionsGroup = 'excursionsGroup',
}

export interface ExcursionConfigurationsEmailGroup {
  [ExcursionConfigurationsEmailGroupKeys.excursionAttendee]?: string;
  [ExcursionConfigurationsEmailGroupKeys.excursionName]?: string;
  [ExcursionConfigurationsEmailGroupKeys.excursionPreferences]?: string;
}

export interface ExcursionConfigurationsEmail {
  [ExcursionConfigurationsEmailKeys.excursionsGroupedDate]?: string;
  [ExcursionConfigurationsEmailKeys.excursionsGroup]?: ExcursionConfigurationsEmailGroup[];
}

export interface SummaryConfigurationsEmailExcursion extends NotifyEmail {
  [ExcursionConfigurationsEmailKeys.excursions]?: ExcursionConfigurationsEmail[];
  [ExcursionConfigurationsEmailKeys.travelDates]?: string;
  [ExcursionConfigurationsEmailKeys.hotelCheckInDate]?: string;
  [ExcursionConfigurationsEmailKeys.hotelCheckOutDate]?: string;
}
