import { Pipe, PipeTransform } from '@angular/core';
import { Excursion, ExcursionKeys, ExcursionConfiguration } from '@ag-common-lib/public-api';

@Pipe({ name: 'excursionConfigurations' })
export class ExcursionConfigurationsPipe implements PipeTransform {
  transform(excursionId: string, conferenceExcursions: Excursion[]): ExcursionConfiguration[] {
    const selectedExcursion = conferenceExcursions?.find(excursion => excursion?.[ExcursionKeys.id] === excursionId);

    return selectedExcursion?.[ExcursionKeys.configuration];
  }
}
