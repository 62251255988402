import { ConferenceKeys } from './conference.model';
import { LookupKeys } from '../crm/lookup.model';
import { ConferenceRegistrationStepName, RegistrantKeys } from './registrants.model';

export enum ConferenceStepsTemplateName {
  noTemplate,
  customTemplate,
  eighteenthAnnualAllianceGroupLeadersConference,
}

export const ConferenceStepsTemplateNameMap = new Map<ConferenceStepsTemplateName, string>([
  [ConferenceStepsTemplateName.customTemplate, 'Custom Template'],
  [ConferenceStepsTemplateName.noTemplate, 'No Template'],
  [
    ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    '18th Annual Alliance Group Leaders Conference',
  ],
]);

export const CONFERENCE_STEPS_TEMPLATE_NAME_LOOKUP = [
  {
    [LookupKeys.value]: ConferenceStepsTemplateName.noTemplate,
    [LookupKeys.description]: ConferenceStepsTemplateNameMap.get(ConferenceStepsTemplateName.noTemplate),
  },
  {
    [LookupKeys.value]: ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    [LookupKeys.description]: ConferenceStepsTemplateNameMap.get(
      ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    ),
  },
  {
    [LookupKeys.value]: ConferenceStepsTemplateName.customTemplate,
    [LookupKeys.description]: ConferenceStepsTemplateNameMap.get(ConferenceStepsTemplateName.customTemplate),
  },
];

export enum ConferenceGuestsStepsConfigurationSectionName {
  shirtSizeSection = 'shirtSizeSection',
  dietaryConsiderationsSection = 'dietaryConsiderationsSection',
}

export const ConferenceGuestsStepsConfigurationSectionNameMap = new Map<
  ConferenceGuestsStepsConfigurationSectionName,
  string
>([
  [ConferenceGuestsStepsConfigurationSectionName.shirtSizeSection, 'Shirt Size'],
  [ConferenceGuestsStepsConfigurationSectionName.dietaryConsiderationsSection, 'Dietary Considerations'],
]);

export const ConferencePersonalStepsConfigurationSectionNameMap = new Map<ConferenceRegistrationStepName, string>([
  [ConferenceRegistrationStepName.registrantPersonalInformationEmailAddressStep, 'Email Address'],
  [ConferenceRegistrationStepName.registrantPersonalInformationAddressStep, 'Address'],
  [ConferenceRegistrationStepName.registrantPersonalInformationPhoneNumberStep, 'Phone Number'],
  [ConferenceRegistrationStepName.registrantPersonalInformationShirtSizeStep, 'Shirt Size'],
  [ConferenceRegistrationStepName.registrantPersonalInformationHeadshotStep, 'Headshot'],
  [ConferenceRegistrationStepName.registrantPersonalInformationDietaryConsiderationsStep, 'Dietary Considerations'],
  [ConferenceRegistrationStepName.registrantPersonalInformationEmergencyContactStep, 'Emergency Contact'],
]);

export enum ConferenceStepsConfigurationKeys {
  title = 'title',
  stepName = 'stepName',
  template = 'template',
  htmlContent = 'htmlContent',
  fields = 'fields',
  excludedSections = 'excludedSections',
  excludedFields = 'excludedFields',

  rsvpAttendingLabel = 'rsvpAttendingText',
  sentPlaqueLabel = 'sentPlaqueText',
  awardNameLabel = 'awardNameLabel',
  shippingAddressConfirmationLabel = 'shippingAddressConfirmationLabel',
  hotelInfoAdditionalInfoLabel = 'hotelInfoAdditionalInfoLabel',
  flightInfoRegistrantNameLabel = 'flightInfoRegistrantNameLabel',
  flightInfoDriverLicenseNameLabel = 'flightInfoDriverLicenseNameLabel',

  enableControlVisible = 'enableControlVisible',
}

export class ConferenceBaseStepConfiguration {
  [ConferenceStepsConfigurationKeys.stepName]: ConferenceRegistrationStepName;
  [ConferenceStepsConfigurationKeys.title]: string;
  [ConferenceStepsConfigurationKeys.template]: ConferenceStepsTemplateName = ConferenceStepsTemplateName.noTemplate;

  constructor(stepName: ConferenceRegistrationStepName, title: string) {
    this[ConferenceStepsConfigurationKeys.stepName] = stepName;
    this[ConferenceStepsConfigurationKeys.title] = title;
  }
}

export class ConferenceRsvpStepConfiguration extends ConferenceBaseStepConfiguration {
  [ConferenceStepsConfigurationKeys.excludedFields]: RegistrantKeys[] = []; // TODO pick only rsvp fields
  [ConferenceStepsConfigurationKeys.shippingAddressConfirmationLabel]: string = null;
  [ConferenceStepsConfigurationKeys.rsvpAttendingLabel]: string = null;
  [ConferenceStepsConfigurationKeys.sentPlaqueLabel]: string = null;
  [ConferenceStepsConfigurationKeys.awardNameLabel]: string = null;

  constructor() {
    super(ConferenceRegistrationStepName.rsvpStep, 'RSVP');
  }
}

export class ConferenceRegistrantPersonalInformationStepConfiguration extends ConferenceBaseStepConfiguration {
  [ConferenceStepsConfigurationKeys.excludedFields]: RegistrantKeys[] = []; // TODO pick only personal info fields
  [ConferenceStepsConfigurationKeys.excludedSections]: ConferenceRegistrationStepName[] = [];

  constructor() {
    super(ConferenceRegistrationStepName.registrantPersonalInformationStep, 'Personal Information');
  }
}

export class ConferenceGuestRegistrationStepConfiguration extends ConferenceBaseStepConfiguration {
  [ConferenceStepsConfigurationKeys.excludedFields]: RegistrantKeys[] = []; // TODO pick only guest registration fields
  [ConferenceStepsConfigurationKeys.excludedSections]: ConferenceGuestsStepsConfigurationSectionName[] = [];

  constructor() {
    super(ConferenceRegistrationStepName.registrantGuestConfigurationStep, 'Guest Registration');
  }
}

export class ConferenceRegistrantHotelReservationStepConfiguration extends ConferenceBaseStepConfiguration {
  [ConferenceStepsConfigurationKeys.excludedFields]: RegistrantKeys[] = []; // TODO pick only hotels reservation fields

  constructor() {
    super(ConferenceRegistrationStepName.registrantHotelReservationStep, 'Hotel Reservation');
  }
}

export class ConferenceRegistrantFlightInformationStepConfiguration extends ConferenceBaseStepConfiguration {
  [ConferenceStepsConfigurationKeys.excludedFields]: RegistrantKeys[] = []; // TODO pick only flight information fields
  [ConferenceStepsConfigurationKeys.flightInfoRegistrantNameLabel]: string = null;
  [ConferenceStepsConfigurationKeys.flightInfoDriverLicenseNameLabel]: string = null;

  constructor() {
    super(ConferenceRegistrationStepName.registrantFlightInformationStep, 'Your Flight Information');
  }
}

export class ConferenceGuestFlightInformationStepConfiguration extends ConferenceBaseStepConfiguration {
  [ConferenceStepsConfigurationKeys.excludedFields]: RegistrantKeys[] = []; // TODO pick only flight information fields
  [ConferenceStepsConfigurationKeys.flightInfoRegistrantNameLabel]: string = null;
  [ConferenceStepsConfigurationKeys.flightInfoDriverLicenseNameLabel]: string = null;

  constructor() {
    super(ConferenceRegistrationStepName.registrationGuestFlightInformationStep, 'Guest Flight Information');
  }
}

export class ConferenceRegistrantExcursionsStepConfiguration extends ConferenceBaseStepConfiguration {
  constructor() {
    super(ConferenceRegistrationStepName.registrantExcursionsStep, 'Excursions');
  }
}

export class ConferenceExcursionsSelectionStepConfiguration extends ConferenceBaseStepConfiguration {
  constructor() {
    super(ConferenceRegistrationStepName.registrantExcursionYourSelectionStep, 'Your Selection Excursions');
  }
}
export class ConferencePaymentStepConfiguration extends ConferenceBaseStepConfiguration {
  constructor() {
    super(ConferenceRegistrationStepName.registrantPaymentStep, 'Payment');
  }
}
export class ConferenceConfirmationStepConfiguration extends ConferenceBaseStepConfiguration {
  constructor() {
    super(ConferenceRegistrationStepName.registrationConfirmationStep, 'Confirmation');
  }
}
export class ConferenceSummaryStepConfiguration extends ConferenceBaseStepConfiguration {
  constructor() {
    super(ConferenceRegistrationStepName.registrationSummaryStep, 'Thank you for Registering!');
  }
}
export class ConferenceDeclinedSummaryStepConfiguration extends ConferenceBaseStepConfiguration {
  constructor() {
    super(ConferenceRegistrationStepName.registrationDeclinedSummaryStep, 'Sorry to Miss You!');
  }
}

export class ConferenceStepsConfigurationObject {
  [ConferenceRegistrationStepName.rsvpStep]: ConferenceRsvpStepConfiguration = new ConferenceRsvpStepConfiguration();
  [ConferenceRegistrationStepName.registrantPersonalInformationStep]: ConferenceRegistrantPersonalInformationStepConfiguration =
    new ConferenceRegistrantPersonalInformationStepConfiguration();
  [ConferenceRegistrationStepName.registrantGuestConfigurationStep]: ConferenceGuestRegistrationStepConfiguration =
    new ConferenceGuestRegistrationStepConfiguration();
  [ConferenceRegistrationStepName.registrantHotelReservationStep]: ConferenceRegistrantHotelReservationStepConfiguration =
    new ConferenceRegistrantHotelReservationStepConfiguration();
  [ConferenceRegistrationStepName.registrantFlightInformationStep]: ConferenceRegistrantFlightInformationStepConfiguration =
    new ConferenceRegistrantFlightInformationStepConfiguration();
  [ConferenceRegistrationStepName.registrationGuestFlightInformationStep]: ConferenceGuestFlightInformationStepConfiguration =
    new ConferenceGuestFlightInformationStepConfiguration();
  [ConferenceRegistrationStepName.registrantExcursionYourSelectionStep]: ConferenceRegistrantExcursionsStepConfiguration =
    new ConferenceRegistrantExcursionsStepConfiguration();
  [ConferenceRegistrationStepName.registrantPaymentStep]: ConferenceExcursionsSelectionStepConfiguration =
    new ConferenceExcursionsSelectionStepConfiguration();
  [ConferenceRegistrationStepName.registrationConfirmationStep]: ConferencePaymentStepConfiguration =
    new ConferencePaymentStepConfiguration();
  [ConferenceRegistrationStepName.registrationSummaryStep]: ConferenceSummaryStepConfiguration =
    new ConferenceSummaryStepConfiguration();
  [ConferenceRegistrationStepName.registrationDeclinedSummaryStep]: ConferenceDeclinedSummaryStepConfiguration =
    new ConferenceDeclinedSummaryStepConfiguration();
}

export class ConferenceStepsConfiguration {
  [ConferenceStepsConfigurationKeys.stepName]: ConferenceRegistrationStepName;
  [ConferenceStepsConfigurationKeys.title]?: string;
  [ConferenceStepsConfigurationKeys.template]?: ConferenceStepsTemplateName;
  [ConferenceStepsConfigurationKeys.htmlContent]?: string;
  [ConferenceStepsConfigurationKeys.enableControlVisible]?: boolean;
  [ConferenceStepsConfigurationKeys.excludedSections]?:
    | ConferenceGuestsStepsConfigurationSectionName[]
    | ConferenceRegistrationStepName[];
  [ConferenceStepsConfigurationKeys.excludedFields]?: RegistrantKeys[];
  [ConferenceStepsConfigurationKeys.hotelInfoAdditionalInfoLabel]?: string;
  [ConferenceStepsConfigurationKeys.shippingAddressConfirmationLabel]?: string;
  [ConferenceStepsConfigurationKeys.rsvpAttendingLabel]?: string;
  [ConferenceStepsConfigurationKeys.sentPlaqueLabel]?: string;
  [ConferenceStepsConfigurationKeys.awardNameLabel]?: string;
  [ConferenceStepsConfigurationKeys.flightInfoRegistrantNameLabel]?: string;
  [ConferenceStepsConfigurationKeys.flightInfoDriverLicenseNameLabel]?: string;
}

export const INITIAL_CONFERENCE_STEPS_CONFIGURATION: ConferenceStepsConfiguration[] = [
  {
    [ConferenceStepsConfigurationKeys.stepName]: ConferenceRegistrationStepName.rsvpStep,
    [ConferenceStepsConfigurationKeys.title]: 'RSVP',
    [ConferenceStepsConfigurationKeys.template]: ConferenceStepsTemplateName.noTemplate,
    [ConferenceStepsConfigurationKeys.excludedFields]: [],
    [ConferenceStepsConfigurationKeys.shippingAddressConfirmationLabel]: null,
    [ConferenceStepsConfigurationKeys.rsvpAttendingLabel]: null,
    [ConferenceStepsConfigurationKeys.sentPlaqueLabel]: null,
    [ConferenceStepsConfigurationKeys.awardNameLabel]: null,
  },
  {
    [ConferenceStepsConfigurationKeys.stepName]: ConferenceRegistrationStepName.registrantPersonalInformationStep,
    [ConferenceStepsConfigurationKeys.title]: 'Personal Information',
    [ConferenceStepsConfigurationKeys.template]: ConferenceStepsTemplateName.noTemplate,
    [ConferenceStepsConfigurationKeys.excludedSections]: [],
  },
  {
    [ConferenceStepsConfigurationKeys.stepName]: ConferenceRegistrationStepName.registrantGuestConfigurationStep,
    [ConferenceStepsConfigurationKeys.title]: 'Guest Registration',
    [ConferenceStepsConfigurationKeys.template]: ConferenceStepsTemplateName.noTemplate,
    [ConferenceStepsConfigurationKeys.enableControlVisible]: true,
    [ConferenceStepsConfigurationKeys.excludedSections]: [],
  },
  {
    [ConferenceStepsConfigurationKeys.stepName]: ConferenceRegistrationStepName.registrantHotelReservationStep,
    [ConferenceStepsConfigurationKeys.title]: 'Hotel Reservation',
    [ConferenceStepsConfigurationKeys.template]: ConferenceStepsTemplateName.noTemplate,
    [ConferenceStepsConfigurationKeys.enableControlVisible]: true,
    [ConferenceStepsConfigurationKeys.hotelInfoAdditionalInfoLabel]: null,
  },
  {
    [ConferenceStepsConfigurationKeys.stepName]: ConferenceRegistrationStepName.registrantFlightInformationStep,
    [ConferenceStepsConfigurationKeys.title]: 'Your Flight Information',
    [ConferenceStepsConfigurationKeys.template]: ConferenceStepsTemplateName.noTemplate,
    [ConferenceStepsConfigurationKeys.flightInfoRegistrantNameLabel]: null,
    [ConferenceStepsConfigurationKeys.flightInfoDriverLicenseNameLabel]: null,
  },
  {
    [ConferenceStepsConfigurationKeys.stepName]: ConferenceRegistrationStepName.registrationGuestFlightInformationStep,
    [ConferenceStepsConfigurationKeys.title]: 'Guest Flight Information',
    [ConferenceStepsConfigurationKeys.template]: ConferenceStepsTemplateName.noTemplate,
    [ConferenceStepsConfigurationKeys.flightInfoRegistrantNameLabel]: null,
    [ConferenceStepsConfigurationKeys.flightInfoDriverLicenseNameLabel]: null,
  },
  {
    [ConferenceStepsConfigurationKeys.stepName]: ConferenceRegistrationStepName.registrantExcursionsStep,
    [ConferenceStepsConfigurationKeys.title]: 'Excursions',
    [ConferenceStepsConfigurationKeys.template]: ConferenceStepsTemplateName.noTemplate,
    [ConferenceStepsConfigurationKeys.enableControlVisible]: true,
  },
  {
    [ConferenceStepsConfigurationKeys.stepName]: ConferenceRegistrationStepName.registrantExcursionYourSelectionStep,
    [ConferenceStepsConfigurationKeys.title]: 'Your Selection Excursions',
    [ConferenceStepsConfigurationKeys.template]: ConferenceStepsTemplateName.noTemplate,
  },
  {
    [ConferenceStepsConfigurationKeys.stepName]: ConferenceRegistrationStepName.registrantPaymentStep,
    [ConferenceStepsConfigurationKeys.title]: 'Payment',
    [ConferenceStepsConfigurationKeys.template]: ConferenceStepsTemplateName.noTemplate,
  },
  {
    [ConferenceStepsConfigurationKeys.stepName]: ConferenceRegistrationStepName.registrationConfirmationStep,
    [ConferenceStepsConfigurationKeys.title]: 'Confirmation',
    [ConferenceStepsConfigurationKeys.template]: ConferenceStepsTemplateName.noTemplate,
  },
  {
    [ConferenceStepsConfigurationKeys.stepName]: ConferenceRegistrationStepName.registrationSummaryStep,
    [ConferenceStepsConfigurationKeys.title]: 'Thank you for Registering!',
    [ConferenceStepsConfigurationKeys.template]: ConferenceStepsTemplateName.noTemplate,
  },
  {
    [ConferenceStepsConfigurationKeys.stepName]: ConferenceRegistrationStepName.registrationDeclinedSummaryStep,
    [ConferenceStepsConfigurationKeys.title]: 'Sorry to Miss You!',
    [ConferenceStepsConfigurationKeys.template]: ConferenceStepsTemplateName.noTemplate,
  },
];
