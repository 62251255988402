import { ApproveDenyReasonVisibilityLevel } from '../../lists/approve-deny-reason-visibility-level.enum';
import { BaseModel } from '../base.model';

export enum ApproveDenyReasonKeys {
  activity = 'activity',
  visibilityLevel = 'visibilityLevel',
}

export class ApproveDenyReason extends BaseModel {
  [ApproveDenyReasonKeys.activity]?: string;
  [ApproveDenyReasonKeys.visibilityLevel]: ApproveDenyReasonVisibilityLevel;
}

export class LegacyApproveDenyReason extends BaseModel {
  reason?: string;
}
