<div class="mt-2">
  <ag-shr-editor-toolbar
    [title]="'States Licensed In'"
    (clickEdit)="stateLicenseEditorModalComponent.showModal()"
    [isEditVisible]="
      Entity.agencyAgencyInfoStatesLicensedIn | hasPermission : EntityPermissionActivityKeys.write | async
    "
  ></ag-shr-editor-toolbar>

  <ag-shr-state-licensed-in-grid
    [stateLicenses]="agency[AgencyKeys.stateLicenses]"
    [isEditable]="false"
  ></ag-shr-state-licensed-in-grid>
</div>

<ag-shr-modal-window
  #stateLicenseEditorModalRef
  [title]="'Add / Edit States Licensed In'"
  [showSaveButton]="false"
  [width]="'75%'"
  [height]="'auto'"
  [inProgress]="inProgress$ | async"
>
  <ag-shr-state-licensed-in-grid
    [stateLicenses]="agency[AgencyKeys.stateLicenses] ?? []"
    [agencyId]="agency[BaseModelKeys.dbId]"
    (stateLicensesChange)="onStateLicensesChange($event)"
  ></ag-shr-state-licensed-in-grid>
</ag-shr-modal-window>
