import { BaseModel } from '../base.model';
import { PaymentIntent } from '@stripe/stripe-js';

export enum StripePaymentDetailsKeys {
  id = 'id',
  amount = 'amount',
  currency = 'currency',
  address = 'address',
  status = 'status',
}

export class StripePaymentDetails extends BaseModel {
  id: string;
  amount: number;
  currency: string;
  address: PaymentIntent.Shipping;
  status: PaymentIntent.Status;
}

export enum StripePaymentDetailsKeys {
  agentDbId = 'agentDbId',
  paymentDescription = 'paymentDescription',
}

export class AgentPaymentDetails extends StripePaymentDetails {
  agentDbId: string;
  paymentDescription: string;
}
export enum PaymentGuestSummaryKeys {
  guest = 'guest',
  fullName = 'fullName',
  feeReason = 'feeReason',
  feeAmount = 'feeAmount',
}
//
// export class PaymentGuestSummary {
//   [PaymentGuestSummaryKeys.guest]: GuestData;
//   [PaymentGuestSummaryKeys.fullName]: string;
//   [PaymentGuestSummaryKeys.feeReason]: string;
//   [PaymentGuestSummaryKeys.feeAmount]: number;
// }
