import { Pipe, PipeTransform } from '@angular/core';
import { isRequestedDifferentHotelCheckInCheckOutDates } from 'ag-common-svc/lib/utils/conference-registration.util';

@Pipe({ name: 'isRequestedDifferentHotelCheckInCheckOutDates' })
export class IsRequestedDifferentHotelCheckInCheckOutDatesPipe implements PipeTransform {
  transform(
    arrivalDate: string,
    departureDate: string,
    requestedArrivalDate: string,
    requestedDepartureDate: string,
  ): boolean {
    return isRequestedDifferentHotelCheckInCheckOutDates(
      arrivalDate,
      departureDate,
      requestedArrivalDate,
      requestedDepartureDate,
    );
  }
}
