export interface ScreenLimits {
  xs?: number;
  sm?: number;
  md?: number;
}

/**
 * @deprecated Use FormScreenByWidthPipe instead
 */
export const defaultScreenByWidth = (limits: ScreenLimits = null, width: number) => {
  if (width < (limits?.xs ?? 768)) {
    return 'xs';
  }
  if (width < (limits?.sm ?? 992)) {
    return 'sm';
  }
  if (width < (limits?.md ?? 1200)) {
    return 'md';
  }

  return 'lg';
};
