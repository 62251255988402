export enum RequestOutcomeState {
  approved = 'approved',
  rejected = 'rejected',
}

export const RequestOutcomeStateMap = new Map([
  [RequestOutcomeState.approved, 'Approved'],
  [RequestOutcomeState.rejected, 'Rejected'],
]);

export const requestOutcomeOptions: any[] = [
  {
    icon: 'agi-thumbs-up',
    outcome: RequestOutcomeState.approved,
    hint: 'Approve',
    type: 'success',
  },
  {
    icon: 'agi-thumbs-down',
    outcome: RequestOutcomeState.rejected,
    hint: 'Reject',
    type: 'danger',
  },
];

export enum RequestOutcomeKeys {
  state = 'state',
  processedBy = 'processedBy',
}

export class RequestOutcome {
  [RequestOutcomeKeys.state]: RequestOutcomeState;
  [RequestOutcomeKeys.processedBy]?: string;
}
