import { Pipe, PipeTransform } from '@angular/core';
import { NotificationOptInGroupType } from '@ag-common-lib/lib';
import { AgentGridFieldsService } from 'ag-common-svc/lib/services/agent-grid-fields/agent-grid-fields.service';
import { ConferenceAttendeeGridFieldsService } from 'ag-common-svc/lib/services/agent-grid-fields/conference-attendee-grid-fields.service';

@Pipe({
  name: 'optInGroupFilterBuilderFields',
})
export class OptInGroupFilterBuilderFieldsPipe implements PipeTransform {
  constructor(
    private agentGridFieldsService: AgentGridFieldsService,
    private conferenceAttendeeGridFieldsService: ConferenceAttendeeGridFieldsService,
  ) {}

  transform(optInGroupType: NotificationOptInGroupType): any[] {
    if (!optInGroupType) {
      return [];
    }

    if (optInGroupType === NotificationOptInGroupType.agent) {
      return this.agentGridFieldsService.fields;
    }

    if (optInGroupType === NotificationOptInGroupType.registrant) {
      return this.conferenceAttendeeGridFieldsService.fields;
    }

    return [];
  }
}
