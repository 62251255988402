<ag-shr-modal-window
  #notificationCampaignModalRef
  [width]="'100vw'"
  [height]="'100vh'"
  title="Notification Campaign"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="saveNotificationCampaign()"
  [onCloseModal]="handleClosePopup"
  [useScrollView]="false"
>
  <ng-container *ngIf="notificationCampaignModalRef?.popupComponent?.visible ?? false">
    <dx-form
      class="ag-assign-owner__form"
      [(formData)]="formData"
      labelMode="floating"
      [validationGroup]="validationGroup"
    >
      <dxi-item
        [dataField]="PushNotificationCampaignKeys.title"
        [label]="{ text: 'Campaign Name' }"
        [isRequired]="true"
      ></dxi-item>

      <dxi-item>
        <div *dxTemplate>
          <shr-html-editor
            class="mt-2 notification-campaign-modal__html-editor"
            [name]="PushNotificationCampaignKeys.description"
            [label]="'Campaign Details'"
            [isRequired]="true"
            [fileDirectory]="'push-notification-campaign'"
            [(value)]="formData && formData[PushNotificationCampaignKeys.description]"
          ></shr-html-editor>
        </div>
      </dxi-item>

      <dxi-item caption="Notification Details" [itemType]="'group'" [colCount]="2">
        <dxi-item [itemType]="'group'">
          <dxi-item
            [dataField]="[PushNotificationCampaignKeys.notification, PushNotificationKeys.title] | path"
            [label]="{ text: 'Title' }"
            [isRequired]="true"
          >
            <dxi-validation-rule type="stringLength" [max]="65"></dxi-validation-rule>
          </dxi-item>

          <dxi-item
            [dataField]="[PushNotificationCampaignKeys.notification, PushNotificationKeys.body] | path"
            [label]="{ text: 'Body' }"
            [isRequired]="true"
            [editorType]="'dxTextArea'"
            [editorOptions]="{ height: 100 }"
          >
            <dxi-validation-rule type="stringLength" [max]="240"></dxi-validation-rule>
          </dxi-item>
        </dxi-item>
      </dxi-item>
    </dx-form>
  </ng-container>
</ag-shr-modal-window>
