import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActiveLookup, Association, AssociationKeys, BaseModelKeys, LookupKeys } from '@ag-common-lib/public-api';
import { AgentAssociationsService } from '../../../../services/agent-associations.service';
import { updateDoc } from 'firebase/firestore';
import { BaseFormService } from '../../../../utils/base-form-service';

@Injectable()
export class AssociationFormService extends BaseFormService<Association> {
  public selectedGender$ = new BehaviorSubject(null);
  public selectedRelationshipType$: BehaviorSubject<ActiveLookup> = new BehaviorSubject(null);

  constructor(private readonly agentAssociationsService: AgentAssociationsService) {
    super();
  }

  public saveAssociation = agentId => {
    return this.formData[BaseModelKeys.dbId] ? this.updateAssociation(agentId) : this.createAssociation(agentId);
  };

  public getComparator = key => {
    if (key === 'associationTypeRef') {
      return (value, initialValue) => {
        return value?.id === initialValue?.id;
      };
    }

    return undefined;
  };

  public getFormData = (association?: Partial<Association>) => {
    const initialAssociationDietaryConsideration = Object.assign(
      {},
      association?.[AssociationKeys.dietaryConsideration],
    );
    const initialTShirtSizes = Object.assign({}, association?.[AssociationKeys.tShirtSizes]);

    const associationDietaryConsiderationProxy = new Proxy(initialAssociationDietaryConsideration, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange(
            [AssociationKeys.dietaryConsideration, prop].join('.'),
            value,
            prevValue,
          );
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });
    const associationTShirtSizesProxy = new Proxy(initialTShirtSizes, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange([AssociationKeys.tShirtSizes, prop].join('.'), value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });

    const initialASsociation = Object.assign({}, new Association(), association, {
      [AssociationKeys.dietaryConsideration]: associationDietaryConsiderationProxy,
      [AssociationKeys.tShirtSizes]: associationTShirtSizesProxy,
    });

    this.formData = new Proxy(initialASsociation, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        const comparator = this.getComparator(prop);
        const isEqualToPrev = comparator ? comparator(value, prevValue) : prevValue === value;

        if (!isEqualToPrev) {
          this.formChangesDetector.handleChange(prop, value, prevValue, comparator);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });

    return this.formData;
  };

  private createAssociation = agentId => {
    this.startProgress();
    return this.agentAssociationsService
      .create(agentId, this.formData)
      .then(this.onSuccess)
      .then(() => {
        this.formChangesDetector.clear();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  private updateAssociation = agentId => {
    this.startProgress();
    return this.agentAssociationsService
      .update(agentId, this.formData[BaseModelKeys.dbId], this.formData)
      .then(this.onSuccess)
      .then(() => {
        this.formChangesDetector.clear();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  private onSuccess = () => {
    const assignedLookups = [this.selectedGender$.value, this.selectedRelationshipType$?.value];
    const promises = assignedLookups
      .filter(lookup => lookup && !lookup?.isAssigned)
      .map(lookup => updateDoc(lookup?.reference, { [LookupKeys.isAssigned]: true }));

    return Promise.all(promises);
  };
}
