<div class="image-gallery__empty" *ngIf="!mediaItems?.length; else imageGalleryTmp">
  <i class="dx-icon-agi-picture image-gallery__empty--icon"></i>
  <p class="image-gallery__empty--text">No Elements</p>
</div>

<ng-template #imageGalleryTmp>
  <div class="ag-media-gallery__select-actions">
    <div *ngIf="multipleSelection" class="ag-media-gallery__select-actions--select-all">
        <dx-button
          class="rounded-button"
          [icon]="isAllSelected ? 'check' : ''"
          [stylingMode]="'outlined'"
          [type]="'default'"
          [width]="25"
          [height]="25"
          [useSubmitBehavior]="false"
          (onClick)="selectAll()"
        >
        </dx-button>
        Select All
    </div>

    <div *ngIf="allowActions" class="ag-media-gallery__select-actions--actions">
      <shr-button-with-indicator
        class="ag-media-gallery__select-actions--actions-button"
        (onClick)="downloadSelectedImages()"
        [stylingMode]="'outlined'"
        [title]="'Download'"
        [icon]="'download'"
        [disabled]="_selectedItems.size === 0"
      ></shr-button-with-indicator>
      <!-- <shr-button-with-indicator -->
      <!-- class="select-actions__actions&#45;&#45;button" -->
      <!-- (onClick)="setPrimarySelectedImages()" -->
      <!-- [stylingMode]="'outlined'" -->
      <!-- [disabled]="_selectedItems.size !== 1" -->
      <!-- [title]="'Set as Primary'" -->
      <!-- ></shr-button-with-indicator> -->
    </div>
  </div>
  <dx-scroll-view class="image-scroll-view" [direction]="'vertical'" [showScrollbar]="'onHover'">
    <div class="image-gallery">
      <div class="image-grid">
        <div class="image-item" *ngFor="let image of mediaItems">
          <ag-shr-image-with-loader
            class="ag-media-gallery__image"
            [wasabiPath]="image?.[AGMediaKeys.wasabiPath]"
            [mediaSize]="MediaSize.large"
          ></ag-shr-image-with-loader>

          <div *ngIf="allowZoomIn" class="zoom-icon">
            <dx-button stylingMode="text" icon="agi-zoom-in" (onClick)="zoomImage(image)"></dx-button>
          </div>

          <div
            class="selector-circle"
            [ngClass]="{
              selected: _selectedItems.has(image),
            }"
            (click)="toggleImageSelection(image, $event)"
          ></div>

          <div *ngIf="allowActions" class="image-options">
            <dx-drop-down-button
              class="image-options__button"
              [showArrowIcon]="false"
              [dropDownOptions]="{ width: 230 }"
              (onItemClick)="logAction($event, image)"
              icon="overflow"
              stylingMode="text"
              [items]="editOptions"
            >
            </dx-drop-down-button>
          </div>
        </div>
      </div>
    </div>
  </dx-scroll-view>
</ng-template>

<ag-shr-modal-window
  #fullScreenImg
  [width]="'100vw'"
  [height]="'100vh'"
  [showSaveButton]="false"
  [useScrollView]="false"
  (onHidden)="fullScreenImgUrl$.next(null)"
>
  <img class="image-gallery__full-size-image" [src]="fullScreenImgUrl$ | async | wasabiImg" alt="Image" />
</ag-shr-modal-window>
