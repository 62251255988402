import { SOCIAL_MEDIA_LOOKUP_VALUE } from '../../lists/social-media-lookup-values.enum';
import { BaseModel } from '../base.model';
import { Address } from './address.model';
import { AgentMedia } from './agent-media.model';
import { EmailAddress } from './email-address.model';
import { PhoneNumber } from './phone-number.model';
import { ImageCropper } from './image-cropper.model';
import { WizardStep } from './wizard.model';

export const AGENT_CAMPAIGNS_COLLECTION_NAME = 'agent-campaigns';

export enum AgentCampaignStepName {
  webSite = 'webSite',
  contactInfo = 'contactInfo',
  contactInfoAddress = 'contactInfoAddress',
  contactInfoPhoneNumber = 'contactInfoPhoneNumber',
  contactInfoEmailAddress = 'contactInfoEmailAddress',
  bio = 'bio',
  socialMedia = 'socialMedia',
  logoHeadshot = 'logoHeadshot',
  appointment = 'appointment',
  extras = 'extras',
  summary = 'summary',
  support = 'support',
}

export interface CampaignBio {
  title?: string;
  aboutUs?: string;
}

export interface CampaignSocialMedia {
  [SOCIAL_MEDIA_LOOKUP_VALUE.FB]?: string;
  [SOCIAL_MEDIA_LOOKUP_VALUE.IG]?: string;
  [SOCIAL_MEDIA_LOOKUP_VALUE.LI]?: string;
  [SOCIAL_MEDIA_LOOKUP_VALUE.TW]?: string;
  [SOCIAL_MEDIA_LOOKUP_VALUE.YT]?: string;
}

export type CampaignExtrasGeneric<T extends number> = {
  [K in `title${T}`]?: string;
} & {
  [K in `url${T}`]?: string;
};

export type CampaignExtras = CampaignExtrasGeneric<0 | 1 | 2 | 3>;

export interface CampaignLogoHeadshot {
  logo?: Partial<AgentMedia & ImageCropper>;
  headshot?: Partial<AgentMedia & ImageCropper>;
  favicon?: Partial<AgentMedia & ImageCropper>;
  socialSharingImage?: Partial<AgentMedia & ImageCropper>;
}

export interface CampaignWebSite {
  domainOption?: string;
  subdomain?: string;
}

export interface CampaignAppointment {
  schedulerLink?: string;
}

export interface AgentCampaignData {
  [AgentCampaignStepName.contactInfoAddress]?: Partial<Address>;
  [AgentCampaignStepName.contactInfoPhoneNumber]?: Partial<PhoneNumber>;
  [AgentCampaignStepName.contactInfoEmailAddress]?: Partial<EmailAddress>;
  [AgentCampaignStepName.bio]?: CampaignBio;
  [AgentCampaignStepName.socialMedia]?: Partial<CampaignSocialMedia>;
  [AgentCampaignStepName.logoHeadshot]?: Partial<CampaignLogoHeadshot>;
  [AgentCampaignStepName.webSite]?: Partial<CampaignWebSite>;
  [AgentCampaignStepName.appointment]?: Partial<CampaignAppointment>;
  [AgentCampaignStepName.extras]?: Partial<CampaignExtras>;
}

export interface AgentCampaignChange<T> {
  currentStepData?: Partial<T>;
  incomingStepData?: Partial<T>;
  isStepDone?: boolean;
}
export interface AgentCampaignChanges {
  [AgentCampaignStepName.contactInfoAddress]?: AgentCampaignChange<Address>;
  [AgentCampaignStepName.contactInfoPhoneNumber]?: AgentCampaignChange<PhoneNumber>;
  [AgentCampaignStepName.contactInfoEmailAddress]?: AgentCampaignChange<EmailAddress>;
  [AgentCampaignStepName.bio]?: AgentCampaignChange<CampaignBio>;
  [AgentCampaignStepName.socialMedia]?: AgentCampaignChange<CampaignSocialMedia>;
  [AgentCampaignStepName.logoHeadshot]?: AgentCampaignChange<CampaignLogoHeadshot>;
  [AgentCampaignStepName.webSite]?: AgentCampaignChange<CampaignWebSite>;
  [AgentCampaignStepName.appointment]?: AgentCampaignChange<CampaignAppointment>;
  [AgentCampaignStepName.extras]?: AgentCampaignChange<CampaignExtras>;
  [AgentCampaignStepName.support]?: AgentCampaignChange<string>;
}

export interface AgentCampaignContactInfoAddressMetaData {
  selectedKey?: string;
}

export interface AgentCampaignContactInfoPhoneNumberMetaData {
  selectedKey?: string;
}

export interface AgentCampaignContactInfoEmailAddressMetaData {
  selectedKey?: string;
}
export interface AgentCampaignMetaData {
  [AgentCampaignStepName.contactInfoAddress]?: AgentCampaignContactInfoAddressMetaData;
  [AgentCampaignStepName.contactInfoPhoneNumber]?: AgentCampaignContactInfoPhoneNumberMetaData;
  [AgentCampaignStepName.contactInfoEmailAddress]?: AgentCampaignContactInfoPhoneNumberMetaData;
}

export enum AgentCampaignKeys {
  data = 'data',
  draftData = 'draftData',
  metaData = 'metaData',
}

export class AgentCampaign extends BaseModel {
  [AgentCampaignKeys.data]?: AgentCampaignData;
  [AgentCampaignKeys.draftData]?: AgentCampaignData;
  [AgentCampaignKeys.metaData]?: AgentCampaignMetaData;
}

export const agentCampaignOrderedSteps = [
  AgentCampaignStepName.webSite,
  AgentCampaignStepName.contactInfoAddress,
  AgentCampaignStepName.contactInfoPhoneNumber,
  AgentCampaignStepName.contactInfoEmailAddress,
  AgentCampaignStepName.bio,
  AgentCampaignStepName.socialMedia,
  AgentCampaignStepName.logoHeadshot,
  AgentCampaignStepName.appointment,
  AgentCampaignStepName.extras,
];

export type AgentCampaignStep = WizardStep<AgentCampaignStepName>;

export const agentCampaignStepsMap = new Map<AgentCampaignStepName, Pick<AgentCampaignStep, 'title' | 'steps'>>([
  [AgentCampaignStepName.webSite, { title: 'Pick Your Website' }],
  [
    AgentCampaignStepName.contactInfo,
    {
      title: 'Add Your Contact Info',
      steps: [
        {
          name: AgentCampaignStepName.contactInfoAddress,
          title: 'Address',
        },
        {
          name: AgentCampaignStepName.contactInfoPhoneNumber,
          title: 'Phone Number',
        },
        {
          name: AgentCampaignStepName.contactInfoEmailAddress,
          title: 'Email Address',
        },
      ],
    },
  ],
  [AgentCampaignStepName.bio, { title: 'Add Your Bio' }],
  [AgentCampaignStepName.socialMedia, { title: 'Add Social Media' }],
  [AgentCampaignStepName.logoHeadshot, { title: 'Add Your Logo / Headshot' }],
  [AgentCampaignStepName.appointment, { title: 'Add Appointment' }],
  [AgentCampaignStepName.extras, { title: 'Add External Links' }],
  [AgentCampaignStepName.summary, { title: 'Summary' }],
]);
