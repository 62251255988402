import { ValidationResults } from '@ag-common-lib/lib';
import { AddressModelKeys } from '@ag-common-lib/public-api';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'addressItemValidation' })
export class AddressItemValidationPipe implements PipeTransform {
  constructor() {}

  transform(field: AddressModelKeys, results: ValidationResults): string {
    if (!results) {
      return '';
    }
    const addressValidationResponse = results?.address;
    const missingComponentTypes = addressValidationResponse?.missingComponentTypes;
    const unconfirmedComponentTypes = addressValidationResponse?.unconfirmedComponentTypes;

    const map = new Map([
      [AddressModelKeys.address1, new Set(['street_number', 'route'])],
      [AddressModelKeys.address2, new Set(['subpremise'])],
      [AddressModelKeys.city, new Set(['locality'])],
      [AddressModelKeys.state, new Set(['administrative_area_level_1'])],
      [AddressModelKeys.county, new Set(['administrative_area_level_2'])],
      [AddressModelKeys.country, new Set(['country'])],
      [AddressModelKeys.zip, new Set(['postal_code'])],
    ]);

    const set = map.get(field);

    if (!set) {
      return null;
    }

    const isMissed = missingComponentTypes?.length && missingComponentTypes?.some(component => set.has(component));
    const isUnconfirmed =
      unconfirmedComponentTypes?.length && unconfirmedComponentTypes?.some(component => set.has(component));

    if (isMissed) {
      return 'Missed Field';
    }

    if (isUnconfirmed) {
      return 'Unconfirmed Field';
    }

    return null;
  }
}
