import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { AGMedia, AGMediaKeys, ImageCropperPayload, MediaSize } from '@ag-common-lib/public-api';
import { WasabiImgPipe } from 'ag-common-svc/shared/pipes/wasabi-img.pipe';

@Component({
  selector: 'ag-shr-image-with-loader',
  templateUrl: './image-with-loader.component.html',
  styleUrls: ['./image-with-loader.component.scss'],
  providers: [WasabiImgPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageWithLoaderComponent {
  @HostBinding('class') className = 'image-with-loader';

  @Output() isImageLoadingIssuesChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() wasabiPath: string;
  @Input() agMedia: AGMedia;
  @Input() mediaSize: MediaSize;
  @Input() isRound: boolean = false;
  @Input() withBorder: boolean = true;
  @Input() usePlaceholder: boolean = true;
  @Input() borderRounded: boolean = false;
  @Input() cropperPayload: ImageCropperPayload;

  protected isLoading = false;
  protected hasLoadingIssues = false;

  protected readonly AGMediaKeys = AGMediaKeys;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  protected handleLoadingStateChange = isLoading => {
    this.isLoading = isLoading;
    // this.changeDetectorRef.detectChanges();
  };
}
