import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import {
  APPROVE_DENY_REASON_VISIBILITY_LEVEL_LOOKUP,
  ApproveDenyReason,
  ApproveDenyReasonKeys,
  ApproveDenyReasonVisibilityLevel,
  BaseModelKeys,
  Constants,
  StatusActivityEditorConfig,
} from '@ag-common-lib/public-api';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import { AgencyStatusActivityService, AuthService, QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/public-api';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import ArrayStore from 'devextreme/data/array_store';
import { StatusActivityModalComponent } from '../status-activity-modal/status-activity-modal.component';
import { User } from 'firebase/auth';
import {
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
} from '../../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/entity-permission.model';
import { Entity } from '../../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/lists/entity-permission.enum';

@UntilDestroy()
@Component({
  selector: 'ag-shr-status-activity-grid',
  templateUrl: './status-activity-grid.component.html',
  styleUrls: ['./status-activity-grid.component.scss'],
})
export class StatusActivityGridComponent {
  @HostBinding('class') className = 'ag-status-activity-grid';
  @ViewChild('agencyStatusActivityModalRef')
  agencyStatusActivityModalComponent: StatusActivityModalComponent;

  @Input() title: string;
  @Input() isEditable = true;
  @Input() editModalOptions: StatusActivityEditorConfig;
  @Input() extraToolbarItems = [];
  @Input() set agencyId(dbId: string) {
    this.agencyId$.next(dbId);
  }

  approveDenyReasons$: Observable<DataSource>;
  loggedInUserEmail: string;

  readonly approveDenyReasonVisibilityLevelLookup = APPROVE_DENY_REASON_VISIBILITY_LEVEL_LOOKUP;
  readonly BaseModelKeys = BaseModelKeys;
  readonly ApproveDenyReasonKeys = ApproveDenyReasonKeys;
  private readonly agencyId$ = new BehaviorSubject<string>(undefined);
  private allowedVisibilityLevels$ = new BehaviorSubject<ApproveDenyReasonVisibilityLevel[]>([]);
  readonly Constants = Constants;
  readonly emptyMessage = 'No Agency Status Activity Currently Exist';
  readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(private authService: AuthService, private agencyStatusActivityService: AgencyStatusActivityService) {
    this.authService.currentUser$.pipe(
      untilDestroyed(this),
      map((user: User) => {
        this.loggedInUserEmail = user?.email;
      }),
    );

    this.approveDenyReasons$ = combineLatest([this.agencyId$, this.allowedVisibilityLevels$]).pipe(
      filter(([agencyId]) => !!agencyId),
      switchMap(([agencyId, allowedVisibilityLevels]) => {
        const qp: QueryParam[] = [];
        if (Array.isArray(allowedVisibilityLevels) && allowedVisibilityLevels?.length) {
          const visibilityLevelsQueryParam = new QueryParam(
            ApproveDenyReasonKeys.visibilityLevel,
            WhereFilterOperandKeys.in,
            allowedVisibilityLevels,
          );
          qp.push(visibilityLevelsQueryParam);
        }

        return this.agencyStatusActivityService.getList(agencyId, qp);
      }),
      map(approveDenyReasons => {
        return new DataSource({
          store: new ArrayStore({
            key: 'dbId',
            data: Array.isArray(approveDenyReasons) ? approveDenyReasons : [],
          }),
        });
      }),
      shareReplay(1),
    );
  }

  onRowRemoving = e => {
    e.cancel = this.agencyStatusActivityService.delete(this.agencyId$.value, e.data[BaseModelKeys.dbId]);
  };

  showAddApproveDenyReasonPopup = (): void => {
    this.agencyStatusActivityModalComponent.showModal(
      this.agencyId$.value,
      this.editModalOptions?.initialApproveDenyReason as ApproveDenyReason,
    );
  };

  showEditPopup = ({ row: { data } }): void => {
    this.agencyStatusActivityModalComponent.showModal(this.agencyId$.value, data);
  };

  getIsEditingAllowed = ({ row: { data } }) =>
    !!this.loggedInUserEmail && this.loggedInUserEmail === data[BaseModelKeys.createdBy];
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
