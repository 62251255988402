import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxFileUploaderModule,
  DxLoadPanelModule,
  DxProgressBarModule,
  DxTextBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { ImageCropperModule as NGXImageCropperModule } from 'ngx-image-cropper';
import { ModalWindowModule } from '../modal-window/modal-window.module';
import { ImageCropperModalComponent } from './image-cropper-modal.component';

@NgModule({
  imports: [
    CommonModule,
    DxFileUploaderModule,
    DxProgressBarModule,
    NGXImageCropperModule,
    ModalWindowModule,
    DxButtonModule,
    DxTextBoxModule,
    DxValidatorModule,
    NgOptimizedImage,
    DxLoadPanelModule,
  ],
  declarations: [ImageCropperModalComponent],
  exports: [ImageCropperModalComponent],
})
export class ImageCropperModalModule {}
