import { Pipe, PipeTransform } from '@angular/core';
import { EmailAddress, EmailAddressKeys, OfficeEmailAddress } from '@ag-common-lib/public-api';

@Pipe({ name: 'AgentPrimaryEmailAddress' })
export class AgentPrimaryEmailAddressPipe implements PipeTransform {
  transform(emails: EmailAddress[]): string {
    const emailAddress = Array.isArray(emails)
      ? emails.find(emailAddress => {
          return emailAddress?.[EmailAddressKeys.isLogin];
        }) ?? null
      : null;

    return emailAddress?.address;
  }
}
