import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AgencyService } from '../../../../services/agency.service';
import { DocumentReference, updateDoc } from 'firebase/firestore';
import { Agency, Lookup, LookupKeys, OfficeEmailAddress } from '@ag-common-lib/public-api';
import { BaseFormService } from '../../../../utils/base-form-service';

@Injectable()
export class OfficeEmailAddressesService extends BaseFormService<OfficeEmailAddress[]> {
  selectedAgencyEmailType$ = new BehaviorSubject<Lookup>(null);
  selectedAgencySupportType$ = new BehaviorSubject<Lookup>(null);

  constructor(private agencyService: AgencyService) {
    super();
  }

  updateAddress(agencyId: string, officeEmailAddress: OfficeEmailAddress[]): Promise<Agency> {
    this.startProgress();
    return this.agencyService
      .updateFields(agencyId, { officeEmailAddress })
      .then(response => {
        const selectedAgencyEmailType = this.selectedAgencyEmailType$?.value;
        const selectedAgencySupportType = this.selectedAgencySupportType$?.value;
        if (selectedAgencyEmailType && !selectedAgencyEmailType?.isAssigned) {
          updateDoc(selectedAgencyEmailType?.reference as DocumentReference<Lookup>, {
            [LookupKeys.isAssigned]: true,
          }).then();
        }
        if (selectedAgencySupportType && !selectedAgencySupportType?.isAssigned) {
          updateDoc(selectedAgencySupportType?.reference as DocumentReference<Lookup>, {
            [LookupKeys.isAssigned]: true,
          }).then();
        }
        return response;
      })
      .finally(() => {
        this.stopProgress();
      });
  }

  setSelectedAgencyEmailType(item): void {
    this.selectedAgencyEmailType$.next(item);
  }

  setSelectedAgencySupportType(item): void {
    this.selectedAgencySupportType$.next(item);
  }
}
