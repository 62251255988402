import { Pipe, PipeTransform } from '@angular/core';
import { Agent, AgentKeys } from '@ag-common-lib/public-api';

@Pipe({ name: 'agentMainInfoPrimaryPhone' })
export class AgentMainInfoPrimaryPhonePipe implements PipeTransform {
  transform(agentData: Agent): string {
    const phoneNumber = Array.isArray(agentData?.[AgentKeys.phone_numbers])
      ? (agentData?.[AgentKeys.phone_numbers]?.find(phoneNumber => {
          return phoneNumber?.is_primary;
        })?.number ?? null)
      : null;

    if (!phoneNumber) {
      return '';
    }
    const matcher = phoneNumber.match(/(\d{3})(\d{3})(\d{4})/);

    return matcher ? `(${matcher[1]}) ${matcher[2]} - ${matcher[3]}` : phoneNumber;
  }
}
