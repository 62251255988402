import {
  Registrant,
  RegistrantKeys,
  RegistrationCategoryStepName,
  RegistrantModelKeys,
  ConferenceRegistrationStatus,
} from '@ag-common-lib/public-api';
import { normalizeSelectedExcursions } from 'ag-common-svc/lib/utils/excursions-data.utils';
import { normalizeFlightData } from 'ag-common-svc/lib/utils/flight-data.utils';
import { normalizeHotelData } from 'ag-common-svc/lib/utils/hotel-data.utils';
import { dateFromTimestamp, localDateTimeFromTimestamp } from 'ag-common-svc/lib/utils/date-from-timestamp';

export const conferenceRegistrantFromFirestore = (item): Registrant => {
  const normalizeData = data => {
    return Object.assign({}, data, {
      approved_date: dateFromTimestamp(data?.approved_date),
      created_at: dateFromTimestamp(data?.created_at),
      updated_at: dateFromTimestamp(data?.updated_at),
      registered_at: dateFromTimestamp(data?.registered_at),
      last_eval_date: dateFromTimestamp(data?.last_eval_date),
      dob: localDateTimeFromTimestamp(data?.dob),
      updated_date: dateFromTimestamp(data?.updated_date),
      flightInformation: normalizeFlightData(data?.[RegistrantKeys.flightInformation] || {}),
      hotelReservation: normalizeHotelData(data?.[RegistrantKeys.hotelReservation] || {}),
      isSendPlaque: data?.isSendPlaque ?? false,
      isSendPlaqueOwner: data?.isSendPlaqueOwner ?? '',
      isSendPlaqueOwnerNote: data?.isSendPlaqueOwnerNote ?? '',

      [RegistrantKeys.selectedExcursions]: normalizeSelectedExcursions(data?.[RegistrantKeys.selectedExcursions]),
    });
  };

  const normalizeTaskData = task => {
    let updatedTasks = {};
    RegistrationCategoryStepName.forEach(step => {
      const stepName = task?.[step];
      Object.assign(updatedTasks, {
        [step]: {
          status: stepName?.status ?? null,
          assignedTo: stepName?.assignedTo ?? '',
          assignedPersonNote: stepName?.assignedPersonNote ?? '',
          isStepDone: stepName?.isStepDone ?? false,
        },
      });
    });
    return Object.assign({}, task, updatedTasks);
  };

  return Object.assign({}, item, {
    [RegistrantModelKeys.data]: normalizeData(item?.data),
    [RegistrantModelKeys.task]: normalizeTaskData(item?.task),
    [RegistrantModelKeys.conferenceRegistrationStatus]:
      item?.conferenceRegistrationStatus ?? ConferenceRegistrationStatus.invited,
  });
};
