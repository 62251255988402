<div class="agent-campaign-wizard__content">
  <ng-content></ng-content>

  <dx-form
    #appointmentFormRef
    [formData]="appointmentFormData"
    labelMode="floating"
    [readOnly]="!canEdit"
  >

    <dxi-item
      dataField="appointmentTool"
      [label]="{ text: 'Appointment Tool' }"
      editorType="dxSelectBox"
      [editorOptions]="{
        items: appointmentTools,
        valueExpr: 'value',
        displayExpr: 'description',
        readOnly: true,
        placeholder: ''
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="'appointmentUrl'"
      [label]="{ text: 'Appointment Url' }"
    >
      <dxi-validation-rule
        type="custom"
        [validationCallback]="validateUrlWithOrWithoutProtocol"
        [message]="Messages.INVALID_URL_FORMAT_TEXT"
      >
      </dxi-validation-rule>
    </dxi-item>
  </dx-form>

</div>

<div class="agent-campaign-wizard__controls-container" *ngIf="canEdit">
  <dx-button
    class="modal-window__toolbar-button"
    text="CANCEL CHANGES"
    [disabled]="!(hasFormChanges$ | async)"
    (onClick)="resetChanges()"
  ></dx-button>

  <shr-button-with-indicator
    [title]="hasStepIssues ? 'RESOLVE ISSUE' :'NEXT'"
    [disabled]="hasStepIssues && !(hasFormChanges$ | async)"
    [cssClass]="'modal-window__toolbar-button'"
    [isInProgress]="inProgress$ | async"
    (onClick)="nextStep()"
  ></shr-button-with-indicator>
</div>
