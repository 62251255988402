import { Component, HostBinding, Input } from '@angular/core';
import { AgentCampaignChange, CampaignWebSite } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-agent-campaign-summary-web-site',
  templateUrl: './agent-campaign-summary-web-site.component.html',
  styleUrls: ['./agent-campaign-summary-web-site.component.scss'],
})
export class AgentCampaignSummaryWebSiteComponent {
  @HostBinding('class') className = 'agent-campaign-summary-web-site';

  @Input() stepData: AgentCampaignChange<CampaignWebSite>;

  constructor() {}
}
