import {
  AgencyKeys,
  ATTENDEE_TITLE_LIST,
  ATTENDEE_WIZARD_STATE_LOOKUP,
  AttendeeKeys,
  BaseModelKeys,
  INVITEE_OUTCOME_STATUS_LOOKUP,
  INVITEE_STATUS_LOOKUP,
  LookupKeys,
  QUALIFIED_AS_LOOKUP,
  REGISTRATION_TYPE_LOOKUP,
} from '@ag-common-lib/public-api';
import { Injectable } from '@angular/core';
import { AgencyService } from '../agency.service';
import { Field } from 'devextreme/ui/filter_builder';
import { DxFilterOperators } from '@ag-common-lib/lib';

@Injectable({ providedIn: 'root' })
export class ConferenceAttendeeGridFieldsService {
  private _registrationStateField: Field = {
    dataField: AttendeeKeys.registrationState,
    caption: ATTENDEE_TITLE_LIST[AttendeeKeys.registrationState],
    lookup: {
      dataSource: ATTENDEE_WIZARD_STATE_LOOKUP,
      valueExpr: LookupKeys.value,
      displayExpr: LookupKeys.description,
    },
  };

  private _inviteeStatusField: Field = {
    dataField: AttendeeKeys.inviteeStatus,
    caption: ATTENDEE_TITLE_LIST[AttendeeKeys.inviteeStatus],
    lookup: {
      dataSource: INVITEE_STATUS_LOOKUP,
      valueExpr: LookupKeys.value,
      displayExpr: LookupKeys.description,
    },
  };

  private _inviteeOutcomeStatusField: Field = {
    dataField: AttendeeKeys.inviteeOutcomeStatus,
    caption: ATTENDEE_TITLE_LIST[AttendeeKeys.inviteeOutcomeStatus],
    lookup: {
      dataSource: INVITEE_OUTCOME_STATUS_LOOKUP,
      valueExpr: LookupKeys.value,
      displayExpr: LookupKeys.description,
    },
  };

  private _registrationTypesField: Field = {
    dataField: AttendeeKeys.registrationType,
    caption: ATTENDEE_TITLE_LIST[AttendeeKeys.registrationType],
    lookup: {
      dataSource: REGISTRATION_TYPE_LOOKUP,
      valueExpr: LookupKeys.value,
      displayExpr: LookupKeys.description,
    },
  };

  private _qualifiedAsField: Field = {
    dataField: AttendeeKeys.qualifiedAs,
    caption: ATTENDEE_TITLE_LIST[AttendeeKeys.qualifiedAs],
    lookup: {
      dataSource: QUALIFIED_AS_LOOKUP,
      valueExpr: LookupKeys.value,
      displayExpr: LookupKeys.description,
    },
  };

  private _agencyField: Field = {
    dataField: AttendeeKeys.agencyId,
    caption: ATTENDEE_TITLE_LIST[AttendeeKeys.agencyId],
    lookup: {
      dataSource: this.agencyService.getAgencyCustomStore(),
      valueExpr: BaseModelKeys.dbId,
      displayExpr: AgencyKeys.agencyId,
    },
  };

  private _mgaField: Field = {
    dataField: AttendeeKeys.mgaId,
    caption: ATTENDEE_TITLE_LIST[AttendeeKeys.mgaId],
    lookup: {
      dataSource: this.agencyService.getMGACustomStore(),
      valueExpr: BaseModelKeys.dbId,
      displayExpr: AgencyKeys.agencyId,
    },
  };

  private _firstTimeAttendeeField: Field = {
    dataField: AttendeeKeys.firstTimeAttendee,
    caption: ATTENDEE_TITLE_LIST[AttendeeKeys.firstTimeAttendee],
    dataType: 'boolean',
    trueText: 'Yes',
    falseText: 'No',
  };

  private _registrantsIdsField: Field = {
    dataField: BaseModelKeys.dbId,
    caption: 'Registrant',
    filterOperations: [DxFilterOperators.doNotEqual],
  };

  fieldsMap = new Map<any, Field>([
    [AttendeeKeys.registrationState, this._registrationStateField],
    [AttendeeKeys.inviteeStatus, this._inviteeStatusField],
    [AttendeeKeys.inviteeOutcomeStatus, this._inviteeOutcomeStatusField],
    [AttendeeKeys.registrationType, this._registrationTypesField],
    [AttendeeKeys.agencyId, this._agencyField],
    [AttendeeKeys.mgaId, this._mgaField],
  ]);

  fields: Field[] = [
    this._registrationStateField,
    this._inviteeStatusField,
    this._inviteeOutcomeStatusField,
    this._registrationTypesField,
    this._agencyField,
    this._mgaField,
    this._firstTimeAttendeeField,
    this._registrantsIdsField,
  ];

  constructor(private agencyService: AgencyService) {}

  getDisplayValue = async (field: AttendeeKeys, value: any): Promise<string> => {
    switch (field) {
      case AttendeeKeys.mgaId:
      case AttendeeKeys.agencyId:
        return this.agencyService.getAgencyDisplayValue(value);
    }
    return value;
  };
}
