import { Component, HostBinding, Input } from '@angular/core';
import { DietaryConsideration, DietaryConsiderationKeys, Messages, YES_NO_TYPE } from '@ag-common-lib/public-api';
import { Observable } from 'rxjs';
import { DietaryConsiderationsFormService } from './dietary-considerations-form.service';

@Component({
  selector: 'ag-shr-dietary-considerations-form',
  templateUrl: './dietary-considerations-form.component.html',
  styleUrls: ['./dietary-considerations-form.component.scss'],
})
export class DietaryConsiderationsFormComponent {
  @HostBinding('class') className = 'dietary-considerations-form';
  @Input() set dietaryConsiderations(data: Partial<DietaryConsideration>) {
    this._formData = this.dietaryConsiderationsFormService.getFormData(data);
  }

  get dietaryConsiderations() {
    return this._formData;
  }
  @Input() isReadOnly: boolean = false;
  @Input() pathPrefix: string;
  @Input() validationGroup: string;
  @Input() label: string = 'Do you have a Dietary or Personal Consideration?';
  @Input() isDietaryOrPersonalConsiderationRequired: boolean = false;

  private _formData: DietaryConsideration = {};

  readonly yesNoType = YES_NO_TYPE;
  readonly DietaryConsiderationKeys = DietaryConsiderationKeys;
  protected inProgress$: Observable<boolean>;
  readonly Messages = Messages;

  constructor(private dietaryConsiderationsFormService: DietaryConsiderationsFormService) {}

  protected handleDietaryConsiderationTypeSelect =
    this.dietaryConsiderationsFormService.handleDietaryConsiderationTypeSelect;
}
