export enum BrokenRuleKeys {
  label = 'label',
  sectionId = 'sectionId',
  editor = 'editor',
}

export interface BrokenRule<T> {
  [BrokenRuleKeys.label]: string;
  [BrokenRuleKeys.sectionId]: T;
  [BrokenRuleKeys.editor]: any; // type from devextreme
}
