import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxScrollViewModule, DxValidationSummaryModule } from 'devextreme-angular';
import { ModalWindowModule } from '../modal-window/modal-window.module';
import { SharedModule } from '../../../shared/shared.module';
import { AttendeeRefundPaymentModalComponent } from './attendee-refund-payment-modal.component';
import { AttendeeRefundPaymentModalService } from './attendee-refund-payment-modal.service';

@NgModule({
  declarations: [AttendeeRefundPaymentModalComponent],
  exports: [AttendeeRefundPaymentModalComponent],
  imports: [CommonModule, SharedModule, DxScrollViewModule, ModalWindowModule, DxValidationSummaryModule,],
  providers: [AttendeeRefundPaymentModalService],
})
export class AttendeeRefundPaymentModalModule {}
