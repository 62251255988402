import { Pipe, PipeTransform } from '@angular/core';
import { Address, LookupBase } from '@ag-common-lib/public-api';

@Pipe({ name: 'agentCampaignSummaryContactInfoAddressCopyOptions' })
export class AgentCampaignSummaryContactInfoAddressCopyOptionsPipe implements PipeTransform {
  constructor() {}

  transform(address: Address, fullAddress): LookupBase[] {
    return [
      fullAddress && { value: fullAddress, description: 'Copy Full Address' },
      address?.address1 && { value: address.address1, description: 'Copy Address 1' },
      address?.address2 && { value: address.address2, description: 'Copy Address 2' },
      address?.city && { value: address.city, description: 'Copy City' },
      address?.state && { value: address.state, description: 'Copy State' },
      address?.zip && { value: address.zip, description: 'Copy Zip' },
      address?.country && { value: address.country, description: 'Copy Country' },
      address?.county && { value: address.county, description: 'Copy County' },
    ].filter(Boolean);
  }
}
