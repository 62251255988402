import { Options } from 'nodemailer/lib/mailer';
import { BaseModel } from '../base.model';
import { EmailTemplates } from '../functions';

export const EMAIL_CAMPAIGN_PATH = 'email-campaign' as const;

export enum CampaignEmailStatus {
  delivered = 'delivered',
  inProgress = 'inProgress',
  cancelled = 'cancelled',
}

export enum BaseCampaignsEmailKeys {
  parentDbId = 'parentDbId',
  mailOptions = 'mailOptions',
  template = 'template',
  context = 'context',
  status = 'status',
}

export class BaseCampaignsEmail extends BaseModel {
  [BaseCampaignsEmailKeys.parentDbId]: string;
  [BaseCampaignsEmailKeys.mailOptions]: Options;
  [BaseCampaignsEmailKeys.template]: EmailTemplates;
  [BaseCampaignsEmailKeys.status]: CampaignEmailStatus = CampaignEmailStatus.inProgress;

  constructor(parentDbId: string) {
    super();
    this[BaseCampaignsEmailKeys.parentDbId] = parentDbId;
  }
}
