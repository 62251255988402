import { Injectable } from '@angular/core';
import { AgentCampaignWizardService } from '../agent-campaign-wizard.service';
import { BaseFormService } from '../../../utils/base-form-service';

@Injectable()
export class AgentCampaignSummaryService extends BaseFormService<null> {
  constructor(private agentCampaignWizardService: AgentCampaignWizardService) {
    super();
  }

  submitChanges = async () => {
    this.startProgress();

    await this.agentCampaignWizardService.submitChanges().finally(() => {
      this.stopProgress();
    });
  };
}
