import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AgencyService } from '../../../../services/agency.service';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import {
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  Messages,
  OfficeEmailAddress,
} from 'ag-common-lib/public-api';
import { OfficeEmailAddressesService } from '../office-email-addresses/office-email-addresses.service';

@Component({
  selector: 'ag-shr-agency-office-email-address',
  templateUrl: './office-email-addresses.component.html',
  styleUrls: ['./office-email-addresses.component.scss'],
  providers: [AgencyService],
})
export class OfficeEmailAddressesComponent {
  @ViewChild('officeEmailAddressesModalRef', { static: true }) officeEmailAddressesModalComponent: ModalWindowComponent;
  @Input() agencyId: string;
  @Input() emails: OfficeEmailAddress[] = [];
  @Output() emailAddressesChange = new EventEmitter<OfficeEmailAddress[]>();

  inProgress$ = this.officeEmailAddressesService.inProgress$;

  readonly Messages = Messages;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(private officeEmailAddressesService: OfficeEmailAddressesService) {}

  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
