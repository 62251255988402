<div class="agent-campaign-wizard__content">
  <ng-content></ng-content>
  <dx-scroll-view height="100%" [useNative]="false" [showScrollbar]="'always'">
    <ag-shr-select-email-address
      [emailAddress]="emailAddressFormData$ | async"
      [emailAddresses]="agentEmailAddresses$ | async"
      [validationGroup]="validationGroup"
      [isReadonly]="!canEdit"
    ></ag-shr-select-email-address>
  </dx-scroll-view>
</div>

<div class="agent-campaign-wizard__controls-container" *ngIf="canEdit">
  <dx-button
    class="modal-window__toolbar-button"
    text="CANCEL CHANGES"
    [disabled]="(hasFormChanges$ | async) === false"
    (onClick)="resetChanges()"
  ></dx-button>

  <shr-button-with-indicator
    [title]="hasStepIssues ? 'RESOLVE ISSUE' : 'NEXT'"
    [disabled]="hasStepIssues && (hasFormChanges$ | async) === false"
    [cssClass]="'modal-window__toolbar-button'"
    [isInProgress]="inProgress$ | async"
    (onClick)="nextStep()"
  ></shr-button-with-indicator>
</div>
