import { Pipe, PipeTransform } from '@angular/core';
import { ExcursionStatistic } from '@ag-common-lib/public-api';

@Pipe({ name: 'excursionSeatsRemain' })
export class ExcursionSeatsRemainPipe implements PipeTransform {
  transform(excursionId: string, conferenceExcursions: Map<string, ExcursionStatistic>) {
    if (!conferenceExcursions || !conferenceExcursions.has(excursionId)) {
      return null;
    }

    return conferenceExcursions.get(excursionId);
  }
}
