import { LookupKeys } from '../crm/lookup.model';
import { LocalDateTimeString } from '../utils/local-date-time-string.model';
import { RequestOutcome } from '../utils/request-outcome.model';
import { ReservationStatus } from './registrants.model';

export enum BedPreference {
  kingBed = 'Room with King bed',
  twoDoubleBed = 'Room with two Double beds',
}

export const BED_PREFERENCE_LOOKUP = [
  { [LookupKeys.value]: BedPreference.kingBed, [LookupKeys.description]: BedPreference.kingBed },
  { [LookupKeys.value]: BedPreference.twoDoubleBed, [LookupKeys.description]: BedPreference.twoDoubleBed },
];

export enum Aids {
  no = 'No',
  visual = 'Visual',
  audio = 'Audio',
  mobile = 'Mobile',
}

export const AIDS_LOOKUP = [
  { [LookupKeys.value]: Aids.no, [LookupKeys.description]: Aids.no },
  { [LookupKeys.value]: Aids.visual, [LookupKeys.description]: Aids.visual },
  { [LookupKeys.value]: Aids.audio, [LookupKeys.description]: Aids.audio },
  { [LookupKeys.value]: Aids.mobile, [LookupKeys.description]: Aids.mobile },
];

export enum HotelBookingKeys {
  hotel = 'hotel',
  bookingNumber = 'bookingNumber',
  arrival = 'arrival',
  departure = 'departure',
  reservationStatus = 'reservationStatus',
}

export class HotelBookingInfo {
  [HotelBookingKeys.arrival]?: Date;
  [HotelBookingKeys.departure]?: Date;
  [HotelBookingKeys.hotel]?: string;
  [HotelBookingKeys.bookingNumber]?: string;
  [HotelBookingKeys.reservationStatus]?: ReservationStatus;
}

export enum HotelReservationKeys {
  checkInDate = 'checkInDate',
  checkOutDate = 'checkOutDate',
  requestedCheckInDate = 'requestedCheckInDate',
  requestedCheckOutDate = 'requestedCheckOutDate',
  requestDifferBookingDatesOutcome = 'requestDifferBookingDatesOutcome',
  bedPreference = 'bedPreference',
  aidsRequired = 'aidsRequired',
  additionalRoomRequested = 'additionalRoomRequested',
  additionalRoomRequestOutcome = 'additionalRoomRequestOutcome',
  additionalInfo = 'additionalInfo',
  bookingInfo = 'bookingInfo',
}

export class HotelReservation {
  [HotelReservationKeys.checkInDate]?: LocalDateTimeString;
  [HotelReservationKeys.checkOutDate]?: LocalDateTimeString;
  [HotelReservationKeys.requestedCheckInDate]?: LocalDateTimeString;
  [HotelReservationKeys.requestedCheckOutDate]?: LocalDateTimeString;
  [HotelReservationKeys.requestDifferBookingDatesOutcome]?: RequestOutcome;
  [HotelReservationKeys.bedPreference]?: BedPreference;
  [HotelReservationKeys.aidsRequired]?: Aids;
  [HotelReservationKeys.additionalRoomRequested]?: boolean;
  [HotelReservationKeys.additionalRoomRequestOutcome]?: RequestOutcome;
  [HotelReservationKeys.additionalInfo]?: string;
  [HotelReservationKeys.bookingInfo]?: HotelBookingInfo;
}

export const HOTEL_RESERVATION_KEYS_LOOKUP = [
  { [LookupKeys.value]: HotelReservationKeys.checkInDate, [LookupKeys.description]: 'Check In Date' },
  { [LookupKeys.value]: HotelReservationKeys.checkOutDate, [LookupKeys.description]: 'Check Out Date' },
  {
    [LookupKeys.value]: HotelReservationKeys.requestedCheckInDate,
    [LookupKeys.description]: 'Requested Check In Date',
  },
  {
    [LookupKeys.value]: HotelReservationKeys.requestedCheckOutDate,
    [LookupKeys.description]: 'Requested Check Out Date',
  },
  {
    [LookupKeys.value]: HotelReservationKeys.requestDifferBookingDatesOutcome,
    [LookupKeys.description]: 'Request Differ Booking Dates Outcome',
  },
  { [LookupKeys.value]: HotelReservationKeys.bedPreference, [LookupKeys.description]: 'Bed Preference' },
  { [LookupKeys.value]: HotelReservationKeys.aidsRequired, [LookupKeys.description]: 'Aids Required' },
  {
    [LookupKeys.value]: HotelReservationKeys.additionalRoomRequested,
    [LookupKeys.description]: 'Additional Room Requested',
  },
  { [LookupKeys.value]: HotelReservationKeys.additionalInfo, [LookupKeys.description]: 'Additional Info' },
  { [LookupKeys.value]: HotelReservationKeys.bookingInfo, [LookupKeys.description]: 'Booking Info' },

  {
    [LookupKeys.value]: HotelReservationKeys.additionalRoomRequestOutcome,
    [LookupKeys.description]: 'Additional Room Requested Outcome',
  },
];
