import { Injectable } from '@angular/core';
import { AgentCampaignWizardService } from '../agent-campaign-wizard.service';
import {
  Agent,
  AgentCampaignContactInfoPhoneNumberMetaData,
  AgentCampaignKeys,
  AgentCampaignStepName,
  AgentKeys,
  PhoneNumber,
  PhoneNumberKeys,
} from '@ag-common-lib/public-api';
import { Observable, combineLatest, filter, map, shareReplay, startWith } from 'rxjs';
import { BaseFormService } from '../../../utils/base-form-service';
import { pick } from 'lodash';
import { FormChangesDetectorActionType } from 'ag-common-svc/shared/utils';
import { getPhoneNumberKeyExpression } from '../../select-phone-number/select-phone-number.service';

@Injectable()
export class AgentCampaignPhoneNumberInfoService extends BaseFormService<PhoneNumber> {
  agentPhoneNumbers$: Observable<PhoneNumber[]>;
  contactInfoPhoneNumberMetaData$: Observable<AgentCampaignContactInfoPhoneNumberMetaData>;

  campaignContactInfoPhoneNumber$: Observable<Partial<PhoneNumber>>;

  constructor(private agentCampaignWizardService: AgentCampaignWizardService) {
    super();
    this.contactInfoPhoneNumberMetaData$ = this.agentCampaignWizardService.campaign$.pipe(
      map(campaign => campaign?.[AgentCampaignKeys.metaData]?.[AgentCampaignStepName.contactInfoPhoneNumber]),
    );

    this.agentPhoneNumbers$ = agentCampaignWizardService.agent$.pipe(
      map((agent: Agent) => agent?.[AgentKeys.phone_numbers] ?? []),
      shareReplay(1),
    );

    this.campaignContactInfoPhoneNumber$ = combineLatest({
      campaign: this.agentCampaignWizardService.campaign$,
      _resetTrigger: this.formChangesDetector.actions$.pipe(
        filter(action => action?.type === FormChangesDetectorActionType.reset),
        startWith(null),
      ),
    }).pipe(
      map(({ campaign }) => {
        const phoneNumber = campaign?.[AgentCampaignKeys.data]?.[AgentCampaignStepName.contactInfoPhoneNumber];
        const draftPhoneNumber =
          campaign?.[AgentCampaignKeys.draftData]?.[AgentCampaignStepName.contactInfoPhoneNumber];

        return Object.assign({}, phoneNumber, draftPhoneNumber);
      }),
      map(this.getFormData),
      shareReplay(1),
    );
  }

  handleSetSelectedPhoneNumber = async phoneNumber => {
    return this.setPhoneNumber(phoneNumber);
  };

  handleNextStepClick = async (selectedKey: string) => {
    const changes = this.formChangesDetector.getAllChanges();

    if (!changes?.length) {
      this.agentCampaignWizardService.nextStep();
      return;
    }

    const updates = {};

    changes.forEach(([key]) => {
      Object.assign(updates, { [key]: this.formData[key] });
    });
    this.startProgress();
    await this.agentCampaignWizardService
      .handleStepChanges(
        {
          [AgentCampaignStepName.contactInfoPhoneNumber]: updates,
        },
        {
          [AgentCampaignStepName.contactInfoPhoneNumber]: {
            selectedKey: selectedKey ?? null,
          },
        },
      )
      .then(() => {
        this.formChangesDetector.clear();
        this.agentCampaignWizardService.nextStep();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  phoneNumberKeyExpression = getPhoneNumberKeyExpression;

  private setPhoneNumber = (phoneNumber?: Partial<PhoneNumber>) => {
    Object.keys(this.formData).forEach(key => {
      this.formData[key] = null;
    });

    Object.assign(this.formData, phoneNumber);
  };

  private getFormData = (phoneNumber?: Partial<PhoneNumber>) => {
    const initialData = this.pickPhoneNumberData(phoneNumber);
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);

          switch (prop) {
            case '':
          }
        }

        return true;
      },
    });

    return this.formData;
  };

  private pickPhoneNumberData = (phoneNumber: PhoneNumber) => {
    return pick(phoneNumber, [
      PhoneNumberKeys.number,
      PhoneNumberKeys.extension,
      PhoneNumberKeys.is_whatsapp,
      PhoneNumberKeys.phone_type,
    ]);
  };
}
