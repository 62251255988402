import { Component, HostBinding, Input } from '@angular/core';
import { Address, AgentCampaignChange, CampaignWebSite, EmailAddress, PhoneNumber } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-agent-campaign-summary-contact-info',
  templateUrl: './agent-campaign-summary-contact-info.component.html',
  styleUrls: ['./agent-campaign-summary-contact-info.component.scss'],
})
export class AgentCampaignSummaryContactInfoComponent {
  @HostBinding('class') className = 'agent-campaign-summary-contact-info';

  @Input() stepDataAddress: AgentCampaignChange<Address>;
  @Input() stepDataPhoneNumber: AgentCampaignChange<PhoneNumber>;
  @Input() stepDataEmailAddress: AgentCampaignChange<EmailAddress>;
}
