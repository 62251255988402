import { Pipe, PipeTransform } from '@angular/core';
import { WizardStepState, WizardStep } from '@ag-common-lib/public-api';

@Pipe({ name: 'wizardStepperStepState' })
export class WizardStepperStepStatePipe implements PipeTransform {
  constructor() {}

  transform(currentStep: string, step?: WizardStep<any>): string | string[] {
    const classes = [];
    const stepHasView = step?.hasView ?? true;

    if (
      Array.isArray(step?.steps) &&
      (currentStep === step?.name || step?.steps?.some(step => step?.name === currentStep))
    ) {
      classes.push('wizard-stepper__step--with-sub-steps');
    }

    if (step.name === currentStep || step?.steps?.some(step => step.name === currentStep)) {
      classes.push('wizard-stepper__step--current');
    }

    if (
      step.stepState === WizardStepState.done ||
      (!stepHasView && step?.steps?.every(step => step.stepState === WizardStepState.done))
    ) {
      classes.push('wizard-stepper__step--done');
    }

    if (
      step.stepState === WizardStepState.locked ||
      (!stepHasView && step?.steps?.every(step => step.stepState === WizardStepState.locked))
    ) {
      classes.push('wizard-stepper__step--locked');
    }

    if (
      step.stepState === WizardStepState.issues ||
      step?.steps?.some(step => step.stepState === WizardStepState.issues)
    ) {
      classes.push('wizard-stepper__step--issues');
    }

    if (
      step.stepState === WizardStepState.changed ||
      step?.steps?.some(step => step.stepState === WizardStepState.changed)
    ) {
      classes.push('wizard-stepper__step--changed');
    }

    if (
      step.stepState === WizardStepState.skipped ||
      step?.steps?.every(step => step.stepState === WizardStepState.skipped)
    ) {
      classes.push('wizard-stepper__step--skipped');
    }

    return classes;
  }
}
