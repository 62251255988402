import { Inject, Injectable } from '@angular/core';

import { FirebaseApp } from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { PUSH_NOTIFICATIONS_CAMPAIGN_COLLECTION, PushNotificationCampaign } from '@ag-common-lib/lib';
import { DataService } from '../data.service';
import { FIREBASE_APP, dateFromTimestamp } from 'ag-common-svc/public-api';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsCampaignService extends DataService<PushNotificationCampaign> {
  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,
    private toastrService: ToastrService,
  ) {
    super(fireBaseApp, PushNotificationsCampaignService.fromFirestore, null);
    this.collection = PUSH_NOTIFICATIONS_CAMPAIGN_COLLECTION;
  }

  static readonly fromFirestore = (data): PushNotificationCampaign => {
    if (!!data?.deliverAt) {
      data.deliverAt = dateFromTimestamp(data?.deliverAt);
    }

    return Object.assign({}, data);
  };
}
