import { LookupBase } from '../models/utils/lookup-base.model';

export enum ConferenceRegistrationStatus {
  invited = 'invited',
  registered = 'registered',
  declined = 'declined',
}

export const CONFERENCE_REGISTRATIONS_STATUS_LOOKUP: LookupBase[] = [
  { value: ConferenceRegistrationStatus.invited, description: 'Invited', cssClass: 'dx-icon-agi-participant-plus' },
  {
    value: ConferenceRegistrationStatus.registered,
    description: 'Registered',
    cssClass: 'dx-icon-agi-participant-check',
  },
  { value: ConferenceRegistrationStatus.declined, description: 'Declined', cssClass: 'dx-icon-agi-participant-minus' },
];
