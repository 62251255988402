import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'usdCurrency',
})
export class UsdCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(value: string | number): any {
    return super.transform(value, 'USD', 'symbol', '1.2-2');
  }
}
