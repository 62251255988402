import { Inject, Injectable } from '@angular/core';
import { ApproveDenyReason } from '@ag-common-lib/public-api';
import { CommonFireStoreDao, QueryParam } from '../dao/CommonFireStoreDao.dao';
import { FIREBASE_APP } from '../injections/firebase-app';
import { FirebaseApp } from 'firebase/app';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AgencyStatusActivityService {
  readonly fsDao: CommonFireStoreDao<ApproveDenyReason>;
  private readonly agenciesCollectionPath = 'agencies';
  private readonly associationCollectionPath = 'approve-deny-reason';

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp, private toastrService: ToastrService) {
    this.fsDao = new CommonFireStoreDao<ApproveDenyReason>(fireBaseApp, null, null);
  }

  getList(agencyId: string, qp: QueryParam[] = []) {
    const table = this.getCollectionPath(agencyId);
    return this.fsDao.getList(table, qp);
  }

  getAll(agencyId: string): Promise<ApproveDenyReason[]> {
    const table = this.getCollectionPath(agencyId);
    return this.fsDao.getAll(table);
  }

  create(agencyId: string, data: ApproveDenyReason, silent = false) {
    const table = this.getCollectionPath(agencyId);

    return this.fsDao
      .create(data, table)
      .then(response => {
        !silent && this.toastrService.success('Agency Status Activity Successfully Created!');
        return response;
      })

      .catch(e => {
        console.log('e', e);
      });
  }

  update(agencyId: string, documentId: any, updates: Partial<ApproveDenyReason>, silent = false) {
    const table = this.getCollectionPath(agencyId);

    return this.fsDao
      .updateFields(updates, documentId, table)
      .then(response => {
        !silent && this.toastrService.success('Agency Status Activity Successfully Updated!');
        return response;
      })
      .catch(e => {
        console.log('e', e);
      });
  }

  delete(agencyId: string, documentId: any) {
    const table = this.getCollectionPath(agencyId);
    return this.fsDao.delete(documentId, table).then(response => {
      this.toastrService.success('Agency ApproveDenyReason Removed!');
      return response;
    });
  }

  private getCollectionPath(agencyId: string): string {
    return [this.agenciesCollectionPath, agencyId, this.associationCollectionPath].join('/');
  }
}
