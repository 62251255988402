import { Pipe, PipeTransform } from '@angular/core';
import { LookupBase } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/lookup-base.model';

@Pipe({
  name: 'lookupDescriptionText',
})
export class LookupDescriptionTextPipe implements PipeTransform {
  transform(id: string, lookup: LookupBase[], type: 'class' | 'text' = 'text'): string {
    if (!lookup) {
      // console.error('*** No lookup provided');
      return '';
    }
    const lookupItem = lookup.find(item => item.value === id);
    return type === 'class' ? lookupItem.cssClass : lookupItem.description;
  }
}
