let RACES = [
  {
    pk: 'African',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'African American',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Albanian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Anabaptist',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Arab',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Argentine',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Armenian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Asian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Austrian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Bangladeshi',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Basque',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Belarusian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Belgian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Bosnian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Brazilian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'British',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Bulgarian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Cajun',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Cambodian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Canadian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Cape Verdean',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Caribbean',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Carpatho-Rusyn',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Chilean',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Chinese',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Croatian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Cuban',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Czech',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Danish',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Dutch',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'East Indian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Ecuadorian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Estonian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Ethiopian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Filipino',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Finnish',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'French',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'German',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Greek',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Haitian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Hungarian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Icelandic',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Indians of North America',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Irish',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Italian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Japanese',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Jewish',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Korean',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Latin American',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Latvian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Lebanese',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Lithuanian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Luxembourger',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Macedonian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Malaysian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Maltese',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Mexican',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Middle Eastern',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Iranian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Norwegian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Pacific Islander',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Pakistani',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Palestinian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Polish',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Portuguese',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Romanian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Russian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Scandinavian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Scottish',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Serbian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Slavic',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Slovak',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Slovenian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Spanish',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Swedish',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Swiss',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Syrian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Thai',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Turkish',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Ukranian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Vietnamese',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Welsh',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'West Indian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 'Yugoslavian',
    model: 'core.Ethnicity',
    fields: {},
  },
  {
    pk: 2,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Estonians',
      ethnicity: 'Estonian',
    },
  },
  {
    pk: 4,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Argentines',
      ethnicity: 'Argentine',
    },
  },
  {
    pk: 5,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Belgian Americans',
      ethnicity: 'Belgian',
    },
  },
  {
    pk: 6,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Belgians',
      ethnicity: 'Belgian',
    },
  },
  {
    pk: 7,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Bulgarian Americans',
      ethnicity: 'Bulgarian',
    },
  },
  {
    pk: 8,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Bulgarians',
      ethnicity: 'Bulgarian',
    },
  },
  {
    pk: 9,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Poles',
      ethnicity: 'Polish',
    },
  },
  {
    pk: 10,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Polish Americans',
      ethnicity: 'Polish',
    },
  },
  {
    pk: 11,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'French Americans',
      ethnicity: 'French',
    },
  },
  {
    pk: 12,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'French in South Carolina',
      ethnicity: 'French',
    },
  },
  {
    pk: 13,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'French-American newspapers.',
      ethnicity: 'French',
    },
  },
  {
    pk: 14,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'French-Canadians',
      ethnicity: 'French',
    },
  },
  {
    pk: 15,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Armenian Americans',
      ethnicity: 'Armenian',
    },
  },
  {
    pk: 16,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Armenians',
      ethnicity: 'Armenian',
    },
  },
  {
    pk: 17,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Haitians',
      ethnicity: 'Haitian',
    },
  },
  {
    pk: 18,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Finnish Americans',
      ethnicity: 'Finnish',
    },
  },
  {
    pk: 19,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Finnish-American newspapers.',
      ethnicity: 'Finnish',
    },
  },
  {
    pk: 20,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Finns',
      ethnicity: 'Finnish',
    },
  },
  {
    pk: 21,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Iranians',
      ethnicity: 'Iranian',
    },
  },
  {
    pk: 22,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Albanian Americans',
      ethnicity: 'Albanian',
    },
  },
  {
    pk: 23,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Albanians',
      ethnicity: 'Albanian',
    },
  },
  {
    pk: 24,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Serbian Americans',
      ethnicity: 'Serbian',
    },
  },
  {
    pk: 25,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Serbian Americans.',
      ethnicity: 'Serbian',
    },
  },
  {
    pk: 26,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Serbs',
      ethnicity: 'Serbian',
    },
  },
  {
    pk: 27,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Ruthenian Americans',
      ethnicity: 'Carpatho-Rusyn',
    },
  },
  {
    pk: 28,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Ruthenians',
      ethnicity: 'Carpatho-Rusyn',
    },
  },
  {
    pk: 31,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Italian Americans',
      ethnicity: 'Italian',
    },
  },
  {
    pk: 32,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Italians',
      ethnicity: 'Italian',
    },
  },
  {
    pk: 33,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Czech American newspapers.',
      ethnicity: 'Czech',
    },
  },
  {
    pk: 34,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Czech Americans',
      ethnicity: 'Czech',
    },
  },
  {
    pk: 35,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Czechs',
      ethnicity: 'Czech',
    },
  },
  {
    pk: 36,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Slovak Americans',
      ethnicity: 'Slovak',
    },
  },
  {
    pk: 37,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Slovaks',
      ethnicity: 'Slovak',
    },
  },
  {
    pk: 38,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Scots',
      ethnicity: 'Scottish',
    },
  },
  {
    pk: 39,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Scottish Americans',
      ethnicity: 'Scottish',
    },
  },
  {
    pk: 40,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Austrians',
      ethnicity: 'Austrian',
    },
  },
  {
    pk: 41,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Austrian Americans',
      ethnicity: 'Austrian',
    },
  },
  {
    pk: 43,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Swedes',
      ethnicity: 'Swedish',
    },
  },
  {
    pk: 44,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Swedish Americans',
      ethnicity: 'Swedish',
    },
  },
  {
    pk: 45,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Swedish Americas',
      ethnicity: 'Swedish',
    },
  },
  {
    pk: 46,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Swedish-American newspapers.',
      ethnicity: 'Swedish',
    },
  },
  {
    pk: 47,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Caribbean Americans',
      ethnicity: 'Caribbean',
    },
  },
  {
    pk: 48,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'West Indian Americans',
      ethnicity: 'Caribbean',
    },
  },
  {
    pk: 49,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'West Indians',
      ethnicity: 'Caribbean',
    },
  },
  {
    pk: 50,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Slovenes',
      ethnicity: 'Slovenian',
    },
  },
  {
    pk: 51,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Slovenian Americans',
      ethnicity: 'Slovenian',
    },
  },
  {
    pk: 52,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Danes',
      ethnicity: 'Danish',
    },
  },
  {
    pk: 53,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Danish Americans',
      ethnicity: 'Danish',
    },
  },
  {
    pk: 55,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Norwegian American newspapers.',
      ethnicity: 'Norwegian',
    },
  },
  {
    pk: 56,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Norwegian Americans',
      ethnicity: 'Norwegian',
    },
  },
  {
    pk: 57,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Norwegians',
      ethnicity: 'Norwegian',
    },
  },
  {
    pk: 58,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Thai Americans',
      ethnicity: 'Thai',
    },
  },
  {
    pk: 59,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Chileans',
      ethnicity: 'Chilean',
    },
  },
  {
    pk: 60,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Cuban Americans',
      ethnicity: 'Cuban',
    },
  },
  {
    pk: 61,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Cuban newspapers',
      ethnicity: 'Cuban',
    },
  },
  {
    pk: 62,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Cubans',
      ethnicity: 'Cuban',
    },
  },
  {
    pk: 63,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Hawaiians',
      ethnicity: 'Pacific Islander',
    },
  },
  {
    pk: 64,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Samoans',
      ethnicity: 'Pacific Islander',
    },
  },
  {
    pk: 65,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Scandinavian Americans',
      ethnicity: 'Scandinavian',
    },
  },
  {
    pk: 66,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Scandinavians',
      ethnicity: 'Scandinavian',
    },
  },
  {
    pk: 67,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Greek Americans',
      ethnicity: 'Greek',
    },
  },
  {
    pk: 68,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Greek newspapers.',
      ethnicity: 'Greek',
    },
  },
  {
    pk: 69,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Greeks',
      ethnicity: 'Greek',
    },
  },
  {
    pk: 70,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Latvian Americans',
      ethnicity: 'Latvian',
    },
  },
  {
    pk: 71,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Latvians',
      ethnicity: 'Latvian',
    },
  },
  {
    pk: 72,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Africans',
      ethnicity: 'African',
    },
  },
  {
    pk: 73,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Croat Americans',
      ethnicity: 'Croatian',
    },
  },
  {
    pk: 74,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Croatian Americans',
      ethnicity: 'Croatian',
    },
  },
  {
    pk: 75,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Croats',
      ethnicity: 'Croatian',
    },
  },
  {
    pk: 76,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Aleuts',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 77,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Apache Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 78,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Arapaho Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 79,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Assiniboine Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 80,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Caddo Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 81,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Chautauquas',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 82,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Cherokee Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 83,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Cherokee Indians.',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 84,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Cheyenne Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 85,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Chickasaw Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 86,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Choctaw Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 87,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Comanche Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 88,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Creek Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 89,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Dakota Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 90,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Eskimos',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 91,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Fox Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 92,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Havasupai Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 93,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Hopi Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 94,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Hualapai Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 95,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Hupa Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 96,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Indian reservations',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 97,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Iowa Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 98,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Iroquois Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 99,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Kamia Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 100,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Klamath Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 101,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Lumbee Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 102,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Lummi Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 103,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Maricopa Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 104,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Mikasuki Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 105,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Mohave Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 106,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Mohegan Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 107,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Navajo Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 108,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Navajo Tribe',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 109,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Nez Perce? Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 110,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Nisqually Tribe',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 111,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Oglala Sioux Tribe',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 112,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Ojibwa Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 113,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Oneida Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 114,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Osage Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 115,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Oto Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 116,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Paiute Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 117,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Passamaquaddy Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 118,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Pawnee Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 119,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Penobscot Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 120,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Pima Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 121,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Potawatomi Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 122,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Powhatan Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 123,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Puyallup Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 124,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Quapaw Agency',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 125,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Quileute Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 126,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Salish Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 127,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Seminole Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 128,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Seneca Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 129,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Shawnee Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 130,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Shoshoni Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 131,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Siksika Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 132,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Siletz Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 133,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Siouan Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 134,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Sisseton Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 135,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Skitswish Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 136,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Skokomish Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 137,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Suquamish Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 138,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Swinomish Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 139,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Tlingit Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 140,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: "Tohono O'Odham Indians",
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 141,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Tsimshian Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 142,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Ute Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 143,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Washo Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 144,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Winnebago Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 145,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Yakama Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 146,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Yurok Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 147,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Zuni Indians',
      ethnicity: 'Indians of North America',
    },
  },
  {
    pk: 148,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Ethiopians',
      ethnicity: 'Ethiopian',
    },
  },
  {
    pk: 149,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Ecuadorians',
      ethnicity: 'Ecuadorian',
    },
  },
  {
    pk: 150,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Syrians',
      ethnicity: 'Syrian',
    },
  },
  {
    pk: 151,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Icelanders',
      ethnicity: 'Icelandic',
    },
  },
  {
    pk: 152,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Luxembourgers',
      ethnicity: 'Luxembourger',
    },
  },
  {
    pk: 153,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Turks',
      ethnicity: 'Turkish',
    },
  },
  {
    pk: 154,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Assyrians',
      ethnicity: 'Middle Eastern',
    },
  },
  {
    pk: 155,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Bangladeshi Americans',
      ethnicity: 'Bangladeshi',
    },
  },
  {
    pk: 156,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Bangladeshis',
      ethnicity: 'Bangladeshi',
    },
  },
  {
    pk: 157,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Hispanic American Catholics',
      ethnicity: 'Latin American',
    },
  },
  {
    pk: 158,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Hispanic American newspapers',
      ethnicity: 'Latin American',
    },
  },
  {
    pk: 159,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Hispanic Americans',
      ethnicity: 'Latin American',
    },
  },
  {
    pk: 160,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Hispanics',
      ethnicity: 'Latin American',
    },
  },
  {
    pk: 161,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Latin Americans',
      ethnicity: 'Latin American',
    },
  },
  {
    pk: 162,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Nicaraguans',
      ethnicity: 'Latin American',
    },
  },
  {
    pk: 163,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Puerto Ricans',
      ethnicity: 'Latin American',
    },
  },
  {
    pk: 164,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Brazilians',
      ethnicity: 'Brazilian',
    },
  },
  {
    pk: 165,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Korean Americans',
      ethnicity: 'Korean',
    },
  },
  {
    pk: 166,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Korean newspapers',
      ethnicity: 'Korean',
    },
  },
  {
    pk: 167,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Koreans',
      ethnicity: 'Korean',
    },
  },
  {
    pk: 168,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Pakistani Americans',
      ethnicity: 'Pakistani',
    },
  },
  {
    pk: 169,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Pakistanis',
      ethnicity: 'Pakistani',
    },
  },
  {
    pk: 170,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Yugoslavs',
      ethnicity: 'Yugoslavian',
    },
  },
  {
    pk: 172,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'East Indian Americans',
      ethnicity: 'East Indian',
    },
  },
  {
    pk: 173,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'East Indians',
      ethnicity: 'East Indian',
    },
  },
  {
    pk: 174,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Hungarian Americans',
      ethnicity: 'Hungarian',
    },
  },
  {
    pk: 175,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Hungarians',
      ethnicity: 'Hungarian',
    },
  },
  {
    pk: 176,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Bosnians',
      ethnicity: 'Bosnian',
    },
  },
  {
    pk: 177,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Lithuanian American Catholics',
      ethnicity: 'Lithuanian',
    },
  },
  {
    pk: 178,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Lithuanian American communists',
      ethnicity: 'Lithuanian',
    },
  },
  {
    pk: 179,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Lithuanian Americans',
      ethnicity: 'Lithuanian',
    },
  },
  {
    pk: 180,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Lithuanians',
      ethnicity: 'Lithuanian',
    },
  },
  {
    pk: 181,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Russian Americans',
      ethnicity: 'Russian',
    },
  },
  {
    pk: 182,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Russian literature',
      ethnicity: 'Russian',
    },
  },
  {
    pk: 183,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Russians',
      ethnicity: 'Russian',
    },
  },
  {
    pk: 184,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Amish',
      ethnicity: 'Anabaptist',
    },
  },
  {
    pk: 185,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Amish Mennonites',
      ethnicity: 'Anabaptist',
    },
  },
  {
    pk: 186,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Mennonites',
      ethnicity: 'Anabaptist',
    },
  },
  {
    pk: 187,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Pennsylvania Dutch',
      ethnicity: 'Anabaptist',
    },
  },
  {
    pk: 188,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Pennsylvania Dutch.',
      ethnicity: 'Anabaptist',
    },
  },
  {
    pk: 189,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Malayalis (Indic people)',
      ethnicity: 'Malaysian',
    },
  },
  {
    pk: 190,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'African American banks',
      ethnicity: 'African American',
    },
  },
  {
    pk: 191,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'African American Baptists',
      ethnicity: 'African American',
    },
  },
  {
    pk: 192,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'African American business enterprises',
      ethnicity: 'African American',
    },
  },
  {
    pk: 193,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'African American Catholics',
      ethnicity: 'African American',
    },
  },
  {
    pk: 194,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'African American freemasons',
      ethnicity: 'African American',
    },
  },
  {
    pk: 195,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'African American Methodists',
      ethnicity: 'African American',
    },
  },
  {
    pk: 196,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'African American newspapers',
      ethnicity: 'African American',
    },
  },
  {
    pk: 197,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'African American newspapers.',
      ethnicity: 'African American',
    },
  },
  {
    pk: 198,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'African American students',
      ethnicity: 'African American',
    },
  },
  {
    pk: 199,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'African Americans',
      ethnicity: 'African American',
    },
  },
  {
    pk: 200,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'African-American',
      ethnicity: 'African American',
    },
  },
  {
    pk: 201,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Back to Africa movement',
      ethnicity: 'African American',
    },
  },
  {
    pk: 204,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Black Muslims',
      ethnicity: 'African American',
    },
  },
  {
    pk: 205,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Blacks',
      ethnicity: 'African American',
    },
  },
  {
    pk: 207,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Irish Americans',
      ethnicity: 'Irish',
    },
  },
  {
    pk: 208,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Irish-American newspapers.',
      ethnicity: 'Irish',
    },
  },
  {
    pk: 209,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Chinese Americans',
      ethnicity: 'Chinese',
    },
  },
  {
    pk: 210,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Chinese newspapers',
      ethnicity: 'Chinese',
    },
  },
  {
    pk: 211,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Chinese-American newspapers',
      ethnicity: 'Chinese',
    },
  },
  {
    pk: 213,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'German American newspapers',
      ethnicity: 'German',
    },
  },
  {
    pk: 214,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'German American newspapers.',
      ethnicity: 'German',
    },
  },
  {
    pk: 215,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'German Americans',
      ethnicity: 'German',
    },
  },
  {
    pk: 216,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'German-American newspapers',
      ethnicity: 'German',
    },
  },
  {
    pk: 217,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Germans',
      ethnicity: 'German',
    },
  },
  {
    pk: 219,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Belarusian Americans',
      ethnicity: 'Belarusian',
    },
  },
  {
    pk: 220,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Belarusians',
      ethnicity: 'Belarusian',
    },
  },
  {
    pk: 221,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Macedonians',
      ethnicity: 'Macedonian',
    },
  },
  {
    pk: 222,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Basque Americans',
      ethnicity: 'Basque',
    },
  },
  {
    pk: 223,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Basques',
      ethnicity: 'Basque',
    },
  },
  {
    pk: 224,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Vietnamese Americans',
      ethnicity: 'Vietnamese',
    },
  },
  {
    pk: 225,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Romanian Americans',
      ethnicity: 'Romanian',
    },
  },
  {
    pk: 226,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Romanians',
      ethnicity: 'Romanian',
    },
  },
  {
    pk: 227,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Dutch Americans',
      ethnicity: 'Dutch',
    },
  },
  {
    pk: 228,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Cape Verdean Americans',
      ethnicity: 'Cape Verdean',
    },
  },
  {
    pk: 229,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Cape Verdeans',
      ethnicity: 'Cape Verdean',
    },
  },
  {
    pk: 230,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Ukrainian Americans',
      ethnicity: 'Ukranian',
    },
  },
  {
    pk: 231,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Ukrainians',
      ethnicity: 'Ukranian',
    },
  },
  {
    pk: 232,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Cajuns',
      ethnicity: 'Cajun',
    },
  },
  {
    pk: 233,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Filipino Americans',
      ethnicity: 'Filipino',
    },
  },
  {
    pk: 234,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Filipinos',
      ethnicity: 'Filipino',
    },
  },
  {
    pk: 235,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Filippino',
      ethnicity: 'Filipino',
    },
  },
  {
    pk: 236,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Welsh Americans',
      ethnicity: 'Welsh',
    },
  },
  {
    pk: 237,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Antillians',
      ethnicity: 'West Indian',
    },
  },
  {
    pk: 238,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Canadians',
      ethnicity: 'Canadian',
    },
  },
  {
    pk: 239,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Humour que?be?cois',
      ethnicity: 'Canadian',
    },
  },
  {
    pk: 240,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Journaux canadiens-anglais',
      ethnicity: 'Canadian',
    },
  },
  {
    pk: 241,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Arab Americans',
      ethnicity: 'Arab',
    },
  },
  {
    pk: 242,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Arabs',
      ethnicity: 'Arab',
    },
  },
  {
    pk: 243,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Hmong',
      ethnicity: 'Cambodian',
    },
  },
  {
    pk: 244,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Hmong Americans',
      ethnicity: 'Cambodian',
    },
  },
  {
    pk: 245,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'British Americans',
      ethnicity: 'British',
    },
  },
  {
    pk: 248,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Slavic Americans',
      ethnicity: 'Slavic',
    },
  },
  {
    pk: 249,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Slavs',
      ethnicity: 'Slavic',
    },
  },
  {
    pk: 251,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Porguguese-Americans',
      ethnicity: 'Portuguese',
    },
  },
  {
    pk: 252,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Portuguese Americans',
      ethnicity: 'Portuguese',
    },
  },
  {
    pk: 253,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Mexican American students',
      ethnicity: 'Mexican',
    },
  },
  {
    pk: 254,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Mexican American youth',
      ethnicity: 'Mexican',
    },
  },
  {
    pk: 255,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Mexican Americans',
      ethnicity: 'Mexican',
    },
  },
  {
    pk: 256,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Mexican Americans (Calif.)',
      ethnicity: 'Mexican',
    },
  },
  {
    pk: 257,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Mexicans',
      ethnicity: 'Mexican',
    },
  },
  {
    pk: 258,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Japanese Americans',
      ethnicity: 'Japanese',
    },
  },
  {
    pk: 259,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Ryukyuans',
      ethnicity: 'Japanese',
    },
  },
  {
    pk: 261,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Asian Americans',
      ethnicity: 'Asian',
    },
  },
  {
    pk: 262,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Asians',
      ethnicity: 'Asian',
    },
  },
  {
    pk: 263,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Spaniards',
      ethnicity: 'Spanish',
    },
  },
  {
    pk: 264,
    model: 'core.EthnicitySynonym',
    fields: {
      synonym: 'Spanish Americans',
      ethnicity: 'Spanish',
    },
  },
];

export { RACES };
