import { CampaignsManagementTasks } from './campaign-management-tasks.model';
import { BaseModel } from '../base.model';
import { CampaignsManagementTaskIssues } from './campaign-management-task-issues.model';
import { CampaignsManagementTaskLogsAction } from '../../lists/campaigns-management-task-logs.enum';

export const CAMPAIGNS_MANAGEMENT_TASKS_LOGS_COLLECTION_NAME = 'campaign-management-task-logs';

export interface RelatedCampaignsManagementTaskLogs {
  data: CampaignManagementTaskLogs;
  parentDbId: string;
}

export enum CampaignsManagementTaskLogsKeys {
  action = 'action',
  agentDbId = 'agentDbId',
  taskDbId = 'taskDbId',
  taskChanges = 'taskChanges',
  issuesChanges = 'issuesChanges',
}

export class CampaignManagementTaskLogs extends BaseModel {
  // [CampaignsManagementTaskLogsKeys.agentDbId]: string;
  [CampaignsManagementTaskLogsKeys.taskDbId]: string;
  [CampaignsManagementTaskLogsKeys.action]: CampaignsManagementTaskLogsAction;
  [CampaignsManagementTaskLogsKeys.taskChanges]?: Partial<CampaignsManagementTasks>;
  [CampaignsManagementTaskLogsKeys.issuesChanges]?: Partial<CampaignsManagementTaskIssues>;
}
