export const environment = {
  production: false,
  defaultauth: 'firebase',
  firebase: {
    apiKey: 'AIzaSyAgjzI_OV-gJIR487dmGqAGlpbK3Rr77mo',
    authDomain: 'alliance-group-portal-prod.firebaseapp.com',
    projectId: 'alliance-group-portal-prod',
    storageBucket: 'alliance-group-portal-prod.appspot.com',
    messagingSenderId: '1088177355571',
    appId: '1:1088177355571:web:1e135d294885409b8d5c46',
    measurementId: 'G-D96H4JP0B1',
  },
  domain: 'localhost',
  session_expires: 120,
};
