import { LookupBase } from '../models/utils/lookup-base.model';

export enum Role {
  ANONYMOUS = 'ANONYMOUS',
  AGENT = 'AGENT',
  MGA = 'MGA',
  RMD = 'RMD',
  MANAGER = 'MANAGER',
  PORTAL_ADMIN = 'PORTAL_ADMIN',
  USER_ADMIN = 'USER_ADMIN',
  STORE_ADMIN = 'STORE_ADMIN',
  CONTENT_ADMIN = 'CONTENT_ADMIN',
  REPORT_ADMIN = 'REPORT_ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  CAMPAIGNS_MANAGER = 'CAMPAIGNS_MANAGER',
  CONFERENCE_MANAGER = 'CONFERENCE_MANAGER',
}

export const RoleDescriptionMap: Map<Role, string> = new Map([
  [Role.ANONYMOUS, 'Anonymous'],
  [Role.AGENT, 'Agent'],
  [Role.MGA, 'MGA'],
  [Role.RMD, 'RMD'],
  [Role.MANAGER, 'Manager'],
  [Role.PORTAL_ADMIN, 'Portal Admin'],
  [Role.USER_ADMIN, 'User Admin'],
  [Role.STORE_ADMIN, 'Store Admin'],
  [Role.CONTENT_ADMIN, 'Content Admin'],
  [Role.REPORT_ADMIN, 'Report Admin'],
  [Role.SUPER_ADMIN, 'Super Admin'],
  [Role.CAMPAIGNS_MANAGER, 'Campaigns Manager'],
  [Role.CONFERENCE_MANAGER, 'Conference Manager'],
]);

export const ROLE_LOOKUP: LookupBase[] = [
  {
    value: Role.ANONYMOUS,
    description: RoleDescriptionMap.get(Role.ANONYMOUS),
  },
  {
    value: Role.AGENT,
    description: RoleDescriptionMap.get(Role.AGENT),
  },
  {
    value: Role.MGA,
    description: RoleDescriptionMap.get(Role.MGA),
  },
  {
    value: Role.RMD,
    description: RoleDescriptionMap.get(Role.RMD),
  },
  {
    value: Role.MANAGER,
    description: RoleDescriptionMap.get(Role.MANAGER),
  },
  {
    value: Role.PORTAL_ADMIN,
    description: RoleDescriptionMap.get(Role.PORTAL_ADMIN),
  },
  {
    value: Role.USER_ADMIN,
    description: RoleDescriptionMap.get(Role.USER_ADMIN),
  },
  {
    value: Role.STORE_ADMIN,
    description: RoleDescriptionMap.get(Role.STORE_ADMIN),
  },
  {
    value: Role.CONTENT_ADMIN,
    description: RoleDescriptionMap.get(Role.CONTENT_ADMIN),
  },
  {
    value: Role.REPORT_ADMIN,
    description: RoleDescriptionMap.get(Role.REPORT_ADMIN),
  },
  {
    value: Role.SUPER_ADMIN,
    description: RoleDescriptionMap.get(Role.SUPER_ADMIN),
  },
  {
    value: Role.CAMPAIGNS_MANAGER,
    description: RoleDescriptionMap.get(Role.CAMPAIGNS_MANAGER),
  },
  {
    value: Role.CONFERENCE_MANAGER,
    description: RoleDescriptionMap.get(Role.CONFERENCE_MANAGER),
  },
];
