import { BehaviorSubject, Subject } from 'rxjs';
import { FormChangesDetector } from 'ag-common-svc/shared/utils';
import { map } from 'rxjs/operators';
import { confirm } from 'devextreme/ui/dialog';
import { set, unset } from 'lodash';

export enum BaseFormActions {
  created = 'created',
  updated = 'updated',
  fieldsUpdated = 'fieldsUpdated',
}

export class BaseFormService<T extends object = null> {
  formData: T;

  private readonly _inProgress$ = new BehaviorSubject<boolean>(false);
  inProgress$ = this._inProgress$.asObservable();

  private _isDataLoading$ = new BehaviorSubject<boolean>(false);
  isDataLoading$ = this._isDataLoading$.asObservable();

  readonly formChangesDetector: FormChangesDetector = new FormChangesDetector();
  hasFormChanges$ = this.formChangesDetector.actions$.pipe(
    map(() => {
      return this.formChangesDetector.hasChanges;
    }),
  );

  private _actions$ = new Subject<BaseFormActions>();
  actions$ = this._actions$.asObservable();

  constructor() {
    this.revertAllChanges.bind(this);
  }

  /**
   * @return {Boolean} Returns true in case data resets
   */
  onCancelEdit = async (onAfterRevertChanges?: () => void): Promise<boolean> => {
    if (!this.formChangesDetector?.hasChanges) {
      return true;
    }

    const isConfirmed = await confirm('<i>Are you sure you want to Cancel without Saving?</i>', 'Confirm');
    if (isConfirmed) {
      this.revertAllChanges();
      onAfterRevertChanges && onAfterRevertChanges();
    }

    return isConfirmed;
  };

  revertAllChanges() {
    const changes = this.formChangesDetector.getAllChanges();
    changes.forEach(([key, value]) => {
      set(this.formData, key, value);
    });
    const added = this.formChangesDetector.getAllAdded();

    added.forEach((key: string) => {
      unset(this.formData, key);
    });

    this.formChangesDetector.clear();
  }

  protected startProgress() {
    this._inProgress$.next(true);
  }

  protected stopProgress() {
    this._inProgress$.next(false);
  }

  protected startLoadData() {
    this._isDataLoading$.next(true);
  }

  protected stopLoadData() {
    this._isDataLoading$.next(false);
  }

  protected onSuccessfulCreated() {
    this.formChangesDetector.clear();
    this._actions$.next(BaseFormActions.created);
  }

  protected onSuccessfulUpdated(updatedFields: string[]) {
    this.formChangesDetector.clearByKeys(updatedFields);
    this._actions$.next(BaseFormActions.updated);
  }
}
