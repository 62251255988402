import { Inject, NgModule, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AGStripeModalComponent } from './ag-stripe-modal.component';
import { DxLoadPanelModule } from 'devextreme-angular';
import { ButtonWithIndicatorModule, ModalWindowModule } from '..';
import { CloudFunctionsService } from '../../services/cloud-functions.service';
import { NgxStripeModule, StripeFactoryService } from 'ngx-stripe';
import { STRIPE_PUBLIC_KEY } from './ag-stripe-modal.models';

@NgModule({
  declarations: [AGStripeModalComponent],
  imports: [CommonModule, NgxStripeModule, ButtonWithIndicatorModule, ModalWindowModule, DxLoadPanelModule],
  providers: [StripeFactoryService, CloudFunctionsService],
  exports: [AGStripeModalComponent],
})
export class AgStripeFormModule {
  constructor(@Optional() @Inject(STRIPE_PUBLIC_KEY) stripePublicKey: string) {
    if (!stripePublicKey) {
      throw new Error('STRIPE_PUBLIC_KEY is not provided. Make sure to provide it in the parent module.');
    }

    NgxStripeModule.forRoot(stripePublicKey);
  }
}
