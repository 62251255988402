import { Component, HostBinding, Input } from '@angular/core';
import { AgentCampaignPhoneNumberInfoService } from './agent-campaign-phone-number-info.service';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import validationEngine from 'devextreme/ui/validation_engine';
import { PhoneNumber } from '@ag-common-lib/public-api';

@UntilDestroy()
@Component({
  selector: 'ag-shr-agent-campaign-phone-number-info',
  templateUrl: './agent-campaign-phone-number-info.component.html',
  styleUrls: ['./agent-campaign-phone-number-info.component.scss'],
})
export class AgentCampaignPhoneNumberInfoComponent {
  @HostBinding('class') className = 'agent-campaign-phone-number-info agent-campaign-wizard__content-container';

  @Input() hasStepIssues: boolean;
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;

  protected readonly inProgress$: Observable<boolean>;
  protected phoneNumberFormData$: Observable<PhoneNumber>;
  protected agentPhoneNumbers$: Observable<PhoneNumber[]>;
  protected selectedPhoneNumberKey: string;
  protected hasFormChanges$: Observable<boolean>;
  validationGroup = 'agentCampaignPhoneNumberInfoValidationGroup';

  constructor(private agentCampaignPhoneNumberInfoService: AgentCampaignPhoneNumberInfoService) {
    this.phoneNumberFormData$ = agentCampaignPhoneNumberInfoService.campaignContactInfoPhoneNumber$;

    this.agentPhoneNumbers$ = agentCampaignPhoneNumberInfoService.agentPhoneNumbers$;
    this.inProgress$ = agentCampaignPhoneNumberInfoService.inProgress$;
    this.hasFormChanges$ = agentCampaignPhoneNumberInfoService.hasFormChanges$;
  }

  protected nextStep = () => {
    const validationResults = validationEngine.validateGroup(this.validationGroup);

    if (!validationResults.isValid) {
      return;
    }

    this.agentCampaignPhoneNumberInfoService.handleNextStepClick(this.selectedPhoneNumberKey);
  };

  protected resetChanges = () => {
    this.agentCampaignPhoneNumberInfoService.revertAllChanges();
  };
}
