import { BaseModelKeys } from '../models/base.model';
import { LookupKeys } from '../models/crm/lookup.model';
import { BUSINESS_PERSONAL_TYPE } from './business-personal-type.enum';

export const BUSINESS_PERSONAL_TYPE_LOOKUP = [
  {
    [BaseModelKeys.dbId]: BUSINESS_PERSONAL_TYPE.BUSINESS,
    [LookupKeys.value]: BUSINESS_PERSONAL_TYPE.BUSINESS,
    [LookupKeys.description]: BUSINESS_PERSONAL_TYPE.BUSINESS,
  },
  {
    [BaseModelKeys.dbId]: BUSINESS_PERSONAL_TYPE.PERSONAL,
    [LookupKeys.value]: BUSINESS_PERSONAL_TYPE.PERSONAL,
    [LookupKeys.description]: BUSINESS_PERSONAL_TYPE.PERSONAL,
  },
];
