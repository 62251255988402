import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxScrollViewModule, DxTabPanelModule, DxValidationSummaryModule, DxValidatorModule } from 'devextreme-angular';
import { AgStripeFormModule, HtmlEditorModule, ModalWindowModule } from 'ag-common-svc/lib/components';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AttendeeTransactionModalComponent } from './attendee-transaction-modal.component';
import { AttendeeTransactionModalService } from './attendee-transaction-modal.service';

@NgModule({
  declarations: [AttendeeTransactionModalComponent],
  exports: [AttendeeTransactionModalComponent],
  imports: [
    CommonModule,
    SharedModule,
    DxScrollViewModule,
    DxTabPanelModule,
    DxValidationSummaryModule,
    HtmlEditorModule,
    ModalWindowModule,
    DxValidatorModule,
    AgStripeFormModule,
  ],
  providers: [AttendeeTransactionModalService],
})
export class AttendeeTransactionModalModule {}
