import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgTabsComponent } from './ag-tabs.component';
import { DxTabsModule } from 'devextreme-angular';

@NgModule({
  declarations: [AgTabsComponent],
  imports: [CommonModule, DxTabsModule],
  exports: [AgTabsComponent],
})
export class AgTabsModule {}
