<dx-load-panel
  *ngIf="isSecretLoading$ | async"
  shadingColor="rgba(0,0,0,0.4)"
  [visible]="true"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>

<ag-shr-modal-window
  #modalWindowRef
  [title]="'Payment'"
  [width]="'500px'"
  [height]="'auto'"
  [useScrollView]="false"
  [inProgress]="inProgress$ | async"
  [actionTitle]="actionTitle$ | async"
  (onSaveClick)="handlePayment()"
  [onCloseModal]="onCloseModal"
  [saveButtonDisabled]="!isCompleted"
>
  <div #rootContainerRef *ngIf="modalWindowRef?.popupComponent?.visible ?? false" class="stripe-modal__container">
    <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
      <ngx-stripe-payment #payment [options]="paymentElementOptions" (change)="onChange($event)"></ngx-stripe-payment>
    </ngx-stripe-elements>
  </div>
</ag-shr-modal-window>
