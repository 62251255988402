import { NgModule } from '@angular/core';
import { VimeoService } from './vimeo.service';
import { VimeoByUriPipe } from './vimeoByUri.pipe';

@NgModule({
  imports: [],
  declarations: [VimeoByUriPipe],
  exports: [VimeoByUriPipe],
  providers: [VimeoService],
})
export class VimeoModule {}
