import { Injectable } from '@angular/core';
import { AgentCampaignWizardService } from '../agent-campaign-wizard.service';
import {
  Address,
  AgentCampaignContactInfoAddressMetaData,
  AgentCampaignKeys,
  AgentCampaignStepName,
} from '@ag-common-lib/public-api';
import { Observable, combineLatest, filter, map, shareReplay, startWith } from 'rxjs';
import { BaseFormService } from '../../../utils/base-form-service';
import { getAddressKeyExpression } from 'ag-common-svc/lib/utils/address.util';
import { pickAddressData } from 'ag-common-svc/public-api';
import { FormChangesDetectorActionType } from 'ag-common-svc/shared/utils';

@Injectable()
export class AgentCampaignAddressInfoService extends BaseFormService<any> {
  agentAddresses$: Observable<Address[]>;
  contactInfoAddressMetaData$: Observable<AgentCampaignContactInfoAddressMetaData>;

  campaignContactInfoAddress$: Observable<Partial<Address>>;

  constructor(private agentCampaignWizardService: AgentCampaignWizardService) {
    super();
    this.contactInfoAddressMetaData$ = this.agentCampaignWizardService.campaign$.pipe(
      map(campaign => campaign?.[AgentCampaignKeys.metaData]?.[AgentCampaignStepName.contactInfoAddress]),
    );

    this.agentAddresses$ = agentCampaignWizardService.agent$.pipe(
      map(agent => agent?.addresses ?? []),

      shareReplay(1),
    );

    this.campaignContactInfoAddress$ = combineLatest({
      campaign: this.agentCampaignWizardService.campaign$,
      _resetTrigger: this.formChangesDetector.actions$.pipe(
        filter(action => action?.type === FormChangesDetectorActionType.reset),
        startWith(null),
      ),
    }).pipe(
      map(({ campaign }) => {
        const address = campaign?.[AgentCampaignKeys.data]?.[AgentCampaignStepName.contactInfoAddress];
        const draftAddress = campaign?.[AgentCampaignKeys.draftData]?.[AgentCampaignStepName.contactInfoAddress];

        return Object.assign({}, address, draftAddress);
      }),
      map(this.getFormData),
      shareReplay(1),
    );
  }

  handleNextStepClick = async (selectedAddressKey: string) => {
    const changes = this.formChangesDetector.getAllChanges();

    if (!changes?.length) {
      this.agentCampaignWizardService.nextStep();
      return;
    }

    const updates = Object.assign({}, this.formData);

    this.startProgress();
    await this.agentCampaignWizardService
      .handleStepChanges(
        {
          [AgentCampaignStepName.contactInfoAddress]: updates,
        },
        {
          [AgentCampaignStepName.contactInfoAddress]: {
            selectedKey: selectedAddressKey ?? null,
          },
        },
      )
      .then(() => {
        this.formChangesDetector.clear();
        this.agentCampaignWizardService.nextStep();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  private getFormData = (address?: Partial<Address>) => {
    const initialData = pickAddressData(address);
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);

          switch (prop) {
            case '':
          }
        }

        return true;
      },
    });

    return this.formData;
  };
}
