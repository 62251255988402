import { Injectable } from '@angular/core';
import { AgentCampaignWizardService } from '../agent-campaign-wizard.service';
import { AgentCampaignKeys, AgentCampaignStepName, CampaignExtras } from '@ag-common-lib/public-api';
import { BehaviorSubject, map } from 'rxjs';
import { BaseFormService } from '../../../utils/base-form-service';

@Injectable()
export class AgentCampaignExtraService extends BaseFormService<Partial<CampaignExtras>> {
  formData$ = new BehaviorSubject<Partial<CampaignExtras>>(this.formData);

  constructor(private agentCampaignWizardService: AgentCampaignWizardService) {
    super();

    const campaignCampaignExtras$ = this.agentCampaignWizardService.campaign$.pipe(
      map(campaign => {
        const campaignExtras = campaign?.[AgentCampaignKeys.data]?.[AgentCampaignStepName.extras];
        const draftExtras = campaign?.[AgentCampaignKeys.draftData]?.[AgentCampaignStepName.extras];

        return Object.assign({}, campaignExtras, draftExtras);
      }),
    );

    campaignCampaignExtras$.subscribe(this.getFormData);
  }

  handleNextStepClick = async () => {
    const changes = this.formChangesDetector.getAllChanges();

    if (!changes?.length) {
      this.agentCampaignWizardService.nextStep();
      return;
    }

    const updates = {};

    changes.forEach(([key]) => {
      Object.assign(updates, { [key]: this.formData[key] });
    });

    this.startProgress();
    await this.agentCampaignWizardService
      .handleStepChanges({
        [AgentCampaignStepName.extras]: updates,
      })
      .then(() => {
        this.formChangesDetector.clear();
        this.agentCampaignWizardService.nextStep();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  private getFormData = (campaignExtras?: Partial<CampaignExtras>) => {
    const initialData = Object.assign({}, campaignExtras);
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);

          switch (prop) {
            case '':
          }
        }

        return true;
      },
    });

    this.formData$.next(this.formData);
  };
}
