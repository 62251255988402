import { Pipe, PipeTransform } from '@angular/core';
import { WizardStepState, WizardStep } from '@ag-common-lib/public-api';

@Pipe({ name: 'wizardStepperSubStepState' })
export class WizardStepperSubStepStatePipe implements PipeTransform {
  constructor() {}

  transform(currentStep: string, step?: WizardStep<any>): string | string[] {
    const classes = [];

    if (step.name === currentStep) {
      classes.push('wizard-stepper__sub-step--current');
    }

    if (step.stepState === WizardStepState.issues) {
      classes.push('wizard-stepper__sub-step--issues');
    }

    if (step.stepState === WizardStepState.done) {
      classes.push('wizard-stepper__sub-step--done');
    }

    if (step.stepState === WizardStepState.locked) {
      classes.push('wizard-stepper__sub-step--locked');
    }

    if (step.stepState === WizardStepState.changed) {
      classes.push('wizard-stepper__sub-step--changed');
    }

    if (step.stepState === WizardStepState.skipped) {
      classes.push('wizard-stepper__sub-step--skipped');
    }

    return classes;
  }
}
