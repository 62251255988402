<ag-shr-agent-campaign-summary-list-item [title]="'External Links'">
  <ng-container agCampaignsActions>
    <ng-content select="[agCampaignsControls]"></ng-content>
  </ng-container>

  <ag-shr-agent-campaign-summary-list-data
    *ngFor="let item of list"
    [hasCurrentData]="!!item?.currentStepData"
    [hasIncomingData]="!!item?.incomingStepData"
  >
    <ng-template
      #websiteDataTmp
      let-data="data"
    >
      {{data?.title}} ({{data?.url}})
    </ng-template>

    <ng-container
      currentDataTmp
      *ngTemplateOutlet="websiteDataTmp; context: { data: item?.currentStepData }"
    >
    </ng-container>

    <ng-container
      incomingDataTmp
      *ngTemplateOutlet="websiteDataTmp; context: { data: item?.incomingStepData }"
    >
    </ng-container>

    <ng-container
      controls
      *var="item?.incomingStepData as data"
    >
      <ag-shr-clipboard-button [clipboardValues]="[{value: data?.title, description: 'Title' }, {value: data?.url, description: 'Url' }]"></ag-shr-clipboard-button>
      <ng-content select="[agControls]"></ng-content>
    </ng-container>

  </ag-shr-agent-campaign-summary-list-data>
</ag-shr-agent-campaign-summary-list-item>
