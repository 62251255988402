import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxScrollViewModule, DxValidatorModule } from 'devextreme-angular';
import { ModalWindowModule } from '../modal-window/modal-window.module';
import { SharedModule } from '../../../shared/shared.module';
import { AttendeeManualRefundPaymentModalComponent } from '../attendee-manual-refund-payment-modal/attendee-manual-refund-payment-modal.component';
import { AttendeeManualRefundPaymentModalService } from '../attendee-manual-refund-payment-modal/attendee-manual-refund-payment-modal.service';

@NgModule({
  declarations: [AttendeeManualRefundPaymentModalComponent],
  exports: [AttendeeManualRefundPaymentModalComponent],
  imports: [CommonModule, SharedModule, DxScrollViewModule, ModalWindowModule, DxValidatorModule],
  providers: [AttendeeManualRefundPaymentModalService],
})
export class AttendeeManualRefundPaymentModalModule {}
