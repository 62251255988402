enum GranularityLevel {
  SUB_PREMISE = 'SUB_PREMISE',
  PREMISE = 'PREMISE',
  STREET = 'STREET',
  LOCALITY = 'LOCALITY',
  REGION = 'REGION',
  COUNTRY = 'COUNTRY',
  UNKNOWN = 'UNKNOWN',
}

const granularityInstructions: Record<GranularityLevel, string> = {
  [GranularityLevel.SUB_PREMISE]:
    'The input is very precise and refers to a specific unit, apartment, or suite. No further action needed.',
  [GranularityLevel.PREMISE]:
    'The input refers to a building or property but lacks specific unit details. Ensure that unit or apartment details are correct if applicable.',
  [GranularityLevel.STREET]:
    'The input is on a street-level, missing specific building or premise information. Please add more details like building or apartment number.',
  [GranularityLevel.LOCALITY]:
    'The input only specifies a city or town, and lacks street or building-level details. Add the full street address, including building number.',
  [GranularityLevel.REGION]:
    'The input only specifies a region, like a state or province. Add more specific information like the city and street address.',
  [GranularityLevel.COUNTRY]:
    'The input only specifies a country, which is too vague. Add more detailed information such as region, city, and street.',
  [GranularityLevel.UNKNOWN]:
    'The input granularity is unknown or incomplete. Please check the address and provide more accurate details.',
};

// Type for the 'verdict' section
export interface Verdict {
  inputGranularity: GranularityLevel;
  validationGranularity: GranularityLevel;
  geocodeGranularity: GranularityLevel;
  addressComplete: boolean;
  hasInferredComponents: boolean;
  hasUnconfirmedComponents: boolean;
}

// Type for address components
export interface AddressComponent {
  componentName: {
    text: string;
    languageCode?: string;
  };
  componentType: string;
  confirmationLevel: string;
  inferred?: boolean;
}

// Type for the geocode section
export interface Geocode {
  location: {
    latitude: number;
    longitude: number;
  };
  plusCode?: {
    globalCode: string;
  };
  bounds?: {
    low: {
      latitude: number;
      longitude: number;
    };
    high: {
      latitude: number;
      longitude: number;
    };
  };
  placeId: string;
  placeTypes: string[];
}

// Type for USPS Data
export interface UspsData {
  standardizedAddress: {
    firstAddressLine: string;
    cityStateZipAddressLine: string;
    city: string;
    state: string;
    zipCode: string;
    zipCodeExtension: string;
  };
  deliveryPointCode: string;
  dpvConfirmation: string;
  dpvFootnote: string;
  carrierRoute: string;
  fipsCountyCode: string;
  county: string;
}
export interface ValidationResults {
  verdict: Verdict;
  address: {
    formattedAddress: string;
    postalAddress: {
      regionCode: string;
      languageCode: string;
      postalCode: string;
      administrativeArea: string;
      locality: string;
      addressLines: string[];
    };
    addressComponents: AddressComponent[];
    missingComponentTypes: string[]; // List of missing components
    unconfirmedComponentTypes: string[]; // List of unconfirmed components
    resolvedComponentTypes: string[]; // List of resolved components
  };
  geocode: Geocode;
  metadata: {
    business: boolean;
    poBox: boolean;
    residential: boolean;
  };
  uspsData: UspsData;
}

// Full response type
export interface AddressValidationResponse {
  result: ValidationResults;
  responseId: string;
}
