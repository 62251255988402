<div class="ag-media row flex-nowrap">
  <div class="ag-media__menu col-auto">
    <div class="ag-media__add-folder row flex-nowrap">
      <dx-text-box
        class="col"
        [(value)]="folderName"
        placeholder="Enter New Folder Name"
        [maxLength]="50"
        [showClearButton]="true"
      ></dx-text-box>

      <dx-button
        *ngIf="Entity.agencyMedia | hasPermission : EntityPermissionActivityKeys.create | async"
        class="col-auto"
        icon="agi-folder-plus"
        stylingMode="outlined"
        type="default"
        hint="Add Folder"
        [disabled]="!folderName"
        (onClick)="addFolder()"
      ></dx-button>
    </div>

    <hr />

    <p class="ag-empty-text text-center" *ngIf="!directoriesDataSource?.length">No folders</p>

    <dx-menu
      [dataSource]="directoriesDataSource"
      displayExpr="name"
      orientation="vertical"
      [hideSubmenuOnMouseLeave]="false"
      itemTemplate="items"
      (onItemClick)="menuItemClick($event)"
    >
      <div
        *dxTemplate="let item of 'items'; let index = index"
        [ngClass]="{ 'selected-item': index === selectedItemIndex }"
        (click)="selectedItemIndex = index"
      >
        <i class="dx-icon-agi-folder"></i> {{ item.name }}

        <ng-container
          *ngIf="!item.isSystem && Entity.agencyMedia | hasPermission : EntityPermissionActivityKeys.delete | async"
        >
          <dx-button
            class="remove-button"
            [icon]="'close'"
            [stylingMode]="'text'"
            (onClick)="deleteFolder(item)"
          ></dx-button>
        </ng-container>
      </div>
    </dx-menu>
  </div>

  <div class="ag-media__gallery col">
    <ag-shr-media-gallery
      [allowActions]="Entity.agencyMedia | hasPermission : EntityPermissionActivityKeys.write | async"
      [allowDelete]="Entity.agencyMedia | hasPermission : EntityPermissionActivityKeys.delete | async"
      [mediaItems]="agencyMediaEditorService.currentMediaList$ | async"
      [mediaActions]="extraMediaActions"
      (itemDeleted)="onDeleteClicked($event)"
    ></ag-shr-media-gallery>

    <div class="gallery-actions">
      <shr-button-with-indicator
        *ngIf="Entity.agencyMedia | hasPermission : EntityPermissionActivityKeys.create | async"
        [title]="'Upload a File'"
        [icon]="'upload'"
        (onClick)="uploadFile()"
      ></shr-button-with-indicator>
    </div>
  </div>
</div>

<ag-shr-media-uploader
  [mediaPathPrefix]="mediaPathPrefix$ | async"
  (mediaChanged)="onMediaChanged($event)"
  [mediaSources]="[MediaUploaderTabs.FromFile, MediaUploaderTabs.ByURL]"
></ag-shr-media-uploader>
