import { Injectable } from '@angular/core';
import { CRMRole } from 'ag-common-lib/lib/lists/crm-roles.enum';
import {
  AGENT_TYPE,
  PROSPECT_STATUS,
  AG_APPLICATIONS,
  AGENT_STATUS,
  Role,
  PROSPECT_PRIORITY,
} from '@ag-common-lib/public-api';

@Injectable({
  providedIn: 'root',
})
export class ListManager {
  public getAgentTypes(): AGENT_TYPE[] {
    let retval: AGENT_TYPE[] = [];

    retval.push(AGENT_TYPE.MGA_PRINCIPAL);
    retval.push(AGENT_TYPE.MGA_ADMIN);
    retval.push(AGENT_TYPE.AGENCY_PRINCIPAL);
    retval.push(AGENT_TYPE.AGENCY_ADMIN);
    retval.push(AGENT_TYPE.GENERAL_AGENT);

    return retval;
  }

  public getProspectStatuses(): PROSPECT_STATUS[] {
    let retval: PROSPECT_STATUS[] = [];

    retval.push(PROSPECT_STATUS.INVESTIGATION);
    retval.push(PROSPECT_STATUS.COMPLETE);
    retval.push(PROSPECT_STATUS.FOLLOW_UP);
    retval.push(PROSPECT_STATUS.INITIAL_INQUIRY);
    retval.push(PROSPECT_STATUS.NEW_PROSPECT);
    retval.push(PROSPECT_STATUS.ROUTING);

    return retval;
  }

  public getApplications(): AG_APPLICATIONS[] {
    let retval: AG_APPLICATIONS[] = [];

    retval.push(AG_APPLICATIONS.PORTAL);
    retval.push(AG_APPLICATIONS.ARM);
    retval.push(AG_APPLICATIONS.AWB);
    retval.push(AG_APPLICATIONS.ACB);
    retval.push(AG_APPLICATIONS.Campaigns);

    return retval;
  }

  public getAgentStatuses(): AGENT_STATUS[] {
    let retval: AGENT_STATUS[] = [];

    retval.push(AGENT_STATUS.APPROVED);
    retval.push(AGENT_STATUS.CARRIER_REP);
    retval.push(AGENT_STATUS.CLOSED_PROSPECT);
    retval.push(AGENT_STATUS.DENIED);
    retval.push(AGENT_STATUS.IN_REVIEW);
    retval.push(AGENT_STATUS.INACTIVE);
    retval.push(AGENT_STATUS.NEW_AGENT);
    retval.push(AGENT_STATUS.NEW_PROSPECT);

    return retval;
  }

  public getRoles(): Role[] {
    let retval: Role[] = [];

    retval.push(Role.ANONYMOUS);
    retval.push(Role.AGENT);
    retval.push(Role.CONTENT_ADMIN);
    retval.push(Role.MANAGER);
    retval.push(Role.MGA);
    retval.push(Role.PORTAL_ADMIN);
    retval.push(Role.REPORT_ADMIN);
    retval.push(Role.RMD);
    retval.push(Role.STORE_ADMIN);
    retval.push(Role.USER_ADMIN);
    retval.push(Role.CAMPAIGNS_MANAGER);

    return retval;
  }

  public getCrmRoles(): CRMRole[] {
    let retval: CRMRole[] = [];

    retval.push(CRMRole.ADMIN);
    retval.push(CRMRole.USER);
    retval.push(CRMRole.SUPER_ADMIN);

    return retval;
  }

  public getProspectPriorities(): PROSPECT_PRIORITY[] {
    let retval: PROSPECT_PRIORITY[] = [];

    retval.push(PROSPECT_PRIORITY.HIGH);
    retval.push(PROSPECT_PRIORITY.MEDIUM);
    retval.push(PROSPECT_PRIORITY.LOW);
    retval.push(PROSPECT_PRIORITY.NONE);

    return retval;
  }
}
