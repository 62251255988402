export const Msgs: Record<string, string> = {
  OPEN_IN_MODAL: 'OPEN IN MODAL',
  REVERT_ALL: 'REVERT ALL',
  SAVE_ALL: 'SAVE ALL',
  BACK_TO_LIST: 'Back to List',
  LOG_CALL: 'LOG CALL',
  LOG_MEETING: 'LOG MEETING',
  LOG_NOTE: 'LOG NOTE',
  LOG_EMAIL: 'LOG EMAIL',
  ADD_TASK: 'ADD TASK',
  SAVE_TXT: 'SAVE',
  CANCEL_TXT: 'CANCEL',
};
