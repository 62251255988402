import { InjectionToken } from '@angular/core';
import { PaymentMethodCreateParams, StripeElementsOptions, StripePaymentElementOptions } from '@stripe/stripe-js';
import { StripePaymentDetails } from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';

export class StripeModalPayload {
  amountToPay: number;
  billingDetails?: PaymentMethodCreateParams.BillingDetails;
}

export enum StripeModalUserActionType {
  success = 'success',
  cancel = 'cancel',
}

export class StripeModalUserAction {
  type: StripeModalUserActionType;
  payload?: StripePaymentDetails;
}

export const STRIPE_PUBLIC_KEY = new InjectionToken<FirebaseApp>('STRIPE_PUBLIC_KEY');

export const elementsOptions: StripeElementsOptions = {
  locale: 'en',
  appearance: {
    theme: 'flat',
    labels: 'floating',
  },
};

export const paymentElementOptions: StripePaymentElementOptions = {
  layout: {
    type: 'tabs',
    defaultCollapsed: false,
    radios: false,
    spacedAccordionItems: false,
  },
  defaultValues: {
    billingDetails: {
      name: 'Jane Doe',
    },
  },
  fields: {
    billingDetails: {
      name: 'auto',
      email: 'auto',
      phone: 'auto',
      address: 'auto',
    },
  },
  wallets: { applePay: 'never', googlePay: 'never' },
};
