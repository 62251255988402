import { Injectable } from '@angular/core';
import { PhoneNumber, PhoneNumberKeys } from '@ag-common-lib/public-api';
import { BehaviorSubject, Observable, combineLatest, map, shareReplay } from 'rxjs';
import { pick } from 'lodash';

export const getPhoneNumberKeyExpression = (phoneNumber: PhoneNumber) => {
  if (!phoneNumber) {
    return null;
  }
  const keyItems = [phoneNumber?.[PhoneNumberKeys.number], phoneNumber?.[PhoneNumberKeys.extension]];

  if (!keyItems.some(Boolean)) {
    return null;
  }

  return keyItems.join(',');
};

@Injectable()
export class SelectPhoneNumberService {
  private _agentPhoneNumbers$ = new BehaviorSubject<PhoneNumber[]>([]);
  agentPhoneNumbersDataSource$ = this._agentPhoneNumbers$.pipe(
    map(phoneNumber => {
      const items = phoneNumber?.map(phoneNumber => this.pickPhoneNumberData(phoneNumber));

      items.push(this.pickPhoneNumberData(null));

      return items;
    }),
    shareReplay(1),
  );

  readonly formData$ = new BehaviorSubject<Partial<PhoneNumber>>(new PhoneNumber());

  selectedPhoneNumbersKeys$: Observable<string[]> = combineLatest({
    agentPhoneNumbersDataSource: this.agentPhoneNumbersDataSource$,
    formData: this.formData$,
  }).pipe(
    map(({ agentPhoneNumbersDataSource, formData }) => {
      const selectedAddressKeys = agentPhoneNumbersDataSource
        ?.map(phoneNumber => getPhoneNumberKeyExpression(phoneNumber))
        ?.filter(address => {
          return address === getPhoneNumberKeyExpression(formData);
        });

      if (!selectedAddressKeys?.length) {
        return [null];
      }

      return selectedAddressKeys;
    }),
  );

  constructor() {}

  setFormData = (phoneNumber: Partial<PhoneNumber>) => {
    this.formData$.next(phoneNumber);
  };

  setPhoneNumbers = (phoneNumber: PhoneNumber[]) => {
    if (!Array.isArray(phoneNumber)) {
      return;
    }
    this._agentPhoneNumbers$.next(phoneNumber);
  };

  pickPhoneNumberData = (phoneNumber: PhoneNumber) => {
    return pick(phoneNumber, [
      PhoneNumberKeys.number,
      PhoneNumberKeys.extension,
      PhoneNumberKeys.is_whatsapp,
      PhoneNumberKeys.phone_type,
    ]);
  };

  setPhoneNumber = (phoneNumber?: Partial<PhoneNumber>) => {
    const formData = this.formData$.value;

    Object.keys(formData).forEach(key => {
      formData[key] = null;
    });

    Object.entries(phoneNumber).forEach(([key, value]) => {
      formData[key] = value;
    });
  };
}
