export enum GoalKeys {
  year = 'year',
  amount = 'amount',
}

export class Goal {
  constructor(year: number, amount: number) {
    this.year = year;
    this.amount = amount;
  }

  [GoalKeys.year]?: number;
  [GoalKeys.amount]?: number;
}
