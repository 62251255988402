<ag-shr-notification-campaign-modal #notificationHubModalRef></ag-shr-notification-campaign-modal>

<ag-shr-notification-add-recipients-modal
  #addRecipientsModalRef
  [title]="'Select Recipients'"
  [actionTitle]="'SEND NOTIFICATION'"
  (handleAddRecipients)="onAddRecipients($event)"
></ag-shr-notification-add-recipients-modal>

<dx-data-grid
  #notificationsListGrid
  class="notifications-list__grid"
  [dataSource]="dataSource$ | async"
  [showBorders]="true"
  [renderAsync]="true"
  [columnAutoWidth]="true"
  [wordWrapEnabled]="true"
  height="100%"
>
  <dxo-editing
    mode="row"
    [useIcons]="true"
    [allowUpdating]="false"
    [allowDeleting]="false"
    [allowAdding]="true"
  ></dxo-editing>

  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>
        <div class="ml-2">
          <b>Notification Hub</b>
        </div>
      </div>
    </dxi-item>

    <dxi-item
      location="after"
      widget="dxButton"
      [options]="{ icon: 'plus', onClick: createNotificationCampaign }"
    ></dxi-item>
  </dxo-toolbar>

  <dxi-column
    [dataField]="PushNotificationCampaignKeys.title"
    dataType="string"
    [caption]="'Title'"
    [width]="200"
  >
  </dxi-column>

  <dxi-column
    [dataField]="PushNotificationCampaignKeys.notification"
    [caption]="'Notification'"
    cellTemplate="notificationCellTemplate"
  >
    <div *dxTemplate="let cell of 'notificationCellTemplate'">
      <div>
        <b>Title:</b>
        <span>{{ cell?.value?.title }}</span>
      </div>
      <div>
        <b>Body:</b>
        <span>{{ cell?.value?.body }}</span>
      </div>
    </div>
  </dxi-column>

  <dxi-column type="buttons">
    <dxi-button
      icon="email"
      [onClick]="sendNotifications"
    ></dxi-button>

    <dxi-button
      icon="edit"
      [onClick]="editNotificationCampaign"
    ></dxi-button>

    <dxi-button
      icon="copy"
      [onClick]="copyNotificationCampaign"
    ></dxi-button>
  </dxi-column>

  <dxo-master-detail
    [enabled]="true"
    template="detail"
  >
  </dxo-master-detail>
  <div *dxTemplate="let details of 'detail'">
    <div [innerHTML]="details?.data?.[PushNotificationCampaignKeys.description]"></div>
  </div>
</dx-data-grid>
