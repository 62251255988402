import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ButtonWithIndicatorModule } from '../button-with-indicator/button-with-indicator.module';
import { ModalWindowModule } from '../modal-window/modal-window.module';
import { SocialsComponent } from './socials.component';
import { SocialsService } from '../socials/socials.service';
import { SocialsGridComponent } from './socials-grid/socials-grid.component';

@NgModule({
  imports: [SharedModule, ModalWindowModule, ButtonWithIndicatorModule],
  declarations: [SocialsComponent, SocialsGridComponent],
  exports: [SocialsComponent, SocialsGridComponent],
  providers: [SocialsService],
})
export class SocialsModule {}
