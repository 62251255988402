import { LookupId } from '../../lists/lookups.enum';

export enum AgencyRelationshipModelKeys {
  initiallyMet = 'initiallyMet',
  contractedDate = 'contractedDate',
  howIntroduced = 'howIntroduced',
  introduceBy = 'introduceBy',
  organizationAffiliations = 'organizationAffiliations',
  note = 'note',
  isFoundingMember = 'isFoundingMember',
}

export class AgencyRelationship {
  [AgencyRelationshipModelKeys.initiallyMet]?: Date | number | string;
  [AgencyRelationshipModelKeys.contractedDate]?: Date | number | string;
  [AgencyRelationshipModelKeys.howIntroduced]?: string;
  [AgencyRelationshipModelKeys.introduceBy]?: string;
  [AgencyRelationshipModelKeys.organizationAffiliations]?: LookupId<'AgencyOrganizationAffiliations'>;
  [AgencyRelationshipModelKeys.note]?: string;
  [AgencyRelationshipModelKeys.isFoundingMember]?: boolean = false;
}
