import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ag-shr-editor-toolbar',
  templateUrl: './ag-editor-toolbar.component.html',
  styleUrls: ['./ag-editor-toolbar.component.scss'],
})
export class AgEditorToolbarComponent {
  @Input() title: string;
  @Input() isEditVisible = true;
  @Output() clickEdit = new EventEmitter<boolean>();
}
