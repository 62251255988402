<ag-shr-agent-campaign-summary-list-item [title]="'Appointment'">
  <ng-container agCampaignsActions>
    <ng-content select="[agCampaignsControls]"></ng-content>
  </ng-container>

  <ag-shr-agent-campaign-summary-list-data
    [title]="'Calendly'"
    [hasCurrentData]="!!stepData?.currentStepData"
    [hasIncomingData]="!!stepData?.incomingStepData"
  >
    <ng-template
      #websiteDataTmp
      let-data="data"
    >
      {{ data?.appointmentUrl}}
    </ng-template>

    <ng-container
      currentDataTmp
      *ngTemplateOutlet="websiteDataTmp; context: { data: stepData?.currentStepData }"
    >
    </ng-container>

    <ng-container
      incomingDataTmp
      *ngTemplateOutlet="websiteDataTmp; context: { data: stepData?.incomingStepData }"
    >
    </ng-container>

    <ng-container
      controls
      *var="stepData?.incomingStepData as data"
    >
      <ag-shr-clipboard-button [clipboardValue]="data?.appointmentUrl"></ag-shr-clipboard-button>
    </ng-container>

  </ag-shr-agent-campaign-summary-list-data>
</ag-shr-agent-campaign-summary-list-item>
