import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import videojs from 'video.js';
import videojsQualitySelector from '@silvermine/videojs-quality-selector';

videojsQualitySelector(videojs);

@UntilDestroy()
@Component({
  selector: 'ag-shr-vimeo-video',
  templateUrl: './vimeo-video.component.html',
  styleUrls: ['./vimeo-video.component.scss'],
})
export class VimeoVideoComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class') className = 'vimeo-video';
  @ViewChild('vimeoVideoRef', { static: false }) videoElement!: ElementRef<HTMLVideoElement>;

  @Input() autoplay = false;
  @Input() showControls = true;
  @Input() preloadMode: 'none' | 'auto' | 'metadata' = 'none';

  @Input() set vimeoVideo(response: any) {
    this.sources = Array.isArray(response?.files)
      ? response?.files
          .filter(source => source?.rendition !== 'adaptive')
          .sort((a, b) =>
            `${a.public_name}`?.localeCompare(b.public_name, 'en', {
              numeric: true,
              sensitivity: 'base',
              ignorePunctuation: true,
            }),
          )
      : [];

    this.poster = response?.pictures.base_link;
  }

  protected sources: any[];
  protected poster: string;

  private _player: any;

  constructor() {}

  ngAfterViewInit(): void {
    this.initializePlayer();
  }

  ngOnDestroy(): void {
    if (this._player) {
      this._player.dispose();
    }
  }

  private initializePlayer() {
    const videoElement = this.videoElement.nativeElement;
    const options = {
      controls: this.showControls,
      autoplay: this.autoplay,
      preload: this.preloadMode,
    };

    this._player = videojs(videoElement, options, () => {
      this._player.controlBar.addChild('QualitySelector');
    });
  }
}
