<ag-shr-agent-campaign-summary-list-item [title]="'Contact Info'">
  <ng-container agCampaignsActions>
    <ng-content select="[agCampaignsControls]"></ng-content>
  </ng-container>

  <ag-shr-agent-campaign-summary-list-data
    [title]="'Address'"
    [hasCurrentData]="!!stepDataAddress?.currentStepData"
    [hasIncomingData]="!!stepDataAddress?.incomingStepData"
  >
    <ng-template
      #contactInfoAddressTmp
      let-data="data"
    >
      {{data| fullAddress}}
    </ng-template>

    <ng-container
      currentDataTmp
      *ngTemplateOutlet="contactInfoAddressTmp; context: { data: stepDataAddress?.currentStepData }"
    >
    </ng-container>

    <ng-container
      incomingDataTmp
      *ngTemplateOutlet="contactInfoAddressTmp; context: { data: stepDataAddress?.incomingStepData }"
    >
    </ng-container>

    <ng-container
      controls
      *var="stepDataAddress?.incomingStepData as data"
    >
      <ag-shr-clipboard-button [clipboardValues]="data | agentCampaignSummaryContactInfoAddressCopyOptions : (data| fullAddress)"></ag-shr-clipboard-button>
      <ng-content select="[agAddressControls]"></ng-content>
    </ng-container>

  </ag-shr-agent-campaign-summary-list-data>

  <ag-shr-agent-campaign-summary-list-data
    [title]="'Phone Number'"
    [hasCurrentData]="!!stepDataPhoneNumber?.currentStepData"
    [hasIncomingData]="!!stepDataPhoneNumber?.incomingStepData"
  >
    <ng-template
      #contactInfoPhoneNumberTmp
      let-data="data"
    >
      {{data| phoneNumberMask}}
    </ng-template>

    <ng-container
      currentDataTmp
      *ngTemplateOutlet="contactInfoPhoneNumberTmp; context: { data: stepDataPhoneNumber?.currentStepData }"
    >
    </ng-container>

    <ng-container
      incomingDataTmp
      *ngTemplateOutlet="contactInfoPhoneNumberTmp; context: { data: stepDataPhoneNumber?.incomingStepData }"
    >
    </ng-container>

    <ng-container
      controls
      *var="stepDataPhoneNumber?.incomingStepData as data"
    >
      <ag-shr-clipboard-button [clipboardValue]="data| phoneNumberMask"></ag-shr-clipboard-button>
      <ng-content select="[agPhoneNumberControls]"></ng-content>
    </ng-container>

  </ag-shr-agent-campaign-summary-list-data>

  <ag-shr-agent-campaign-summary-list-data
    [title]="'Email Address'"
    [hasCurrentData]="!!stepDataEmailAddress?.currentStepData"
    [hasIncomingData]="!!stepDataEmailAddress?.incomingStepData"
  >
    <ng-template
      #contactInfoEmailAddressTmp
      let-data="data"
    >
      {{data?.address}}
    </ng-template>

    <ng-container
      currentDataTmp
      *ngTemplateOutlet="contactInfoEmailAddressTmp; context: { data: stepDataEmailAddress?.currentStepData }"
    >
    </ng-container>

    <ng-container
      incomingDataTmp
      *ngTemplateOutlet="contactInfoEmailAddressTmp; context: { data: stepDataEmailAddress?.incomingStepData }"
    >
    </ng-container>

    <ng-container
      controls
      *var="stepDataEmailAddress?.incomingStepData as data"
    >
      <ag-shr-clipboard-button [clipboardValue]="data?.address"></ag-shr-clipboard-button>
      <ng-content select="[agEmailControls]"></ng-content>
    </ng-container>

  </ag-shr-agent-campaign-summary-list-data>

</ag-shr-agent-campaign-summary-list-item>
