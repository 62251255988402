import { AGMedia, MediaContentType } from './ag-media.model';

export enum AgencyMediaKeys {
  agencyDbId = 'agencyDbId',
}

export class AgencyMedia extends AGMedia {
  [AgencyMediaKeys.agencyDbId]: string;

  constructor(mediaType: MediaContentType, agencyDbId: string) {
    super(mediaType);
    this[AgencyMediaKeys.agencyDbId] = agencyDbId;
  }
}
