import { DocumentReference } from 'firebase/firestore';

export enum BaseModelKeys {
  dbId = 'dbId',
  actionId = 'actionId',
  createdDate = 'created_date',
  createdBy = 'created_by',
  createdByEmail = 'created_by_email',
  createdByAgentDbId = 'created_by_agent_dbId',
  updatedDate = 'updated_date',
  updatedBy = 'updated_by',
  updatedByEmail = 'updated_by_email',
  updatedByAgentDbId = 'updated_by_agent_dbId',
  deletedDate = 'deleted_date',
  deletedBy = 'deleted_by',
  deletedByEmail = 'deleted_by_email',
  deletedByAgentDbId = 'deleted_by_agent_dbId',
  isDeleted = 'isDeleted',
  firebaseRef = 'firebaseRef',
}

export class BaseModel {
  [BaseModelKeys.dbId]?: string;
  [BaseModelKeys.actionId]?: string;
  [BaseModelKeys.createdDate]?: Date;
  [BaseModelKeys.createdBy]?: string;
  [BaseModelKeys.updatedDate]?: Date;
  [BaseModelKeys.updatedBy]?: string;
  [BaseModelKeys.updatedByEmail]?: string;
  [BaseModelKeys.updatedByAgentDbId]?: string;
  [BaseModelKeys.isDeleted]?: boolean;
  [BaseModelKeys.firebaseRef]?: DocumentReference;
}
