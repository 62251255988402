import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ModalWindowModule } from '../modal-window/modal-window.module';
import { GetSupportModalComponent } from './get-support-modal.component';
import { DxValidatorModule } from 'devextreme-angular';

@NgModule({
  imports: [CommonModule, SharedModule, ModalWindowModule, DxValidatorModule],
  declarations: [GetSupportModalComponent],
  exports: [GetSupportModalComponent],
})
export class GetSupportModalModule {}
