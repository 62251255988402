import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  Constants,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  Messages,
  RESIDENCY_TYPES_LOOKUP,
  StateLicense,
  StateLicenseKeys,
} from 'ag-common-lib/public-api';
import { DxDataGridComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { StatesLicensedInService } from '../states-licensed-in.service';
import { LookupsService } from 'ag-common-svc/lib/services/lookups.service';

@Component({
  selector: 'ag-shr-state-licensed-in-grid',
  templateUrl: './states-licensed-in-grid.component.html',
  styleUrls: ['./states-licensed-in-grid.component.scss'],
})
export class StatesLicensedInGridComponent {
  @ViewChild('dataGridRef', { static: false }) stateLicensedInGridComponent: DxDataGridComponent;
  @Input() isEditable: boolean = true;
  @Input() agencyId: string;
  @Input() stateLicenses: StateLicense[];
  @Output() stateLicensesChange = new EventEmitter<StateLicense[]>();

  statesLookup$ = this.lookupsService.statesLookup$;
  inProgress$ = this.statesLicensedInService.inProgress$;
  protected readonly Messages = Messages;
  protected readonly residencyTypes = RESIDENCY_TYPES_LOOKUP;
  protected readonly StateLicenseKeys = StateLicenseKeys;
  readonly emptyMessage = 'No State Licenses Currently Exist';
  readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(
    private toastrService: ToastrService,
    private lookupsService: LookupsService,
    private statesLicensedInService: StatesLicensedInService,
  ) {}

  onRowInserting = e => {
    const { __KEY__: key, ...data } = e?.data;
    const stateLicenses = this.normalizeStateLicense(data);
    stateLicenses.push(Object.assign({ id: key }, data));
    e.cancel = this.updateStateLicense(stateLicenses);
  };

  onRowUpdating = e => {
    const data = Object.assign({}, e?.oldData, e?.newData);
    const stateLicenses = this.normalizeStateLicense(data, e?.key);
    this.updateStateLicense(stateLicenses);
    e.cancel = this.updateStateLicense(stateLicenses);
  };

  onRowRemoving = e => {
    const stateLicenses = this.stateLicenses.filter(stateLicense => stateLicense !== e.key);
    this.updateStateLicense(stateLicenses);
    e.cancel = this.updateStateLicense(stateLicenses);
  };

  onCancel = e => {
    this.stateLicensedInGridComponent?.instance?.cancelEditData();
  };

  private updateStateLicense = (stateLicenses: StateLicense[]) => {
    return this.statesLicensedInService.updateStateLicenseChange(this.agencyId, stateLicenses).then(() => {
      this.stateLicenses = stateLicenses;
      this.stateLicensesChange.emit(stateLicenses);
    });
  };

  private normalizeStateLicense = (data, key?: StateLicense) => {
    return this.stateLicenses.map(stateLicense => {
      if (key && stateLicense === key) {
        return data;
      }
      return Object.assign({}, stateLicense);
    });
  };
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
