import { Pipe, PipeTransform } from '@angular/core';
import { WizardStep } from '@ag-common-lib/public-api';

@Pipe({ name: 'wizardStepperSubStepsContainerClass' })
export class WizardStepperSubStepsContainerClassPipe implements PipeTransform {
  constructor() {}

  transform(currentStep: string, step?: WizardStep<any>): string | string[] {
    const classes = [];

    const isStepActive = step.name === currentStep;
    const hasActiveSubStep = step?.steps?.some(step => step?.name === currentStep);

    if (isStepActive || hasActiveSubStep) {
      classes.push('wizard-stepper__sub-steps-container--visible');
    }

    return classes;
  }
}
