//b
export const environment = {
  production: false,
  isPWAEnabled: true,
  defaultauth: 'firebase',
  firebaseConfig: {
    apiKey: 'AIzaSyBQk0mf1jUDYzgdZrOoKi7TT9cINP-ttn0',
    authDomain: 'alliance-group-portal.firebaseapp.com',
    projectId: 'alliance-group-portal',
    storageBucket: 'alliance-group-portal.appspot.com',
    messagingSenderId: '271585780604',
    appId: '1:271585780604:web:bfa92dc406920565befaf9',
    measurementId: 'G-H76S2T9L7G',
    vapidKey: 'BDYIY4bSKDkveuqzQdsLR7Sj_9OBNM0VTwpW0fHQQNUI2G3bcrpDBx4opKemsymHcq5Dxl1KX5TPHnVZDVVqWrE',
  },
  google: {
    mapsAPIKey: 'AIzaSyCrFVl8PrFGe10YSFCBr9WDBtdEKb1A7LE',
    mapId: '56461284e4d20403',
  },
  portal_url: 'https://dev.alliancegrouplife.com/',
  crm_url: 'https://dev-arm.alliancegrouplife.com/',
  domain: 'dev.alliancegrouplife.com',
  stripe_store_key:
    'pk_live_51Iv0qdAA1F9sT11gZOrl9UWWKBeCSEa510pbjraU8stwK4wdUMkX9kPw72PRPdhd2Ob4J1nYIGSydsf9Vs4nvBUw00O9TgUfUM',
  stripe_payments_public_key:
    'pk_test_51MON3hBZ6K5GAvsGVuCE875tXoedNEHv3TKS9okIkxKUDh0ML3iusdx76etQ5G5J3nMbyNQC1M28e8bSBRepNhMs00vcjKL5K2',
  version: '1.3.0',
  session_expires: 120,
  user_admin_url: 'https://us-central1-alliance-group-portal.cloudfunctions.net/users',
  devextremeLicenseKey:
    'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjQzNTMxYTU3LWQxNTMtNGRiYS1hMjZjLTdlMjE1OTc0MWM3YiIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjMyCn0=.QoiYKRdma2s+Ylj9zHLLWvDigChChRjHeqNv1AD0TV7er3xxoBR52uDeT++5z0zNbqwWMBrlM/DTd9va/tVbzZsFIHHPQtAWjg4ruc0f2swWcHy4ZMx5A5f3nKjhtJcQrOBrcQ==',
};
