import { Component, HostBinding, Input } from '@angular/core';
import { AgentCampaignChange, CampaignLogoHeadshot, MediaSize } from '@ag-common-lib/public-api';
import {
  ImageCropper,
  ImageCropperKeys,
} from '../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/image-cropper.model';
import { WasabiImgPipe } from '../../../../shared/pipes/wasabi-img.pipe';
import { saveAs } from 'file-saver';
import { AGMediaKeys } from '../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/ag-media.model';
import { AgentMedia } from '../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/agent-media.model';

@Component({
  selector: 'ag-shr-agent-campaign-summary-logo-headshot',
  templateUrl: './agent-campaign-summary-logo-headshot.component.html',
  styleUrls: ['./agent-campaign-summary-logo-headshot.component.scss'],
})
export class AgentCampaignSummaryLogoHeadshotComponent {
  @HostBinding('class') className = 'agent-campaign-summary-logo-headshot';

  @Input() imageKey: keyof CampaignLogoHeadshot;
  @Input() title: string;

  @Input() stepData: AgentCampaignChange<CampaignLogoHeadshot>;
  @Input() canEdit = true;

  protected readonly ImageCropperKeys = ImageCropperKeys;
  protected readonly MediaSize = MediaSize;

  protected readonly editOptions = [
    { text: 'Optimized Image', actionFn: this.onOptimizedDownloadItemClicked.bind(this) },
    { text: 'Origin Image', actionFn: this.onDownloadItemClicked.bind(this) },
  ];

  constructor(private wasabiImgPipe: WasabiImgPipe) {}

  async getBlob(image: Partial<AgentMedia & ImageCropper>, optimized?: boolean) {
    let url = this.wasabiImgPipe.transform(
      image.wasabiPath,
      MediaSize.origin,
      optimized,
      image[ImageCropperKeys.imageCropperPayload],
    );
    return fetch(url).then(res => res.blob());
  }

  async onDownloadItemClicked(imageItem: Partial<AgentMedia & ImageCropper>) {
    const blob = await this.getBlob(imageItem);
    saveAs(blob, imageItem[AGMediaKeys.fileName]);
  }

  async onOptimizedDownloadItemClicked(imageItem: Partial<AgentMedia & ImageCropper>) {
    const blob = await this.getBlob(imageItem, true);
    saveAs(blob, imageItem[AGMediaKeys.fileName]);
  }

  logAction(e, imageItem) {
    e.itemData.actionFn(imageItem);
  }
}
