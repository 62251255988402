import { Component, HostBinding, Input } from '@angular/core';
import { Address } from '@ag-common-lib/public-api';
import { AgentCampaignAddressInfoService } from './agent-campaign-address-info.service';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import validationEngine from 'devextreme/ui/validation_engine';

@UntilDestroy()
@Component({
  selector: 'ag-shr-agent-campaign-address-info',
  templateUrl: './agent-campaign-address-info.component.html',
  styleUrls: ['./agent-campaign-address-info.component.scss'],
})
export class AgentCampaignAddressInfoComponent {
  @HostBinding('class') className = 'agent-campaign-address-info agent-campaign-wizard__content-container';

  @Input() hasStepIssues: boolean;
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;

  inProgress$: Observable<boolean>;
  addressFormData: Address;
  agentAddresses$: Observable<Address[]>;

  hasFormChanges$: Observable<boolean>;

  readonly validationGroup = 'agentCampaignAddressInfoValidationGroup';

  protected selectedAddressKey: string;

  constructor(private agentCampaignAddressInfoService: AgentCampaignAddressInfoService) {
    agentCampaignAddressInfoService.campaignContactInfoAddress$.pipe(untilDestroyed(this)).subscribe(formData => {
      this.addressFormData = formData;
    });

    this.agentAddresses$ = agentCampaignAddressInfoService.agentAddresses$;
    this.inProgress$ = agentCampaignAddressInfoService.inProgress$;
    this.hasFormChanges$ = agentCampaignAddressInfoService.hasFormChanges$;
  }

  nextStep = () => {
    const validationResults = validationEngine.validateGroup(this.validationGroup);

    if (!validationResults.isValid) {
      return;
    }

    this.agentCampaignAddressInfoService.handleNextStepClick(this.selectedAddressKey);
  };

  resetChanges = () => {
    this.agentCampaignAddressInfoService.revertAllChanges();
  };

  addressIsRequiredForAddress = e => {
    if (!this.addressFormData) {
      return false;
    }
    const { address1, ...rest } = this.addressFormData;
    const restIsEmpty = Object.values(rest).every(item => !item);

    return restIsEmpty || address1;
  };
}
