import { LookupKeys } from '../models/crm/lookup.model';
import { CARRIER_LINE_OF_BUSINESS_TYPE, CARRIER_ORGANIZATION_TYPE } from './carrier.enum';

export const CARRIER_LINE_OF_BUSINESS_LOOKUP = [
  {
    [LookupKeys.value]: CARRIER_LINE_OF_BUSINESS_TYPE.Life,
    [LookupKeys.description]: CARRIER_LINE_OF_BUSINESS_TYPE.Life,
  },
  {
    [LookupKeys.value]: CARRIER_LINE_OF_BUSINESS_TYPE.Annuity,
    [LookupKeys.description]: CARRIER_LINE_OF_BUSINESS_TYPE.Annuity,
  },
];

export const CARRIER_ORGANIZATION_LOOKUP = [
  {
    [LookupKeys.value]: CARRIER_ORGANIZATION_TYPE.Individual,
    [LookupKeys.description]: CARRIER_ORGANIZATION_TYPE.Individual,
  },
  { [LookupKeys.value]: CARRIER_ORGANIZATION_TYPE.Corp, [LookupKeys.description]: CARRIER_ORGANIZATION_TYPE.Corp },
];
