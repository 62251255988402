import { NgModule } from '@angular/core';
import { AgentCampaignWebSiteComponent } from './agent-campaign-web-site.component';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ButtonWithIndicatorModule } from '../../button-with-indicator/button-with-indicator.module';
import { AgentCampaignWebSiteService } from './agent-campaign-web-site.service';
import { DxValidatorModule } from 'devextreme-angular';
import { WebSiteInputModule } from '../../website-input/website-input.module';

@NgModule({
  declarations: [AgentCampaignWebSiteComponent],
  imports: [SharedModule, ButtonWithIndicatorModule, DxValidatorModule, WebSiteInputModule],
  exports: [AgentCampaignWebSiteComponent],
  providers: [AgentCampaignWebSiteService],
})
export class AgentCampaignWebSiteModule {}
