import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { NotificationAddRecipientsModalComponent } from './notification-add-recipients-modal.component';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { NotificationRecipientsGridModule } from '../notification-recipients-grid/notification-recipients-grid.module';

@NgModule({
  declarations: [NotificationAddRecipientsModalComponent],
  imports: [CommonModule, SharedModule, ModalWindowModule, NotificationRecipientsGridModule],
  exports: [NotificationAddRecipientsModalComponent],
})
export class NotificationAddRecipientsModalModule {}
