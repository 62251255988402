import { Component, HostBinding, ViewChild } from '@angular/core';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { NotificationCampaignModalService } from './notification-campaign-modal.service';
import { PushNotificationCampaign, PushNotificationCampaignKeys, PushNotificationKeys } from '@ag-common-lib/lib';
import { Constants } from '@ag-common-lib/public-api';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';

@Component({
  selector: 'ag-shr-notification-campaign-modal',
  templateUrl: './notification-campaign-modal.component.html',
  styleUrls: ['./notification-campaign-modal.component.scss'],
  providers: [NotificationCampaignModalService],
})
export class NotificationCampaignModalComponent {
  @HostBinding('class') className = 'notification-campaign-modal';
  @ViewChild('notificationCampaignModalRef', { static: false })
  protected notificationCampaignModalComponent: ModalWindowComponent;

  protected dateNow = new Date();
  protected PushNotificationCampaignKeys = PushNotificationCampaignKeys;
  protected PushNotificationKeys = PushNotificationKeys;
  protected validationGroup = 'validationGroup';
  protected Constants = Constants;
  protected inProgress$ = this.notificationCampaignModalService.inProgress$;
  protected formData: PushNotificationCampaign;

  constructor(protected notificationCampaignModalService: NotificationCampaignModalService) {}

  showModal = async (pushNotificationCampaign?: Partial<PushNotificationCampaign>): Promise<void> => {
    this.formData = this.notificationCampaignModalService.getFormData(pushNotificationCampaign);
    this.notificationCampaignModalComponent?.showModal();
  };

  protected hideModal = (): void => {
    this.notificationCampaignModalComponent.hideModal();
  };

  protected saveNotificationCampaign = async () => {
    const isValid = await validateDxGroups([this.validationGroup]);
    if (isValid) {
      await this.notificationCampaignModalService.handleSave();
      this.notificationCampaignModalComponent.forceCloseModal();
    }
  };

  protected handleClosePopup = this.notificationCampaignModalService.onCancelEdit;
}
