<ag-shr-modal-window
  #assignOwnerModalRef
  [width]="450"
  [height]="'auto'"
  title="Assign Owner"
  [inProgress]="inProgress$ | async"
  [onCloseModal]="handleClosePopup"
  (onSaveClick)="handleAssignRegistrantOwnerClick()"
>
  <dx-form
    #assignOwnerFormRef
    *ngIf="!!assignOwnerFormData"
    class="ag-assign-owner__form"
    [(formData)]="assignOwnerFormData"
    labelMode="floating"
  >
    <dxi-item [dataField]="AssignedOwnerDetailsKeys.assignedTo" [template]="'assignedToTemplate'"></dxi-item>
    <div *dxTemplate="let data of 'assignedToTemplate'">
      <dx-select-box
        label="Owner"
        [(value)]="assignOwnerFormData.assignedTo"
        [dataSource]="agentsDataSource"
        [valueExpr]="'dbId'"
        [displayExpr]="AgentKeys.p_agent_name"
        [searchEnabled]="true"
        [searchExpr]="[AgentKeys.p_agent_first_name, AgentKeys.p_agent_last_name]"
        [showClearButton]="true"
      >
        <div *dxTemplate="let data of 'item'">
          {{ data.p_agent_name }}
          <ng-container *ngIf="data?.[AgentKeys.email_addresses] | AgentPrimaryEmailAddress as email">
            (<i>{{ email | empty }}</i
            >)
          </ng-container>
        </div>
      </dx-select-box>
    </div>

    <dxi-item
      [dataField]="AssignedOwnerDetailsKeys.assignedToNote"
      [label]="{ text: 'Note' }"
      [editorType]="'dxTextArea'"
      [editorOptions]="{ height: 100 }"
    ></dxi-item>
  </dx-form>
</ag-shr-modal-window>
