<header class="agent-campaign-wizard__header">
  <p class="agent-campaign-wizard__title">My Campaign</p>
</header>

<div class="agent-campaign-wizard__body" *var="stepsIssues$ | async as stepsIssues">
  <ag-shr-wizard-stepper
    [withSupportButton]="withSupportButton"
    [currentStepName]="currentStepName$ | async"
    [steps]="steps$ | async"
    (setCurrentStep)="setCurrentStep($event)"
    [onGetSupport]="onGetSupport"
  ></ag-shr-wizard-stepper>

  <ng-container [ngSwitch]="currentStepName$ | async">
    <ng-container *var="AgentCampaignStepName.contactInfoAddress as key">
      <ag-shr-agent-campaign-address-info
        *ngSwitchCase="key"
        [hasStepIssues]="!!stepsIssues?.[key]?.length"
        [canEdit]="canEdit"
        [canDelete]="canDelete"
        [canCreate]="canCreate"
      >
        <ng-container *ngTemplateOutlet="issuesTmp; context: { issues: stepsIssues?.[key] }"></ng-container>
      </ag-shr-agent-campaign-address-info>
    </ng-container>

    <ng-container *var="AgentCampaignStepName.contactInfoPhoneNumber as key">
      <ag-shr-agent-campaign-phone-number-info
        *ngSwitchCase="key"
        [hasStepIssues]="!!stepsIssues?.[key]?.length"
        [canEdit]="canEdit"
        [canDelete]="canDelete"
        [canCreate]="canCreate"
      >
        <ng-container *ngTemplateOutlet="issuesTmp; context: { issues: stepsIssues?.[key] }"></ng-container>
      </ag-shr-agent-campaign-phone-number-info>
    </ng-container>

    <ng-container *var="AgentCampaignStepName.contactInfoEmailAddress as key">
      <ag-shr-agent-campaign-email-address-info
        *ngSwitchCase="key"
        [hasStepIssues]="!!stepsIssues?.[key]?.length"
        [canEdit]="canEdit"
        [canDelete]="canDelete"
        [canCreate]="canCreate"
      >
        <ng-container *ngTemplateOutlet="issuesTmp; context: { issues: stepsIssues?.[key] }"></ng-container>
      </ag-shr-agent-campaign-email-address-info>
    </ng-container>

    <ng-container *var="AgentCampaignStepName.bio as key">
      <ag-shr-agent-campaign-bio
        *ngSwitchCase="key"
        [hasStepIssues]="!!stepsIssues?.[key]?.length"
        [canEdit]="canEdit"
        [canDelete]="canDelete"
        [canCreate]="canCreate"
      >
        <ng-container *ngTemplateOutlet="issuesTmp; context: { issues: stepsIssues?.[key] }"></ng-container>
      </ag-shr-agent-campaign-bio>
    </ng-container>

    <ng-container *var="AgentCampaignStepName.socialMedia as key">
      <ag-shr-agent-campaign-social-media
        *ngSwitchCase="key"
        [canEdit]="canEdit"
        [canDelete]="canDelete"
        [canCreate]="canCreate"
        [hasStepIssues]="!!stepsIssues?.[key]?.length"
      >
        <ng-container *ngTemplateOutlet="issuesTmp; context: { issues: stepsIssues?.[key] }"></ng-container>
      </ag-shr-agent-campaign-social-media>
    </ng-container>

    <ng-container *var="AgentCampaignStepName.logoHeadshot as key">
      <ag-shr-agent-campaign-logo-headshot
        *ngSwitchCase="key"
        [hasStepIssues]="!!stepsIssues?.[key]?.length"
        [canEdit]="canEdit"
        [canDelete]="canDelete"
        [canCreate]="canCreate"
      >
        <ng-container *ngTemplateOutlet="issuesTmp; context: { issues: stepsIssues?.[key] }"></ng-container>
      </ag-shr-agent-campaign-logo-headshot>
    </ng-container>

    <ng-container *var="AgentCampaignStepName.webSite as key">
      <ag-shr-agent-campaign-web-site
        *ngSwitchCase="key"
        [hasStepIssues]="!!stepsIssues?.[key]?.length"
        [canEdit]="canEdit"
        [canDelete]="canDelete"
        [canCreate]="canCreate"
      >
        <ng-container *ngTemplateOutlet="issuesTmp; context: { issues: stepsIssues?.[key] }"></ng-container>
      </ag-shr-agent-campaign-web-site>
    </ng-container>

    <ng-container *var="AgentCampaignStepName.appointment as key">
      <ag-shr-agent-campaign-appointment
        *ngSwitchCase="key"
        [hasStepIssues]="!!stepsIssues?.[key]?.length"
        [canEdit]="canEdit"
        [canDelete]="canDelete"
        [canCreate]="canCreate"
      >
        <ng-container *ngTemplateOutlet="issuesTmp; context: { issues: stepsIssues?.[key] }"></ng-container>
      </ag-shr-agent-campaign-appointment>
    </ng-container>

    <ng-container *var="AgentCampaignStepName.extras as key">
      <ag-shr-agent-campaign-extra
        *ngSwitchCase="key"
        [hasStepIssues]="!!stepsIssues?.[key]?.length"
        [canEdit]="canEdit"
        [canDelete]="canDelete"
        [canCreate]="canCreate"
      >
        <ng-container *ngTemplateOutlet="issuesTmp; context: { issues: stepsIssues?.[key] }"></ng-container>
      </ag-shr-agent-campaign-extra>
    </ng-container>

    <ag-shr-agent-campaign-summary
      *ngSwitchCase="AgentCampaignStepName.summary"
      [canEdit]="canEdit"
    ></ag-shr-agent-campaign-summary>
  </ng-container>
</div>

<ng-template #issuesTmp let-issues="issues">
  <div class="agent-campaign-wizard__issues-container">
    <p *ngFor="let item of issues">{{ item?.data?.description }}</p>
  </div>
</ng-template>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '.agent-campaign-wizard' }"
  [visible]="inProgress$ | async"
  [wrapperAttr]="{ class: 'agent-campaign-wizard__load-panel' }"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
>
</dx-load-panel>
