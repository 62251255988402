import { EmailTemplates } from './mailer.model';

export enum SendOTPKeys {
  email = 'email',
  duration = 'duration',
  emailTemplate = 'emailTemplate',
  subject = 'subject',
}

export interface SendOTP {
  [SendOTPKeys.duration]: 5 | 120;
  [SendOTPKeys.email]: string;
  [SendOTPKeys.emailTemplate]:
    | EmailTemplates.confirmEmailUpdateTmp
    | EmailTemplates.confirmRegisterEmailTmp
    | EmailTemplates.confirmPasswordUpdateTmp;
  [SendOTPKeys.subject]: string;
}
