import { NgModule } from '@angular/core';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { DxPopoverModule } from 'devextreme-angular';
import { GetSupportModalModule } from '../get-support-modal/get-support-modal.module';
import { WizardStepperComponent } from './wizard-stepper.component';
import { WizardStepperStepStatePipe } from './wizard-stepper-step-state.pipe';
import { WizardStepperSubStepStatePipe } from './wizard-stepper-sub-step-state.pipe';
import { WizardStepperSubStepsContainerClassPipe } from './wizard-stepper-sub-steps-container-class.pipe';

@NgModule({
  declarations: [
    WizardStepperComponent,
    WizardStepperStepStatePipe,
    WizardStepperSubStepStatePipe,
    WizardStepperSubStepsContainerClassPipe,
  ],
  imports: [SharedModule, DxPopoverModule, GetSupportModalModule],
  exports: [WizardStepperComponent],
})
export class WizardStepperModule {}
