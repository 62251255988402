import { Inject, Injectable } from '@angular/core';

import { FIREBASE_APP } from '../injections/firebase-app';
import { FirebaseApp } from 'firebase/app';
import { DataService } from './data.service';
import {
  AgentCampaign,
  AgentCampaignChanges,
  AgentCampaignKeys,
  AgentCampaignStepName,
  CampaignWebSite,
  agentCampaignOrderedSteps,
} from '@ag-common-lib/public-api';
import { QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';
import { documentId } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class AgentCampaignService extends DataService<AgentCampaign> {
  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp);
    this.collection = 'agent-campaigns';
  }

  getAllWithSameWebSite = (campaignDbId, data: CampaignWebSite) => {
    const dataQp: QueryParam[] = [
      !!campaignDbId && new QueryParam(documentId(), WhereFilterOperandKeys.notEqual, campaignDbId),
      new QueryParam(this.getDomainPath(AgentCampaignKeys.data), WhereFilterOperandKeys.equal, data?.domainOption),
      new QueryParam(this.getSubDomainPath(AgentCampaignKeys.data), WhereFilterOperandKeys.equal, data?.subdomain),
    ].filter(Boolean);
    const draftQp: QueryParam[] = [
      !!campaignDbId && new QueryParam(documentId(), WhereFilterOperandKeys.notEqual, campaignDbId),
      new QueryParam(this.getDomainPath(AgentCampaignKeys.draftData), WhereFilterOperandKeys.equal, data?.domainOption),
      new QueryParam(this.getSubDomainPath(AgentCampaignKeys.draftData), WhereFilterOperandKeys.equal, data?.subdomain),
    ].filter(Boolean);

    return Promise.all([super.getAllByValue(dataQp), super.getAllByValue(draftQp)]).then(response => response?.flat(1));
  };

  prepareSummary = (campaign: AgentCampaign, onlyChanges = false) => {
    if (!campaign) {
      return {};
    }

    const { [AgentCampaignKeys.data]: campaignData, [AgentCampaignKeys.draftData]: draftCampaignData } = campaign;
    const agentCampaignSummary: AgentCampaignChanges = {};

    agentCampaignOrderedSteps.forEach(stepName => {
      const currentStepData = campaignData?.[stepName] ?? null;
      const incomingStepData = draftCampaignData?.[stepName] ?? null;

      const hasChanges = !!incomingStepData;

      if (!hasChanges && onlyChanges) {
        return;
      }

      Object.assign(agentCampaignSummary, { [stepName]: { currentStepData, incomingStepData } });
    });

    return agentCampaignSummary;
  };

  private getDomainPath = (prefix: AgentCampaignKeys.data | AgentCampaignKeys.draftData) => {
    return [prefix, AgentCampaignStepName.webSite, 'domainOption'].join('.');
  };

  private getSubDomainPath = (prefix: AgentCampaignKeys.data | AgentCampaignKeys.draftData) => {
    return [prefix, AgentCampaignStepName.webSite, 'subdomain'].join('.');
  };
}
