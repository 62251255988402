import { Injectable } from '@angular/core';
import { BaseFormService } from '../../utils/base-form-service';
import { ProcessRefundPayload } from 'ag-common-lib/public-api';

@Injectable()
export class AttendeeManualRefundPaymentModalService extends BaseFormService<ProcessRefundPayload> {
  constructor() {
    super();
  }

  getFormData = (refundTransaction?: Partial<ProcessRefundPayload>) => {
    this.formData = new Proxy(Object.assign({}, new ProcessRefundPayload(), refundTransaction), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);

        return true;
      },
    });
    return this.formData;
  };
}
