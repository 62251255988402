<img
  class="image-with-loader__image"
  [class.image-with-loader__image--round]="isRound"
  [class.image-with-loader__image--with-border]="withBorder"
  [class.image-with-loader__image--rounded]="borderRounded"
  [src]="{
    usePlaceholder: usePlaceholder,
    src: (wasabiPath || agMedia?.[AGMediaKeys.wasabiPath]) | wasabiImg: mediaSize: false: cropperPayload
  }  "
  (imageLoadingState)="isLoading = $event"
  (isImageLoadingIssuesChange)="hasLoadingIssues = $event"
/>

<dx-load-indicator *ngIf="isLoading" class="image-with-loader__load-indicator"></dx-load-indicator>
