import { NgModule } from '@angular/core';
import { ImageWithLoaderModule } from '../image-with-loader/image-with-loader.module';
import { ModalWindowModule } from '../modal-window/modal-window.module';
import { AgAssignOwnerViewerComponent } from './ag-assign-owner-viewer.component';
import { AgAssignOwnerModalComponent } from './modal/ag-assign-owner-modal.component';

import { AgAssignOwnerFormService } from './modal/ag-assign-owner-form.service';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { AgAgentButtonModule } from '../ag-agent-button/ag-agent-button.module';

@NgModule({
  declarations: [AgAssignOwnerViewerComponent, AgAssignOwnerModalComponent],
  imports: [SharedModule, ImageWithLoaderModule, ModalWindowModule, AgAgentButtonModule],
  exports: [AgAssignOwnerViewerComponent, AgAssignOwnerModalComponent],
  providers: [AgAssignOwnerFormService],
})
export class AgAssignOwnerViewerModule {}
