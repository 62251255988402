export enum ASSOCIATION_TYPE {
  SPOUSE = 'Spouse',
  BROTHER = 'Brother',
  SISTER = 'Sister',
  MOTHER = 'Mother',
  FATHER = 'Father',
  SON = 'Son',
  DAUGHTER = 'Daughter',
  FRIEND = 'Friend',
  BUSINESS_PARTNER = 'Business Partner',
  FAMILY_MEMBER = 'Family Member',
}
