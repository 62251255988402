<div class="mt-2">
  <ag-shr-editor-toolbar
    [title]="'Agency Relationship with Alliance Group'"
    (clickEdit)="agencyRelationshipModalComponent.showModal()"
    [isEditVisible]="
      Entity.agencyAgencyRelationshipWithAllianceGroup | hasPermission : EntityPermissionActivityKeys.write | async
    "
  ></ag-shr-editor-toolbar>

  <div class="row my-2 px-3">
    <div class="col-3 text-start"><b>Initially Met:</b></div>
    <div class="col-3">
      {{ relationshipFormData?.initiallyMet | date : dateFormat | empty }}
    </div>
    <div class="col-3 text-start"><b>How Introduced:</b></div>
    <div class="col-3">
      {{ relationshipFormData?.howIntroduced | empty }}
    </div>
  </div>

  <div class="row my-2 px-3">
    <div class="col-3 text-start"><b>Contracted Date:</b></div>
    <div class="col-3">
      {{ relationshipFormData?.contractedDate | date : dateFormat | empty }}
    </div>
    <div class="col-3 text-start"><b>Introduce By:</b></div>
    <div class="col-3">
      {{ relationshipFormData?.introduceBy | empty }}
    </div>
  </div>

  <div class="row my-2 px-3">
    <div class="col-3 text-start"><b>Organization Affiliations:</b></div>
    <div class="col-3">
      {{
        relationshipFormData?.organizationAffiliations
          | organizationAffiliationsDescription : (agencyOrganizationAffiliations$ | async)
          | empty
      }}
    </div>
    <div class="col-3 text-start"><b>Founding Member:</b></div>
    <div class="col-3">
      {{ relationshipFormData?.isFoundingMember ? "Yes" : "No" }}
    </div>
  </div>

  <div class="row my-2 px-3">
    <div class="col-3 text-start"><b>Note About Relationship:</b></div>
    <div class="ag-preformatted col">
      {{ relationshipFormData?.note | empty }}
    </div>
  </div>
</div>

<ag-shr-modal-window
  #agencyRelationshipModalRef
  [title]="'Add / Edit Agency Relationship with Alliance Group'"
  [width]="'75%'"
  [height]="'auto'"
  [inProgress]="inProgress$ | async"
  [onCloseModal]="handleClosePopup"
  (onSaveClick)="handelSaveClick()"
>
  <dx-form
    #agencyRelationshipFormRef
    class="mt-2"
    [colCount]="2"
    [(formData)]="relationshipFormData"
    labelMode="floating"
  >
    <dxi-item
      [dataField]="AgencyRelationshipModelKeys.initiallyMet"
      [colSpan]="1"
      [label]="{ text: 'Initially Met' }"
      editorType="dxDateBox"
      [editorOptions]="dateEditorOptions"
    ></dxi-item>

    <dxi-item
      [dataField]="AgencyRelationshipModelKeys.contractedDate"
      [colSpan]="1"
      [label]="{ text: 'Contracted Date' }"
      editorType="dxDateBox"
      [editorOptions]="dateEditorOptions"
    ></dxi-item>

    <dxi-item
      [dataField]="AgencyRelationshipModelKeys.howIntroduced"
      [colSpan]="1"
      [label]="{ text: 'How Introduced' }"
    ></dxi-item>

    <dxi-item
      [dataField]="AgencyRelationshipModelKeys.introduceBy"
      [colSpan]="1"
      [label]="{ text: 'Introduce By' }"
    ></dxi-item>

    <dxi-item
      [dataField]="AgencyRelationshipModelKeys.organizationAffiliations"
      [colSpan]="1"
      [label]="{ text: 'Organization Affiliations' }"
      editorType="dxTagBox"
      [editorOptions]="{
        items: agencyOrganizationAffiliations$ | async,
        valueExpr: BaseModelKeys.dbId,
        displayExpr: LookupKeys.description,
        searchEnabled: true,
        showSelectionControls: true,
        applyValueMode: 'useButtons',
        placeholder: '',
        onSelectionChanged: onOrganizationAffiliationsChanged
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="AgencyRelationshipModelKeys.isFoundingMember"
      [colSpan]="1"
      [label]="{ text: 'Founding Member' }"
      editorType="dxSwitch"
      [editorOptions]="{ switchedOffText: 'false', switchedOnText: 'true' }"
    ></dxi-item>

    <dxi-item
      [dataField]="AgencyRelationshipModelKeys.note"
      [colSpan]="2"
      [label]="{ text: 'Note About Relationship' }"
      editorType="dxTextArea"
    ></dxi-item>
  </dx-form>
</ag-shr-modal-window>
