import { LookupKeys } from '../models/crm/lookup.model';
import { ApproveDenyReasonVisibilityLevel } from './approve-deny-reason-visibility-level.enum';

export const APPROVE_DENY_REASON_VISIBILITY_LEVEL_LOOKUP = [
  { [LookupKeys.value]: ApproveDenyReasonVisibilityLevel.All, [LookupKeys.description]: 'All' },
  { [LookupKeys.value]: ApproveDenyReasonVisibilityLevel.AgencyLevel, [LookupKeys.description]: 'Agency' },
  { [LookupKeys.value]: ApproveDenyReasonVisibilityLevel.ProspectLevel, [LookupKeys.description]: 'Prospect' },
  { [LookupKeys.value]: ApproveDenyReasonVisibilityLevel.MGALevel, [LookupKeys.description]: 'MGA' },
  {
    [LookupKeys.value]: ApproveDenyReasonVisibilityLevel.AllianceGroupLevel,
    [LookupKeys.description]: 'Alliance Group',
  },
];
