import { Pipe, PipeTransform } from '@angular/core';
import { PermissionsService } from '../../lib/services/permissions.service';
import { map, Observable } from 'rxjs';
import { Entity } from '@ag-common-lib/lib/lists/entity-permission.enum';
import { EntityPermissionActivityKeys } from '@ag-common-lib/public-api';

@Pipe({
  name: 'hasPermission',
})
export class HasPermissionPipe implements PipeTransform {
  constructor(private permissionsService: PermissionsService) {}

  transform(
    entity: keyof typeof Entity,
    entityPermissionKey?: EntityPermissionActivityKeys | EntityPermissionActivityKeys[],
  ): Observable<boolean> {
    return this.permissionsService.permissions$.pipe(
      map(permission => {
        if (!permission[entity]) {
          return false;
        }
        if (entityPermissionKey && entityPermissionKey?.length) {
          const keysToCheck = Array.isArray(entityPermissionKey) ? entityPermissionKey : [entityPermissionKey];
          return keysToCheck.some(key => permission[entity][key]);
        }

        return Object.values(EntityPermissionActivityKeys).some(key => permission[entity][key]);
      }),
    );
  }
}
