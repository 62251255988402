<p class="editor__data-grid--empty" *ngIf="!isEditable && !stateLicenses?.length; else dataGridTmp">
  {{ emptyMessage }}
</p>

<ng-template #dataGridTmp>
  <dx-data-grid
    #dataGridRef
    class="editor editor__data-grid"
    [class.editor__data-grid--view]="!isEditable"
    [dataSource]="stateLicenses"
    [showRowLines]="isEditable"
    [showBorders]="isEditable"
    [showColumnLines]="isEditable"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    [rowAlternationEnabled]="false"
    [noDataText]="emptyMessage"
    (onRowInserting)="onRowInserting($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event)"
  >
    <dxo-toolbar [visible]="isEditable">
      <dxi-item location="before" [visible]="!isEditable">
        <div *dxTemplate>
          <b>State Licenses</b>
        </div>
      </dxi-item>

      <dxi-item
        *ngIf="Entity.agencyAgencyInfoStatesLicensedIn | hasPermission : EntityPermissionActivityKeys.create | async"
        name="addRowButton"
      ></dxi-item>
    </dxo-toolbar>

    <dxo-editing
      mode="popup"
      [useIcons]="true"
      [allowUpdating]="
        Entity.agencyAgencyInfoStatesLicensedIn | hasPermission : EntityPermissionActivityKeys.write | async
      "
      [allowDeleting]="
        Entity.agencyAgencyInfoStatesLicensedIn | hasPermission : EntityPermissionActivityKeys.delete | async
      "
      [allowAdding]="
        Entity.agencyAgencyInfoStatesLicensedIn | hasPermission : EntityPermissionActivityKeys.create | async
      "
    >
      <dxo-popup title="State License" [showTitle]="true" [width]="'75%'" [height]="'auto'">
        <dxi-toolbar-item
          widget="dxButton"
          location="after"
          toolbar="bottom"
          *ngIf="!!dataGridRef.instance"
          [options]="{
            text: 'CANCEL',
            onClick: onCancel,
            elementAttr: {
              class: 'modal-window__toolbar-button'
            }
          }"
        >
        </dxi-toolbar-item>

        <dxi-toolbar-item widget="dxButton" location="after" toolbar="bottom">
          <div *dxTemplate>
            <shr-button-with-indicator
              [isInProgress]="inProgress$ | async"
              (onClick)="dataGridRef.instance.saveEditData()"
            ></shr-button-with-indicator>
          </div>
        </dxi-toolbar-item>
      </dxo-popup>

      <dxo-form [colCount]="12" [labelMode]="'floating'">
        <dxi-item [dataField]="StateLicenseKeys.state" [colSpan]="6"></dxi-item>

        <dxi-item [dataField]="StateLicenseKeys.licenseNumber" [colSpan]="6"></dxi-item>

        <dxi-item [dataField]="StateLicenseKeys.residency" [colSpan]="6"></dxi-item>

        <dxi-item [dataField]="StateLicenseKeys.expiration" [colSpan]="6"></dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxi-column [dataField]="StateLicenseKeys.state" caption="State" editCellTemplate="stateEditorTmp">
      <dxo-lookup [dataSource]="statesLookup$ | async" [displayExpr]="'description'" [valueExpr]="'value'"></dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="StateLicenseKeys.licenseNumber" caption="License Number"></dxi-column>

    <dxi-column [dataField]="StateLicenseKeys.residency" caption="Residency">
      <dxo-lookup [dataSource]="residencyTypes" [displayExpr]="'description'" [valueExpr]="'value'"></dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="StateLicenseKeys.expiration"
      caption="Expiration"
      [dataType]="'date'"
      [format]="dateFormat"
    ></dxi-column>

    <dxi-column type="buttons" [visible]="isEditable">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
    </dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let cell of 'stateEditorTmp'">
      <ag-shr-state-select-box [value]="cell.value" (valueChange)="cell.setValue($event)"> </ag-shr-state-select-box>
    </div>
  </dx-data-grid>
</ng-template>
