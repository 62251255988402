export enum StateLicenseKeys {
  state = 'state',
  licenseNumber = 'license_number',
  residency = 'residency',
  expiration = 'expiration',
}
export class StateLicense {
  [StateLicenseKeys.state]: string;
  [StateLicenseKeys.licenseNumber]: string;
  [StateLicenseKeys.residency]: string;
  [StateLicenseKeys.expiration]: Date | string;
}
