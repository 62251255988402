import { Inject, Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { NotificationOptInGroupKeys, PushNotificationCampaign, PushNotificationCampaignKeys } from '@ag-common-lib/lib';
import { DataService } from '../data.service';
import { FIREBASE_APP, dateFromTimestamp } from 'ag-common-svc/public-api';
import { get, set } from 'lodash';

@Injectable()
export class ScheduledPushNotificationsService extends DataService<PushNotificationCampaign> {
  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(
      fireBaseApp,
      ScheduledPushNotificationsService.fromFirestore /* , ScheduledPushNotificationsService.toFirestore */,
    );
    this.collection = 'scheduled-push-notifications';
  }

  static readonly fromFirestore = (data): PushNotificationCampaign => {
    const result = structuredClone(data);

    if (!!data?.deliverAt) {
      Object.assign(result, { [PushNotificationCampaignKeys.deliverAt]: dateFromTimestamp(data?.deliverAt) });
    }

    // const optInGroups = data?.[PushNotificationCampaignKeys.optInGroups];
    // if (Array.isArray(optInGroups)) {
    //   Object.assign(result, {
    //     [PushNotificationCampaignKeys.optInGroups]: optInGroups?.map(optInGroup => {
    //       let normalizedFilterExpression = [];

    //       try {
    //         const filterExpression = optInGroup?.[NotificationOptInGroupKeys.filterExpression];

    //         if (filterExpression) {
    //           normalizedFilterExpression = JSON.parse(filterExpression);
    //         }
    //       } catch (error) {}

    //       return Object.assign({}, optInGroup, {
    //         [NotificationOptInGroupKeys.filterExpression]: normalizedFilterExpression,
    //       });
    //     }),
    //   });
    // }

    return result;
  };

  static readonly toFirestore = (data: PushNotificationCampaign) => {
    const result = Object.assign({}, data);
    const optInGroups = data?.[PushNotificationCampaignKeys.optInGroups];

    if (!Array.isArray(optInGroups)) {
      return result;
    }

    return Object.assign({}, data, {
      [PushNotificationCampaignKeys.optInGroups]: optInGroups?.map(optInGroup => {
        const normalizedOptInGroup = Object.assign({}, optInGroup);

        const filterExpression = get(normalizedOptInGroup, [NotificationOptInGroupKeys.filterExpression]);
        set(normalizedOptInGroup, [NotificationOptInGroupKeys.filterExpression], JSON.stringify(filterExpression));

        return normalizedOptInGroup;
      }),
    });
  };
}
