import { Pipe, PipeTransform } from '@angular/core';
import {
  AT_LEISURE_EXCURSION_SUFFIX,
  AT_LEISURE_ITEM_TEXT,
  Excursion,
  ExcursionKeys,
  MMDDYYYY_DATE_FORMAT,
} from '@ag-common-lib/public-api';
import { differenceInYears, format, isSameDay, isValid, toDate } from 'date-fns';

@Pipe({ name: 'excursionsSource' })
export class ExcursionSourcePipe implements PipeTransform {
  transform(conferenceExcursions: Excursion[], date: Date, attendeeDob?: Date): Partial<Excursion>[] {
    if (!isValid(toDate(date))) {
      return conferenceExcursions;
    }
    const excursionsByDate: Partial<Excursion>[] = conferenceExcursions.filter(excursion => {
      const excursionData = excursion?.[ExcursionKeys.date];
      const excursionMinAge = excursion?.[ExcursionKeys.minAge];
      if (!isValid(toDate(excursionData))) {
        return true;
      }

      if (!isSameDay(date, excursionData)) {
        return false;
      }

      if (!excursionMinAge) {
        return true;
      }

      if (!isValid(toDate(attendeeDob))) {
        return false;
      }

      const calculatedAge = differenceInYears(date, attendeeDob);

      return calculatedAge >= excursionMinAge;
    });

    const excursionId = format(date, 'yyyy-MM-dd') + AT_LEISURE_EXCURSION_SUFFIX;

    excursionsByDate.push({
      [ExcursionKeys.id]: excursionId,
      [ExcursionKeys.name]: AT_LEISURE_ITEM_TEXT,
    });

    return excursionsByDate;
  }
}
