import { NgModule } from '@angular/core';
import { AgentCampaignExtraComponent } from './agent-campaign-extra.component';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ButtonWithIndicatorModule } from '../../button-with-indicator/button-with-indicator.module';
import { AgentCampaignExtraService } from './agent-campaign-extra.service';
import { NgIf } from '@angular/common';

@NgModule({
  declarations: [AgentCampaignExtraComponent],
    imports: [ SharedModule, ButtonWithIndicatorModule, NgIf ],
  exports: [AgentCampaignExtraComponent],
  providers: [AgentCampaignExtraService],
})
export class AgentCampaignExtraModule {}
