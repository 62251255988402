<ag-shr-status-activity-modal
  *ngIf="isEditable"
  #agencyStatusActivityModalRef
  [title]="editModalOptions?.title ?? 'Agency Status Activity'"
  [isVisibilityTypeLocked]="editModalOptions?.isVisibilityTypeLocked"
></ag-shr-status-activity-modal>

<ng-container *var="approveDenyReasons$ | async as approveDenyReasons">
  <p class="editor__data-grid--empty" *ngIf="!isEditable && !approveDenyReasons?.totalCount(); else dataGridTmp">
    {{ emptyMessage }}
  </p>

  <ng-template #dataGridTmp>
    <dx-data-grid
      class="ag-status-activity editor editor__data-grid"
      [class.editor__data-grid--view]="!isEditable"
      [dataSource]="approveDenyReasons ?? []"
      [keyExpr]="BaseModelKeys.dbId"
      [showRowLines]="isEditable"
      [showBorders]="isEditable"
      [showColumnLines]="isEditable"
      [columnAutoWidth]="true"
      [wordWrapEnabled]="isEditable"
      [rowAlternationEnabled]="false"
      [noDataText]="emptyMessage"
      (onRowRemoving)="onRowRemoving($event)"
      height="100%"
    >
      <dxo-toolbar [visible]="isEditable">
        <dxi-item
          *ngIf="Entity.agencyAgencyInfoStatusActivity | hasPermission : EntityPermissionActivityKeys.create | async"
          location="after"
          widget="dxButton"
          [options]="{ icon: 'plus', onClick: showAddApproveDenyReasonPopup }"
        ></dxi-item>
      </dxo-toolbar>

      <dxo-editing
        mode="popup"
        [useIcons]="true"
        [allowUpdating]="false"
        [allowDeleting]="
          Entity.agencyAgencyInfoStatusActivity | hasPermission : EntityPermissionActivityKeys.delete | async
        "
        [allowAdding]="false"
      >
      </dxo-editing>

      <dxo-paging [enabled]="false"></dxo-paging>

      <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>

      <dxi-column [width]="150" [dataField]="BaseModelKeys.createdBy" [allowEditing]="false"> </dxi-column>

      <dxi-column
        [width]="150"
        [dataField]="BaseModelKeys.createdDate"
        [allowEditing]="false"
        [dataType]="'date'"
        [sortOrder]="'desc'"
        [format]="dateFormat"
      ></dxi-column>

      <dxi-column [width]="150" [dataField]="ApproveDenyReasonKeys.visibilityLevel">
        <dxo-lookup
          [dataSource]="approveDenyReasonVisibilityLevelLookup"
          valueExpr="value"
          displayExpr="description"
        ></dxo-lookup>
      </dxi-column>

      <dxi-column [dataField]="ApproveDenyReasonKeys.activity"></dxi-column>

      <dxi-column type="buttons" [visible]="isEditable">
        <dxi-button
          [visible]="Entity.agencyAgencyInfoStatusActivity | hasPermission : EntityPermissionActivityKeys.write | async"
          icon="edit"
          [onClick]="showEditPopup"
        ></dxi-button>
        <dxi-button
          [visible]="
            getIsEditingAllowed && Entity.agencyAgencyInfoStatusActivity
              | hasPermission : EntityPermissionActivityKeys.delete
              | async
          "
          name="delete"
        >
        </dxi-button>
      </dxi-column>
    </dx-data-grid>
  </ng-template>
</ng-container>
