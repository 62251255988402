export enum AGENT_TYPE {
  MGA_PRINCIPAL = 'MGA_PRINCIPAL',
  MGA_ADMIN = 'MGA_ADMIN',
  AGENCY_PRINCIPAL = 'AGENCY_PRINCIPAL',
  AGENCY_ADMIN = 'AGENCY_ADMIN',
  GENERAL_AGENT = 'GENERAL_AGENT',
}

export const AGENT_TYPE_LOOKUP = [
  { value: AGENT_TYPE.MGA_PRINCIPAL, description: 'MGA Principal' },
  { value: AGENT_TYPE.MGA_ADMIN, description: 'MGA Admin' },
  { value: AGENT_TYPE.AGENCY_PRINCIPAL, description: 'Agency Principal' },
  { value: AGENT_TYPE.AGENCY_ADMIN, description: 'Agency Admin' },
  { value: AGENT_TYPE.GENERAL_AGENT, description: 'General Agent' },
];
