import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { AgentCampaignWizardService } from '../agent-campaign-wizard.service';
import { DxFormComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';
import { validateUrlWithOrWithoutProtocol } from 'ag-common-svc/lib/utils/validation';
import { Messages } from '@ag-common-lib/public-api';
import { AgentCampaignAppointmentService } from './agent-campaign-appointment.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export enum AppointmentTool {
  calendly = 'calendly',
}
export const AppointmentToolMap = new Map<AppointmentTool, string>([[AppointmentTool.calendly, 'Calendly']]);
export const appointmentTools = [
  { value: AppointmentTool.calendly, description: AppointmentToolMap.get(AppointmentTool.calendly) },
];
@UntilDestroy()
@Component({
  selector: 'ag-shr-agent-campaign-appointment',
  templateUrl: './agent-campaign-appointment.component.html',
  styleUrls: ['./agent-campaign-appointment.component.scss'],
})
export class AgentCampaignAppointmentComponent {
  @HostBinding('class') className = 'agent-campaign-appointment agent-campaign-wizard__content-container';
  @ViewChild('appointmentFormRef') appointmentFormComponent: DxFormComponent;

  @Input() hasStepIssues: boolean;
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;

  inProgress$: Observable<boolean>;
  appointmentFormData: Partial<any>;
  hasFormChanges$: Observable<boolean>;

  protected readonly Messages = Messages;
  protected readonly appointmentTools = appointmentTools;

  constructor(private agentCampaignAppointmentService: AgentCampaignAppointmentService) {
    this.inProgress$ = agentCampaignAppointmentService.inProgress$;
    this.hasFormChanges$ = agentCampaignAppointmentService.hasFormChanges$;
    agentCampaignAppointmentService.formData$.pipe(untilDestroyed(this)).subscribe(formData => {
      this.appointmentFormData = formData;
    });
  }

  validateUrlWithOrWithoutProtocol = e => validateUrlWithOrWithoutProtocol(e?.value);

  nextStep = () => {
    const validationResults = this.appointmentFormComponent?.instance?.validate();

    if (!validationResults.isValid) {
      return;
    }
    this.agentCampaignAppointmentService.handleNextStepClick();
  };

  resetChanges = this.agentCampaignAppointmentService.revertAllChanges;
}
