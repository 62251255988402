export interface FilterCondition {
  field: string;
  title?: string;
  operator: DxFilterOperators;
  value: any;
  displayValue?: string;
}

export interface FilterGroup {
  filters: (FilterCondition | FilterGroup)[];
  groupOperator?: string;
}

export enum DxFilterOperators {
  equal = '=',
  doNotEqual = '<>',
  more = '>',
  moreOrEqual = '>=',
  less = '<',
  lessOrEqual = '<=',
  between = 'between',
  startsWith = 'startswith',
  endsWith = 'endswith',
  contains = 'contains',
  notContains = 'notcontains',
  or = 'or',
  and = 'and',
  not = '!',
}
export const DxFilterOperatorsMap = new Map([
  [DxFilterOperators.not, 'Not'],
  [DxFilterOperators.equal, 'Equals'],
  [DxFilterOperators.doNotEqual, 'Does not equal'],
  [DxFilterOperators.more, 'More'],
  [DxFilterOperators.moreOrEqual, 'More or Equal'],
  [DxFilterOperators.less, 'Less'],
  [DxFilterOperators.lessOrEqual, 'Less or Equal'],
  [DxFilterOperators.between, 'Between'],
  [DxFilterOperators.startsWith, 'Starts With'],
  [DxFilterOperators.endsWith, 'Ends With'],
  [DxFilterOperators.contains, 'Contains'],
  [DxFilterOperators.notContains, 'Does not contain'],
  [DxFilterOperators.or, 'Or'],
  [DxFilterOperators.and, 'And'],
]);
