<div class="mt-2">
  <ag-shr-editor-toolbar [title]="'Sizes for Fun Stuff'" [isEditVisible]="canEdit" (clickEdit)="showEditorModal()"></ag-shr-editor-toolbar>

  <div class="row my-2 px-3">
    <div class="col-3 text-start"><b>T-Shirt Size:</b></div>
    <div class="col-3">
      {{ funStaffFormDetails?.[AgentKeys.tShirtSizes]?.[TShirtSizesKeys?.tShirtSize] | tShortSize | async }}
      <span *ngIf="funStaffFormDetails?.[AgentKeys.tShirtSizes]?.[TShirtSizesKeys?.tShirtSizeOther] as tShirtSizeOther">/ {{ tShirtSizeOther }}</span>
    </div>
    <div class="col-3 text-start"><b>Hobbies:</b></div>
    <div class="col-3">{{ funStaffFormDetails?.[AgentKeys.hobbies] }}</div>
  </div>
  <div class="row my-2 px-3">
    <div class="col-3 text-start"><b>Unisex T-Shirt Size:</b></div>
    <div class="col-3">
      {{ funStaffFormDetails?.[AgentKeys.tShirtSizes]?.[TShirtSizesKeys?.unisexTShirtSize] | tShortSize | async }}
      <span *ngIf="funStaffFormDetails?.[AgentKeys.tShirtSizes]?.[TShirtSizesKeys?.unisexTShirtSizeOther] as unisexTShirtSizeOther"
        >/ {{ unisexTShirtSizeOther }}</span
      >
    </div>
    <div class="col-3 text-start"><b>Favorite Destination:</b></div>
    <div class="col-3">{{ funStaffFormDetails?.[AgentKeys.favorite_destination] }}</div>
  </div>
  <div class="row my-2 px-3">
    <div class="col-3 text-start"><b>Shoe Size:</b></div>
    <div class="col-3">{{ funStaffFormDetails?.[AgentKeys.shoe_size] }}</div>
    <div class="col-3 text-start"></div>
  </div>
</div>

<ag-shr-modal-window
  [width]="'50vw'"
  [height]="'auto'"
  #sizeModalRef
  [title]="'Sizes For Fun'"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="saveAgentUpdates()"
  (onPopupClose)="handleClosePopup($event)"
>
  <dx-form #sizeFormRef class="mt-2" [formData]="funStaffFormDetails" labelMode="floating">
    <dxi-item>
      <div *dxTemplate>
        <ag-shr-t-shirt-seizes-form
        [tShirtSizes]="funStaffFormDetails?.[AgentKeys.tShirtSizes]"
        [validationGroup]="shirtSeizesFormValidationGroup"
        [useFullWith]="true"
        ></ag-shr-t-shirt-seizes-form>
      </div>
    </dxi-item>
    <dxi-item itemType="group" [colCount]="12">
      <dxi-item [dataField]="AgentKeys.shoe_size" [colSpan]="6">
        <dxo-label [text]="'Shoe Size'"></dxo-label>
      </dxi-item>

      <dxi-item [dataField]="AgentKeys.favorite_destination" [colSpan]="6">
        <dxo-label [text]="'Favorite Destination'"></dxo-label>
      </dxi-item>
    </dxi-item>

    <dxi-item [dataField]="AgentKeys.hobbies">
      <dxo-label [text]="'Hobbies'"></dxo-label>
    </dxi-item>
  </dx-form>
</ag-shr-modal-window>
