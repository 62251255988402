import { TShirtSizesMap } from '../utils/t-shirt-seizes.model';
import { LookupKeys } from '../crm/lookup.model';
import { AssociationMap } from '../utils/association.model';
import { DietaryConsiderationMap } from '../utils/dietary-consideration.model';

export enum AttendeeType {
  Invitee = 'invitee',
  Guest = 'guest',
}

export const ATTENDEE_TYPE_LOOKUP = [
  { [LookupKeys.value]: AttendeeType.Invitee, [LookupKeys.description]: 'Invitee' },
  { [LookupKeys.value]: AttendeeType.Guest, [LookupKeys.description]: 'Guest' },
];

export const AttendeeTypeMap = new Map([
  [AttendeeType.Invitee, 'Invitee'],
  [AttendeeType.Guest, 'Guest'],
]);

export enum AttendeeKeys {
  dbId = 'dbId',
  inviteeStatus = 'inviteeStatus',
  inviteeOutcomeStatus = 'inviteeOutcomeStatus',
  conferenceDbId = 'conferenceDbId',
  registrantDbId = 'registrantDbId',
  attendeeType = 'attendeeType',
  inviteeEmail = 'inviteeEmail',
  inviteeEmailType = 'inviteeEmailType',
  firstName = 'firstName',
  middleName = 'middleName',
  lastName = 'lastName',
  prefix = 'prefix',
  suffix = 'suffix',
  registrationType = 'registrationType',
  qualifiedAs = 'qualifiedAs',
  companyReference = 'companyReference',
  mgaId = 'mgaId',
  agencyId = 'agencyId',
  dob = 'dob',
  gender = 'gender',
  awardName = 'awardName',
  emergencyContact = 'emergencyContact',
  dietaryConsideration = 'dietaryConsideration',
  associationType = 'associationType',
  badgeName = 'badgeName',
  tShirtSizes = 'tShirtSizes',
  childOrAdult = 'childOrAdult',
  headshot = 'headshot',
  flightInformation = 'flightInformation',
  phoneNumber = 'phoneNumber',
  firstTimeAttendee = 'firstTimeAttendee',
  registrationState = 'registrationState',
  stepInProgress = 'stepInProgress',
}

export const ATTENDEE_TITLE_LIST = {
  [AttendeeKeys.inviteeEmail]: 'Invitee Email',
  [AttendeeKeys.attendeeType]: 'Attendee Type',
  [AttendeeKeys.qualifiedAs]: 'Qualified As',
  [AttendeeKeys.registrationType]: 'Registration Type',
  [AttendeeKeys.firstName]: 'First Name',
  [AttendeeKeys.middleName]: 'Middle Name',
  [AttendeeKeys.lastName]: 'Last Name',
  [AttendeeKeys.prefix]: 'Prefix',
  [AttendeeKeys.suffix]: 'Suffix',
  [AttendeeKeys.mgaId]: 'MGA',
  [AttendeeKeys.agencyId]: 'Agency',
  [AttendeeKeys.gender]: 'Gender',
  [AttendeeKeys.companyReference]: 'Company Reference',
  [AttendeeKeys.dob]: 'Date Of Birth',
  [AttendeeKeys.registrationState]: 'Registration State',
  [AttendeeKeys.inviteeStatus]: 'Invitee Status',
  [AttendeeKeys.associationType]: 'Relationship Type',
  [AttendeeKeys.inviteeOutcomeStatus]: 'Invitee Outcome Status',
  [AttendeeKeys.firstTimeAttendee]: 'First Time Attendee',
  [AttendeeKeys.inviteeEmailType]: 'Invitee Email Type',
  [AttendeeKeys.awardName]: 'Award Name',
  [AttendeeKeys.childOrAdult]: 'T-Shirt Size Range',
  [AttendeeKeys.dietaryConsideration]: DietaryConsiderationMap,
  [AttendeeKeys.emergencyContact]: AssociationMap,
  [AttendeeKeys.tShirtSizes]: TShirtSizesMap,
};

export enum AttendeeWizardState {
  initial = 'initial',
  inProgress = 'inProgress',
  supportRequested = 'supportRequested',
  initialRegistrationSubmitted = 'initialRegistrationSubmitted',
  completedAndSubmitted = 'completedAndSubmitted',
}

export const ATTENDEE_WIZARD_STATE_LOOKUP = [
  { [LookupKeys.value]: AttendeeWizardState.initial, [LookupKeys.description]: 'Initial' },
  { [LookupKeys.value]: AttendeeWizardState.inProgress, [LookupKeys.description]: 'In Progress' },
  { [LookupKeys.value]: AttendeeWizardState.supportRequested, [LookupKeys.description]: 'Support Requested' },
  {
    [LookupKeys.value]: AttendeeWizardState.initialRegistrationSubmitted,
    [LookupKeys.description]: 'Initial Registration Passed',
  },
  {
    [LookupKeys.value]: AttendeeWizardState.completedAndSubmitted,
    [LookupKeys.description]: 'Completed And Submitted',
  },
];

export interface AttendeeInterface {}
