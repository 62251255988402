import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';

interface VarContext<T> {
  var: T | null;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[var]',
})
export class VarDirective<T> {
  private context: VarContext<T> = { var: null };

  constructor(viewContainer: ViewContainerRef, templateRef: TemplateRef<VarContext<T>>) {
    viewContainer.createEmbeddedView(templateRef, this.context);
  }

  @Input()
  set var(value: T) {
    this.context.var = value;
  }
}

/* Usage: <element *var="agency$ | async as agency"></element> */
