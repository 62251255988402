import { Injectable } from '@angular/core';
import { AssignedOwnerDetails, AssignedOwnerDetailsKeys } from '../config/ag-assign-owner.config';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, map, Observable } from 'rxjs';
import { BaseModelKeys } from '@ag-common-lib/public-api';
import { BaseFormService } from 'ag-common-svc/lib/utils/base-form-service';
import { AuthService } from 'ag-common-svc/lib/services/auth.service';

@Injectable()
export class AgAssignOwnerFormService extends BaseFormService<AssignedOwnerDetails> {
  loggedInUserId$: Observable<string>;

  constructor(authService: AuthService, private toastrService: ToastrService) {
    super();
    this.loggedInUserId$ = authService.loggedInAgent$.pipe(map(agent => agent?.[BaseModelKeys.dbId]));
  }

  getOwnerFormData = async (owner?: AssignedOwnerDetails): Promise<Partial<AssignedOwnerDetails>> => {
    let assignedTo = owner?.assignedTo;
    if (!assignedTo) {
      assignedTo = await firstValueFrom(this.loggedInUserId$);
    }

    const initialData = Object.assign({}, owner);
    Object.assign(initialData, {
      [AssignedOwnerDetailsKeys.assignedTo]: assignedTo ?? '',
      [AssignedOwnerDetailsKeys.assignedToNote]: owner?.assignedToNote ?? '',
    });
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);

        return true;
      },
    });

    return this.formData;
  };

  onSuccessfulOwnerCreated(): void {
    this.onSuccessfulCreated();
  }
}
