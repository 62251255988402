<!-- <span>
  <ng-container *ngIf="!!hasIncomingData">
    {{hasCurrentData ? 'Updated': 'Added'}}:
  </ng-container>
</span> -->
<span
  class="agent-campaign-summary-list-data__title"
  *ngIf="title"
>
  {{title}}:
</span>

<div
  *ngIf="hasCurrentData"
  class="agent-campaign-summary-list-data__current-data"
  [class.agent-campaign-summary-list-data__current-data--empty]="!hasCurrentData"
  [class.agent-campaign-summary-list-data__current-data--has-changes]="!!hasIncomingData"
>

  <ng-content select="[currentDataTmp]"></ng-content>
</div>

<div
  *ngIf="!hasCurrentData && ! hasIncomingData"
  class="agent-campaign-summary-list-data__current-data agent-campaign-summary-list-data__current-data--empty"
>
  Not Assigned
</div>

<ng-container *ngIf="hasIncomingData">
  <i
    *ngIf="!!hasCurrentData"
    class="dx-icon dx-icon-arrowright"
  ></i>

  <div class="agent-campaign-summary-list-data__incoming-data">
    <ng-content select="[incomingDataTmp]"></ng-content>
  </div>

  <ng-content select="[controls]"></ng-content>
</ng-container>
