import { Component, HostBinding, Input } from '@angular/core';
import { AgentCampaignChange, CampaignAppointment, CampaignWebSite } from '@ag-common-lib/public-api';
import { AppointmentToolMap } from '../../agent-campaign-wizard/agent-campaign-appointment/agent-campaign-appointment.component';

@Component({
  selector: 'ag-shr-agent-campaign-summary-appointment',
  templateUrl: './agent-campaign-summary-appointment.component.html',
  styleUrls: ['./agent-campaign-summary-appointment.component.scss'],
})
export class AgentCampaignSummaryAppointmentComponent {
  @HostBinding('class') className = 'agent-campaign-summary-appointment';

  @Input() stepData: AgentCampaignChange<CampaignAppointment>;

  protected readonly AppointmentToolMap = AppointmentToolMap;
  constructor() {}
}
