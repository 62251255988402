import { LookupBase } from '../models/utils/lookup-base.model';

export enum ConferenceRegistrationTicketState {
  invited = 'invited', // invited + no issues
  hasIssues = 'hasIssues', // registered + issues || invited + issues
  registered = 'registered', // registered + no issues
  done = 'done', // all steps done
  declined = 'declined',
}

export const CONFERENCE_REGISTRATIONS_TECKET_STATUS_LOOKUP: LookupBase[] = [
  { value: ConferenceRegistrationTicketState.invited, description: 'Invited' },
  { value: ConferenceRegistrationTicketState.hasIssues, description: 'Has Issues' },
  { value: ConferenceRegistrationTicketState.registered, description: 'Registered' },
  { value: ConferenceRegistrationTicketState.done, description: 'Done' },
  { value: ConferenceRegistrationTicketState.declined, description: 'Declined' },
];
