<ag-shr-modal-window #associationModalRef [title]="'Associations'" [showSaveButton]="false">
  <ng-container *ngTemplateOutlet="grid; context: { isEdit: true }"></ng-container>
</ag-shr-modal-window>

<ag-shr-modal-window
  #editAssociationModalRef
  [title]="'Add / Edit Association'"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="handleSaveAssociation($event)"
  [onCloseModal]="handleAssociationFormPopupClose"
>
  <dx-form
    #associationFormRef
    *ngIf="!!associationFormData"
    [formData]="associationFormData"
    labelMode="floating"
    [validationGroup]="validationGroup"
  >
    <dxi-item itemType="group" caption="Main Info" [colCount]="12">
      <dxi-item [colSpan]="6">
        <div *dxTemplate>
          <ag-shr-relationship-type-select-box
            [(value)]="associationFormData[AssociationKeys.associationType]"
            (selectedItemChange)="onRelationshipTypeSelectionChanged($event)"
          ></ag-shr-relationship-type-select-box>
        </div>
      </dxi-item>

      <dxi-item
        [dataField]="AssociationKeys.isEmergencyContact"
        [label]="{ text: 'Is Emergency Contact?' }"
        [alignItemLabels]="'left'"
        [colSpan]="6"
        editorType="dxSwitch"
        [editorOptions]="{ switchedOffText: 'false', switchedOnText: 'true' }"
      ></dxi-item>

      <dxi-item [dataField]="AssociationKeys.firstName" [label]="{ text: 'First Name' }" [colSpan]="6"></dxi-item>

      <dxi-item [dataField]="AssociationKeys.lastName" [label]="{ text: 'Last Name' }" [colSpan]="6"></dxi-item>

      <dxi-item [dataField]="AssociationKeys.emailAddress" [label]="{ text: 'Email Address' }" [colSpan]="6"></dxi-item>

      <dxi-item
        [dataField]="AssociationKeys.contactNumber"
        [label]="{ text: 'Phone Number' }"
        [colSpan]="6"
        [editorOptions]="{ mask: phoneMask }"
      >
        <dxi-validation-rule
          type="stringLength"
          [min]="totalPhoneDigits"
          [max]="totalPhoneDigits"
          [ignoreEmptyValue]="true"
          [trim]="true"
          [message]="Messages.INVALID_PHONE_DIGITS_TEXT"
        >
        </dxi-validation-rule>
      </dxi-item>

      <!-- <dxi-item [dataField]="p_nick_first_name" [label]="{ text: 'Nick Name First' }" [colSpan]="6"></dxi-item>

      <dxi-item [dataField]="p_nick_last_name" [label]="{ text: 'Nick Name Last' }" [colSpan]="6"></dxi-item> -->

      <dxi-item
        [dataField]="AssociationKeys.dob"
        [colSpan]="6"
        editorType="dxDateBox"
        [label]="{ text: 'Date Of Birth' }"
        [editorOptions]="dateEditorOptions"
      >
      </dxi-item>

      <dxi-item [dataField]="AssociationKeys.gender" [colSpan]="6">
        <dxo-label [text]="'Gender'"></dxo-label>

        <div *dxTemplate>
          <ag-shr-gender-select-box
            [(value)]="associationFormData.gender"
            (selectedItemChange)="handleGenderSelect($event)"
          ></ag-shr-gender-select-box>
        </div>
      </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" caption="Fun Stuff Sizes">
      <div *dxTemplate>
        <ag-shr-t-shirt-seizes-form
          [containerColCount]="2"
          [tShirtSizes]="associationFormData?.[AssociationKeys.tShirtSizes]"
          [validationGroup]="validationGroup"
          [useFullWith]="true"
          [screenLimits]="{ md: 1400 }"
        ></ag-shr-t-shirt-seizes-form>
      </div>
    </dxi-item>

    <dxi-item itemType="group" caption="Address" [colCount]="12">
      <dxi-item
        [dataField]="[AssociationKeys.address, AddressModelKeys.address1].join('.')"
        [label]="{ text: 'Address 1' }"
        [colSpan]="6"
      ></dxi-item>
      <dxi-item
        [dataField]="[AssociationKeys.address, AddressModelKeys.address2].join('.')"
        [label]="{ text: 'Address 2' }"
        [colSpan]="6"
      ></dxi-item>
      <dxi-item
        [dataField]="[AssociationKeys.address, AddressModelKeys.city].join('.')"
        [label]="{ text: 'City' }"
        [colSpan]="6"
      ></dxi-item>
      <dxi-item [dataField]="[AssociationKeys.address, AddressModelKeys.state].join('.')" [colSpan]="3">
        <div *dxTemplate>
          <ag-shr-state-select-box [value]="associationFormData?.address?.state" (valueChange)="setState($event)">
          </ag-shr-state-select-box>
        </div>
      </dxi-item>
      <dxi-item
        [dataField]="[AssociationKeys.address, AddressModelKeys.zip].join('.')"
        [label]="{ text: 'Zip Code' }"
        [colSpan]="3"
      ></dxi-item>
      <dxi-item
        [dataField]="[AssociationKeys.address, AddressModelKeys.county].join('.')"
        [label]="{ text: 'Country' }"
        editorType="dxSelectBox"
        [editorOptions]="{
          acceptCustomValue: true,
          items: countries,
          valueExpr: 'name',
          displayExpr: 'name',
          searchEnabled: true,
          placeholder: ''
        }"
        [colSpan]="6"
      ></dxi-item>
      <dxi-item
        [dataField]="[AssociationKeys.address, AddressModelKeys.county].join('.')"
        [label]="{ text: 'County' }"
        [colSpan]="6"
      ></dxi-item>
    </dxi-item>

    <dxi-item itemType="group" caption="Dietary Considerations" [colCount]="2">
      <div *dxTemplate>
        <ag-shr-dietary-considerations-form
          [validationGroup]="validationGroup"
          [dietaryConsiderations]="associationFormData?.[AssociationKeys.dietaryConsideration]"
        ></ag-shr-dietary-considerations-form>
      </div>
    </dxi-item>
  </dx-form>
</ag-shr-modal-window>

<ng-container *ngTemplateOutlet="grid; context: { isEdit: false }"></ng-container>

<ng-template #grid let-isEdit="isEdit">
  <dx-data-grid
    #dataGridRef
    class="associations__data-grid"
    [ngClass]="{ 'associations__data-grid--view': !isEdit }"
    [dataSource]="associations$ | async"
    [keyExpr]="BaseModelKeys.dbId"
    [showRowLines]="isEdit"
    [showBorders]="isEdit"
    [showColumnLines]="isEdit"
    [showColumnHeaders]="isEdit"
    [columnAutoWidth]="false"
    [wordWrapEnabled]="isEdit"
    [rowAlternationEnabled]="false"
    [columnHidingEnabled]="true"
    [noDataText]="emptyMessage"
    (onRowRemoving)="onRowRemoving($event)"
    height="100%"
  >
    <dxo-toolbar>
      <dxi-item location="before" [visible]="!isEdit">
        <div *dxTemplate>
          <div class="toolbar-label">
            <b>Associations</b>
          </div>
        </div>
      </dxi-item>

      <dxi-item
        [visible]="isEdit && canCreate"
        location="after"
        widget="dxButton"
        [options]="{ icon: 'plus', onClick: showAddAssociationPopup }"
      ></dxi-item>

      <dxi-item
        [visible]="!isEdit && canEdit"
        location="after"
        widget="dxButton"
        [options]="{ icon: 'edit', stylingMode: 'text', onClick: showListPopup }"
      >
      </dxi-item>
    </dxo-toolbar>

    <dxo-editing
      mode="popup"
      [useIcons]="true"
      [allowUpdating]="false"
      [allowDeleting]="canDelete"
      [allowAdding]="false"
    >
    </dxo-editing>

    <dxi-column caption="Name" [calculateDisplayValue]="calculateNameDisplayValue" [allowHiding]="false"></dxi-column>

    <dxi-column dataField="email_address" [allowHiding]="false" caption="Email Address"></dxi-column>

    <dxi-column
      dataField="contact_number"
      [allowHiding]="false"
      [width]="150"
      caption="Number"
      [calculateDisplayValue]="calculateContactNumberDisplayValue"
    ></dxi-column>

    <dxi-column dataField="association_type" caption="Relationship">
      <dxo-lookup
        [dataSource]="relationshipTypeLookup$ | async"
        [valueExpr]="BaseModelKeys.dbId"
        [displayExpr]="LookupKeys.description"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="AssociationKeys.dob"
      [caption]="'Date Of Birth'"
      [allowHiding]="true"
      [hidingPriority]="4"
      dataType="date"
      [format]="dateFormat"
    ></dxi-column>

    <dxi-column
      [dataField]="AssociationKeys.gender"
      [caption]="'Gender'"
      [allowHiding]="true"
      [hidingPriority]="5"
      cellTemplate="genderCellTemplate"
    ></dxi-column>
    <div *dxTemplate="let cell of 'genderCellTemplate'" class="associations__address-container">
      {{ cell?.data?.gender | gender | async }}
    </div>

    <dxi-column
      [width]="150"
      cellTemplate="tShirtSizeCellTemplate"
      [caption]="'T-Shirt Size'"
      [allowHiding]="true"
      [hidingPriority]="2"
    ></dxi-column>
    <div *dxTemplate="let cell of 'tShirtSizeCellTemplate'" class="associations__address-container">
      {{ cell?.data?.[AssociationKeys.tShirtSizes]?.[TShirtSizesKeys?.tShirtSize] | tShortSize | async }}
      <span *ngIf="cell?.data?.[AssociationKeys.tShirtSizes]?.[TShirtSizesKeys?.tShirtSizeOther] as tShirtSizeOther"
        >/ {{ tShirtSizeOther }}</span
      >
    </div>

    <dxi-column
      [width]="150"
      cellTemplate="unisexTShirtSizeCellTemplate"
      [caption]="'Unisex T-Shirt Size'"
      [allowHiding]="true"
      [hidingPriority]="3"
    ></dxi-column>
    <div *dxTemplate="let cell of 'unisexTShirtSizeCellTemplate'" class="associations__address-container">
      {{ cell?.data?.[AssociationKeys.tShirtSizes]?.[TShirtSizesKeys?.unisexTShirtSize] | tShortSize | async }}
      <span
        *ngIf="cell?.data?.[AssociationKeys.tShirtSizes]?.[TShirtSizesKeys?.unisexTShirtSizeOther] as tShirtSizeOther"
        >/ {{ tShirtSizeOther }}</span
      >
    </div>

    <dxi-column
      dataField="address"
      cellTemplate="addressCellTemplate"
      [caption]="'Address'"
      [allowHiding]="true"
      [hidingPriority]="7"
    ></dxi-column>
    <div
      *dxTemplate="let data of 'addressCellTemplate'"
      title="{{ data.displayValue | fullAddress }}"
      class="associations__address-container"
    >
      {{ data.displayValue | fullAddress }}
    </div>

    <dxi-column
      cellTemplate="considerationCellTemplate"
      [caption]="'Consideration(s)'"
      [allowHiding]="true"
      [hidingPriority]="6"
    ></dxi-column>
    <div *dxTemplate="let cell of 'considerationCellTemplate'" class="associations__address-container">
      {{ cell?.data?.[AssociationKeys.dietaryConsideration]?.[DietaryConsiderationKeys.dietaryConsiderationType] | dietaryConsiderationType | async }}
      <span
        *ngIf="cell?.data?.[AssociationKeys.dietaryConsideration]?.[DietaryConsiderationKeys.dietaryConsideration] as consideration"
        >/ {{ consideration }}</span
      >
    </div>

    <dxi-column
      dataField="is_emergency_contact"
      sortOrder="desc"
      dataType="boolean"
      caption="EC"
      width="90"
      [allowHiding]="false"
    ></dxi-column>

    <dxi-column type="buttons" [visible]="isEdit">
      <dxi-button icon="edit" [visible]="canEdit" [onClick]="showEditPopup"></dxi-button>
      <dxi-button name="delete" [visible]="canDelete"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</ng-template>
