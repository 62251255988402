import { LookupBase } from '../models/utils/lookup-base.model';
import { EntityApplications } from './entity-applications.enum';

export const EntityApplicationsMap = new Map([
  [EntityApplications.crm, 'Agent Resource Manager'],
  [EntityApplications.portal, 'Portal'],
]);

export const ENTITY_APP_LOOKUP: LookupBase[] = Array.from(EntityApplicationsMap.entries()).map(
  ([value, description]) => {
    return {
      value,
      description,
    };
  },
);
