import { CARRIER_LINE_OF_BUSINESS_TYPE, CARRIER_ORGANIZATION_TYPE } from '../../lists/carrier.enum';
import { BaseModel } from '../base.model';

export enum AgentCarrierModelKeys {
  id = 'id',
  carrier = 'carrier',
  name = 'name',
  emailAddress = 'email_address',
  writingNumber = 'writing_number',
  individualOrCorp = 'individualOrCorp',
  lineOfBusiness = 'lineOfBusiness',
  isActive = 'is_active',
}

export class AgentCarrier extends BaseModel {
  [AgentCarrierModelKeys.id]?: string;
  [AgentCarrierModelKeys.carrier]?: string;
  [AgentCarrierModelKeys.name]?: string;
  [AgentCarrierModelKeys.emailAddress]?: string;
  [AgentCarrierModelKeys.writingNumber]?: string;
  [AgentCarrierModelKeys.individualOrCorp]?: CARRIER_ORGANIZATION_TYPE;
  [AgentCarrierModelKeys.lineOfBusiness]?: CARRIER_LINE_OF_BUSINESS_TYPE;
  [AgentCarrierModelKeys.isActive]?: boolean;
}
