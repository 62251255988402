<dx-form
  *var="!!dietaryConsiderations?.[DietaryConsiderationKeys.hasDietaryOrPersonalConsideration] &&
    dietaryConsiderations?.[DietaryConsiderationKeys.hasDietaryOrPersonalConsideration] === yesNoType.Yes as hasDietaryOrPersonalConsideration"
  #dietaryConsiderationFormRef
  class="mt-2"
  [formData]="dietaryConsiderations"
  [labelLocation]="'top'"
  labelMode="floating"
  [validationGroup]="validationGroup"
>
  <dxi-item
    [dataField]="DietaryConsiderationKeys.hasDietaryOrPersonalConsideration"
    editorType="dxSelectBox"
    [editorOptions]="{
      name: [pathPrefix, DietaryConsiderationKeys.hasDietaryOrPersonalConsideration] | path,
      items: [yesNoType.Yes, yesNoType.No],
      readOnly: isReadOnly
    }"
    [isRequired]="isDietaryOrPersonalConsiderationRequired"
  >
    <dxo-label [text]="label"></dxo-label>
  </dxi-item>

  <dxi-item>
    <dxo-label [text]="'Type of Dietary or Personal Consideration'"></dxo-label>

    <div *dxTemplate>
      <ag-shr-dietary-consideration-type-select-box
        [name]="[pathPrefix, DietaryConsiderationKeys.dietaryConsiderationType] | path"
        [readOnly]="isReadOnly || !hasDietaryOrPersonalConsideration"
        [isRequired]="hasDietaryOrPersonalConsideration"
        [validationGroup]="validationGroup"
        (selectedItemChange)="handleDietaryConsiderationTypeSelect($event)"
        [(value)]="dietaryConsiderations && dietaryConsiderations[DietaryConsiderationKeys.dietaryConsiderationType]"
      ></ag-shr-dietary-consideration-type-select-box>
    </div>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-area
        [name]="[pathPrefix, DietaryConsiderationKeys.dietaryConsideration] | path"
        [readOnly]="isReadOnly || !hasDietaryOrPersonalConsideration"
        [(value)]="dietaryConsiderations && dietaryConsiderations[DietaryConsiderationKeys.dietaryConsideration]"
        [height]="200"
        [label]="'Description of Consideration(s)'"
        [labelMode]="'floating'"
      >
        <dx-validator [validationGroup]="validationGroup">
          <dxi-validation-rule
            *ngIf="hasDietaryOrPersonalConsideration"
            type="required"
            [message]="'Description of Consideration(s) is required'"
          >
          </dxi-validation-rule>
        </dx-validator>
      </dx-text-area>
    </div>
  </dxi-item>
</dx-form>
