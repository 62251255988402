import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ButtonWithIndicatorModule } from '../button-with-indicator/button-with-indicator.module';
import { StateSelectBoxComponent } from './state-select-box.component';
import { DxValidatorModule } from 'devextreme-angular';

@NgModule({
  imports: [SharedModule, ButtonWithIndicatorModule, DxValidatorModule],
  declarations: [StateSelectBoxComponent],
  exports: [StateSelectBoxComponent],
})
export class StateSelectBoxModule {}
