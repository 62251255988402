import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionDetailsComponent } from './transaction-details.component';
import { DxDataGridModule } from 'devextreme-angular';

@NgModule({
  declarations: [TransactionDetailsComponent],
  exports: [TransactionDetailsComponent],
  imports: [CommonModule, DxDataGridModule],
})
export class TransactionDetailsModule {}
