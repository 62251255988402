export enum AutocompleteService {
  google = 'google',
  smartySheets = 'smartySheets',
}

export enum SmartyStreetsSuggestionFields {
  streetLine = 'street_line',
  secondaryLine = 'secondary',
  city = 'city',
  state = 'state',
  zipCode = 'zipcode',
  entries = 'entries',
}

export interface SuggestionModel {
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

export type SmartyStreetsErrorResponse = {
  error: { errors: Array<{ id: string; message: string }> };
};

export function getSmartyStreetsErrorMessage(err: SmartyStreetsErrorResponse): string {
  return Array.isArray(err.error?.errors) && err.error.errors.length > 0
    ? err.error.errors[0].message
    : 'Something Bad Happened with Smarty Streets Service';
}
