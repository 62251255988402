import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutes } from './app.model';

const routes: Routes = [
  { path: AppRoutes.PWA, loadChildren: () => import('./pwa/pwa.module').then(m => m.PWAModule) },
  { path: '', loadChildren: () => import('./web/web.module').then(m => m.WebModule) },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
