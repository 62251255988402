import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'mgaName',
  pure: true,
})
export class MgaNamePipe implements PipeTransform {
  transform(id: string, dictionary: any, textField = 'name', defaultText = ''): any {
    if (!dictionary) {
      return '';
    }
    let item = _.find(dictionary, dictItem => dictItem.agency_id === id);
    return item ? _.get(item, textField) : defaultText;
  }
}
