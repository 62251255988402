import { SupportType, SupportTypeDescriptionMap } from './support-type.enum';

export const SUPPORT_TYPE_LOOKUP = [
  {
    value: SupportType.MainOffice,
    description: SupportTypeDescriptionMap.get(SupportType.MainOffice),
  },
  {
    value: SupportType.Contracting,
    description: SupportTypeDescriptionMap.get(SupportType.Contracting),
  },
  {
    value: SupportType.Support,
    description: SupportTypeDescriptionMap.get(SupportType.Support),
  },
  {
    value: SupportType.NewBusiness,
    description: SupportTypeDescriptionMap.get(SupportType.NewBusiness),
  },
];
