import { Goal } from '../utils/goal.model';
import { AgentKeys } from '../domain/agent.model';

export enum UpdateAgentsGoalsKeys {
  agentDbId = 'agentDbId',
  updateData = 'updateData',
}

export interface UpdateAgentsGoalsModel {
  [AgentKeys.personal_goals]?: Goal[];
  [AgentKeys.conference_goals]?: Goal[];
  [AgentKeys.manager_goals]?: Goal[];
}

export interface UpdateAgentGoalsPayload {
  [UpdateAgentsGoalsKeys.agentDbId]: string;
  [UpdateAgentsGoalsKeys.updateData]: UpdateAgentsGoalsModel;
}
