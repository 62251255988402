export enum MediaUploaderTabs {
  FromFile = 'From File',
  ByURL = 'By URL',
  Gallery = 'Gallery',
}

export interface AgMediaUploadModel {
  description: string;
  contentType: string;
  file: any;
}

export const FILE_NAME_VALIDATION_GROUP = 'fileNameValidationGroup';
