export enum AgentCampaignStatisticName {
  issuesChanges = 'issuesChanges',
}

export interface AgentCampaignStatistic {
  itemsDone: number;
  total: number;
}

export interface AgentCampaignStatisticChanges {
  [AgentCampaignStatisticName.issuesChanges]?: Partial<AgentCampaignStatistic>;
}
