import { Inject, Injectable } from '@angular/core';
import { DataService } from './data.service';
import { FIREBASE_APP } from '../injections/firebase-app';
import { FirebaseApp } from 'firebase/app';
import { dateFromTimestamp } from '../../public-api';
import { AgentCampaigns } from '@ag-common-lib/public-api';

@Injectable({
  providedIn: 'root',
})
export class AgentCampaignsService extends DataService<AgentCampaigns> {
  private readonly agentCampaignsCollectionPath = 'agent-campaigns';

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, AgentCampaignsService.fromFirestore);
    super.setCollection(this.agentCampaignsCollectionPath);
  }

  static readonly fromFirestore = (data): AgentCampaigns => {
    if (!!data?.updatedDate) {
      data.updatedDate = dateFromTimestamp(data?.updatedDate);
    }
    return Object.assign({}, data);
  };

  updateFields(documentId: string, data: Partial<AgentCampaigns>): Promise<AgentCampaigns> {
    return super.updateFields(documentId, data);
  }
}
