import { Pipe, PipeTransform } from '@angular/core';
import { ConferenceGuestsStepsConfigurationSectionName } from '@ag-common-lib/public-api';

@Pipe({ name: 'conferenceGuestSectionVisible' })
export class ConferenceGuestsSectionVisiblePipe implements PipeTransform {
  transform(
    sectionName: ConferenceGuestsStepsConfigurationSectionName,
    excludedSections: ConferenceGuestsStepsConfigurationSectionName[] = [],
  ): boolean {
    if (!sectionName) {
      return null;
    }
    return !excludedSections?.some(section => section === sectionName);
  }
}
