import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxButtonModule,
  DxHtmlEditorModule,
  DxLoadIndicatorModule,
  DxPopupModule,
  DxScrollViewModule,
  DxToolbarModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { HtmlEditorComponent } from './html-editor.component';
import { HtmlEditorFileDirectoryPipe } from './html-editor-file-directory.pipe';

@NgModule({
  imports: [
    CommonModule,
    DxHtmlEditorModule,
    DxToolbarModule,
    DxScrollViewModule,
    DxButtonModule,
    DxLoadIndicatorModule,
    DxValidatorModule,
    DxPopupModule,
  ],
  declarations: [HtmlEditorComponent, HtmlEditorFileDirectoryPipe],
  exports: [HtmlEditorComponent],
  providers: [],
})
export class HtmlEditorModule {}
