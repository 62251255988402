import { ExcursionPreferences } from '../registration/excursion.model';
import { LookupKeys } from '../crm/lookup.model';
import { AttendeeType } from '../registration/attendees.model';
import { LocalDateTimeString } from '../utils/local-date-time-string.model';
import { RefundReasonKeys } from '../functions';

export enum SubtotalType {
  BuyInRate = 'BuyInRate',
  Excursion = 'Excursion',
  ExcursionBudget = 'ExcursionBudget',
}

export enum AgStripeSubtotalKeys {
  description = 'description',
  amount = 'amount',
  meta = 'meta',
  subtotalType = 'subtotalType',
  refundFailureReason = 'refundFailureReason',
  refundReason = 'refundReason',
  refundStatus = 'refundStatus',
  refundProcessedDate = 'refundProcessedDate',
}

export const SubtotalTypeNameMap = new Map<SubtotalType, string>([
  [SubtotalType.BuyInRate, 'Buy-in'],
  [SubtotalType.Excursion, 'Excursions'],
  [SubtotalType.ExcursionBudget, 'Excursions Budget'],
]);

export const SUBTOTAL_TYPE_LOOKUP = [
  {
    [LookupKeys.value]: SubtotalType.BuyInRate,
    [LookupKeys.description]: SubtotalTypeNameMap.get(SubtotalType.BuyInRate),
  },
  {
    [LookupKeys.value]: SubtotalType.Excursion,
    [LookupKeys.description]: SubtotalTypeNameMap.get(SubtotalType.Excursion),
  },
  {
    [LookupKeys.value]: SubtotalType.ExcursionBudget,
    [LookupKeys.description]: SubtotalTypeNameMap.get(SubtotalType.ExcursionBudget),
  },
];

// Second Code Block

type AgStripeSubtotalMeta<T extends SubtotalType> = T extends SubtotalType.BuyInRate
  ? ConferenceRegistrationBuyInRateSubtotalMeta
  : T extends SubtotalType.Excursion
  ? ConferenceRegistrationExcursionChargeMeta
  : T extends SubtotalType.ExcursionBudget
  ? ConferenceRegistrationExcursionBudgetMeta
  : never;

export interface AgStripeSubtotal<T extends SubtotalType> {
  [AgStripeSubtotalKeys.description]: string;
  [AgStripeSubtotalKeys.amount]: number;
  [AgStripeSubtotalKeys.meta]: AgStripeSubtotalMeta<T>;
  [AgStripeSubtotalKeys.subtotalType]?: T;
  [AgStripeSubtotalKeys.refundFailureReason]?: RefundFailureReason;
  [AgStripeSubtotalKeys.refundReason]?: RefundReasonKeys;
  [AgStripeSubtotalKeys.refundStatus]?: string;
  [AgStripeSubtotalKeys.refundProcessedDate]?: Date;
}

export enum ConferenceRegistrationMetaKeys {
  // ConferenceRegistrationBuyInRateSubtotalMeta keys
  guestDbId = 'guestDbId',
  guestDob = 'guestDob',

  // ConferenceRegistrationExcursionChargeMeta keys
  attendeeDbId = 'attendeeDbId',
  attendeeType = 'attendeeType',
  selectedExcursionId = 'selectedExcursionId',
  excursionPreferences = 'excursionPreferences',

  // ConferenceRegistrationExcursionBudgetMeta keys
  registrantDbId = 'registrantDbId',
}

export class ConferenceRegistrationBuyInRateSubtotalMeta {
  [ConferenceRegistrationMetaKeys.guestDbId]: string;
  [ConferenceRegistrationMetaKeys.guestDob]: LocalDateTimeString;
}

export class ConferenceRegistrationExcursionChargeMeta {
  [ConferenceRegistrationMetaKeys.attendeeDbId]: string;
  [ConferenceRegistrationMetaKeys.attendeeType]: AttendeeType;
  [ConferenceRegistrationMetaKeys.selectedExcursionId]: string;
  [ConferenceRegistrationMetaKeys.excursionPreferences]: ExcursionPreferences;
}

export class ConferenceRegistrationExcursionBudgetMeta {
  [ConferenceRegistrationMetaKeys.registrantDbId]: string;
}

// Refund
export enum RefundFailureReason {
  chargeForPendingRefundDisputed = 'charge_for_pending_refund_disputed',
  declined = 'declined',
  expiredOrCanceledCard = 'expired_or_canceled_card',
  insufficientFunds = 'insufficient_funds',
  lostOrStolenCard = 'lost_or_stolen_card',
  merchantRequest = 'merchant_request',
  unknown = 'unknown',
}

export const REFUND_FAILURE_REASON_LOOKUP = [
  {
    [LookupKeys.value]: RefundFailureReason.declined,
    [LookupKeys.description]: 'Declined',
  },
  {
    [LookupKeys.value]: RefundFailureReason.unknown,
    [LookupKeys.description]: 'Unknown',
  },
  {
    [LookupKeys.value]: RefundFailureReason.chargeForPendingRefundDisputed,
    [LookupKeys.description]: 'Charge for Pending Refund Disputed',
  },
  {
    [LookupKeys.value]: RefundFailureReason.merchantRequest,
    [LookupKeys.description]: 'Merchant Request',
  },
  {
    [LookupKeys.value]: RefundFailureReason.expiredOrCanceledCard,
    [LookupKeys.description]: 'Expired or Canceled Card',
  },
  {
    [LookupKeys.value]: RefundFailureReason.insufficientFunds,
    [LookupKeys.description]: 'Insufficient Funds',
  },
  {
    [LookupKeys.value]: RefundFailureReason.lostOrStolenCard,
    [LookupKeys.description]: 'Lost or Stolen Card',
  },
];
