import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AgMediaUploaderService } from './ag-media-uploader.service';
import { Observable } from 'rxjs';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AGMedia } from '../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/ag-media.model';
import { MediaUploaderTabs } from './ag-media-uploader.models';

@UntilDestroy()
@Component({
  selector: 'ag-shr-media-uploader',
  templateUrl: './ag-media-uploader.component.html',
  styleUrls: ['./ag-media-uploader.component.scss'],
  providers: [AgMediaUploaderService],
})
export class AgMediaUploaderComponent {
  @Input() accept = 'image/*';
  @Input() mediaPathPrefix;
  @Input() mediaSources: MediaUploaderTabs[];
  @Input() mediaItems: AGMedia[];

  @Output() mediaChanged = new EventEmitter<AGMedia>();

  @ViewChild('mediaUploaderModalRef', { static: true }) mediaUploaderModalComponent: ModalWindowComponent;

  protected inProgress$: Observable<boolean> = this.mediaUploaderService.inProgress$;

  protected isImageSelected$: Observable<boolean> = this.mediaUploaderService.isImageSelected$;

  constructor(private mediaUploaderService: AgMediaUploaderService) {}

  async saveImage() {
    try {
      const media = await this.mediaUploaderService.handleSave();
      this.mediaChanged.emit(media);
      this.mediaUploaderModalComponent.hideModal();
    } catch (error) {}
  }

  onAfterRevertChanges = (): void => {
    // this.uploadFromDiskTabComponent?.clear();
    // this.uploadFromUrlTabComponent?.clear();
    // this.fileNameValidatorComponent?.instance.reset();
  };

  handleClosePopup = () => this.mediaUploaderService.onCancelEdit.call(this, this.onAfterRevertChanges);

  showModal() {
    this.mediaUploaderService.setImage();
    this.mediaUploaderModalComponent.showModal();
  }
}
