import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { StateSelectBoxModule } from '../state-select-box/state-select-box.module';
import { AddressFormComponent } from './address-form.component';
import { AddressAutocompleteModule } from '../address-autocomplete/address-autocomplete.module';
import { AddressItemValidationPipe } from './address-item-validation.pipe';
import { DxValidationSummaryModule, DxValidatorModule } from 'devextreme-angular';

@NgModule({
  declarations: [AddressFormComponent, AddressItemValidationPipe],
  imports: [
    CommonModule,
    SharedModule,
    StateSelectBoxModule,
    AddressAutocompleteModule,
    DxValidatorModule,
    DxValidationSummaryModule,
  ],
  exports: [AddressFormComponent],
})
export class AddressFormModule {}
