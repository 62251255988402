import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { AgencyCarriersComponent } from './agency-carriers.component';
import { AgencyCarriersGridComponent } from './carriers-grid/agency-carriers-grid.component';
import { ButtonWithIndicatorModule } from '../../../button-with-indicator/button-with-indicator.module';
import { AgEditorToolbarModule } from '../../../ag-editor-toolbar/ag-editor-toolbar.module';
import { AgencyCarriersModalComponent } from './carriers-modal/agency-carriers-modal.component';
import { AgencyCarriersModalService } from './agency-carriers-modal.service';

@NgModule({
  imports: [SharedModule, ModalWindowModule, ButtonWithIndicatorModule, AgEditorToolbarModule],
  declarations: [AgencyCarriersComponent, AgencyCarriersGridComponent, AgencyCarriersModalComponent],
  exports: [AgencyCarriersComponent, AgencyCarriersGridComponent, AgencyCarriersModalComponent],
  providers: [AgencyCarriersModalService],
})
export class AgencyCarriersModule {}
