import { NgModule } from '@angular/core';
import { DxValidatorModule } from 'devextreme-angular';
import { DietaryConsiderationsFormComponent } from './dietary-considerations-form.component';
import { DietaryConsiderationTypeSelectBoxModule } from '../../../dietary-consideration-type-select-box/dietary-consideration-type-select-box.module';
import { AgEditorToolbarModule } from '../../../ag-editor-toolbar/ag-editor-toolbar.module';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { DietaryConsiderationsFormService } from './dietary-considerations-form.service';
import { NgIf } from '@angular/common';

@NgModule({
    imports: [SharedModule, DietaryConsiderationTypeSelectBoxModule, DxValidatorModule, AgEditorToolbarModule, NgIf],
  declarations: [DietaryConsiderationsFormComponent],
  exports: [DietaryConsiderationsFormComponent],
  providers: [DietaryConsiderationsFormService],
})
export class DietaryConsiderationsFormModule {}
