import { Component, HostBinding, Input } from '@angular/core';
import { AgentCampaignChange, CampaignExtras } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-agent-campaign-summary-extra',
  templateUrl: './agent-campaign-summary-extra.component.html',
  styleUrls: ['./agent-campaign-summary-extra.component.scss'],
})
export class AgentCampaignSummaryExtraComponent {
  @HostBinding('class') className = 'agent-campaign-summary-extra';

  @Input() set stepData(data: AgentCampaignChange<CampaignExtras>) {
    const list = new Array(4)
      .fill(null)
      .map((_, index) => {
        const currentStepData = data?.currentStepData;
        const incomingStepData = data?.incomingStepData;
        const itemCurrentStepData = this.getExternalLinkContent(currentStepData, index);
        const itemIncomingStepData = this.getExternalLinkContent(incomingStepData, index);

        if (!itemCurrentStepData && !itemIncomingStepData) {
          return null;
        }
        return {
          currentStepData: itemCurrentStepData,
          incomingStepData: itemIncomingStepData,
        };
      })
      .filter(Boolean);
    if (!list?.length) {
      list.push(null);
    }

    this.list = list;
  }

  list: any[];

  constructor() {}

  private getExternalLinkContent = (data, index: number) => {
    const currentUrl = data?.[`url${index}`];
    const currentTitle = data?.[`title${index}`];

    if (!currentUrl || !currentTitle) {
      return null;
    }

    return { url: currentUrl, title: currentTitle };
  };
}
