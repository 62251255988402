<div class="agent-campaign-wizard__content">
  <dx-scroll-view height="100%" [useNative]="false" [showScrollbar]="'always'">
    <ag-shr-agent-campaign-summary-list
      [agentCampaign]="agentCampaign$ | async"
      [canEdit]="canEdit"
    ></ag-shr-agent-campaign-summary-list>
  </dx-scroll-view>
</div>

<div class="agent-campaign-wizard__controls-container" *ngIf="canEdit">
  <shr-button-with-indicator
    [title]="'SUBMIT CHANGES'"
    [cssClass]="'modal-window__toolbar-button'"
    [isInProgress]="inProgress$ | async"
    (onClick)="submitChanges()"
    [disabled]="!(hasDraftData$ | async)"
  ></shr-button-with-indicator>
</div>
