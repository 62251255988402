import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'isNewPhoneNumberSelected' })
export class IsNewPhoneNumberSelectedPipe implements PipeTransform {
  constructor() {}

  transform(keys: string[]): boolean {
    return !keys?.some(Boolean);
  }
}
