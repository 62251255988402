import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToString',
})
export class ArrayToStringPipe implements PipeTransform {
  transform(input: string[], separator = ', '): string {
    return input.filter(Boolean).join(separator);
  }
}
