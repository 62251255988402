import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'templateWithArguments' })
export class TemplateWithArgumentsPipe implements PipeTransform {
  transform(template: string, ...args: any): string {
    if (!template) {
      return '';
    }
    /* substitutes the template arguments like '{0} requests' => '10 requests', when the argument[0] === 10 */
    return template.replace(/{(\d+)}/g, (match, number) => {
      return typeof args[number] !== undefined ? args[number] : match;
    });
  }
}
