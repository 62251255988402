import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseModelKeys, UserRole } from 'ag-common-lib/public-api';
import { map, shareReplay } from 'rxjs/operators';
import { UserRolesService } from 'ag-common-svc/lib/services/user-roles.service';

@Pipe({
  name: 'userRolesNameByIds',
})
export class UserRolesNameByIdsPipe implements PipeTransform {
  constructor(private rolesService: UserRolesService) {}

  transform(roles: string[]): Observable<UserRole[]> {
    const rolesSet = new Set<string>(roles ?? []);
    return this.rolesService.getList().pipe(
      map(
        roles =>
          roles?.filter(role => rolesSet.has(role?.[BaseModelKeys.dbId])),
      ),
      shareReplay(1),
    );
  }
}
