import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { DxScrollViewModule } from 'devextreme-angular';
import { ModalWindowModule } from '../modal-window/modal-window.module';
import { AttendeeTransactionDetailsModalComponent } from './attendee-transaction-details-modal.component';

@NgModule({
  declarations: [AttendeeTransactionDetailsModalComponent],
  exports: [AttendeeTransactionDetailsModalComponent],
  imports: [CommonModule, SharedModule, DxScrollViewModule, ModalWindowModule],
})
export class AttendeeTransactionDetailsModalModule {}
