import { Entity, EntityPermissionActivityKeys, RoleEntityPermissionMap } from '@ag-common-lib/public-api';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  permissions$: Observable<RoleEntityPermissionMap>;

  constructor() {}

  async hasPermission(entity: Entity, entityPermissionKey?: EntityPermissionActivityKeys): Promise<boolean> {
    const permissionsMap = await firstValueFrom(this.permissions$);

    if (!permissionsMap[entity]) {
      return false;
    }

    if (entityPermissionKey) {
      return permissionsMap[entity][entityPermissionKey];
    }
    return (
      permissionsMap[entity][EntityPermissionActivityKeys.create] ||
      permissionsMap[entity][EntityPermissionActivityKeys.read] ||
      permissionsMap[entity][EntityPermissionActivityKeys.write] ||
      permissionsMap[entity][EntityPermissionActivityKeys.delete]
    );
  }
}
