<video
  #vimeoVideoRef
  class="video-video__video video-js"
  [poster]="poster"
  [preload]="preloadMode"
  [attr.autoplay]="autoplay ? '' : null"
  [attr.controls]="showControls ? '' : null"
>
  <source
    *ngFor="let source of sources"
    [src]="source.link"
    [type]="source.type"
    [attr.label]="source.public_name"
    [attr.selected]="source.quality === 'hd' ? true : null"
  />
</video>
