import { AgencyCampaignsStatus } from '../../lists/agent-campaigns-status.enum';
import { BaseModel } from '../base.model';

export enum AgentCampaignsKeys {
  originalOutstandUser = 'originalOutstandUser',
  outstandSite = 'outstandSite',
  outstandEmailsSent = 'outstandEmailsSent',
  outstandContacts = 'outstandContacts',
  outstandSubscriptionLevel = 'outstandSubscriptionLevel',
  campaignsStatus = 'campaignsStatus',
  userSince = 'userSince',
  stoppedCampaigns = 'stoppedCampaigns',
}

export class AgentCampaigns extends BaseModel {
  [AgentCampaignsKeys.originalOutstandUser]?: boolean;
  [AgentCampaignsKeys.outstandSite]?: string;
  [AgentCampaignsKeys.outstandEmailsSent]?: number;
  [AgentCampaignsKeys.outstandContacts]?: number;
  [AgentCampaignsKeys.outstandSubscriptionLevel]?: string;
  [AgentCampaignsKeys.campaignsStatus]?: AgencyCampaignsStatus = AgencyCampaignsStatus.Registered;
  [AgentCampaignsKeys.userSince]?: Date;
  [AgentCampaignsKeys.stoppedCampaigns]?: Date;
}
