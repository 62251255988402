<div class="mt-2">
  <ag-shr-editor-toolbar
    [title]="'Office Phone Numbers'"
    (clickEdit)="officePhoneNumbersModalComponent.showModal()"
    [isEditVisible]="
      Entity.agencyContactInfoOfficePhoneNumbers | hasPermission : EntityPermissionActivityKeys.write | async
    "
  ></ag-shr-editor-toolbar>

  <ag-shr-agency-office-phone-numbers-grid
    [phoneNumbers]="phoneNumbers"
    [isEditable]="false"
  ></ag-shr-agency-office-phone-numbers-grid>
</div>

<ag-shr-modal-window
  #officePhoneNumbersModalRef
  [title]="'Add / Edit Office Phone Numbers'"
  [showSaveButton]="false"
  [width]="'75%'"
  [height]="'auto'"
  [inProgress]="inProgress$ | async"
>
  <ag-shr-agency-office-phone-numbers-grid
    [phoneNumbers]="phoneNumbers ?? []"
    [agencyId]="agencyId"
    (phoneNumbersChange)="phoneNumbersChange.emit($event)"
  ></ag-shr-agency-office-phone-numbers-grid>
</ag-shr-modal-window>
