import { NgModule } from '@angular/core';
import { DxTabsModule } from 'devextreme-angular';
import { AgEditorToolbarComponent } from '../ag-editor-toolbar/ag-editor-toolbar.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [AgEditorToolbarComponent],
  imports: [SharedModule, DxTabsModule],
  exports: [AgEditorToolbarComponent],
})
export class AgEditorToolbarModule {}
