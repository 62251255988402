<dx-drop-down-box
  #dropDownBox
  [value]="value"
  [showDropDownButton]="false"
  [openOnFieldClick]="false"
  fieldTemplate="field"
  [deferRendering]="false"
  (onOpened)="handleDropDownOpened()"
  (onClosed)="handleDropDownClosed()"
  [label]="label"
  [labelMode]="'floating'"
  [elementAttr]="{ class: isReadonly ? 'dx-state-readonly' : '' }"
  [dropDownOptions]="{ wrapperAttr: { class: 'address-autocomplete__wrapper' } }"
>
  <dx-validator [validationGroup]="validationGroup">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dx-validator>

  <div *dxTemplate="let data of 'field'">
    <div class="custom-item">
      <dx-text-box
        #textBoxRef
        [value]="data"
        (onInput)="handleUserInput($event)"
        (onKeyDown)="handleKeyDown($event)"
        [readOnly]="isReadonly"
        (onEnterKey)="handleCustomValue($event)"
      >
        <dxi-button
          name="spinner"
          location="after"
          [options]="{
            icon: 'plus',
            stylingMode: 'text',
            disabled: true,
            template: 'buttonTemplate',
            visible: isSuggestionsFetch$ | async,
          }"
        >
          <div *dxTemplate="let data of 'buttonTemplate'">
            <dx-load-indicator [visible]="true" height="20" width="20"></dx-load-indicator>
          </div>
        </dxi-button>
      </dx-text-box>
    </div>
  </div>

  <dxo-drop-down-options [focusStateEnabled]="false"></dxo-drop-down-options>

  <div *dxTemplate="let data of 'content'">
    <ng-container *ngIf="listItem$ | async as listItem">
      <dx-list
        #treeListRef
        [focusStateEnabled]="focusStateEnabled"
        class="smarty-streets__list"
        [dataSource]="listItem"
        [keyExpr]="'place_id'"
        [displayExpr]="'description'"
        (onItemClick)="handleItemClick($event)"
        noDataText="There're no suggestions"
      >
      </dx-list>
    </ng-container>
  </div>
</dx-drop-down-box>
