import { Address, AddressModelMap } from './address.model';
import { BaseModel } from '../base.model';
import { LookupId } from '../../lists/lookups.enum';
import { DietaryConsideration, DietaryConsiderationMap } from './dietary-consideration.model';
import { TShirtSizes, TShirtSizesMap } from './t-shirt-seizes.model';
import { YES_NO_TYPE } from '../../lists/yes-no.enum';
import { LocalDateTimeString } from './local-date-time-string.model';
import { LookupKeys } from '../crm/lookup.model';

export enum AssociationKeys {
  firstName = 'first_name',
  middleName = 'middle_name',
  lastName = 'last_name',
  prefix = 'prefix',
  suffix = 'suffix',
  passportFirstName = 'passport_first_name',
  passportLastName = 'passport_last_name',
  associationType = 'association_type',
  dob = 'dob',
  address = 'address',
  gender = 'gender',
  emailAddress = 'email_address',
  contactNumber = 'contact_number',
  isEmergencyContact = 'is_emergency_contact',
  tShirtSizes = 'tShirtSizes',
  dietaryConsideration = 'dietaryConsideration',
}

export const AssociationMap = {
  [AssociationKeys.firstName]: 'First Name',
  [AssociationKeys.middleName]: 'Middle Name',
  [AssociationKeys.lastName]: 'Last Name',
  [AssociationKeys.prefix]: 'Prefix',
  [AssociationKeys.suffix]: 'Suffix',
  [AssociationKeys.passportFirstName]: 'Passport First Name',
  [AssociationKeys.passportLastName]: 'Passport Last Name',
  [AssociationKeys.associationType]: 'Relationship',
  [AssociationKeys.dob]: 'Date of Birth',
  [AssociationKeys.gender]: 'Gender',
  [AssociationKeys.emailAddress]: 'Email Address',
  [AssociationKeys.contactNumber]: 'Contact Number',
  [AssociationKeys.tShirtSizes]: TShirtSizesMap,
  [AssociationKeys.isEmergencyContact]: 'EC',
  [AssociationKeys.dietaryConsideration]: DietaryConsiderationMap,
  [AssociationKeys.address]: AddressModelMap,
};

export class Association extends BaseModel {
  [AssociationKeys.prefix]?: LookupId<'Prefixes'> = null;
  [AssociationKeys.firstName]?: string;
  [AssociationKeys.lastName]?: string;
  [AssociationKeys.suffix]?: LookupId<'Suffixes'> = null;
  [AssociationKeys.passportFirstName]?: string;
  [AssociationKeys.middleName]?: string;
  [AssociationKeys.passportLastName]?: string;
  [AssociationKeys.dietaryConsideration]?: DietaryConsideration = null;
  [AssociationKeys.tShirtSizes]?: TShirtSizes = null;
  [AssociationKeys.address]?: Address = { ...new Address() };
  [AssociationKeys.emailAddress]?: string;
  [AssociationKeys.contactNumber]: string;
  [AssociationKeys.isEmergencyContact]: boolean = false;
  [AssociationKeys.gender]?: LookupId<'Genders'>;
  [AssociationKeys.dob]?: LocalDateTimeString = null;
  [AssociationKeys.associationType]?: LookupId<'AssociationType'> = null;
}

export interface LegacyASsociation extends Omit<Association, AssociationKeys.tShirtSizes | 'dietaryConsideration'> {
  tshirt_size?: LookupId<'TShirtSize'>;
  tshirt_size_other?: string;
  unisex_tshirt_size?: LookupId<'TShirtSize'>;
  unisex_tshirt_size_other?: string;
  dietary_consideration: string;
  dietary_consideration_type: LookupId<'DietaryConsiderationType'>;
  dietary_or_personal_considerations: YES_NO_TYPE;
}

export const ASSOCIATION_KEYS_LOOKUP = [
  {
    [LookupKeys.value]: AssociationKeys.prefix,
    [LookupKeys.description]: 'Prefix',
  },
  {
    [LookupKeys.value]: AssociationKeys.firstName,
    [LookupKeys.description]: 'First Name',
  },
  {
    [LookupKeys.value]: AssociationKeys.middleName,
    [LookupKeys.description]: 'Middle Name',
  },
  {
    [LookupKeys.value]: AssociationKeys.lastName,
    [LookupKeys.description]: 'Last Name',
  },
  {
    [LookupKeys.value]: AssociationKeys.suffix,
    [LookupKeys.description]: 'Suffix',
  },
  {
    [LookupKeys.value]: AssociationKeys.passportFirstName,
    [LookupKeys.description]: 'Passport First Name',
  },
  {
    [LookupKeys.value]: AssociationKeys.passportLastName,
    [LookupKeys.description]: 'Passport Last Name',
  },
  {
    [LookupKeys.value]: AssociationKeys.dietaryConsideration,
    [LookupKeys.description]: 'Dietary Consideration',
  },
  {
    [LookupKeys.value]: AssociationKeys.tShirtSizes,
    [LookupKeys.description]: 'T-Shirt Sizes',
  },
  {
    [LookupKeys.value]: AssociationKeys.address,
    [LookupKeys.description]: 'Address',
  },
  {
    [LookupKeys.value]: AssociationKeys.emailAddress,
    [LookupKeys.description]: 'Email Address',
  },
  {
    [LookupKeys.value]: AssociationKeys.contactNumber,
    [LookupKeys.description]: 'Contact Number',
  },
  {
    [LookupKeys.value]: AssociationKeys.isEmergencyContact,
    [LookupKeys.description]: 'Is Emergency Contact',
  },
  {
    [LookupKeys.value]: AssociationKeys.gender,
    [LookupKeys.description]: 'Gender',
  },
  {
    [LookupKeys.value]: AssociationKeys.dob,
    [LookupKeys.description]: 'Date of Birth',
  },
  {
    [LookupKeys.value]: AssociationKeys.associationType,
    [LookupKeys.description]: 'Association Type',
  },
];
