<div class="agent-campaign-wizard__content">
  <ng-content></ng-content>
  <dx-scroll-view height="100%">

    <dx-form
      #socialsMediaFormRef
      [formData]="socialMediaFormData"
      labelMode="floating"
      [colCount]="2"
      [readOnly]="!canEdit"
    >
      <dxi-item
        itemType="group"
        caption="Facebook"
      >
        <dxi-item
          [dataField]="socialMediaLookupValue.FB"
          editorType="dxSelectBox"
          [label]="{ text: 'Link' }"
          [editorOptions]="{
            items: agentSocialsDataSource$ | async | agentCampaignSocialMediaDataSource: socialMediaLookupValue.FB,
            valueExpr: SocialKeys.url,
            displayExpr: SocialKeys.url,
            searchEnabled: true,
            showClearButton: true,
            acceptCustomValue: true,
            onCustomItemCreating: onCustomItemCreating,
            noDataText: 'Press \'Enter\' to add Media link',
            placeholder: 'www.example.com/my-account'
          }"
        >

          <dxi-validation-rule
            type="custom"
            [validationCallback]="validateUrlWithOrWithoutProtocol"
            [message]="Messages.INVALID_URL_FORMAT_TEXT"
          >
          </dxi-validation-rule>
        </dxi-item>
      </dxi-item>
      <dxi-item
        itemType="group"
        caption="Instagram"
      >
        <dxi-item
          [dataField]="socialMediaLookupValue.IG"
          editorType="dxSelectBox"
          [label]="{ text: 'Link' }"
          [editorOptions]="{
            items: agentSocialsDataSource$ | async | agentCampaignSocialMediaDataSource: socialMediaLookupValue.IG,
            valueExpr: SocialKeys.url,
            displayExpr: SocialKeys.url,
            searchEnabled: true,
            showClearButton: true,
            acceptCustomValue: true,
            onCustomItemCreating: onCustomItemCreating,
            noDataText: 'Press \'Enter\' to add Media link',
            placeholder: 'www.example.com/my-account'
          }"
        >

          <dxi-validation-rule
            type="custom"
            [validationCallback]="validateUrlWithOrWithoutProtocol"
            [message]="Messages.INVALID_URL_FORMAT_TEXT"
          >
          </dxi-validation-rule>
        </dxi-item>
      </dxi-item>
      <dxi-item
        itemType="group"
        caption="LinkedIn"
      >
        <dxi-item
          [dataField]="socialMediaLookupValue.LI"
          editorType="dxSelectBox"
          [label]="{ text: 'Link' }"
          [editorOptions]="{
            items: agentSocialsDataSource$ | async | agentCampaignSocialMediaDataSource: socialMediaLookupValue.LI,
            valueExpr: SocialKeys.url,
            displayExpr: SocialKeys.url,
            searchEnabled: true,
            showClearButton: true,
            acceptCustomValue: true,
            onCustomItemCreating: onCustomItemCreating,
            noDataText: 'Press \'Enter\' to add Media link',
            placeholder: 'www.example.com/my-account'
          }"
        >
          <dxi-validation-rule
            type="custom"
            [validationCallback]="validateUrlWithOrWithoutProtocol"
            [message]="Messages.INVALID_URL_FORMAT_TEXT"
          >
          </dxi-validation-rule>
        </dxi-item>
      </dxi-item>
      <dxi-item
        itemType="group"
        caption="Twitter"
      >
        <dxi-item
          [dataField]="socialMediaLookupValue.TW"
          editorType="dxSelectBox"
          [label]="{ text: 'Link' }"
          [editorOptions]="{
            items: agentSocialsDataSource$ | async | agentCampaignSocialMediaDataSource: socialMediaLookupValue.TW,
            valueExpr: SocialKeys.url,
            displayExpr: SocialKeys.url,
            searchEnabled: true,
            showClearButton: true,
            acceptCustomValue: true,
            onCustomItemCreating: onCustomItemCreating,
            noDataText: 'Press \'Enter\' to add Media link',
            placeholder: 'www.example.com/my-account'
          }"
        >

          <dxi-validation-rule
            type="custom"
            [validationCallback]="validateUrlWithOrWithoutProtocol"
            [message]="Messages.INVALID_URL_FORMAT_TEXT"
          >
          </dxi-validation-rule>
        </dxi-item>
      </dxi-item>
      <dxi-item
        itemType="group"
        caption="YouTube"
      >
        <dxi-item
          [dataField]="socialMediaLookupValue.YT"
          editorType="dxSelectBox"
          [label]="{ text: 'Link' }"
          [editorOptions]="{
            items: agentSocialsDataSource$ | async | agentCampaignSocialMediaDataSource: socialMediaLookupValue.YT,
            valueExpr: SocialKeys.url,
            displayExpr: SocialKeys.url,
            searchEnabled: true,
            showClearButton: true,
            acceptCustomValue: true,
            onCustomItemCreating: onCustomItemCreating,
            noDataText: 'Press \'Enter\' to add Media link',
            placeholder: 'www.example.com/my-account'
          }"
        >

          <dxi-validation-rule
            type="custom"
            [validationCallback]="validateUrlWithOrWithoutProtocol"
            [message]="Messages.INVALID_URL_FORMAT_TEXT"
          >
          </dxi-validation-rule>
        </dxi-item>
      </dxi-item>
    </dx-form>
  </dx-scroll-view>

</div>

<div class="agent-campaign-wizard__controls-container" *ngIf="canEdit">
  <dx-button
    class="modal-window__toolbar-button"
    text="CANCEL CHANGES"
    [disabled]="!(hasFormChanges$ | async)"
    (onClick)="resetChanges()"
  ></dx-button>

  <shr-button-with-indicator
    [title]="hasStepIssues ? 'RESOLVE ISSUE' :'NEXT'"
    [disabled]="hasStepIssues && !(hasFormChanges$ | async)"
    [cssClass]="'modal-window__toolbar-button'"
    [isInProgress]="inProgress$ | async"
    (onClick)="nextStep()"
  ></shr-button-with-indicator>
</div>
