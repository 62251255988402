import { LookupBase } from '../models/utils/lookup-base.model';

export enum RegistrantIssuesType {
  technical = 'technical',
  content = 'content',
}

export const REGISTRANT_ISSUES_TYPE_LOOKUP: LookupBase[] = [
  { value: RegistrantIssuesType.technical, description: 'Technical Issue' },
  { value: RegistrantIssuesType.content, description: 'Content Issue' },
];
