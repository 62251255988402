import { Component, Input, ViewChild } from '@angular/core';
import { Agency, BaseModelKeys } from '@ag-common-lib/public-api';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
} from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/entity-permission.model';
import { Entity } from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/lists/entity-permission.enum';

@Component({
  selector: 'ag-shr-agency-status-activity',
  templateUrl: './status-activity.component.html',
  styleUrls: ['./status-activity.component.scss'],
})
export class StatusActivityComponent {
  @ViewChild('agencyStatusActivityModalRef', { static: true }) agencyStatusActivityModalComponent: ModalWindowComponent;

  @Input()
  set agency(agency: Agency) {
    this.agencyId$.next(agency[BaseModelKeys.dbId]);
  }
  @Input() readOnly = false;

  statusActivityFormData;
  inProgress$: Observable<boolean>;
  readonly BaseModelKeys = BaseModelKeys;
  readonly agencyId$ = new BehaviorSubject<string>(undefined);
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;
}
