import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { StatusActivityComponent } from './status-activity.component';
import { StatusActivityModalComponent } from './status-activity-modal/status-activity-modal.component';
import { StatusActivityGridComponent } from './status-activity-grid/status-activity-grid.component';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { AgEditorToolbarModule } from '../../../ag-editor-toolbar/ag-editor-toolbar.module';

@NgModule({
  imports: [SharedModule, ModalWindowModule, AgEditorToolbarModule],
  declarations: [StatusActivityComponent, StatusActivityModalComponent, StatusActivityGridComponent],
  exports: [StatusActivityComponent, StatusActivityModalComponent, StatusActivityGridComponent],
})
export class StatusActivityModule {}
