import { Component, HostBinding, Input } from '@angular/core';
import { AgentCampaignChange, CampaignSocialMedia, SocialMediaMapping } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-agent-campaign-summary-social-media',
  templateUrl: './agent-campaign-summary-social-media.component.html',
  styleUrls: ['./agent-campaign-summary-social-media.component.scss'],
})
export class AgentCampaignSummarySocialMediaComponent {
  @HostBinding('class') className = 'agent-campaign-summary-social-media';

  @Input() set stepData(data: AgentCampaignChange<CampaignSocialMedia>) {
    const socials = [];
    const currentStepData = data?.currentStepData;
    const incomingStepData = data?.incomingStepData;

    SocialMediaMapping.forEach((value, key) => {
      socials.push({
        title: value,
        currentStepData: currentStepData?.[key] ?? null,
        incomingStepData: incomingStepData?.[key] ?? null,
      });
    });

    this.list = socials;
  }

  list: any[];
  constructor() {}
}
