import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ButtonWithIndicatorModule } from '../button-with-indicator/button-with-indicator.module';
import { RelationshipTypeSelectBoxComponent } from './relationship-type-select-box.component';
import { DxValidatorModule } from 'devextreme-angular';

@NgModule({
  imports: [SharedModule, ButtonWithIndicatorModule, DxValidatorModule],
  declarations: [RelationshipTypeSelectBoxComponent],
  exports: [RelationshipTypeSelectBoxComponent],
})
export class RelationshipTypeSelectBoxModule {}
