import { Component, HostBinding, Input } from '@angular/core';
import { AgentCampaign, AgentCampaignChanges, AgentCampaignStepName } from '@ag-common-lib/public-api';
import { AgentCampaignService } from 'ag-common-svc/lib/services/agent-campaign.service';

@Component({
  selector: 'ag-shr-agent-campaign-summary-list',
  templateUrl: './agent-campaign-summary-list.component.html',
  styleUrls: ['./agent-campaign-summary-list.component.scss'],
})
export class AgentCampaignSummaryListComponent {
  @HostBinding('class') className = 'agent-campaign-summary-list';

  @Input() set agentCampaign(data: AgentCampaign) {
    this.agentCampaignSummary = this.agentCampaignService.prepareSummary(data);
  }

  @Input() canEdit = true;

  agentCampaignSummary: AgentCampaignChanges;

  readonly AgentCampaignStepName = AgentCampaignStepName;

  constructor(private agentCampaignService: AgentCampaignService) {}
}
