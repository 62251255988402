import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { TShirtSeizesFormComponent } from './t-shirt-seizes-form.component';
import { TShortSizeSelectBoxModule } from '../t-short-size-select-box/t-short-size-select-box.module';
import { TShirtSeizesFormService } from './t-shirt-seizes-form.service';

@NgModule({
  declarations: [TShirtSeizesFormComponent],
  imports: [CommonModule, SharedModule, TShortSizeSelectBoxModule],
  exports: [TShirtSeizesFormComponent],
  providers: [TShirtSeizesFormService],
})
export class TShirtSeizesFormModule {}
