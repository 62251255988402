import { LookupBase } from '../models/utils/lookup-base.model';
import { ConferenceRegistrationCategoryName } from '../models/registration/registration-task.model';

export const REGISTRANT_ISSUES_CATEGORY_LOOKUP: LookupBase[] = [
  {
    value: ConferenceRegistrationCategoryName.registrantFlightInformationCategory,
    description: 'Flight Information',
  },
  {
    value: ConferenceRegistrationCategoryName.registrantExcursionCategory,
    description: 'Excursions',
  },
  {
    value: ConferenceRegistrationCategoryName.registrantHotelReservationCategory,
    description: 'Hotel Reservation',
  },
  {
    value: ConferenceRegistrationCategoryName.reservationCategory,
    description: 'Reservation Information',
  },
];

export const REGISTRATS_TASKS_TITLE_LIST = {
  [ConferenceRegistrationCategoryName.registrantFlightInformationCategory]: 'Flight Information',
  [ConferenceRegistrationCategoryName.registrantHotelReservationCategory]: 'Hotel Information',
  [ConferenceRegistrationCategoryName.registrantExcursionCategory]: 'Excursions',
  [ConferenceRegistrationCategoryName.reservationCategory]: 'Reservation Information',
};

export const RegistrationCategoryStepName = Object.keys(REGISTRATS_TASKS_TITLE_LIST);
