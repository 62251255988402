<div class="mt-2">
  <ag-shr-editor-toolbar
    [title]="'Agency Carriers'"
    (clickEdit)="carriersEditorModalComponent.showModal()"
    [isEditVisible]="Entity.agencyAgencyInfoCarriers | hasPermission : EntityPermissionActivityKeys.write | async"
  ></ag-shr-editor-toolbar>

  <ag-shr-agency-carriers-grid
    [carriers]="agency[AgencyKeys.carriers]"
    [agencyId]="agency[BaseModelKeys.dbId]"
    [isEditable]="false"
  ></ag-shr-agency-carriers-grid>
</div>

<ag-shr-modal-window
  #carriersEditorModalRef
  [title]="'Add / Edit Carriers'"
  [showSaveButton]="false"
  [width]="'75%'"
  [height]="'auto'"
  [inProgress]="inProgress$ | async"
>
  <ag-shr-agency-carriers-grid
    [carriers]="agencyCarriers"
    [agencyId]="agency[BaseModelKeys.dbId]"
    (carriersChange)="onCarriersChange($event)"
  ></ag-shr-agency-carriers-grid>
</ag-shr-modal-window>
