<ng-container *var="agentDbId | agentNameByDbId | async as agentFullName">
  <ng-container *var="agentDbId | agentWasabiPath | async as headshot">
    <dx-button
      class="ag-agent-button__button"
      [disabled]="isDisabled"
      [type]="agentFullName ? 'success' : 'danger'"
      [hint]="agentFullName ?? 'Not assigned'"
      (onClick)="handleClick.emit()"
    >
      <span>{{ agentFullName | shortName }}</span>

      <ag-shr-image-with-loader
        class="ag-agent-button__avatar"
        [wasabiPath]="headshot?.wasabiPath"
        [cropperPayload]="headshot?.imageCropperPayload ?? null"
        [mediaSize]="MediaSize.small"
        [title]="agentFullName"
        [usePlaceholder]="false"
        (isImageLoadingIssuesChange)="isImageLoadingIssuesChange($event)"
      >
      </ag-shr-image-with-loader>
    </dx-button>
  </ng-container>
</ng-container>
