import { Pipe, PipeTransform } from '@angular/core';
import { PermissionsService } from '../../lib/services/permissions.service';
import { map, Observable } from 'rxjs';
import {
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
} from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/entity-permission.model';
import { Entity } from 'ag-common-lib/lib/lists/entity-permission.enum';

@Pipe({
  name: 'hasAnyPermission',
})
export class HasAnyPermissionPipe implements PipeTransform {
  constructor(private permissionsService: PermissionsService) {}

  transform(entities: (keyof typeof Entity)[]): Observable<boolean> {
    return this.permissionsService.permissions$.pipe(
      map(permission => {
        let anyPermission = false;

        for (let entity of entities) {
          if (
            permission[entity] &&
            (permission[entity][EntityPermissionActivityKeys.create] ||
              permission[entity][EntityPermissionActivityKeys.read] ||
              permission[entity][EntityPermissionActivityKeys.write] ||
              permission[entity][EntityPermissionActivityKeys.delete])
          ) {
            anyPermission = true;
            break;
          }
        }

        return anyPermission;
      }),
    );
  }
}
