import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { Messages, SocialKeys, Patterns, CampaignExtras } from '@ag-common-lib/public-api';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgentCampaignExtraService } from './agent-campaign-extra.service';
import { DxFormComponent } from 'devextreme-angular';
import { validateUrlWithOrWithoutProtocol } from 'ag-common-svc/lib/utils/validation';

@UntilDestroy()
@Component({
  selector: 'ag-shr-agent-campaign-extra',
  templateUrl: './agent-campaign-extra.component.html',
  styleUrls: ['./agent-campaign-extra.component.scss'],
})
export class AgentCampaignExtraComponent {
  @HostBinding('class') className = 'agent-campaign-social-media agent-campaign-wizard__content-container';
  @ViewChild('extrasFormRef') extrasFormComponent: DxFormComponent;

  @Input() hasStepIssues: boolean;
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;

  inProgress$: Observable<boolean>;
  extrasFormData: Partial<CampaignExtras> = {};
  hasFormChanges$: Observable<boolean>;

  protected readonly Messages = Messages;
  protected readonly SocialKeys = SocialKeys;

  constructor(private agentCampaignExtraService: AgentCampaignExtraService) {
    this.inProgress$ = agentCampaignExtraService.inProgress$;
    this.hasFormChanges$ = agentCampaignExtraService.hasFormChanges$;

    agentCampaignExtraService.formData$.pipe(untilDestroyed(this)).subscribe(formData => {
      this.extrasFormData = formData;
    });
  }

  validateUrlWithOrWithoutProtocol = e => validateUrlWithOrWithoutProtocol(e?.value);

  validateIsTitleRequired = (urlValue, e) => {
    return !urlValue || !!e.value;
  };

  validateIsUrlRequired = (titleValue, e) => {
    return !titleValue || !!e.value;
  };

  nextStep = () => {
    const validationResults = this.extrasFormComponent.instance.validate();

    if (!validationResults.isValid) {
      return;
    }
    this.agentCampaignExtraService.handleNextStepClick();
  };

  resetChanges = this.agentCampaignExtraService.revertAllChanges;
}
