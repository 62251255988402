<ng-content></ng-content>

<ag-shr-image-preview
  [src]="imagePreviewUrl"
  (isValidChange)="handleIsImageValid($event)"
>
  <input
    #fileInput
    class="upload-from-disk-tab__drop-zone-input"
    type="file"
    (change)="fileChangeEvent($event)"
    [accept]="accept"
  >
</ag-shr-image-preview>
