import { Pipe, PipeTransform } from '@angular/core';
import { AGENT_STATUS } from 'ag-common-lib/lib/lists/agent-status.enum';

@Pipe({ name: 'agentStatusThumbsIcon' })
export class AgentStatusThumbsUpIconPipe implements PipeTransform {
  transform(agentStatus: AGENT_STATUS): string {
    const cssClassName = {
      [AGENT_STATUS.APPROVED]: 'success',
      [AGENT_STATUS.DENIED]: 'danger',
    };
    return cssClassName[agentStatus] || 'normal';
  }
}
