import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Agent, AgentKeys, BaseModelKeys, Lookup, TShirtSizesKeys } from '@ag-common-lib/public-api';
import { DxFormComponent } from 'devextreme-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { SizesService } from './sizes.service';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';

@Component({
  // eslint-disable-next-line
  selector: 'ag-shr-sizes',
  templateUrl: './sizes.component.html',
  styleUrls: ['./sizes.component.scss'],
  providers: [SizesService],
})
export class SizesComponent {
  // eslint-disable-next-line
  @Input('agent') set _agent(value: Agent) {
    this.agent = value;
    this.funStaffFormDetails = this.sizesService.getFormData(value);
  }
  @Input() canEdit: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() canCreate: boolean = true;
  // eslint-disable-next-line
  @Output() onFieldsUpdated: EventEmitter<{ agentId: string; updates: Partial<Agent> }> = new EventEmitter();
  @ViewChild('sizeModalRef', { static: true }) sizeModalComponent: ModalWindowComponent;

  public AgentKeys = AgentKeys;
  public TShirtSizesKeys = TShirtSizesKeys;
  public funStaffFormDetails;
  public inProgress$: Observable<boolean>;

  protected readonly validationGroup = 'validationGroup';
  protected readonly shirtSeizesFormValidationGroup = 'shirtSeizesFormValidationGroup';

  private agent: Agent;

  constructor(private sizesService: SizesService) {
    this.inProgress$ = sizesService.inProgress$;
  }

  public saveAgentUpdates = async () => {
    const isValid = await validateDxGroups([this.validationGroup, this.shirtSeizesFormValidationGroup]);

    if (isValid) {
      const agentId = this.agent[BaseModelKeys.dbId];
      this.sizesService.handleSave(agentId).then(updates => {
        this.sizeModalComponent.hideModal();
        this.onFieldsUpdated.emit({ agentId, updates });
      });
    }
  };

  public showEditorModal = () => {
    this.sizeModalComponent.showModal();
  };

  public handleClosePopup = e => {
    this.sizesService.onCancelEdit(e);
  };
}
