import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AgMediaGalleryComponent } from './ag-media-gallery.component';
import { SharedModule } from '../../../shared/shared.module';
import { ImageWithLoaderModule } from '../image-with-loader/image-with-loader.module';
import { WasabiImgPipe } from '../../../shared/pipes/wasabi-img.pipe';
import { ButtonWithIndicatorModule, ModalWindowModule } from '../../../lib/components';
import { DxDropDownButtonModule } from 'devextreme-angular';

@NgModule({
  declarations: [AgMediaGalleryComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgOptimizedImage,
    ImageWithLoaderModule,
    ModalWindowModule,
    DxDropDownButtonModule,
    ButtonWithIndicatorModule,
  ],
  exports: [AgMediaGalleryComponent],
  providers: [WasabiImgPipe],
})
export class AgMediaGalleryModule {}
