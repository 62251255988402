<ag-shr-modal-window
  #getSupportModalRef
  [title]="'Get Support'"
  [width]="'50vw'"
  [height]="'auto'"
  [inProgress]="inProgress"
  [actionTitle]="'SUBMIT'"
  [useScrollView]="true"
  (onSaveClick)="handleGetSupport($event)"
>
  <div class="get-support-modal__content">
    <dx-text-area
      [label]="'Description'"
      labelMode="floating"
      class="get-support-modal__text-area"
      [(value)]="formData && formData.description"
      [inputAttr]="{ rows: 4 }"
    >
      >
      <dx-validator #validatorRef>
        <dxi-validation-rule type="required" [message]="Messages.REQUIRED_TEXT"> </dxi-validation-rule>
      </dx-validator>
    </dx-text-area>

    <p class="mt-2 mb-0">
      or contact via
      <a [href]="'mailto:' + supportEmail + '?subject=Event Questions'" class="get-support-modal__link">{{
        supportEmail
      }}</a>
      or
      <a [href]="'tel:' + supportPhone" class="get-support-modal__link">{{
        calculatePhoneNumberDisplayValue(supportPhone)
      }}</a>
    </p>

    <dx-data-grid
      *ngIf="supportTickets?.length"
      class="get-support-modal__logs get-support-modal__logs-grid mb-2 mt-3"
      [width]="'100%'"
      height="auto"
      [dataSource]="supportTickets"
      [columnAutoWidth]="true"
      [wordWrapEnabled]="true"
      [showColumnHeaders]="false"
      [showRowLines]="true"
      [showColumnLines]="false"
    >
      <dxo-load-panel [enabled]="true"></dxo-load-panel>

      <dxi-column dataField="data.details" [caption]="'Open Support Ticket'"></dxi-column>

      <dxi-column
        [dataField]="BaseModelKeys.createdDate"
        [caption]="'Submitted'"
        [width]="200"
        dataType="date"
        [format]="dateFormat"
        [sortOrder]="'desc'"
        cellTemplate="createdDateCellTemplate"
      ></dxi-column>
      <div *dxTemplate="let cell of 'createdDateCellTemplate'" class="associations__address-container">
        Submitted {{ cell?.value | date : dateFormat }}
      </div>
    </dx-data-grid>
  </div>
</ag-shr-modal-window>
