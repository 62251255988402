import { Injectable } from '@angular/core';
import {
  Agent,
  AgentCampaign,
  AgentCampaignChanges,
  AgentCampaignData,
  AgentCampaignMetaData,
  AgentCampaignStepName,
  agentCampaignStepsMap,
  BaseModelKeys,
  CampaignsManagementIssuesStatus,
  CampaignsManagementTaskIssuesKeys,
  CampaignsManagementTaskLogsAction,
  CampaignsManagementTasks,
  agentCampaignOrderedSteps,
  RelatedCampaignsManagementTaskIssues,
  WizardStepState,
  AgentCampaignStep,
  AgentCampaignKeys,
} from '@ag-common-lib/public-api';
import { AgentCampaignService } from '../../services/agent-campaign.service';
import { AgentCampaignsManagementTaskIssuesService } from '../../services/agent-campaigns-management-task-issues.service';
import { AgentCampaignsManagementTasksService } from '../../../public-api';
import { isEmpty } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  defer,
  filter,
  firstValueFrom,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  take,
  tap,
} from 'rxjs';
import { AgentCampaignAgentProfileDataService } from './agent-campaign-agent-profile-data.service';

@Injectable()
export class AgentCampaignWizardService {
  private readonly _currentStepName$ = new Subject<AgentCampaignStepName>();
  currentStepName$: Observable<AgentCampaignStepName>;
  stepsIssues$: Observable<{
    [key in AgentCampaignStepName]?: RelatedCampaignsManagementTaskIssues[];
  }>;
  steps$: Observable<AgentCampaignStep[]>;

  private _agent$ = new BehaviorSubject(null);
  agent$ = this._agent$.asObservable();

  campaign$: Observable<AgentCampaign>;
  notStartedCampaignManagementTask$: Observable<CampaignsManagementTasks>;
  campaignData$: Observable<AgentCampaignData>;
  draftCampaignData$: Observable<AgentCampaignData>;
  hasDataToSubmit$: Observable<boolean>;

  private readonly _inProgress$ = new BehaviorSubject<boolean>(false);
  inProgress$ = this._inProgress$.asObservable();

  constructor(
    private agentCampaignService: AgentCampaignService,
    private agentCampaignAgentProfileDataService: AgentCampaignAgentProfileDataService,
    private agentCampaignsManagementTaskIssuesService: AgentCampaignsManagementTaskIssuesService,
    private agentCampaignsManagementTasksService: AgentCampaignsManagementTasksService,
  ) {
    this.campaign$ = this._agent$.pipe(
      map((agent: Agent) => agent?.[BaseModelKeys.dbId]),
      filter(Boolean),
      mergeMap(this.getAgentCampaign),
      shareReplay(1),
    );

    this.notStartedCampaignManagementTask$ = this.campaign$.pipe(
      mergeMap(campaign => {
        if (!campaign?.[BaseModelKeys.dbId]) {
          return of([]);
        }
        return this.agentCampaignsManagementTasksService.getNotStartedTasksForCampaign(campaign?.[BaseModelKeys.dbId]);
      }),
      map((tasks: CampaignsManagementTasks[]) => {
        return tasks[0] ?? null;
      }),
    );

    this.stepsIssues$ = this.campaign$.pipe(
      mergeMap(campaign => {
        if (!campaign?.[BaseModelKeys.dbId]) {
          return of([]);
        }
        return this.agentCampaignsManagementTaskIssuesService.getAllCampaignActiveContentIssues(
          campaign?.[BaseModelKeys.dbId],
        );
      }),
      map(issues => {
        const map = {};

        issues?.forEach((issue: RelatedCampaignsManagementTaskIssues) => {
          const key = issue?.data?.[CampaignsManagementTaskIssuesKeys.issueField];
          const list = map?.[key] ?? [];
          list.push(issue);

          Object.assign(map, {
            [key]: list,
          });
        });

        return map;
      }),
      shareReplay(1),
    );

    this.campaignData$ = this.campaign$.pipe(map(campaign => campaign?.[AgentCampaignKeys.data]));
    this.draftCampaignData$ = this.campaign$.pipe(map(campaign => campaign?.[AgentCampaignKeys.draftData]));
    this.hasDataToSubmit$ = this.draftCampaignData$.pipe(
      map((draftCampaignData = {}) => Object.values(draftCampaignData).some(Boolean)),
    );
    this.currentStepName$ = merge(
      this._currentStepName$.pipe(startWith(AgentCampaignStepName.contactInfoAddress)),
      this.agent$.pipe(
        mergeMap(() => this.campaign$.pipe(take(1))),
        map(campaign => {
          const campaignData = campaign?.[AgentCampaignKeys.data];
          const draftCampaignData = campaign?.[AgentCampaignKeys.draftData];

          const initialStep = agentCampaignOrderedSteps.find(stepName => {
            const stepCampaignData = campaignData?.[stepName];
            const stepDraftCampaignData = draftCampaignData?.[stepName];

            return !stepCampaignData && !stepDraftCampaignData;
          });

          return initialStep ?? agentCampaignOrderedSteps[0];
        }),
      ),
    ).pipe(shareReplay(1));

    this.steps$ = combineLatest({ campaign: this.campaign$.pipe(startWith({})), stepsIssues: this.stepsIssues$ }).pipe(
      map(this._getSteps),
    );
  }

  setAgent = agent => {
    this._agent$.next(agent);
  };

  setCurrentStep = (step: Partial<AgentCampaignStep>) => {
    this._currentStepName$.next(step?.name);
  };

  nextStep = async () => {
    const currentStepName = await firstValueFrom(this.currentStepName$);
    const currentStepIndex = agentCampaignOrderedSteps.findIndex(stepName => stepName === currentStepName);
    const nextStep = agentCampaignOrderedSteps[currentStepIndex + 1];

    this._currentStepName$.next(nextStep ?? AgentCampaignStepName.summary);
  };

  handleStepChanges = async (changes: AgentCampaignData, metaData?: AgentCampaignMetaData) => {
    const currentStepName = await firstValueFrom(this.currentStepName$);

    const issues = await firstValueFrom(this.stepsIssues$.pipe(map(stepsIssues => stepsIssues?.[currentStepName])));

    return issues?.length ? this.resolveIssues(issues, changes) : this.saveDraft(changes, metaData);
  };

  getSupport = async (details: string) => {
    const campaign = await firstValueFrom(this.campaign$);
    const campaignId = campaign?.[BaseModelKeys.dbId];
    const agent = this._agent$.value;
    const agentDbId = agent?.[BaseModelKeys.dbId];
    const task: CampaignsManagementTasks = Object.assign(
      {},
      new CampaignsManagementTasks(agentDbId, { support: { incomingStepData: details } }),
    );

    this.agentCampaignsManagementTasksService.create(campaignId, task, false);
  };

  private saveDraft = async (draftData: AgentCampaignData, metaData?: AgentCampaignMetaData) => {
    const agent: Agent = this._agent$.value;

    const updates = {
      [AgentCampaignKeys.draftData]: draftData,
    };

    if (metaData) {
      Object.assign(updates, { [AgentCampaignKeys.metaData]: metaData });
    }

    return this.agentCampaignService.updateFields(agent?.[BaseModelKeys.dbId], updates);
  };

  private resolveIssues = async (issues: RelatedCampaignsManagementTaskIssues[], changes: AgentCampaignData) => {
    const campaign = await firstValueFrom(this.campaign$);
    const campaignId = campaign?.[BaseModelKeys.dbId];

    return Promise.all([
      issues.map(issue => {
        const issueData = issue?.data;
        const issueField: AgentCampaignStepName = issueData?.issueField;
        return Promise.all([
          // Fix data in the ticket
          this.agentCampaignsManagementTasksService.correctDataStepIncomingData(
            campaignId,
            issue.parentDbId,
            issueField,
            changes[issueField],
          ),
          // Save changes to Agent Profile
          this.agentCampaignAgentProfileDataService.handleAgentData(this._agent$.value, changes),
          // Save changes to Campaign Data
          this.agentCampaignService.updateFields(campaignId, {
            [AgentCampaignKeys.data]: changes,
          }),
          // Mark issue as resolved by Agent
          this.agentCampaignsManagementTaskIssuesService.updateIssues(
            campaignId,
            issue.parentDbId,
            issue.data?.[BaseModelKeys.dbId],
            { [CampaignsManagementTaskIssuesKeys.issueStatus]: CampaignsManagementIssuesStatus.resolved },
            true,
          ),
        ]);
      }),
    ]);
  };

  submitChanges = async () => {
    const campaign = await firstValueFrom(this.campaign$);
    const details = this.agentCampaignService.prepareSummary(campaign);
    const campaignId = campaign?.[BaseModelKeys.dbId];
    const draftCampaignData = campaign?.draftData;

    await Promise.all([
      this.saveTicket(campaignId, details),

      this.agentCampaignService.updateFields(campaignId, {
        [AgentCampaignKeys.data]: draftCampaignData,
        [AgentCampaignKeys.draftData]: null,
        [AgentCampaignKeys.metaData]: null,
      }),
      this.agentCampaignAgentProfileDataService.handleAgentData(this._agent$.value, draftCampaignData),
    ]);
  };

  private _getSteps = ({ campaign = {}, stepsIssues }): AgentCampaignStep[] => {
    const campaignData = campaign?.[AgentCampaignKeys.data];
    const draftCampaignData = campaign?.[AgentCampaignKeys.draftData];

    const getStepState = (step: AgentCampaignStepName) => {
      const stepCampaignData = Object.assign({}, campaignData?.[step]);
      const stepDraftCampaignData = Object.assign({}, draftCampaignData?.[step]);
      const hasStepCampaignData = !isEmpty(stepCampaignData);
      const hasStepDraftCampaignData = !isEmpty(stepDraftCampaignData);

      const hasDraftChangesForData = Object.entries(stepDraftCampaignData).some(([key, value = null]) => {
        const item = stepCampaignData?.[key] ?? null;
        return item !== value;
      });

      if (hasDraftChangesForData) {
        return WizardStepState.changed;
      }

      if (!!stepsIssues?.[step]?.length) {
        return WizardStepState.issues;
      }

      if (hasStepCampaignData && !hasStepDraftCampaignData) {
        return WizardStepState.done;
      }

      return hasStepCampaignData ? WizardStepState.done : WizardStepState.initial;
    };
    const steps: AgentCampaignStep[] = [];

    agentCampaignStepsMap.forEach((value, name) => {
      const stepState = getStepState(name);
      const step = Object.assign({ name, stepState: stepState ?? WizardStepState.initial }, value);

      if (step?.steps) {
        const stepsWithStatus = step?.steps?.map(subStep => {
          const subStepState = getStepState(subStep?.name);
          return Object.assign({ stepState: subStepState ?? WizardStepState.initial }, subStep);
        });

        Object.assign(step, { steps: stepsWithStatus });
      }

      steps.push(step);
    });

    return steps;
  };

  private saveTicket = async (campaignId, details: AgentCampaignChanges) => {
    const notStartedCampaignManagementTask = await firstValueFrom(this.notStartedCampaignManagementTask$);
    const notStartedCampaignManagementTaskId = notStartedCampaignManagementTask?.[BaseModelKeys.dbId];

    if (notStartedCampaignManagementTaskId) {
      const notStartedCampaignManagementTaskDetails = notStartedCampaignManagementTask?.details ?? {};

      Object.keys(details).forEach((key: AgentCampaignStepName) => {
        if (details?.[key]?.incomingStepData) {
          notStartedCampaignManagementTaskDetails[key].incomingStepData = details?.[key]?.incomingStepData;
        }
      });

      return this.agentCampaignsManagementTasksService.update(
        campaignId,
        notStartedCampaignManagementTaskId,
        { details: notStartedCampaignManagementTaskDetails },
        CampaignsManagementTaskLogsAction.taskUpdating,
        false,
      );
    }

    const agent = this._agent$.value;
    const agentDbId = agent?.[BaseModelKeys.dbId];
    const task: CampaignsManagementTasks = Object.assign({}, new CampaignsManagementTasks(agentDbId, details));

    return this.agentCampaignsManagementTasksService.create(campaignId, task, false);
  };

  private getAgentCampaign = agentDbId =>
    defer(() => {
      this._inProgress$.next(true);
      return this.agentCampaignService.getDocument(agentDbId).pipe(
        map(snapshot => {
          return snapshot.data() ?? {};
        }),
        tap(() => {
          this._inProgress$.next(false);
        }),
        shareReplay(1),
      );
    });
}
