import { LookupKeys } from '../crm/lookup.model';

export enum EmailTemplates {
  confirmEmailUpdateTmp = 'confirmEmailUpdateTmp',
  confirmRegisterEmailTmp = 'confirmRegisterEmailTmp',
  notifyEmailChangeTmp = 'notifyEmailChangeTmp',
  confirmPasswordUpdateTmp = 'confirmPasswordUpdateTmp',
  notifyEmailConferenceRegistrationSummary = 'notifyEmailConferenceRegistrationSummary',
  notifyEmailSupportTask = 'notifyEmailSupportTask',
  notifyEmailKnowBeforeYouGoToConference = 'notifyEmailKnowBeforeYouGoToConference',
  notifyEmailInviteesActivitiesFirstDay = 'notifyEmailInviteesActivitiesFirstDay',
  notifyEmailInviteesActivitiesSecondDay = 'notifyEmailInviteesActivitiesSecondDay',
  notifyEmailConferenceRegistrationShortSummary = 'notifyEmailConferenceRegistrationShortSummary',
}

export const EMAIL_TEMPLATE_LOOKUP = [
  {
    [LookupKeys.value]: EmailTemplates.notifyEmailConferenceRegistrationShortSummary,
    [LookupKeys.description]: 'Registration Short Summary Email Template',
  },
  {
    [LookupKeys.value]: EmailTemplates.notifyEmailConferenceRegistrationSummary,
    [LookupKeys.description]: 'Registration Summary Email Template',
  },
];
