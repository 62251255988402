import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ag-shr-agent-campaign-summary-list-data',
  templateUrl: './agent-campaign-summary-list-data.component.html',
  styleUrls: ['./agent-campaign-summary-list-data.component.scss'],
})
export class AgentCampaignSummaryListDataComponent {
  @HostBinding('class') className = 'agent-campaign-summary-list-data';

  @Input() title?: string;
  @Input() hasIncomingData: boolean;
  @Input() hasCurrentData: boolean;

  constructor() {}
}
