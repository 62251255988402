<ag-shr-stripe-modal #stripeModalRef></ag-shr-stripe-modal>

<ag-shr-modal-window
  #attendeeTransactionModalRef
  [title]="'Transaction Details'"
  [width]="'50vw'"
  [height]="'590px'"
  [actionTitle]="actionTitle"
  [inProgress]="inProgress$ | async"
  [useScrollView]="false"
  (onSaveClick)="onSaveTransaction($event)"
>
  <div
    *ngIf="attendeeTransactionModalRef?.popupComponent?.visible ?? false"
    class="attendee-transaction-modal__container"
  >
    <div class="attendee-transaction-modal__grid-container">
      <dx-data-grid
        [dataSource]="formData?.[StripeTransactionKeys.details]"
        (dataSourceChange)="onDetailsChanged($event)"
        (onInitNewRow)="onInitNewTransactionDetailRow($event)"
        [showBorders]="true"
        height="100%"
      >
        <dxo-editing
          #excursionPreferencesItemsEditingTmp
          mode="row"
          [useIcons]="true"
          [allowUpdating]="true"
          [allowDeleting]="true"
          [allowAdding]="true"
          [newRowPosition]="'last'"
        >
        </dxo-editing>

        <dxo-toolbar>
          <dxi-item location="before">
            <dx-text-box
              [(value)]="formData && formData[StripeTransactionKeys.description]"
              label="Transaction Details Description"
              labelMode="floating"
              [width]="250"
            >
              <dx-validator [validationGroup]="validationGroup">
                <dxi-validation-rule
                  type="required"
                  message="Transaction Description is required"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </dxi-item>

          <dxi-item [visible]="true" name="addRowButton"></dxi-item>
        </dxo-toolbar>

        <dxi-column [dataField]="AgStripeSubtotalKeys.subtotalType" caption="Transaction Point" [width]="150">
          <dxo-lookup
            [dataSource]="SUBTOTAL_TYPE_LOOKUP"
            [displayExpr]="LookupKeys.description"
            [valueExpr]="LookupKeys.value"
          >
          </dxo-lookup>

          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column [dataField]="AgStripeSubtotalKeys.description" caption="Description">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column
          [dataField]="AgStripeSubtotalKeys.amount"
          [dataType]="'number'"
          [format]="DX_USD_CURRENCY_FORMAT"
          [alignment]="'right'"
          [editorOptions]="{ format: DX_USD_CURRENCY_FORMAT }"
          width="auto"
          [minWidth]="150"
        >
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column [visible]="true" type="buttons"></dxi-column>

        <dxo-summary [recalculateWhileEditing]="true">
          <dxi-total-item
            [column]="AgStripeSubtotalKeys.amount"
            summaryType="sum"
            [valueFormat]="DX_USD_CURRENCY_FORMAT"
            displayFormat="Total: {0}"
          >
          </dxi-total-item>
        </dxo-summary>
      </dx-data-grid>
    </div>

    <div *ngIf="isNoDetails" class="dx-item dx-validationsummary-item mt-2">
      <div class="dx-item-content dx-validationsummary-item-content">Details is empty</div>
    </div>
  </div>
</ag-shr-modal-window>
