import { Pipe, PipeTransform } from '@angular/core';

export interface ScreenLimits {
  xs?: number;
  sm?: number;
  md?: number;
}

@Pipe({ name: 'formScreenByWidth' })
export class FormScreenByWidthPipe implements PipeTransform {
  transform(formWidth: number, limits?: ScreenLimits) {
    return (windowWidth: number) => {
      const width = formWidth ?? windowWidth;

      if (width < (limits?.xs ?? 300)) {
        return 'xs';
      }
      if (width < (limits?.sm ?? 460)) {
        return 'sm';
      }
      if (width < (limits?.md ?? 700)) {
        return 'md';
      }

      return 'lg';
    };
  }
}
