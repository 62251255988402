import { DocumentReference } from 'firebase/firestore';
import { BaseModel, BaseModelKeys } from '../base.model';

export enum LookupKeys {
  value = 'value',
  description = 'description',
  isSystem = 'isSystem',
  isActive = 'isActive',
  isAssigned = 'isAssigned',
  dependsOn = 'dependsOn',
  visible = 'visible',
  reference = 'reference',
  isDefault = 'isDefault',
}

export class ValueDescription {
  [LookupKeys.value]: string | number;
  [LookupKeys.description]: string;
}

export class Lookup extends BaseModel {
  [LookupKeys.value]: string = null;
  [LookupKeys.description]: string = null;
  [LookupKeys.dependsOn]?: string = '';
  [LookupKeys.isSystem]: boolean = false;
  [LookupKeys.isAssigned]: boolean = false;
  [LookupKeys.isActive]: boolean = true;
  [LookupKeys.isDefault]?: boolean = false;
  [LookupKeys.reference]: DocumentReference<Lookup>;
}

export class ActiveLookup {
  [BaseModelKeys.dbId]: string;
  [LookupKeys.value]: string;
  [LookupKeys.description]: string;
  [LookupKeys.visible]: boolean;
  [LookupKeys.isDefault]?: boolean;
}
