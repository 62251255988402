<dx-scroll-view
  #scrollViewRef
  class="wizard-stepper__container"
  height="100%"
  [useNative]="false"
  [showScrollbar]="'always'"
>
  <ng-container *ngIf="steps">
    <ng-container *ngFor="let step of steps; let index = index">
      <div #stepRef class="wizard-stepper__step" [ngClass]="currentStepName | wizardStepperStepState : step">
        <button class="wizard-stepper__step-title-container" (click)="onSetCurrentStep(step)">
          <span class="wizard-stepper__step-indicator"></span>
          <span class="wizard-stepper__step-title">{{ step.title }}</span>
          <span *ngIf="!!step.icon" class="dx-icon" [ngClass]="step.icon"></span>
        </button>

        <!-- <i
          #popoverTarget
          class="dx-icon dx-icon-help wizard-stepper__step-help"
          (mouseenter)="openInstructionsPopup(step.name)"
        ></i>

        <dx-popover
          [target]="popoverTarget"
          [showEvent]="'mouseenter'"
          [hideEvent]="'mouseleave'"
          position="right"
          [width]="'auto'"
          [height]="'auto'"
          [showTitle]="false"
          [wrapperAttr]="{  class: 'clipboard-button__popover-wrapper' }"
        >
          <div *dxTemplate="let data = model; of: 'content'">Helper text</div>
        </dx-popover> -->
      </div>

      <div
        *ngIf="step.steps"
        class="wizard-stepper__sub-steps-container"
        [ngClass]="currentStepName | wizardStepperSubStepsContainerClass : step"
      >
        <div
          class="wizard-stepper__sub-step"
          *ngFor="let subStep of step.steps; let subindex = index"
          [ngClass]="currentStepName | wizardStepperSubStepState : subStep"
        >
          <button class="wizard-stepper__step-title-container" (click)="onSetCurrentStep(subStep)">
            <span class="wizard-stepper__sub-step-indicator"></span>
            <span class="wizard-stepper__sub-step-title">{{ subStep.title }}</span>
            <span *ngIf="!!subStep.icon" class="dx-icon" [ngClass]="subStep.icon"></span>
          </button>

          <!-- <i
            id="id{{ subStep.name }}"
            #popoverTarget
            class="dx-icon dx-icon-help wizard-stepper__step-help"
          ></i>

          <dx-popover
            [target]="popoverTarget"
            [showEvent]="'mouseenter'"
            [hideEvent]="'mouseleave'"
            position="right"
            [width]="'auto'"
            [height]="'auto'"
            [showTitle]="false"
            [wrapperAttr]="{  class: 'clipboard-button__popover-wrapper' }"
          >
            <div *dxTemplate="let data = model; of: 'content'">Helper text</div>
          </dx-popover> -->
        </div>
      </div>
    </ng-container>
  </ng-container>
</dx-scroll-view>

<dx-button
  *ngIf="withSupportButton"
  [icon]="'help'"
  [text]="'Need Help ?'"
  [type]="'default'"
  [stylingMode]="'text'"
  (onClick)="showGetSupportModal()"
></dx-button>

<ag-shr-get-support-modal
  #getSupportModalRef
  [onGetSupport]="onGetSupport"
  [supportTickets]="supportTickets"
  [supportEmail]="supportEmail"
  [supportPhone]="supportPhone"
></ag-shr-get-support-modal>
