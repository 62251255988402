import { Metadata } from 'aws-sdk/clients/s3';

export enum MediaSize {
  origin = 'origin',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum UploadMediaPayloadKeys {
  fileId = 'fileId',
  filePath = 'filePath',
  mediaData = 'mediaData',
  metadata = 'metadata',
  contentType = 'contentType',
  withThumbnail = 'withThumbnail',
}

export enum DeleteMediaPayloadKeys {
  filePath = 'filePath',
}

export interface UploadMediaPayload {
  [UploadMediaPayloadKeys.fileId]?: string;
  [UploadMediaPayloadKeys.filePath]: string;
  [UploadMediaPayloadKeys.withThumbnail]?: boolean;
  [UploadMediaPayloadKeys.mediaData]: string;
  [UploadMediaPayloadKeys.contentType]: string;
  [UploadMediaPayloadKeys.metadata]?: Metadata;
}
export interface UploadFilePayload {
  [UploadMediaPayloadKeys.filePath]: string;
  [UploadMediaPayloadKeys.mediaData]: string;
  [UploadMediaPayloadKeys.contentType]: string;
  [UploadMediaPayloadKeys.metadata]?: Metadata;
}

export interface DeleteMediaPayload {
  [DeleteMediaPayloadKeys.filePath]: string;
}
