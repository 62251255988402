import { LookupKeys } from '../models/crm/lookup.model';
import { PhoneNumberType, PhoneNumberTypeDescriptionMap } from './phone_number_type.enum';

export const PHONE_NUMBER_TYPE_LOOKUP = [
  {
    [LookupKeys.value]: PhoneNumberType.Mobile,
    [LookupKeys.description]: PhoneNumberTypeDescriptionMap.get(PhoneNumberType.Mobile),
  },
  {
    [LookupKeys.value]: PhoneNumberType.Landline,
    [LookupKeys.description]: PhoneNumberTypeDescriptionMap.get(PhoneNumberType.Landline),
  },
];
