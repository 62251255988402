import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { AgEditorToolbarModule } from '../../../ag-editor-toolbar/ag-editor-toolbar.module';
import { OfficeEmailAddressesComponent } from './office-email-addresses.component';
import { OfficeEmailAddressesGridComponent } from './grid/office-email-addresses-grid.component';
import { OfficeEmailAddressesService } from './office-email-addresses.service';

@NgModule({
  imports: [SharedModule, ModalWindowModule, AgEditorToolbarModule],
  declarations: [OfficeEmailAddressesComponent, OfficeEmailAddressesGridComponent],
  exports: [OfficeEmailAddressesComponent, OfficeEmailAddressesGridComponent],
  providers: [OfficeEmailAddressesService],
})
export class OfficeEmailAddressesModule {}
