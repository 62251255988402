import { NgModule } from '@angular/core';
import { ImageWithLoaderModule } from '../image-with-loader/image-with-loader.module';
import { ModalWindowModule } from '../modal-window/modal-window.module';
import { AgAgentButtonComponent } from './ag-agent-button.component';
import { AgentWasabiPathPipe } from './pipe/agent-wasabi-path.pipe';

import { SharedModule } from 'ag-common-svc/shared/shared.module';

@NgModule({
  declarations: [AgAgentButtonComponent, AgentWasabiPathPipe],
  imports: [SharedModule, ImageWithLoaderModule, ModalWindowModule],
  exports: [AgAgentButtonComponent, AgentWasabiPathPipe],
})
export class AgAgentButtonModule {}
