import { Pipe, PipeTransform } from '@angular/core';
import { MILITARY_TIME_FORMAT, SHORT_TIME_AM_PM_FORMAT } from '@ag-common-lib/public-api';
import { format, isValid, parse } from 'date-fns';

@Pipe({ name: 'militaryTimeFormat' })
export class MilitaryTimeFormatPipe implements PipeTransform {
  transform(militaryTime: string, outputFormat = SHORT_TIME_AM_PM_FORMAT): string {
    if (!militaryTime) {
      return '';
    }

    const date = parse(militaryTime, MILITARY_TIME_FORMAT, new Date());

    if (!isValid(date)) {
      return '';
    }

    return format(date, outputFormat);
  }
}
