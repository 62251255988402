<dx-text-box
  #urlTextBoxRef
  [width]="'100%'"
  (onValueChanged)="imageUrlToBase64($event)"
  [label]="'Url'"
  [labelMode]="'floating'"
  [showClearButton]="true"
  class="upload-from-disk-tab__text-input"
>
</dx-text-box>

<ng-content></ng-content>

<ag-shr-image-preview
  [src]="imagePreviewUrl"
  [placeholder]="'Insert the Image Url'"
  [isValid]="_isImageValid$ | async"
  (isValidChange)="handleIsImageValid($event)"
></ag-shr-image-preview>
