import { Pipe, PipeTransform } from '@angular/core';
import { AgentService } from 'ag-common-svc/public-api';
import { Observable, of, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImageCropperPayload } from '../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/image-cropper.model';

@Pipe({ name: 'agentWasabiPath' })
export class AgentWasabiPathPipe implements PipeTransform {
  constructor(private readonly agentService: AgentService) {}

  transform(agentDbId: string): Observable<{ wasabiPath: string; imageCropperPayload?: ImageCropperPayload } | null> {
    if (!agentDbId) {
      return of(null);
    }
    return this.agentService.getDocument(agentDbId).pipe(
      map(agentSnapshot => {
        if (agentSnapshot.exists()) {
          const data = agentSnapshot.data();
          const headshot = {
            wasabiPath: data?.head_shot?.wasabiPath,
            imageCropperPayload: data?.head_shot?.imageCropperPayload,
          };
          return headshot || null;
        }
        return null;
      }),
      shareReplay(1),
    );
  }
}
