import { MappingProperty } from '@elastic/elasticsearch/lib/api/types';

export {
  SearchRequest,
  SearchResponse,
  SortResults,
  QueryDslQueryContainer,
  QueryDslBoolQuery,
  SearchTotalHits,
  Sort,
  ScriptSort,
  MappingProperty,
  MappingNestedProperty,
} from '@elastic/elasticsearch/lib/api/types';

export interface IndexProperties {
  [key: string]: MappingProperty;
}
