import { NgModule } from '@angular/core';
import { AgentCampaignSummaryComponent } from './agent-campaign-summary.component';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ButtonWithIndicatorModule } from '../../button-with-indicator/button-with-indicator.module';
import { AgentCampaignSummaryService } from './agent-campaign-summary.service';
import { AgentCampaignSummaryListModule } from '../../agent-campaign-summary-list/agent-campaign-summary-list.module';

@NgModule({
  declarations: [AgentCampaignSummaryComponent],
  imports: [SharedModule, ButtonWithIndicatorModule, AgentCampaignSummaryListModule],
  exports: [AgentCampaignSummaryComponent],
  providers: [AgentCampaignSummaryService],
})
export class AgentCampaignSummaryModule {}
