import { BaseModel } from '../base.model';
import { CampaignsManagementIssuesStatus } from '../../lists/campaign-management-issues-status.enum';
import { CampaignsManagementIssuesType } from '../../lists/campaign-management-issues-type.enum';
import { AgentCampaignStepName } from './agent-campaign-wizard.model';

export const CAMPAIGNS_MANAGEMENT_ISSUES_COLLECTION_NAME = 'campaign-management-task-issues';

export interface RelatedCampaignsManagementTaskIssues {
  data: CampaignsManagementTaskIssues;
  parentDbId: string;
}

export enum CampaignsManagementTaskIssuesKeys {
  issueType = 'issueType',
  issueStatus = 'issueStatus',
  issueField = 'issueField',
  description = 'description',
  updatedStatusAt = 'updatedStatusAt',
  assignedTo = 'assignedTo',
  assignedAt = 'assignedAt',
  taskDbId = 'taskDbId',
}

export class CampaignsManagementTaskIssues extends BaseModel {
  [CampaignsManagementTaskIssuesKeys.taskDbId]?: string;
  [CampaignsManagementTaskIssuesKeys.issueStatus]?: CampaignsManagementIssuesStatus =
    CampaignsManagementIssuesStatus.posted;
  [CampaignsManagementTaskIssuesKeys.issueType]?: CampaignsManagementIssuesType = CampaignsManagementIssuesType.content;
  [CampaignsManagementTaskIssuesKeys.issueField]?: AgentCampaignStepName = AgentCampaignStepName.webSite;
  [CampaignsManagementTaskIssuesKeys.description]?: string = '';
  [CampaignsManagementTaskIssuesKeys.updatedStatusAt]?: Date;
  [CampaignsManagementTaskIssuesKeys.assignedTo]?: string = '';
  [CampaignsManagementTaskIssuesKeys.assignedAt]?: Date;
}
