import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MediaSize } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-agent-button',
  templateUrl: './ag-agent-button.component.html',
  styleUrls: ['./ag-agent-button.component.scss'],
})
export class AgAgentButtonComponent {
  @HostBinding('class') className = 'ag-agent-button';
  @Input() agentDbId: string;
  @Input() isDisabled = false;

  @Output() handleClick = new EventEmitter();

  protected readonly MediaSize = MediaSize;

  constructor() {}

  isImageLoadingIssuesChange = hasIssues => {
    if (hasIssues) {
    }
  };
}
