import { Inject, Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { DataService } from './data.service';
import { FIREBASE_APP } from 'ag-common-svc/public-api';
import { DynamicSegmentsGroup } from '@ag-common-lib/lib';

@Injectable()
export class DynamicSegmentsGroupService extends DataService<DynamicSegmentsGroup> {
    constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
        super(fireBaseApp);
        this.collection = 'dynamic-segments-group';
    }

}