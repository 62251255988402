import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { BehaviorSubject } from 'rxjs';
import {
  AgencyRelationship,
  AgencyRelationshipModelKeys,
  BaseModelKeys,
  Constants,
  EditorOptions,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  LookupKeys,
  Lookups,
  Messages,
} from '@ag-common-lib/public-api';
import { DxFormComponent } from 'devextreme-angular';
import { AgencyRelationshipService } from './agency-relationship.service';
import { LookupsService } from 'ag-common-svc/public-api';

@Component({
  selector: 'ag-shr-agency-relationship',
  templateUrl: './agency-relationship.component.html',
  styleUrls: ['./agency-relationship.component.scss'],
})
export class AgencyRelationshipComponent {
  @ViewChild('agencyRelationshipFormRef', { static: false }) agencyRelationshipFormComponent: DxFormComponent;
  @ViewChild('agencyRelationshipModalRef', { static: true }) agencyRelationshipModalComponent: ModalWindowComponent;
  @Input() agencyId: string;
  @Input()
  set agencyRelationship(v: AgencyRelationship) {
    const data = v ? v : null;
    this.relationshipFormData = this.agencyRelationshipService.getFormData(v);
  }
  @Output() agencyRelationshipChange = new EventEmitter<AgencyRelationship>();

  inProgress$ = new BehaviorSubject<boolean>(false);
  agencyOrganizationAffiliations$ = this.lookupsService.agencyOrganizationAffiliations$;
  selectedOrganizationAffiliations$ = this.agencyRelationshipService.selectedOrganizationAffiliations$;
  relationshipFormData: Partial<AgencyRelationship>;

  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly AgencyRelationshipModelKeys = AgencyRelationshipModelKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly Lookups = Lookups;
  readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  readonly dateEditorOptions = {
    ...EditorOptions.DATE,
  };
  readonly Messages = Messages;

  constructor(private agencyRelationshipService: AgencyRelationshipService, private lookupsService: LookupsService) {}

  async handelSaveClick(): Promise<void> {
    const validationResults = this.agencyRelationshipFormComponent.instance.validate();
    if (validationResults.isValid) {
      const results = await this.agencyRelationshipService?.saveAgencyRelashionship(
        this.agencyId,
        this.agencyRelationshipModalComponent,
      );

      if (results) {
        const updates = Object.assign({}, this.relationshipFormData, results.relationshipWithAG);
        this.agencyRelationshipChange.emit(updates);
      }
    }
  }

  onAfterRevertChanges = (): void => {
    this.agencyRelationshipFormComponent.instance.repaint();
  };

  handleClosePopup = () => this.agencyRelationshipService.onCancelEdit.call(this, this.onAfterRevertChanges);

  onOrganizationAffiliationsChanged = (e): void => {
    const selectedItems = e?.component?.option('selectedItems');
    this.agencyRelationshipService.setSelectedOrganizationAffiliations(selectedItems);
  };
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
