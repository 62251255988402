import { Pipe, PipeTransform } from '@angular/core';
import { AGENT_STATUS } from 'ag-common-lib/public-api';

@Pipe({ name: 'agentMainInfoThumbsUpIcon' })
export class AgentMainInfoThumbsUpIconPipe implements PipeTransform {
  transform(agentStatus: AGENT_STATUS): string {
    if (agentStatus === AGENT_STATUS.APPROVED) {
      return 'success';
    }
    if (agentStatus === AGENT_STATUS.DENIED) {
      return 'danger';
    }

    return 'normal';
  }
}
