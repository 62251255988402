import { LookupBase } from '../models/utils/lookup-base.model';
import { InviteeStatus } from "ag-common-lib/lib/models/registration/registrants.model";

export enum ConferenceRegistrationCommonTaskStatus {
  new = 'new',
  inProgress = 'inProgress',
  issues = 'issues',
  done = 'done',
}

export const ConferenceRegistrationCommonTaskStatusMap = new Map([
  [ConferenceRegistrationCommonTaskStatus.new, 'New Tasks'],
  [ConferenceRegistrationCommonTaskStatus.inProgress, 'In Progress'],
  [ConferenceRegistrationCommonTaskStatus.issues, 'Issues'],
  [ConferenceRegistrationCommonTaskStatus.done, 'Done'],
]);

export const CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP: LookupBase[] = [
  {
    value: ConferenceRegistrationCommonTaskStatus.new,
    description: 'New Tasks',
    cssClass: 'conference-registration-grid__button--new',
  },
  {
    value: ConferenceRegistrationCommonTaskStatus.inProgress,
    description: 'In Progress',
    cssClass: 'conference-registration-grid__button--in-progress',
  },
  {
    value: ConferenceRegistrationCommonTaskStatus.issues,
    description: 'Issues',
    cssClass: 'conference-registration-grid__button--issues',
  },
  {
    value: ConferenceRegistrationCommonTaskStatus.done,
    description: 'Done',
    cssClass: 'conference-registration-grid__button--done',
  },
];
