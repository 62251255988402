import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared/shared.module';
import { ClipboardButtonComponent } from './clipboard-button.component';
import { DxDropDownButtonModule, DxPopoverModule } from 'devextreme-angular';

@NgModule({
  imports: [SharedModule, DxDropDownButtonModule, DxPopoverModule],
  declarations: [ClipboardButtonComponent],
  exports: [ClipboardButtonComponent],
})
export class ClipboardButtonModule {}
