import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: any, currencyCode: string = 'USD', locale: string = 'en-US'): string {
    const isNegative = parseFloat(value) < 0;
    const formattedValue = Math.abs(value).toLocaleString(locale, { style: 'currency', currency: currencyCode });

    return isNegative ? `-${formattedValue}` : formattedValue;
  }
}
