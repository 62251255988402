import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { VimeoVideoComponent } from './vimeo-video.component';

@NgModule({
  declarations: [VimeoVideoComponent],
  exports: [VimeoVideoComponent],
  imports: [CommonModule, SharedModule],
})
export class VimeoVideoModule {}
