import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, EMPTY, lastValueFrom, map, take, tap } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

@Injectable()
export class VimeoService {
  private accessToken = 'd6dd4115affc43f2f62be38d58150ce7';
  private baseApiUrl = 'https://api.vimeo.com';
  private meApiUrl = 'https://api.vimeo.com/me';

  constructor(private http: HttpClient) {}

  getVideos(page: number = 1, perPage: number = 10, searchQuery: string = ''): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });

    const params: any = {
      page: page.toString(),
      per_page: perPage.toString(),
    };

    if (searchQuery) {
      params.query = searchQuery;
    }

    const response = this.http.get<any>([this.meApiUrl, 'videos'].join('/'), { headers, params });

    return lastValueFrom(response.pipe(take(1)));
  }

  getVideoById(path: string): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });

    const response = this.http.get<any>(`${this.meApiUrl}${path}`, { headers }).pipe(
      catchError(e => {
        debugger;
        return EMPTY;
      }),
    );

    return lastValueFrom(response.pipe(take(1)));
  }

  videoDataSource = new DataSource({
    paginate: true,
    pageSize: 15,
    store: new CustomStore({
      key: 'uri',
      byKey: uri => this.getVideoById(uri),
      load: async loadOptions => {
        const page = Math.ceil((loadOptions.skip || 0) / (loadOptions.take || 10)) + 1;
        const pageSize = loadOptions.take || 20;

        return this.getVideos(page, pageSize, loadOptions?.searchValue)

          .then(response => {
            return {
              data: response.data, // Vimeo API returns the videos in `data`
              totalCount: response.total, // Total video count for pagination
            };
          })
          .catch(error => {
            throw error;
          });
      },
    }),
  });

  getVideosByFolder(projectId: string) {
    const headers = {
      Authorization: `Bearer ${this.accessToken}`,
    };

    const response = this.http.get<any>([this.meApiUrl, 'projects', projectId, 'videos'].join('/'), { headers });

    return lastValueFrom(response.pipe(take(1)));
  }

  getFolders(page: number = 1, perPage: number = 10, searchQuery: string = '') {
    const headers = {
      Authorization: `Bearer ${this.accessToken}`,
    };

    const params: any = {
      page: page.toString(),
      per_page: perPage.toString(),
      fields: 'uri,name,project_id',
    };

    if (searchQuery) {
      params.query = searchQuery;
    }

    const response = this.http.get<any>([this.meApiUrl, 'projects'].join('/'), { headers, params });

    return lastValueFrom(response.pipe(take(1)));
  }

  getFolderById(path: string): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });

    const response = this.http.get<any>(`${this.baseApiUrl}${path}`, { headers }).pipe(
      map(data => {
        debugger;
        return data;
      }),
      catchError(e => {
        return EMPTY;
      }),
    );

    return lastValueFrom(response.pipe(take(1)));
  }

  foldersDataSource = new DataSource({
    paginate: true,
    pageSize: 15,
    store: new CustomStore({
      key: 'uri',
      byKey: uri => this.getFolderById(uri),
      load: async loadOptions => {
        const page = Math.ceil((loadOptions.skip || 0) / (loadOptions.take || 10)) + 1;
        const pageSize = loadOptions.take || 20;

        return this.getFolders(page, pageSize, loadOptions?.searchValue)

          .then(response => {
            return {
              data: response.data, // Vimeo API returns the videos in `data`
              totalCount: response.total, // Total video count for pagination
            };
          })
          .catch(error => {
            throw error;
          });
      },
    }),
  });

  getVideosInFolder(
    folderPath: string,
    page: number = 1,
    perPage: number = 10,
    searchQuery: string = '',
  ): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });

    const params: any = {
      page: page.toString(),
      per_page: perPage.toString(),
      fields: 'uri,name,description',
    };

    if (searchQuery) {
      params.query = searchQuery;
    }

    const response = this.http.get<any>(`${this.baseApiUrl}${folderPath}/videos`, { headers, params });

    return lastValueFrom(response.pipe(take(1)));
  }
}
