import { Observable, of } from 'rxjs';

export class Tab {
  id: number;
  text: string;
  template?: string;
  disabled?: boolean = false;
  visible?: boolean = true;
  isPermitted?: Observable<boolean> = of(true);
}
