import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { Entity, EntityPermissionActivityKeys, EntityPermissionModelKeys, PhoneNumber } from 'ag-common-lib/public-api';
import { OfficePhoneNumberService } from '../office-phone-number/office-phone-number.service';

@Component({
  selector: 'ag-shr-agency-office-phone-numbers',
  templateUrl: './office-phone-number.component.html',
  styleUrls: ['./office-phone-number.component.scss'],
})
export class OfficePhoneNumberComponent {
  @ViewChild('officePhoneNumbersModalRef', { static: true }) officePhoneNumbersModalComponent: ModalWindowComponent;
  @Input() agencyId: string;
  @Input() phoneNumbers: PhoneNumber[] = [];
  @Output() phoneNumbersChange = new EventEmitter<PhoneNumber[]>();

  inProgress$ = this.officePhoneNumberService.inProgress$;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(private officePhoneNumberService: OfficePhoneNumberService) {}

  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
