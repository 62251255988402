import { BaseModel } from '@ag-common-lib/public-api';
import { DynamicListsGroup } from '@ag-common-lib/lib';

export enum AddAgentToDynamicListKeys {
  dynamicLists = 'dynamicLists',
}

export class AddAgentToDynamicListFormData  extends BaseModel {
  [AddAgentToDynamicListKeys.dynamicLists]: DynamicListsGroup[] = [];
}
