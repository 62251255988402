import { NgModule } from '@angular/core';
import { AgencyMediaEditorService } from './agency-media-editor.service';
import { AgencyMediaComponent } from './agency-media.component';
import { ButtonWithIndicatorModule } from '../../../button-with-indicator/button-with-indicator.module';
import { DxMenuModule } from 'devextreme-angular';
import { AgMediaUploaderModule } from '../../../ag-media-uploader/ag-media-uploader.module';
import { AgMediaGalleryModule } from '../../../ag-media-gallery/ag-media-gallery.module';
import { SharedModule } from '../../../../../shared/shared.module';

@NgModule({
  imports: [SharedModule, ButtonWithIndicatorModule, DxMenuModule, AgMediaUploaderModule, AgMediaGalleryModule],
  declarations: [AgencyMediaComponent],
  exports: [AgencyMediaComponent],
  providers: [AgencyMediaEditorService],
})
export class AgencyMediaModule {}
