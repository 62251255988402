import { Injectable } from '@angular/core';
import { AgentCampaignWizardService } from '../agent-campaign-wizard.service';
import { AgentCampaignKeys, AgentCampaignStepName, CampaignAppointment } from '@ag-common-lib/public-api';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { BaseFormService } from '../../../utils/base-form-service';

@Injectable()
export class AgentCampaignAppointmentService extends BaseFormService<CampaignAppointment> {
  formData$ = new BehaviorSubject<CampaignAppointment>(this.formData);

  draftAppointment$: Observable<CampaignAppointment>;

  constructor(private agentCampaignWizardService: AgentCampaignWizardService) {
    super();
    this.draftAppointment$ = this.agentCampaignWizardService.draftCampaignData$.pipe(
      map(draftCampaignData => draftCampaignData?.[AgentCampaignStepName.appointment]),
    );

    const campaignAppointment$ = this.agentCampaignWizardService.campaign$.pipe(
      map(campaign => {
        const appointment = campaign?.[AgentCampaignKeys.data]?.[AgentCampaignStepName.appointment];
        const draftAppointment = campaign?.[AgentCampaignKeys.draftData]?.[AgentCampaignStepName.appointment];

        return Object.assign({}, appointment, draftAppointment);
      }),
    );

    campaignAppointment$.subscribe(this.getFormData);
  }

  handleNextStepClick = async () => {
    const changes = this.formChangesDetector.getAllChanges();

    if (!changes?.length) {
      this.agentCampaignWizardService.nextStep();
      return;
    }

    const updates = {};

    Object.assign(updates, this.formData);
    this.startProgress();
    await this.agentCampaignWizardService
      .handleStepChanges({
        [AgentCampaignStepName.appointment]: updates,
      })
      .then(() => {
        this.formChangesDetector.clear();
        this.agentCampaignWizardService.nextStep();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  private getFormData = (appointment?: CampaignAppointment) => {
    const initialData = Object.assign({ appointmentTool: 'calendly' }, appointment);
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue || prop !== 'appointmentTool') {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);

          switch (prop) {
            case '':
          }
        }

        return true;
      },
    });

    this.formData$.next(this.formData);
  };
}
