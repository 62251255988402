import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { BaseModelKeys, EmailAddress, EmailAddressKeys, Messages, ScreenLimits } from '@ag-common-lib/public-api';
import { DxListComponent } from 'devextreme-angular';
import { InitializedEvent, SelectionChangedEvent } from 'devextreme/ui/list';
import { SelectEmailAddressService } from './select-email-address.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ag-shr-select-email-address',
  templateUrl: './select-email-address.component.html',
  styleUrls: ['./select-email-address.component.scss'],
  providers: [SelectEmailAddressService],
})
export class SelectEmailAddressComponent {
  @HostBinding('class') protected className = 'select-email-address';
  @ViewChild('listRef') listComponent: DxListComponent;

  @Input() set emailAddress(data: Partial<EmailAddress>) {
    this.selectEmailAddressService.setFormData(data);
  }
  @Input() set emailAddresses(data: Partial<EmailAddress[]>) {
    this.selectEmailAddressService.setEmailAddresses(data);
  }
  @Input() isReadonly: boolean = false;
  @Input() label: string;
  @Input() pathPrefix: string;
  @Input() validationGroup: string;
  @Input() isRequired = true;
  @Input() colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 6,
    lg: 12,
  };
  @Input() screenLimits: ScreenLimits;

  protected selectedEmailAddressesKeys: string[];

  protected formData$ = this.selectEmailAddressService.formData$;
  protected emailAddressesDataSource$ = this.selectEmailAddressService.agentEmailAddressesDataSource$;

  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly Messages = Messages;
  readonly EmailAddressKeys = EmailAddressKeys;

  protected formSize$ = new BehaviorSubject(null);

  constructor(private selectEmailAddressService: SelectEmailAddressService) {
    this.selectEmailAddressService.selectedEmailAddressesKeys$.subscribe(selectedEmailAddressesKeys => {
      this.listComponent?.instance?.off('selectionChanged');
      this.selectedEmailAddressesKeys = selectedEmailAddressesKeys;

      setTimeout(() => {
        this.listComponent?.instance?.on('selectionChanged', this.onSelectionChanged);
      }, 0);
    });
  }

  protected emailAddressKeyExpr = (emailAddress: EmailAddress) => {
    return emailAddress?.[BaseModelKeys.dbId] ?? null;
  };

  protected onInitialized = (e: InitializedEvent) => {
    if (this.onSelectionChanged) {
      e.component.instance().on('selectionChanged', this.onSelectionChanged);
    }
  };

  protected onFormInitialized = e => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === e.element) {
          this.formSize$.next(entry.contentRect.width);
          break;
        }
      }
    });

    resizeObserver.observe(e.element);
  };

  protected onSelectionChanged = (e: SelectionChangedEvent) => {
    const emailAddress = e.addedItems[0];

    this.selectEmailAddressService.setAddress(emailAddress);
  };
}
