import { AGENCY_TYPE } from '../../lists';
import { Agent } from './agent.model';
import { BaseModel } from '../base.model';
import { PhoneNumber } from '../utils/phone-number.model';
import { OfficeEmailAddress } from '../utils/email-address.model';
import { Address } from '../utils/address.model';
import { Website } from '../utils/website.model';
import { Social } from '../utils/social.model';
import { AgencyCarrier } from '../utils/agency-carrier.model';
import { StateLicense } from '../utils/state-license.model';
import { AGENCY_STATUS_ACTIVITY } from '../utils/agency-status-activity.model';
import { AgencyRelationship } from '../utils/agency-relationship.model';
import { LookupId } from '../../lists/lookups.enum';
import { Headshot } from '../utils/headshot.model';

export const AGENCIES_COLLECTION_NAME = 'agencies';

export enum AgencyDocumentCollections {
  approveDenyReason = 'approve-deny-reason',
  media = 'media',
  fileDirectories = 'fileDirectories',
}

export enum AgencyKeys {
  agencyId = 'agency_id',
  agencyNumber = 'agency_number',
  name = 'name',
  agentList = 'agentList',
  rmds = 'rmds',
  levelDivisor = 'level_divisor',
  startingMultiplier = 'starting_multiplier',
  agencyType = 'agency_type',
  mga = 'mga',
  contactFirstName = 'contact_first_name',
  contactLastName = 'contact_last_name',
  contactEmail = 'contact_email',
  contactPhone = 'contact_phone',
  agencyLead = 'agency_lead',
  //
  companyLogoLink = 'companyLogoLink',
  officePhoneNumbers = 'officePhoneNumbers',
  officeEmailAddress = 'officeEmailAddress',
  addresses = 'addresses',
  websites = 'websites',
  socials = 'socials',
  carriers = 'carriers',
  stateLicenses = 'stateLicenses',
  statusActivity = 'statusActivity',
  relationshipWithAG = 'relationshipWithAG',
  agencyStatus = 'agencyStatus',
  headshot = 'head_shot',
}

export class Agency extends BaseModel {
  [AgencyKeys.agencyId]?: string = '';
  [AgencyKeys.agencyNumber]?: string = '';
  [AgencyKeys.name]?: string = '';
  [AgencyKeys.agentList]?: Agent[] = [];
  [AgencyKeys.rmds]?: string[] = [];
  [AgencyKeys.levelDivisor]?: number = 0;
  [AgencyKeys.startingMultiplier]?: number = 0;
  [AgencyKeys.agencyType]?: AGENCY_TYPE = AGENCY_TYPE.AGENCY;
  [AgencyKeys.mga]?: string = '';
  [AgencyKeys.contactFirstName]?: string = '';
  [AgencyKeys.contactLastName]?: string = '';
  [AgencyKeys.contactEmail]?: string = ''; // replaced with officeEmailAddress
  [AgencyKeys.contactPhone]?: string = ''; // replaced with officePhoneNumbers
  [AgencyKeys.statusActivity]?: AGENCY_STATUS_ACTIVITY; // not used now (for future implementation)
  // TODO: clarify and make migration to the agent id field
  [AgencyKeys.agencyLead]?: string = '';

  [AgencyKeys.companyLogoLink]?: string = '';
  [AgencyKeys.officePhoneNumbers]?: PhoneNumber[] = [];
  [AgencyKeys.officeEmailAddress]?: OfficeEmailAddress[] = [];
  [AgencyKeys.addresses]?: Address[] = [];
  [AgencyKeys.websites]?: Website[] = [];
  [AgencyKeys.socials]?: Social[] = [];
  [AgencyKeys.carriers]?: AgencyCarrier[] = [];
  [AgencyKeys.stateLicenses]?: StateLicense[] = [];
  [AgencyKeys.relationshipWithAG]?: AgencyRelationship;
  [AgencyKeys.agencyStatus]?: LookupId<'AgencyStatuses'>;
  [AgencyKeys.headshot]?: Headshot;
}
