import { AgentReviewLevel, AgentReviewLevelDescriptionMap } from './agent-review-level.enum';

export const AGENT_REVIEW_LEVEL_LOOKUP = [
  {
    value: AgentReviewLevel.AllianceGroupLevel,
    description: AgentReviewLevelDescriptionMap.get(AgentReviewLevel.AllianceGroupLevel),
  },
  {
    value: AgentReviewLevel.InternalLevel,
    description: AgentReviewLevelDescriptionMap.get(AgentReviewLevel.InternalLevel),
  },
  {
    value: AgentReviewLevel.ExternalLevel,
    description: AgentReviewLevelDescriptionMap.get(AgentReviewLevel.ExternalLevel),
  },
];
