import { Inject, Injectable } from '@angular/core';
import { BaseModelKeys, Registrant, RegistrantKeys, RegistrantModelKeys } from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';
import { FIREBASE_APP } from '../../injections/firebase-app';
import { CommonFireStoreDao, QueryParam, WhereFilterOperandKeys } from '../../../public-api';
import { map, Observable } from 'rxjs';
import { CONFERENCES_COLLECTION_PATH } from '../conference.service';
import { ToastrService } from 'ngx-toastr';
import { InviteeStatus } from '../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/registration/registrants.model';
import { conferenceRegistrantFromFirestore } from './conference-registrants-utils';

export const CONFERENCE_REGISTRANTS_COLLECTION_PATH = 'registrants';

@Injectable({
  providedIn: 'root',
})
export class ConferenceRegistrantsService {
  public readonly fsDao: CommonFireStoreDao<Registrant>;
  private readonly conferenceCollectionPath = CONFERENCES_COLLECTION_PATH;
  private readonly registrantsCollectionPath = CONFERENCE_REGISTRANTS_COLLECTION_PATH;

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp, private toastrService: ToastrService) {
    this.fsDao = new CommonFireStoreDao<Registrant>(fireBaseApp, ConferenceRegistrantsService.fromFirestore, null);
  }

  static readonly fromFirestore = conferenceRegistrantFromFirestore;

  getDocumentData(conferenceDbId: string, registrantDbId: string): Observable<Registrant> {
    const table = this.getCollectionPath(conferenceDbId);

    return this.fsDao.getDocument(table, registrantDbId).pipe(
      map(snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          return data;
        }
        return null;
      }),
    );
  }

  public getList(agentId: string, qp: QueryParam[] = []) {
    const table = this.getCollectionPath(agentId);

    return this.getCollectionGroupSnapshot(table, qp);
  }

  async create(conferenceDbId: string, agentDbId: string, data: Registrant, silent = false) {
    const table = this.getCollectionPath(conferenceDbId);

    Object.assign(data, { [RegistrantModelKeys.conferenceDbId]: conferenceDbId });

    const registrant = await this.fsDao.createWithId(data, agentDbId, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success('Conference Registration Successfully Created!');

    return registrant;
  }

  async update(conferenceDbId: string, documentId: any, updates: Partial<Registrant>, silent = false) {
    const table = this.getCollectionPath(conferenceDbId);
    const registrant = await this.fsDao.updateFields(updates, documentId, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success('Conference Registration Successfully Updated!');

    return registrant;
  }

  async delete(conferenceDbId: string, documentId: any, silent = false) {
    const table = this.getCollectionPath(conferenceDbId);
    // TODO delete guests sub-collection
    await this.fsDao.delete(documentId, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success('Conference Registration Successfully Updated!');
  }

  getRegistrationsByConferencesIdsAndRegistrantDbId(
    conferenceDbIds: string[],
    registrantDbId: string,
    ignoreSkipped = false,
  ) {
    const queries: QueryParam[] = [];
    conferenceDbIds?.length &&
      queries.push(new QueryParam(RegistrantModelKeys.conferenceDbId, WhereFilterOperandKeys.in, conferenceDbIds));
    queries.push(new QueryParam(BaseModelKeys.dbId, WhereFilterOperandKeys.equal, registrantDbId));

    return this.getCollectionGroupSnapshot(this.registrantsCollectionPath, queries).pipe(
      map(registrants =>
        registrants?.filter(registrant => {
          const data = registrant[RegistrantModelKeys.data];

          if (!ignoreSkipped) {
            return true;
          }
          return !(data?.[RegistrantKeys.inviteeStatus] === InviteeStatus.declined);
        }),
      ),
    );
  }

  getRegistrationsByAgentDbId(agentDbId: string, sortField?: string): Observable<Registrant[]> {
    const queries: QueryParam[] = [];
    queries.push(new QueryParam(BaseModelKeys.dbId, WhereFilterOperandKeys.equal, agentDbId));

    return this.getCollectionGroupSnapshot(this.registrantsCollectionPath, queries);
  }

  getRegistrantsByConferenceId(conferenceId: string, sortField?: string, qp: QueryParam[] = []): Observable<Registrant[]> {
    qp.push(new QueryParam(RegistrantKeys.conferenceDbId, WhereFilterOperandKeys.equal, conferenceId));

    const table = this.getCollectionPath(conferenceId);

    return this.fsDao.getList(table, qp, { sortField });
  }

  getRegistrant(conferenceDbId: string, registrantId: string): Observable<Registrant[]> {
    let qp: QueryParam[] = [];
    qp.push(new QueryParam(RegistrantKeys.conferenceDbId, WhereFilterOperandKeys.equal, conferenceDbId));
    qp.push(new QueryParam(BaseModelKeys.dbId, WhereFilterOperandKeys.equal, registrantId));

    return this.getCollectionGroupSnapshot(this.registrantsCollectionPath, qp);
  }

  private getCollectionPath(conferenceId: string): string {
    return [this.conferenceCollectionPath, conferenceId, this.registrantsCollectionPath].join('/');
  }

  getCollectionGroupSnapshot(table, queries: QueryParam[] = []) {
    return this.fsDao.getCollectionGroupSnapshot(table, queries, true).pipe(
      map(snapshot =>
        snapshot.docs?.map(doc => {
          if (!doc.exists()) {
            return null;
          }

          const data = doc.data();

          return data;
        }),
      ),
    );
  }
}
