import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, startWith } from 'rxjs';
import {
  AgencyFileDirectory,
  AgencyFileDirectoryKeys,
} from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/agency-file-directory.model';
import {
  AgencyMedia,
  AgencyMediaKeys,
} from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/agency-media.model';
import { ToastrService } from 'ngx-toastr';
import { AgencyFilesMediaService } from '../../../../services/agency-files-media.service';
import { shareReplay, switchMap } from 'rxjs/operators';
import { BaseModelKeys } from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/base.model';
import { AgencyFileDirectoriesService } from '../../../../services/agency-file-directories.service';
import { AGMedia } from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/ag-media.model';

@Injectable()
export class AgencyMediaEditorService {
  private _agencyId$ = new BehaviorSubject<string>(null);
  agencyId$ = this._agencyId$.asObservable();

  private _currentDirectory$ = new BehaviorSubject<AgencyFileDirectory>(null);
  currentDirectory$ = this._currentDirectory$.asObservable();

  currentMediaList$: Observable<AgencyMedia[]>;

  constructor(
    private toastrService: ToastrService,
    private agencyFilesMediaService: AgencyFilesMediaService,
    private agencyFileDirectoriesService: AgencyFileDirectoriesService,
  ) {
    this.currentMediaList$ = combineLatest([this.agencyId$, this.currentDirectory$]).pipe(
      startWith([]),
      switchMap(([agencyId, currentDirectory]) => {
        return !!currentDirectory
          ? this.agencyFilesMediaService.getList(agencyId, currentDirectory[BaseModelKeys.dbId])
          : [];
      }),
      shareReplay(1),
    );
  }

  createMedia(agencyId: string, media: AGMedia): Promise<boolean> {
    return this.agencyFilesMediaService
      .create(
        agencyId,
        this._currentDirectory$.value[BaseModelKeys.dbId],
        Object.assign(media, { [AgencyMediaKeys.agencyDbId]: agencyId }) as AgencyMedia,
        true,
      )
      .then(() => false)
      .catch(err => {
        this.toastrService.error('Media is not created!');
        throw err;
      })
      .finally(() => {});
  }

  deleteMedia(agencyId: string, media: AGMedia): Promise<boolean> {
    return this.agencyFilesMediaService
      .delete(agencyId, this._currentDirectory$.value[BaseModelKeys.dbId], media[BaseModelKeys.dbId])
      .then(() => false)
      .catch(err => {
        this.toastrService.error('Media is not deleted!');
        throw err;
      })
      .finally(() => {});
  }

  createFolder(directory: AgencyFileDirectory): Promise<boolean> {
    const agencyId = directory[AgencyFileDirectoryKeys.agencyDbId];
    return this.agencyFileDirectoriesService
      .create(
        agencyId,
        Object.assign(directory, { [AgencyFileDirectoryKeys.agencyDbId]: agencyId }) as AgencyFileDirectory,
      )
      .then(() => false)
      .catch(err => {
        this.toastrService.error('Folder is not created!');
        throw err;
      })
      .finally(() => {});
  }

  deleteFolder(agencyId: string, documentId: string): Promise<boolean> {
    return this.agencyFileDirectoriesService
      .delete(agencyId, documentId)
      .then(() => false)
      .catch(err => {
        this.toastrService.error('Folder is not removed!');
        throw err;
      })
      .finally(() => {});
  }

  setAgencyId(id: string): void {
    this._agencyId$.next(id);
  }

  setCurrentDirectory(directory: AgencyFileDirectory): void {
    this._currentDirectory$.next(directory);
  }
}
