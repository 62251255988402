<ag-shr-modal-window #approveDenyReasonModalRef [title]="'Agent Status Activity'" [showSaveButton]="false">
  <ag-shr-approve-deny-reasons-grid [agentId]="agentId$ | async" ></ag-shr-approve-deny-reasons-grid>
</ag-shr-modal-window>

<ag-shr-approve-deny-reasons-grid
  class="approve-deny-reasons__data-grid"
  [title]="'Agent Status Activity'"
  [agentId]="agentId$ | async"
  [isEditable]="false"
  [extraToolbarItems]="[
    {
      widget: 'dxButton',
      location: 'after',
      visible: canEdit,
      options: {
        icon: 'edit',
        stylingMode: 'text',
        onClick: showListPopup
      }
    }
  ]"
></ag-shr-approve-deny-reasons-grid>
