<div class="mt-2">
  <ag-shr-editor-toolbar
    [title]="'Agency Status Activity'"
    (clickEdit)="agencyStatusActivityModalComponent.showModal()"
    [isEditVisible]="Entity.agencyAgencyInfoStatusActivity | hasPermission : EntityPermissionActivityKeys.write | async"
  ></ag-shr-editor-toolbar>

  <ag-shr-status-activity-grid
    class="editor__data-grid"
    [agencyId]="agencyId$ | async"
    [isEditable]="false"
  ></ag-shr-status-activity-grid>
</div>

<ag-shr-modal-window
  #agencyStatusActivityModalRef
  [title]="'Add / Edit Agency Status Activity'"
  [showSaveButton]="false"
  [width]="'75%'"
  [height]="'auto'"
  [inProgress]="inProgress$ | async"
>
  <ag-shr-status-activity-grid [agencyId]="agencyId$ | async"></ag-shr-status-activity-grid>
</ag-shr-modal-window>
