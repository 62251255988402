import { Injectable } from '@angular/core';
import { AgentCampaignWizardService } from '../agent-campaign-wizard.service';
import {
  Agent,
  AgentCampaignKeys,
  AgentCampaignStepName,
  AgentKeys,
  CampaignSocialMedia,
  Social,
} from '@ag-common-lib/public-api';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { BaseFormService } from '../../../utils/base-form-service';

@Injectable()
export class AgentCampaignSocialMediaService extends BaseFormService<Partial<CampaignSocialMedia>> {
  formData$ = new BehaviorSubject<CampaignSocialMedia>(this.formData);

  agentSocialsDataSource$: Observable<Social[]>;

  constructor(private agentCampaignWizardService: AgentCampaignWizardService) {
    super();

    this.agentSocialsDataSource$ = agentCampaignWizardService.agent$.pipe(
      map((agent: Agent) => agent?.[AgentKeys.socials] ?? []),
    );

    const campaignContactInfoSocial$ = this.agentCampaignWizardService.campaign$.pipe(
      map(campaign => {
        const socialMedia = campaign?.[AgentCampaignKeys.data]?.[AgentCampaignStepName.socialMedia];
        const draftSocial = campaign?.[AgentCampaignKeys.draftData]?.[AgentCampaignStepName.socialMedia];

        return Object.assign({}, socialMedia, draftSocial);
      }),
    );

    campaignContactInfoSocial$.subscribe(this.getFormData);
  }

  handleNextStepClick = async () => {
    const changes = this.formChangesDetector.getAllChanges();

    if (!changes?.length) {
      this.agentCampaignWizardService.nextStep();
      return;
    }

    const updates = {};

    changes.forEach(([key]) => {
      Object.assign(updates, { [key]: this.formData[key] });
    });

    this.startProgress();
    await this.agentCampaignWizardService
      .handleStepChanges({
        [AgentCampaignStepName.socialMedia]: updates,
      })
      .then(() => {
        this.formChangesDetector.clear();
        this.agentCampaignWizardService.nextStep();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  public handleCustomItemCreating = e => {
    const url = e?.text;

    if (!url) {
      Object.assign(e, { customItem: null });
      return;
    }

    const customItem = { url, visible: false };

    Object.assign(e, { customItem });
  };

  private getFormData = (campaignSocialMedia?: Partial<CampaignSocialMedia>) => {
    const initialData = Object.assign({}, campaignSocialMedia);
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);

          switch (prop) {
            case '':
          }
        }

        return true;
      },
    });

    this.formData$.next(this.formData);
  };
}
