<div class="agent-campaign-wizard__content">
  <ng-content></ng-content>
  <dx-form
    #bioFormRef
    [formData]="bioFormData"
    labelMode="floating"
    [readOnly]="!canEdit"
  >
    <dxi-item
      [dataField]="'title'"
      [label]="{ text: 'Title' }"
    ></dxi-item>
  </dx-form>

  <dx-text-area
    [name]="'aboutUs'"
    [label]="'About Us'"
    labelMode="floating"
    class="agent-campaign-bio__text-area"
    [(value)]="bioFormData && bioFormData['aboutUs']"
    [readOnly]="!canEdit"
  ></dx-text-area>
</div>

<div class="agent-campaign-wizard__controls-container" *ngIf="canEdit">
  <dx-button
    class="modal-window__toolbar-button"
    text="CANCEL CHANGES"
    [disabled]="!(hasFormChanges$ | async)"
    (onClick)="resetChanges()"
  ></dx-button>

  <shr-button-with-indicator
    [title]="hasStepIssues ? 'RESOLVE ISSUE' :'NEXT'"
    [disabled]="hasStepIssues && !(hasFormChanges$ | async)"
    [cssClass]="'modal-window__toolbar-button'"
    [isInProgress]="inProgress$ | async"
    (onClick)="nextStep()"
  ></shr-button-with-indicator>
</div>
