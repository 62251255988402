import { Component, HostBinding, Input } from '@angular/core';
import {
  PhoneNumber,
  PhoneNumberKeys,
  Constants,
  Messages,
  Patterns,
  PHONE_NUMBER_TYPE_LOOKUP,
  ScreenLimits,
} from '@ag-common-lib/public-api';
import { ValidationCallbackData } from 'devextreme/common';
import { InitializedEvent } from 'devextreme/ui/form';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ag-shr-phone-number-form',
  templateUrl: './phone-number-form.component.html',
  styleUrls: ['./phone-number-form.component.scss'],
})
export class PhoneNumberFormComponent {
  @HostBinding('class') protected className = 'phone-number-form';

  @Input() phoneNumber: Partial<PhoneNumber>;
  @Input() isNumberReadonly: boolean = false;
  @Input() isExtensionReadonly: boolean = false;
  @Input() isTypeReadonly: boolean = false;
  @Input() isWhatsappReadonly: boolean = false;
  @Input() isNumberFieldRequired: boolean = true;
  @Input() pathPrefix: string;
  @Input() validationGroup: string;
  @Input() colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 6,
    lg: 12,
  };
  @Input() screenLimits: ScreenLimits;

  protected readonly totalPhoneDigits: number = Constants.TOTAL_PHONE_DIGITS;
  protected readonly phoneMask: string = Constants.PHONE_MASK;
  protected readonly phonePattern = Patterns.PHONE_NUMBER;
  protected readonly Messages = Messages;
  protected readonly PhoneNumberKeys = PhoneNumberKeys;
  protected readonly PHONE_NUMBER_TYPE_LOOKUP = PHONE_NUMBER_TYPE_LOOKUP;

  protected formSize$ = new BehaviorSubject(null);

  protected numberIsRequiredForPhoneNumber = e => {
    if (!this.phoneNumber) {
      return false;
    }
    const { number, ...rest } = this.phoneNumber;
    const restIsEmpty = Object.values(rest).every(item => !item);

    return restIsEmpty || number;
  };

  protected onFormInitialized = (e: InitializedEvent) => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === e.element) {
          this.formSize$.next(entry.contentRect.width);
          break;
        }
      }
    });

    resizeObserver.observe(e.element);
  };

  protected numberValidationCallback = (e: ValidationCallbackData) => {
    if (e.value) {
      return true;
    }

    return !Object.values(this.phoneNumber ?? {}).some(Boolean);
  };
}
