import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectAddressComponent } from './select-address.component';
import { SharedModule } from '../../../shared/shared.module';
import { IsNewAddressSelectedPipe } from './is-new-address-selected.pipe';
import { AddressFormModule } from '../address-form/address-form.module';
import { DxValidationSummaryModule, DxValidatorModule } from 'devextreme-angular';
import { IsAddressFormReadonlyPipe } from './is-address-form-readonly.pipe';

@NgModule({
  declarations: [SelectAddressComponent, IsAddressFormReadonlyPipe],
  imports: [CommonModule, SharedModule, AddressFormModule, DxValidatorModule, DxValidationSummaryModule],
  exports: [SelectAddressComponent],
})
export class SelectAddressModule {}
