export enum CallOutcomeKeys {
  busy = 'Busy',
  connected = 'Connected',
  leftLiveMessage = 'Left Live Message',
  leftVoiceMessage = 'Left Voicemail',
  noAnswer = 'No Answer',
  notInService = 'Not in Service',
  schedules = 'Scheduled',
  wrongNumber = 'Wrong Number',
}
