<div class="agent-campaign-wizard__content">
  <ng-content></ng-content>

  <dx-scroll-view height="100%" [useNative]="false" [showScrollbar]="'always'">
    <ag-shr-select-phone-number
      [phoneNumber]="phoneNumberFormData$ | async"
      [phoneNumbers]="agentPhoneNumbers$ | async"
      [validationGroup]="validationGroup"
      [isReadonly]="!canEdit"
    ></ag-shr-select-phone-number>
  </dx-scroll-view>
</div>

<ng-container *ngIf="canEdit">
  <div class="agent-campaign-wizard__controls-container" *var="hasFormChanges$ | async as hasFormChanges">
    <dx-button
      class="modal-window__toolbar-button"
      text="CANCEL CHANGES"
      [disabled]="!hasFormChanges"
      (onClick)="resetChanges()"
    ></dx-button>

    <shr-button-with-indicator
      [title]="hasStepIssues ? 'RESOLVE ISSUE' : 'NEXT'"
      [disabled]="hasStepIssues && !hasFormChanges"
      [cssClass]="'modal-window__toolbar-button'"
      [isInProgress]="inProgress$ | async"
      (onClick)="nextStep()"
    ></shr-button-with-indicator>
  </div>
</ng-container>
