import { Inject, Injectable } from '@angular/core';
import { CloudFunctionsService } from '../../services/cloud-functions.service';
import { BehaviorSubject, defer, filter, finalize, firstValueFrom, map, mergeMap, Observable, Subject } from 'rxjs';
import {
  STRIPE_PUBLIC_KEY,
  StripeModalPayload,
  StripeModalUserAction,
  StripeModalUserActionType,
} from './ag-stripe-modal.models';
import { StripeFactoryService, StripeInstance } from 'ngx-stripe';
import { ConfirmPaymentData, PaymentMethodCreateParams, StripeElements } from '@stripe/stripe-js';
import { StripePaymentDetails } from '@ag-common-lib/public-api';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AGStripeService {
  private _showModal$ = new Subject<StripeModalPayload>();
  showModal$: Observable<string> = this._showModal$.pipe(
    map(config => config?.amountToPay),
    filter(Boolean),
    mergeMap(amount => this.getClientSecret(amount)),
    map(response => response?.data?.client_secret),
  );

  userAction$ = new Subject<StripeModalUserAction>();

  readonly stripe: StripeInstance;

  private _isSecretLoading$ = new BehaviorSubject(false);
  isSecretLoading$ = this._isSecretLoading$.asObservable();

  private _inProgress$ = new BehaviorSubject(false);
  inProgress$ = this._inProgress$.asObservable();

  private _amountToPay$ = new BehaviorSubject<number>(null);
  amountToPay$ = this._amountToPay$.asObservable();

  private _billingDetails: PaymentMethodCreateParams.BillingDetails;

  constructor(
    @Inject(STRIPE_PUBLIC_KEY) stripePublicKey: string,
    private cloudFunctions: CloudFunctionsService,
    private factoryService: StripeFactoryService,
    private toastrService: ToastrService,
  ) {
    this.stripe = this.factoryService.create(stripePublicKey);
  }

  showModal = (config: StripeModalPayload): Promise<StripeModalUserAction> => {
    this._amountToPay$.next(config?.amountToPay);
    this._billingDetails = config?.billingDetails;

    this._showModal$.next(config);

    return firstValueFrom(this.userAction$);
  };

  closeModal = async () => {
    if (this._inProgress$.value) {
      return false;
    }

    this.userAction$.next({ type: StripeModalUserActionType.cancel });

    return true;
  };

  async handlePayment(elements: StripeElements) {
    try {
      this._inProgress$.next(true);

      const results = await firstValueFrom(
        this.stripe.confirmPayment({
          elements,
          confirmParams: this.getPaymentDetailsParams(),
          redirect: 'if_required',
        }),
      );

      if (results.error) {
        throw new Error(results.error?.message);
      }

      this.toastrService.success('Payment successful!');

      const paymentIntent = results?.paymentIntent;

      const resultDetails: StripePaymentDetails = {
        id: paymentIntent.id,
        amount: paymentIntent.amount,
        currency: paymentIntent.currency,
        address: paymentIntent.shipping,
        status: paymentIntent.status,
      };

      this.userAction$.next({ type: StripeModalUserActionType.success, payload: resultDetails });
      this._inProgress$.next(false);

      return resultDetails;
    } catch (error) {
      this._inProgress$.next(false);
      this.toastrService.error(error?.message ?? 'Please try again later', 'Payment failed', { disableTimeOut: true });
      throw new Error(error);
    }
  }

  private getClientSecret(amountToPay: number) {
    return defer((): Promise<{ data: any }> => {
      this._isSecretLoading$.next(true);
      const amount = amountToPay * 100;
      return this.cloudFunctions.collectPaymentIntent({ amount });
    }).pipe(
      finalize(() => {
        this._isSecretLoading$.next(false);
      }),
    );
  }

  getPaymentDetailsParams = (): Partial<ConfirmPaymentData> => {
    return {
      payment_method_data: {
        billing_details: Object.assign(
          {},
          {
            name: 'Jane Doe',
          },
          this._billingDetails,
        ),
      },
    };
  };
}
