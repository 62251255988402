import { AgentCampaignStepName } from '../models/utils/agent-campaign-wizard.model';
import { LookupBase } from '../models/utils/lookup-base.model';

export const CAMPAIGNS_MANAGEMENT_ISSUES_FIELD_LOOKUP: LookupBase[] = [
  { value: AgentCampaignStepName.webSite, description: 'Web Site Data' },
  { value: AgentCampaignStepName.contactInfoAddress, description: 'Contact Info Address' },
  { value: AgentCampaignStepName.contactInfoPhoneNumber, description: 'Contact Info Phone Number' },
  { value: AgentCampaignStepName.contactInfoEmailAddress, description: 'Contact Info Email Address' },
  { value: AgentCampaignStepName.bio, description: 'Bio' },
  { value: AgentCampaignStepName.socialMedia, description: 'Social Media' },
  { value: AgentCampaignStepName.logoHeadshot, description: 'Logo Headshot' },
  { value: AgentCampaignStepName.appointment, description: 'Appointment' },
  { value: AgentCampaignStepName.extras, description: 'Extra' },
  { value: AgentCampaignStepName.summary, description: 'Summary' },
];
