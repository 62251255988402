import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { AgEditorToolbarModule } from '../../../ag-editor-toolbar/ag-editor-toolbar.module';
import { OfficePhoneNumberComponent } from './office-phone-number.component';
import { OfficePhoneNumberGridComponent } from './grid/office-phone-number-grid.component';
import { OfficePhoneNumberService } from './office-phone-number.service';

@NgModule({
  imports: [SharedModule, ModalWindowModule, AgEditorToolbarModule],
  declarations: [OfficePhoneNumberComponent, OfficePhoneNumberGridComponent],
  exports: [OfficePhoneNumberComponent, OfficePhoneNumberGridComponent],
  providers: [OfficePhoneNumberService],
})
export class OfficePhoneNumberModule {}
