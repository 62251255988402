import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { DxFormComponent } from 'devextreme-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgentCampaignBioService } from './agent-campaign-bio.service';
import { CampaignBio } from '@ag-common-lib/public-api';

@UntilDestroy()
@Component({
  selector: 'ag-shr-agent-campaign-bio',
  templateUrl: './agent-campaign-bio.component.html',
  styleUrls: ['./agent-campaign-bio.component.scss'],
})
export class AgentCampaignBioComponent {
  @HostBinding('class') className = 'agent-campaign-bio agent-campaign-wizard__content-container';
  @ViewChild('bioFormRef') bioFormComponent: DxFormComponent;

  @Input() hasStepIssues: boolean;
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;

  inProgress$: Observable<boolean>;
  bioFormData: Partial<CampaignBio>;
  hasFormChanges$: Observable<boolean>;

  constructor(private agentCampaignBioService: AgentCampaignBioService) {
    this.inProgress$ = agentCampaignBioService.inProgress$;
    this.hasFormChanges$ = agentCampaignBioService.hasFormChanges$;
    agentCampaignBioService.formData$.pipe(untilDestroyed(this)).subscribe(formData => {
      this.bioFormData = formData;
    });
  }

  setBio = (details): void => {
    Object.assign(this.bioFormData, { details });
  };

  nextStep = () => {
    const validationResults = this.bioFormComponent.instance.validate();

    if (!validationResults.isValid) {
      return;
    }
    this.agentCampaignBioService.handleNextStepClick();
  };

  resetChanges = this.agentCampaignBioService.revertAllChanges;
}
