import { Injectable } from '@angular/core';
import {
  Agency,
  AGENCY_TYPE,
  AgencyKeys,
  AgencyMedia,
  Agent,
  AGENT_TYPE,
  AgentKeys,
  AGMedia,
  AGMediaKeys,
  BaseModelKeys,
  ImageCropperPayload,
  Lookup,
  LookupKeys,
  MediaContentType,
} from '@ag-common-lib/public-api';
import { AgencyService } from '../../../../services/agency.service';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { pick } from 'lodash';
import { BaseFormService } from '../../../../utils/base-form-service';
import { AgencyHeaderKeys } from '../../../../components/agency-editor/components/agency-header/agency-header.model';
import { FireStorageDao } from 'ag-common-svc/public-api';
import { map } from 'rxjs/operators';
import { AgentService } from '../../../../services/agent.service/agent.service';
import { QueryParam, WhereFilterOperandKeys } from '../../../../dao/CommonFireStoreDao.dao';
import { ToastrService } from 'ngx-toastr';
import { DocumentReference, updateDoc } from 'firebase/firestore';
import { AgencyMediaService } from '../../../../services/agency-media.service';

const agencyLeadQueryParam: QueryParam[] = [
  new QueryParam(AgentKeys.agent_type, WhereFilterOperandKeys.notEqual, AGENT_TYPE.GENERAL_AGENT),
];
const rmdQueryParam: QueryParam[] = [new QueryParam(AgentKeys.is_rmd, WhereFilterOperandKeys.equal, true)];

@Injectable()
export class AgencyHeaderService extends BaseFormService<Agency> {
  rmds$ = this.agentService.getList(rmdQueryParam, { sortField: 'name', includeRef: false }).pipe(
    map(agents => agents.map(this.mapAgentByName)),
    map(agents => agents.sort(this.sortAgentByName)),
  );
  mgas$ = from(this.agencyService.getMGAAgencies('name'));
  allAgencyLeads$ = this.agentService.getList(agencyLeadQueryParam, { sortField: 'name', includeRef: false }).pipe(
    map(agents => agents.map(this.mapAgentByName)),
    map(agents => agents.sort(this.sortAgentByName)),
  );
  selectedAgencyStatus$ = new BehaviorSubject<Lookup>(null);

  constructor(
    private readonly agencyService: AgencyService,
    private fireStorageDao: FireStorageDao,
    private agentService: AgentService,
    private toastrService: ToastrService,
    private agencyMediaService: AgencyMediaService,
  ) {
    super();
  }

  getAgencyById = (agencyId: string): Observable<Agency> => {
    return this.agencyService.getDocument(agencyId).pipe(map(doc => doc.data()));
  };

  getFormData = (agency: Partial<Agency>) => {
    const initialData = pick(agency, [
      AgencyKeys.agencyId,
      AgencyKeys.companyLogoLink,
      AgencyKeys.name,
      AgencyKeys.agencyNumber,
      AgencyKeys.agencyType,
      AgencyKeys.agencyStatus,
      AgencyKeys.addresses,
      AgencyKeys.officePhoneNumbers,
      AgencyKeys.officeEmailAddress,
      AgencyKeys.mga,
      AgencyKeys.relationshipWithAG,
      AgencyKeys.contactFirstName,
      AgencyKeys.contactLastName,
      AgencyKeys.agencyLead,
      AgencyKeys.rmds,
      AgencyKeys.startingMultiplier,
      AgencyKeys.levelDivisor,
      AgencyKeys.headshot,
    ]);

    const primaryEmailAddress = agency[AgencyKeys.officeEmailAddress]?.find(email => email?.is_primary);

    let primaryShippingAddress = null;
    agency[AgencyKeys.addresses]?.forEach(address => {
      if (address.is_primary_shipping) {
        primaryShippingAddress = address;
      }
    });

    const primaryPhoneNumber = agency[AgencyKeys.officePhoneNumbers]?.find(phoneNumber => phoneNumber?.is_primary);

    Object.assign(initialData, {
      [AgencyHeaderKeys.primaryShippingAddress]: primaryShippingAddress,
      [AgencyHeaderKeys.primaryPhoneNumber]: primaryPhoneNumber,
      [AgencyHeaderKeys.primaryEmailAddress]: primaryEmailAddress,
    });

    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);

          switch (prop) {
            case AgencyKeys.agencyType:
              const mga = value === AGENCY_TYPE.MGA ? agency.agency_id ?? null : agency.mga;
              Object.assign(this.formData, { [AgencyKeys.mga]: mga });
              break;
          }
        }
        return true;
      },
    });

    return this.formData;
  };

  successfulCreatedForm(): void {
    this.onSuccessfulCreated();
  }
  updateFieldsData = (values: Partial<Agency>): void => {
    this.onSuccessfulUpdated(Object.keys(values));
  };

  handleSave = async agencyId => {
    const updates = {};
    const changes = this.formChangesDetector.getAllChanges();
    changes.forEach(([key]) => {
      const update = this.formData[key] ?? null;
      const addresses = updates[AgencyKeys.addresses] ?? this.formData[AgencyKeys.addresses] ?? [];
      const phoneNumbers = updates[AgencyKeys.officePhoneNumbers] ?? this.formData[AgencyKeys.officePhoneNumbers] ?? [];

      switch (key) {
        case AgencyHeaderKeys.primaryPhoneNumber:
          Object.assign(updates, {
            [AgencyKeys.officePhoneNumbers]: phoneNumbers.map(phoneNumber => {
              const isSame = phoneNumber === update;

              Object.assign(phoneNumber, { is_primary: isSame });
              return phoneNumber;
            }),
          });
          return;

        case AgencyHeaderKeys.primaryBillingAddress:
          Object.assign(updates, {
            [AgencyKeys.addresses]: addresses.map(address => {
              const isSame = address === update;
              Object.assign(address, { is_primary_billing: isSame });
              return address;
            }),
          });
          return;

        case AgencyHeaderKeys.primaryShippingAddress:
          Object.assign(updates, {
            [AgencyKeys.addresses]: addresses.map(address => {
              const isSame = address === update;
              Object.assign(address, { is_primary_shipping: isSame });
              return address;
            }),
          });
          return;

        default:
          Object.assign(updates, { [key]: update });
          return;
      }
    });
    this.startProgress();
    const pictureUrl: string = updates[AgencyKeys.companyLogoLink];
    if (pictureUrl && pictureUrl.startsWith('data:image/png;base64')) {
      const filename = this.formData[AgencyKeys.name];
      const file = await fetch(pictureUrl)
        .then(res => res.blob())
        .then(blob => {
          return new File([blob], `${filename}.png`, { type: blob.type });
        });
      const firestoreFile = await this.fireStorageDao.uploadFile(file, '/head_shot/' + filename);

      const url = await this.fireStorageDao.getFileURL(firestoreFile.ref);

      updates[AgencyKeys.companyLogoLink] = url;
      this.formData[AgencyKeys.companyLogoLink] = url;
    }

    await this.agencyService
      .updateFields(agencyId, updates)
      .then(() => {
        const selectedAgencyStatus = this.selectedAgencyStatus$?.value;
        if (selectedAgencyStatus && !selectedAgencyStatus?.isAssigned) {
          updateDoc(selectedAgencyStatus?.reference as DocumentReference<Lookup>, { [LookupKeys.isAssigned]: true });
        }
        this.toastrService.success('Agency Successfully Updated');
        this.formChangesDetector.clear();
        return updates;
      })
      .finally(() => {
        this.stopProgress();
      });
    return { agencyId, updates };
  };

  lockLookup = () => {};

  getSelectedAgencyLeads(agencyId: string): Observable<Agent[]> {
    return this.allAgencyLeads$.pipe(
      map(agents => agents.filter(agent => agencyId && agent[AgentKeys.p_agency_id] == agencyId)),
    );
  }

  setSelectedAgencyStatus = (item): void => {
    this.selectedAgencyStatus$.next(item);
  };

  onImageCropped = async (agencyId: string, imageCropperPayload: ImageCropperPayload) => {
    const headshot = Object.assign({}, this.formData?.[AgencyKeys.headshot], { imageCropperPayload });
    this.startProgress();
    await this.agencyService
      .updateFields(agencyId, { [AgentKeys.headshot]: headshot })

      .finally(() => {
        this.stopProgress();
      });
  };

  onProfileImageChanged = async (agencyId: string, mediaUrl: AGMedia): Promise<void> => {
    if (!mediaUrl?.[BaseModelKeys.dbId]) {
      const media = Object.assign({}, new AgencyMedia(MediaContentType.image, agencyId), {
        [AGMediaKeys.contentType]: mediaUrl?.contentType ?? null,
        [AGMediaKeys.wasabiPath]: mediaUrl?.wasabiPath ?? null,
        [AGMediaKeys.fileName]: mediaUrl?.fileName ?? null,
      });

      const response = await this.agencyMediaService.create(agencyId, media, true);
      Object.assign(mediaUrl, response);
    }

    await this.agencyService
      .updateFields(agencyId, {
        [AgencyKeys.headshot]: { wasabiPath: mediaUrl?.wasabiPath, imageCropperPayload: null },
      })

      .finally(() => {
        this.stopProgress();
      });
  };

  private mapAgentByName = (rmd: Agent) => {
    rmd[AgentKeys.p_agent_name] = rmd[AgentKeys.p_agent_first_name] + ' ' + rmd[AgentKeys.p_agent_last_name];
    return rmd;
  };

  private sortAgentByName = (a: Agent, b: Agent): number => {
    return a[AgentKeys.p_agent_first_name] < b[AgentKeys.p_agent_first_name]
      ? -1
      : a[AgentKeys.p_agent_first_name] > b[AgentKeys.p_agent_first_name]
      ? 1
      : 0;
  };
}
