import { NgModule } from '@angular/core';
import { AgentCampaignAddressInfoComponent } from './agent-campaign-address-info.component';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ButtonWithIndicatorModule } from '../../button-with-indicator/button-with-indicator.module';
import { AgentCampaignAddressInfoService } from './agent-campaign-address-info.service';
import { DxListModule, DxRadioGroupModule } from 'devextreme-angular';
import { SelectAddressModule } from '../../select-address/select-address.module';

@NgModule({
  declarations: [AgentCampaignAddressInfoComponent],
  imports: [SharedModule, ButtonWithIndicatorModule, SelectAddressModule, DxRadioGroupModule, DxListModule],
  exports: [AgentCampaignAddressInfoComponent],
  providers: [AgentCampaignAddressInfoService],
})
export class AgentCampaignAddressInfoModule {}
