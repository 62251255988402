export enum Permission {
  read = 'read',
  write = 'write',
  create = 'create',
  delete = 'delete',
}

// export enum PERMISSION_DESCRIPTION_LOOKUP {
//   Permission.read: 'Read'
// }
