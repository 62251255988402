import { BaseModel } from '../base.model';
import { Address } from '../utils/address.model';
import { EmailAddress } from '../utils/email-address.model';
import { AgStripeSubtotal, SubtotalType } from './stripe-billing.model';
import { LookupKeys } from '../crm/lookup.model';
import { LookupBase } from '../utils/lookup-base.model';
import { PaymentIntent } from '@stripe/stripe-js';

export enum ChargeType {
  buyInRates = 'buyInRates',
  excursions = 'excursions',
  log = 'log',
  refund = 'refund',
}

export enum StripeTransactionRelatesToType {
  conferenceRegistration = 'conferenceRegistration',
}

export const conferenceRegistrationChargeTypeNameMap = new Map<ChargeType, { title }>([
  [ChargeType.buyInRates, { title: 'Buy In Payment' }],
  [ChargeType.excursions, { title: 'Excursions Payment' }],
  [ChargeType.log, { title: 'Log' }],
  [ChargeType.refund, { title: 'Refund' }],
]);

export enum TransactionsStatuses {
  initial = 'initial',
  inProgress = 'inProgress',
  done = 'done',
  declined = 'declined',
  failed = 'failed',
}

export const stripeStatusesToTransactionStatusesMap = new Map<PaymentIntent.Status | 'failed', TransactionsStatuses>([
  ['canceled', TransactionsStatuses.declined],
  ['processing', TransactionsStatuses.inProgress],
  ['requires_action', TransactionsStatuses.inProgress],
  ['requires_capture', TransactionsStatuses.inProgress],
  ['requires_confirmation', TransactionsStatuses.inProgress],
  ['requires_payment_method', TransactionsStatuses.inProgress],
  ['succeeded', TransactionsStatuses.done],
  ['failed', TransactionsStatuses.failed],
]);

export enum TransactionType {
  regular = 'regular',
  manual = 'manual',
  log = 'log',
  refund = 'refund',
}

export const TransactionTypeTypeNameMap = new Map<TransactionType, string>([
  [TransactionType.regular, 'Reg Process '],
  [TransactionType.manual, 'Manual Process'],
  [TransactionType.log, 'Transaction Log'],
  [TransactionType.refund, 'Refund'],
]);

export const TRANSACTION_TYPE_LOOKUP = [
  {
    [LookupKeys.value]: TransactionType.regular,
    [LookupKeys.description]: TransactionTypeTypeNameMap.get(TransactionType.regular),
  },
  {
    [LookupKeys.value]: TransactionType.manual,
    [LookupKeys.description]: TransactionTypeTypeNameMap.get(TransactionType.manual),
  },
  {
    [LookupKeys.value]: TransactionType.log,
    [LookupKeys.description]: TransactionTypeTypeNameMap.get(TransactionType.log),
  },
  {
    [LookupKeys.value]: TransactionType.refund,
    [LookupKeys.description]: TransactionTypeTypeNameMap.get(TransactionType.refund),
  },
];

export const conferenceRegistrationStatusesIconMap = new Map<TransactionsStatuses, { icon }>([
  [TransactionsStatuses.initial, { icon: 'dx-icon-clock' }],
  [TransactionsStatuses.inProgress, { icon: 'dx-icon-clock' }],
  [TransactionsStatuses.done, { icon: 'dx-icon-check' }],
  [TransactionsStatuses.declined, { icon: 'dx-icon-clearcircle' }],
  [TransactionsStatuses.failed, { icon: 'dx-icon-close' }],
]);

export enum StripeTransactionKeys {
  stripeId = 'stripeId',
  type = 'type',
  relatesToDbId = 'relatesToDbId',
  relatesToType = 'relatesToType',
  shippingAddress = 'shippingAddress',
  emailAddress = 'emailAddress',
  billingAddress = 'billingAddress',
  status = 'status',
  paymentDate = 'paymentDate',
  amount = 'amount',
  details = 'details',
  description = 'description',
  isFullRefunded = 'isFullRefunded',
  totalAmountAvailableToRefund = 'totalAmountAvailableToRefund',
  parentStripeId = 'parentStripeId',
}

export class StripeTransaction extends BaseModel {
  [StripeTransactionKeys.stripeId]?: string;
  [StripeTransactionKeys.parentStripeId]?: string;
  [StripeTransactionKeys.relatesToDbId]?: string;
  [StripeTransactionKeys.type]?: TransactionType;
  [StripeTransactionKeys.relatesToType]?: StripeTransactionRelatesToType;
  [StripeTransactionKeys.shippingAddress]?: Address;
  [StripeTransactionKeys.emailAddress]?: EmailAddress;
  [StripeTransactionKeys.billingAddress]?: Address;
  [StripeTransactionKeys.status]?: TransactionsStatuses;
  [StripeTransactionKeys.paymentDate]?: Date;
  [StripeTransactionKeys.isFullRefunded]?: boolean;
  [StripeTransactionKeys.totalAmountAvailableToRefund]?: number;
  private _amount?: number;
  get [StripeTransactionKeys.amount](): number | undefined {
    return this._amount;
  }
  set [StripeTransactionKeys.amount](value: number | undefined) {
    if (value !== undefined && value < 0) {
      throw new Error('Amount cannot be less than 0');
    }
    this._amount = value;
  }
  [StripeTransactionKeys.details]?: AgStripeSubtotal<SubtotalType>[] = [];
  [StripeTransactionKeys.description]?: string;
}

export const TRANSACTIONS_STATUSES_LOOKUP: LookupBase[] = [
  { [LookupKeys.value]: TransactionsStatuses.done, [LookupKeys.description]: 'Done' },
  { [LookupKeys.value]: TransactionsStatuses.declined, [LookupKeys.description]: 'Declined' },
  { [LookupKeys.value]: TransactionsStatuses.initial, [LookupKeys.description]: 'Initial' },
  { [LookupKeys.value]: TransactionsStatuses.inProgress, [LookupKeys.description]: 'In Progress' },
  { [LookupKeys.value]: TransactionsStatuses.failed, [LookupKeys.description]: 'Failed' },
];
