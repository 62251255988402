import { HotelReservationKeys, BedPreference, Aids, HotelBookingKeys } from '@ag-common-lib/public-api';

export const normalizeHotelData = hotel => {
  return Object.assign({}, hotel, {
    [HotelReservationKeys.bedPreference]: hotel?.[HotelReservationKeys.bedPreference] ?? BedPreference.kingBed,
    [HotelReservationKeys.aidsRequired]: hotel?.[HotelReservationKeys.aidsRequired] ?? Aids.no,
    [HotelReservationKeys.additionalRoomRequested]: hotel?.[HotelReservationKeys.additionalRoomRequested] ?? false,
    [HotelReservationKeys.additionalInfo]: hotel?.[HotelReservationKeys.additionalInfo] ?? '',
  });
};
