import {
  EntityItem,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
} from '../models/utils/entity-permission.model';
import { PermissionType } from './permission-type.enum';
import { Entity } from './entity-permission.enum';
import { EntityApplications } from './entity-applications.enum';

export const PermissionEntitiesList: EntityItem[] = [
  // PARENTS:
  //** CRM: **//
  {
    [EntityPermissionModelKeys.id]: Entity.agentCRMParent,
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.title]: 'Agent',
    [EntityPermissionModelKeys.description]: `Open <b>Contacts</b> menu. Select <b>Agents</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyParent,
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.title]: 'Agency',
    [EntityPermissionModelKeys.description]: `Open <b>Contacts</b> menu. Select <b>Agencies</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.carrierCrm,
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.title]: 'Carrier',
    [EntityPermissionModelKeys.description]: `Open <b>Contacts</b> menu. Select <b>Carriers</b> menu item.`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.prospectParent,
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.title]: 'Prospect',
    [EntityPermissionModelKeys.description]: `Open <b>Contacts</b> menu. Select <b>Prospects</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.registrantsParent,
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.title]: 'Registrants',
    [EntityPermissionModelKeys.description]: `Open <b>Contacts</b> menu. Select <b>Registrants</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.registrationsConferencesParent,
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.title]: 'Events',
    [EntityPermissionModelKeys.description]: ``,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignServices,
    [EntityPermissionModelKeys.title]: 'Campaign Services',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Campaigns</b> menu. Select <b>Campaign Services</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignTaskBoardParent,
    [EntityPermissionModelKeys.title]: 'Campaign Task Board',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Campaigns</b> menu. Select <b>Task Board</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignTaskGridParent,
    [EntityPermissionModelKeys.title]: 'Campaign Task Grid',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Campaigns</b> menu. Select <b>Task Grid</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.reportChristmasCardList,
    [EntityPermissionModelKeys.title]: 'Christmas Card List Report',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Reports</b> menu. Select <b>Christmas Card List</b> menu item.`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.write,
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.administrationTaskListManager,
    [EntityPermissionModelKeys.title]: 'Task Lists Administration',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Administration</b> menu. Select <b>Task Lists</b> menu item. Include "Create New Workflow" and "Task Template".`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.administrationImportRulesManager,
    [EntityPermissionModelKeys.title]: 'Import Rules',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Administration</b> menu. Select <b>Import Rules</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.administrationLookupsManager,
    [EntityPermissionModelKeys.title]: 'Lookups Manager',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Administration</b> menu. Select <b>Lookups Manager</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.administrationErrorsManager,
    [EntityPermissionModelKeys.title]: 'Errors Manager',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Administration</b> menu. Select <b>Errors Manager</b> menu item.`,
    [EntityPermissionModelKeys.disabledActivities]: [EntityPermissionActivityKeys.create],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.administrationLogsManager,
    [EntityPermissionModelKeys.title]: 'Logs Manager',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Administration</b> menu. Select <b>Logs Manager</b> menu item.`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.write,
      EntityPermissionActivityKeys.delete,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.administrationReportsManager,
    [EntityPermissionModelKeys.title]: 'Reports Manager',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Administration</b> menu. Select <b>Reports Manager</b> menu item.`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.administrationUserManager,
    [EntityPermissionModelKeys.title]: 'User Manager',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Administration</b> menu. Select <b>User Manager</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.administrationRoleManager,
    [EntityPermissionModelKeys.title]: 'Role Manager',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Administration</b> menu. Select <b>Role Manager</b> menu item. <u>Only Super Admin can have permissions.</u>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.administrationGoogleAuthManager,
    [EntityPermissionModelKeys.title]: 'Google Auth Manager',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Administration</b> menu. Select <b>Google Auth</b> menu item. <u>Only Super Admin can have permissions.</u>`,
    [EntityPermissionModelKeys.disabledActivities]: [EntityPermissionActivityKeys.create],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.administrationAuthStats,
    [EntityPermissionModelKeys.title]: 'Auth Stats',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Administration</b> menu. Select <b>Auth Stats Data</b> menu item.`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.write,
      EntityPermissionActivityKeys.delete,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.administrationMergeTool,
    [EntityPermissionModelKeys.title]: 'Agent Merge Tool',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Administration</b> menu. Select <b>Agent Merge Tool</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.notificationsCRMParent,
    [EntityPermissionModelKeys.title]: 'Notifications',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },
  //** Portal: **//
  {
    [EntityPermissionModelKeys.id]: Entity.agentPortalParent,
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.title]: 'Agent',
    [EntityPermissionModelKeys.description]: `Open personal menu. Select <b>Profile</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignWizardParent,
    [EntityPermissionModelKeys.title]: 'Campaign Wizard',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open personal menu. Select <b>My Campaign</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceWizardParent,
    [EntityPermissionModelKeys.title]: 'Conference Wizard',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open personal menu. Select <b>My Conferences</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.ordersEditor,
    [EntityPermissionModelKeys.title]: 'Orders',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open personal menu. Select <b>My Orders</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.contestsEditor,
    [EntityPermissionModelKeys.title]: 'Contests',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open personal menu. Select <b>My Contests</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.title]: 'Admin Tools',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Admin tools</b> menu`,
  },
  // AG Panel entity lvl 1
  {
    [EntityPermissionModelKeys.id]: Entity.agentAdminister,
    [EntityPermissionModelKeys.parentId]: Entity.agentCRMParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.title]: 'Overall Agent List',
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents`,
    [EntityPermissionModelKeys.disabledActivities]: [EntityPermissionActivityKeys.write],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentSalesGoal,
    [EntityPermissionModelKeys.title]: 'Agent Sales Goal',
    [EntityPermissionModelKeys.parentId]: Entity.agentCRMParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<ul class="mb-0"><li>Open <b>Administration</b> menu. Select <b>Sales Goals</b> menu item.</li><li>Contacts &rarr; Agents &rarr; <i class="dx-icon dx-icon-money"></i> Sales Goal button.</li></ul>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentProspectStatusActivity,
    [EntityPermissionModelKeys.title]: 'Agent Prospect Status Activity',
    [EntityPermissionModelKeys.parentId]: Entity.agentCRMParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; <i class="dx-icon dx-icon-agi-comment-dots"></i> "Prospect Status Activity" button.</li></ul>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentGenerateAgentId,
    [EntityPermissionModelKeys.title]: 'Generate Agent ID',
    [EntityPermissionModelKeys.parentId]: Entity.agentCRMParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; <i class="dx-icon dx-icon-key"></i> "Generate Agent ID" button.</li></ul>`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
    ],
  },
  // Events
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceListAdmin,
    [EntityPermissionModelKeys.title]: 'Event List Admin',
    [EntityPermissionModelKeys.parentId]: Entity.registrationsConferencesParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<div>User with this permission can manipulate with <u>the whole list of events.</u><br>NB: “Update” permission is not applied to the list and is controlled via “Event Details” permissions.</div>`,
    [EntityPermissionModelKeys.disabledActivities]: [EntityPermissionActivityKeys.write],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceList,
    [EntityPermissionModelKeys.title]: 'Event List',
    [EntityPermissionModelKeys.parentId]: Entity.registrationsConferencesParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<div>User with this permission can see/manipulate <u>only those events in the list, where he was assigned to</u>.<br>NB: “Update” permission is not applied to the list and is controlled via “Event Details” permissions.</div>`,
    [EntityPermissionModelKeys.disabledActivities]: [EntityPermissionActivityKeys.write],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceParent,
    [EntityPermissionModelKeys.title]: 'Event Details',
    [EntityPermissionModelKeys.parentId]: Entity.registrationsConferencesParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conference,
    [EntityPermissionModelKeys.title]: 'Overall Event Details',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceRegistrantsParent,
    [EntityPermissionModelKeys.title]: 'Event Registrants',
    [EntityPermissionModelKeys.parentId]: Entity.registrationsConferencesParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceTransactions,
    [EntityPermissionModelKeys.title]: 'Event Transactions',
    [EntityPermissionModelKeys.parentId]: Entity.registrationsConferencesParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceExcursionStatistic,
    [EntityPermissionModelKeys.title]: 'Event Excursion Statistic',
    [EntityPermissionModelKeys.parentId]: Entity.registrationsConferencesParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
      EntityPermissionActivityKeys.write,
    ],
  },
  // REGISTRANTS
  {
    [EntityPermissionModelKeys.id]: Entity.registrants,
    [EntityPermissionModelKeys.parentId]: Entity.registrantsParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.title]: 'Overall Registrants List',
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Registrants Administration.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentDisposition,
    [EntityPermissionModelKeys.title]: 'Agent Disposition',
    [EntityPermissionModelKeys.parentId]: Entity.registrantsParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Registrants &rarr; <i class="dx-icon dx-icon-export"></i> button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.assignOwnerRegistrants,
    [EntityPermissionModelKeys.title]: 'Assign Owner',
    [EntityPermissionModelKeys.parentId]: Entity.registrantsParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Registrants &rarr; "Assign Owner" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.assignAgentIdRegistrants,
    [EntityPermissionModelKeys.title]: 'Assign AgentId',
    [EntityPermissionModelKeys.parentId]: Entity.registrantsParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Registrants &rarr; "Agent ID" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.assignMGA,
    [EntityPermissionModelKeys.title]: 'Assign MGA',
    [EntityPermissionModelKeys.parentId]: Entity.registrantsParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Registrants &rarr; "MGA" button`,
  },
  // PROSPECT
  {
    [EntityPermissionModelKeys.id]: Entity.prospect,
    [EntityPermissionModelKeys.parentId]: Entity.prospectParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.title]: 'Overall Prospect List',
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Prospect Administration.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.prospectStatusActivity,
    [EntityPermissionModelKeys.title]: 'Prospect Status Activity',
    [EntityPermissionModelKeys.parentId]: Entity.prospectParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Prospect &rarr; <i class="dx-icon dx-icon-comment"></i> button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.prospectWrapUp,
    [EntityPermissionModelKeys.title]: 'Prospect Wrap-Up',
    [EntityPermissionModelKeys.parentId]: Entity.prospectParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Prospect &rarr; <i class="dx-icon dx-icon-export"></i> button`,
  },
  // Campaign Task Board
  {
    [EntityPermissionModelKeys.id]: Entity.campaignTaskBoard,
    [EntityPermissionModelKeys.title]: 'Overall Campaign Task Board',
    [EntityPermissionModelKeys.parentId]: Entity.campaignTaskBoardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Campaigns &rarr; Task Board Administration.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.assignOwnerCampaign,
    [EntityPermissionModelKeys.title]: 'Assign Owner',
    [EntityPermissionModelKeys.parentId]: Entity.campaignTaskBoardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Campaigns &rarr; Task Board &rarr; "Assign Owner" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignsInfo,
    [EntityPermissionModelKeys.title]: 'Campaigns Info',
    [EntityPermissionModelKeys.parentId]: Entity.campaignTaskBoardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<ul class="mb-0"><li>Campaigns &rarr; Task Board &rarr; Double click on task &rarr; "Campaigns Info" modal</li><li>Campaigns &rarr; Task Grid &rarr; Click on <i class="dx-icon dx-icon-agi-details"></i> button &rarr; "Campaigns Info" modal</li></ul>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignsTaskIssues,
    [EntityPermissionModelKeys.title]: 'Campaigns Task Issues',
    [EntityPermissionModelKeys.parentId]: Entity.campaignTaskBoardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<ul class="mb-0"><li>Campaigns &rarr; Task Board &rarr; Double click on task &rarr; "Issues" tab</li><li>Campaigns &rarr; Task Grid &rarr; Click on <i class="dx-icon dx-icon-agi-details"></i> button &rarr; "Campaigns Info" modal &rarr; "Issues" tab</li></ul>`,
  },
  // Campaigns Task Grid
  {
    [EntityPermissionModelKeys.id]: Entity.campaignTaskGrid,
    [EntityPermissionModelKeys.title]: 'Overall Campaign Task Grid',
    [EntityPermissionModelKeys.parentId]: Entity.campaignTaskGridParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Campaigns &rarr; Task Grid Administration.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignsTaskLogs,
    [EntityPermissionModelKeys.title]: 'Campaigns Task Logs',
    [EntityPermissionModelKeys.parentId]: Entity.campaignTaskGridParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Campaigns &rarr; Task Grid &rarr; <i class="dx-icon dx-icon-agi-logs"></i> Task Logs" button`,
  },
  // Status Report
  {
    [EntityPermissionModelKeys.id]: Entity.reportStatusReport,
    [EntityPermissionModelKeys.title]: 'Overall Status Report',
    [EntityPermissionModelKeys.parentId]: Entity.reportStatusReportParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Reports</b> menu. Select <b>Status Report</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.statusAppointmentScheduler,
    [EntityPermissionModelKeys.title]: 'Appointment Scheduler',
    [EntityPermissionModelKeys.parentId]: Entity.reportStatusReportParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Reports &rarr; Status Report &rarr; Double click on calendar item.`,
  },
  // Email Report
  {
    [EntityPermissionModelKeys.id]: Entity.reportEmailReport,
    [EntityPermissionModelKeys.title]: 'Overall Email Report',
    [EntityPermissionModelKeys.parentId]: Entity.reportEmailReportParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Reports</b> menu. Select <b>Email Report</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.emailAppointmentScheduler,
    [EntityPermissionModelKeys.title]: 'Appointment Scheduler',
    [EntityPermissionModelKeys.parentId]: Entity.reportEmailReportParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Reports &rarr; Email Report &rarr; Double click on calendar item.`,
  },
  // Agency Details
  {
    [EntityPermissionModelKeys.id]: Entity.agencyAdminister,
    [EntityPermissionModelKeys.parentId]: Entity.agencyParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.title]: 'Overall Agency List',
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.title]: 'Agency Details',
    [EntityPermissionModelKeys.parentId]: Entity.agencyParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click on agency`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyManagement,
    [EntityPermissionModelKeys.title]: 'Overall Agency Details',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click on agency`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
      EntityPermissionActivityKeys.write,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.logAgencyMeeting,
    [EntityPermissionModelKeys.title]: 'Log Meeting',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<ul class="mb-0"><li>Contacts &rarr; Agencies &rarr; Double click on agency &rarr; <i class="dx-icon dx-icon-agi-people-group"></i> button</li><li>Contacts &rarr; Agencies &rarr; Double click on agency &rarr; "Meetings" tab &rarr; "Log Meeting" button</li></ul>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.logAgencyCall,
    [EntityPermissionModelKeys.title]: 'Log Call',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<ul class="mb-0"><li>Contacts &rarr; Agencies &rarr; Double click on agency &rarr; <i class="dx-icon dx-icon-tel"></i> button</li><li>Contacts &rarr; Agencies &rarr; Double click on agency &rarr; "Calls" tab &rarr; "Log Call" button</li></ul>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.logAddAgencyTask,
    [EntityPermissionModelKeys.title]: 'Log Task',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<ul class="mb-0"><li>Contacts &rarr; Agencies &rarr; Double click on agency &rarr; <i class="dx-icon dx-icon-checklist"></i> button</li><li>Contacts &rarr; Agencies &rarr; Double click on agency &rarr; "Tasks" tab &rarr; "Log Task" button </li></ul>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.logAgencyEmail,
    [EntityPermissionModelKeys.title]: 'Log Email',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<ul class="mb-0"><li>Contacts &rarr; Agencies &rarr; Double click on agency &rarr; <i class="dx-icon dx-icon-email"></i> button</li><li>Contacts &rarr; Agencies &rarr; Double click on agency &rarr; "Emails" tab &rarr; "Log Email" button </li></ul>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyEditPicture,
    [EntityPermissionModelKeys.title]: 'Change Picture / Edit Thumbnail',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click on agency &rarr; <i class="dx-icon dx-icon-agi-up-right-and-down-left-from-center"></i> button &rarr; <i class="dx-icon dx-icon-edit"></i> next to the picture &rarr; "Change Picture" or "Edit Thumbnail" buttons`,
    [EntityPermissionModelKeys.disabledActivities]: [EntityPermissionActivityKeys.delete],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyInformation,
    [EntityPermissionModelKeys.title]: 'Agency Information',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click on agency &rarr; <i class="dx-icon dx-icon-agi-up-right-and-down-left-from-center"></i> button &rarr; <i class="dx-icon dx-icon-edit"></i> next to the Agency Name.`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.delete,
      EntityPermissionActivityKeys.create,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyEditPicture,
    [EntityPermissionModelKeys.title]: 'Change Picture / Edit Thumbnail',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click on agency &rarr; <i class="dx-icon dx-icon-agi-up-right-and-down-left-from-center"></i> button &rarr; <i class="dx-icon dx-icon-edit"></i> next to the picture &rarr; "Change Picture" or "Edit Thumbnail" buttons`,
    [EntityPermissionModelKeys.disabledActivities]: [EntityPermissionActivityKeys.delete],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.logAgencyNote,
    [EntityPermissionModelKeys.title]: 'Log Note',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<ul class="mb-0"><li>Contacts &rarr; Agencies &rarr; Double click on agency &rarr; <i class="dx-icon dx-icon-comment"></i> button</li><li>Contacts &rarr; Agencies &rarr; Double click on agency &rarr; "Notes" tab &rarr; "Log Note" button </li></ul>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyMedia,
    [EntityPermissionModelKeys.title]: 'Media',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click on agency &rarr; Media tab`,
  },
  // Agent Details
  {
    [EntityPermissionModelKeys.id]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.title]: 'Agent Details',
    [EntityPermissionModelKeys.parentId]: Entity.agentCRMParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentManagement,
    [EntityPermissionModelKeys.title]: 'Overall Agent Details',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
      EntityPermissionActivityKeys.write,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentContactInfoAddresses,
    [EntityPermissionModelKeys.title]: 'Addresses',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agent &rarr; Double click on email &rarr; "Contact Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Addresses" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentMedia,
    [EntityPermissionModelKeys.title]: 'Media',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Media" tab`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentContactInfoSocials,
    [EntityPermissionModelKeys.title]: 'Socials',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Contact Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Socials" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentContactInfoEmailAddresses,
    [EntityPermissionModelKeys.title]: 'Email Addresses',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agent &rarr; Double click on email &rarr; "Contact Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Email Addresses" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.forceChangeLoginEmail,
    [EntityPermissionModelKeys.title]: 'Force Change Login Email',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Force Change Login Email`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.read,
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentContactInfoPhoneNumbers,
    [EntityPermissionModelKeys.title]: 'Phone Numbers',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Contact Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Phone Numbers" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentContactInfoWebsites,
    [EntityPermissionModelKeys.title]: 'Websites',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Contact Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Websites" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentPersonalInformation,
    [EntityPermissionModelKeys.title]: 'Personal Information',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Personal Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Personal Information" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentPersonalInfoAssociations,
    [EntityPermissionModelKeys.title]: 'Associations',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Personal Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Associations" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentPersonalInfoDietaryConsiderations,
    [EntityPermissionModelKeys.title]: 'Dietary Considerations',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Personal Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Dietary Considerations" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentPersonalInfoSizesForFunStuff,
    [EntityPermissionModelKeys.title]: 'Sizes for Fun Stuff',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Personal Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Sizes for Fun Stuff" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentAgentInfoAgentPortal,
    [EntityPermissionModelKeys.title]: 'Agent Portal',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Agent Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Agent Portal" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentAgentInfoStatusActivity,
    [EntityPermissionModelKeys.title]: 'Agent Status Activity',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Agent Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Agent Status Activity" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentAgentInfoMga,
    [EntityPermissionModelKeys.title]: 'MGA',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Agent Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "MGA" button`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentAgentInfoNeedToKnow,
    [EntityPermissionModelKeys.title]: 'Need To Know',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Agent Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Need To Know" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentAgentInfoDepartmentOfInsuranceInfo,
    [EntityPermissionModelKeys.title]: 'Department Of Insurance Information',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Agent Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Department Of Insurance Information" button`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentAgentInfoStatesLicensedIn,
    [EntityPermissionModelKeys.title]: 'States Licensed In',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Agent Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "States Licensed In" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentAgentInfoCarriers,
    [EntityPermissionModelKeys.title]: 'Agent Carriers',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Agent Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Carriers" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentCampaignWizard,
    [EntityPermissionModelKeys.title]: 'Alliance Campaigns',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Alliance Campaigns" tab`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentPermissions,
    [EntityPermissionModelKeys.title]: 'Permissions',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; "Permissions" tab`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyAgencyRelationshipWithAllianceGroup,
    [EntityPermissionModelKeys.title]: 'Agency Relationship with Alliance Group',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click agency &rarr; "Agency Relationship with Alliance Group" tab`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyAgencyInfoCarriers,
    [EntityPermissionModelKeys.title]: 'Agency Carriers',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click agency &rarr; "Agency Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Agency Carriers" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyAgencyInfoStatusActivity,
    [EntityPermissionModelKeys.title]: 'Agency Status Activity',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click agency &rarr; "Agency Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Agency Status Activity" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyAgencyInfoStatesLicensedIn,
    [EntityPermissionModelKeys.title]: 'States Licensed In',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click agency &rarr; "Agency Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "States Licensed In" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyContactInfoAddresses,
    [EntityPermissionModelKeys.title]: 'Agency Addresses',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click agency &rarr; "Agency Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Agency Addresses" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyContactInfoSocials,
    [EntityPermissionModelKeys.title]: 'Socials',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click agency &rarr; "Agency Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Socials" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyContactInfoOfficeEmailAddresses,
    [EntityPermissionModelKeys.title]: 'Office Email Addresses',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click agency &rarr; "Agency Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Office Email Addresses" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyContactInfoOfficePhoneNumbers,
    [EntityPermissionModelKeys.title]: 'Office Phone Numbers',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click agency &rarr; "Agency Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Office Phone Numbers" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agencyContactInfoWebsites,
    [EntityPermissionModelKeys.title]: 'Websites',
    [EntityPermissionModelKeys.parentId]: Entity.agencyManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agencies &rarr; Double click agency &rarr; "Agency Info" tab &rarr; <i class="dx-icon dx-icon-edit"></i> "Websites" button`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.logAgentNote,
    [EntityPermissionModelKeys.title]: 'Log Note',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<ul class="mb-0"><li>Contacts &rarr; Agents &rarr; Double click on email &rarr; <i class="dx-icon dx-icon-comment"></i> button</li><li>Contacts &rarr; Agent &rarr; Double click on email &rarr; "Notes" tab &rarr; "Log Note" button</li></ul>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.logAgentActivity,
    [EntityPermissionModelKeys.title]: 'Log Activity',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<ul class="mb-0"><li>Contacts &rarr; Agents &rarr; Double click on email &rarr; "Activity" tab &rarr; </li></ul>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.logAgentMeeting,
    [EntityPermissionModelKeys.title]: 'Log Meeting',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<ul class="mb-0"><li>Contacts &rarr; Agents &rarr; Double click on email &rarr; <i class="dx-icon dx-icon-agi-people-group"></i> button</li><li>Contacts &rarr; Agent &rarr; Double click on email &rarr; "Meetings" tab &rarr; "Log Meeting" button</li></ul>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.logAgentCall,
    [EntityPermissionModelKeys.title]: 'Log Call',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<ul class="mb-0"><li>Contacts &rarr; Agents &rarr; Double click on email &rarr; <i class="dx-icon dx-icon-tel"></i> button</li><li>Contacts &rarr; Agents &rarr; Double click on email &rarr; "Calls" tab &rarr; "Log Call" button</li></ul>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.logAddAgentTask,
    [EntityPermissionModelKeys.title]: 'Log Task',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<ul class="mb-0"><li>Contacts &rarr; Agents &rarr; Double click on email &rarr; <i class="dx-icon dx-icon-checklist"></i> button</li><li>Contacts &rarr; Agents &rarr; Double click on email &rarr; "Tasks" tab &rarr; "Log Task" button</li></ul>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.logAgentEmail,
    [EntityPermissionModelKeys.title]: 'Log Email',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<ul class="mb-0"><li>Contacts &rarr; Agents &rarr; Double click on email &rarr; <i class="dx-icon dx-icon-email"></i> button</li><li>Contacts &rarr; Agents &rarr; Double click on email &rarr; "Emails" tab &rarr; "Log Email" button </li></ul>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentEditPicture,
    [EntityPermissionModelKeys.title]: 'Change Picture / Edit Thumbnail',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; <i class="dx-icon dx-icon-agi-up-right-and-down-left-from-center"></i> button &rarr; <i class="dx-icon dx-icon-edit"></i> next to the picture &rarr; "Change Picture" or "Edit Thumbnail" buttons`,
    [EntityPermissionModelKeys.disabledActivities]: [EntityPermissionActivityKeys.delete],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentInformation,
    [EntityPermissionModelKeys.title]: 'Agent Information',
    [EntityPermissionModelKeys.parentId]: Entity.agentManagementParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Contacts &rarr; Agents &rarr; Double click on email &rarr; <i class="dx-icon dx-icon-agi-up-right-and-down-left-from-center"></i> button &rarr; <i class="dx-icon dx-icon-edit"></i> next to the Agent Name.`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.delete,
      EntityPermissionActivityKeys.create,
    ],
  },
  // Agent Portal SubEntities
  {
    [EntityPermissionModelKeys.id]: Entity.agentPortal,
    [EntityPermissionModelKeys.parentId]: Entity.agentPortalParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.title]: 'Overall Agent List',
    [EntityPermissionModelKeys.description]: `Open personal menu. Select <b>Profile</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentPortalInformation,
    [EntityPermissionModelKeys.title]: 'Agent Information',
    [EntityPermissionModelKeys.parentId]: Entity.agentPortalParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; Profile &rarr; <i class="dx-icon dx-icon-edit"></i> button next to the Agent Name`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentPortalSalesGoal,
    [EntityPermissionModelKeys.title]: 'Sales Goals Info',
    [EntityPermissionModelKeys.parentId]: Entity.agentPortalParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; Profile &rarr; <b>Sales Goals Info</b> tab`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentPortalContactInfo,
    [EntityPermissionModelKeys.title]: 'Contact Info',
    [EntityPermissionModelKeys.parentId]: Entity.agentPortalParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; Profile &rarr; <b>Contact Info</b> tab`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentPortalPersonalInfo,
    [EntityPermissionModelKeys.title]: 'Personal Info',
    [EntityPermissionModelKeys.parentId]: Entity.agentPortalParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; Profile &rarr; <b>Personal Info</b> tab`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentPortalPermissions,
    [EntityPermissionModelKeys.title]: 'Permissions',
    [EntityPermissionModelKeys.parentId]: Entity.agentPortalParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; Profile &rarr; <b>Permissions</b> tab`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentPortalMedia,
    [EntityPermissionModelKeys.title]: 'Media',
    [EntityPermissionModelKeys.parentId]: Entity.agentPortalParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; Profile &rarr; <b>Media</b> tab`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.agentPortalEditPicture,
    [EntityPermissionModelKeys.title]: 'Change Picture / Edit Thumbnail',
    [EntityPermissionModelKeys.parentId]: Entity.agentPortalParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; Profile &rarr; <i class="dx-icon dx-icon-agi-up-right-and-down-left-from-center"></i> button &rarr; <i class="dx-icon dx-icon-edit"></i> next to the picture &rarr; "Change Picture" or "Edit Thumbnail" buttons`,
  },
  // Admin Tools Portal SubEntities
  {
    [EntityPermissionModelKeys.id]: Entity.adminToolsMenu,
    [EntityPermissionModelKeys.title]: 'Overall Admin Tools',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open <b>Admin tools</b> menu`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.settingsGoals,
    [EntityPermissionModelKeys.title]: 'Settings Goals',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Admin tools &rarr; Settings &rarr; Goals`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.settingsErrors,
    [EntityPermissionModelKeys.title]: 'Settings Errors',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Admin tools &rarr; Settings &rarr; Errors`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.contentAnnouncements,
    [EntityPermissionModelKeys.title]: 'Content Announcements',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Admin tools &rarr; Content &rarr; Announcements`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.contentCalendar,
    [EntityPermissionModelKeys.title]: 'Content Calendar',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Admin tools &rarr; Content &rarr; Calendar`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.contentCards,
    [EntityPermissionModelKeys.title]: 'Content Card',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Admin tools &rarr; Content &rarr; Cards`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.contentForms,
    [EntityPermissionModelKeys.title]: 'Content Forms',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Admin tools &rarr; Content &rarr; Forms`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.contentMacros,
    [EntityPermissionModelKeys.title]: 'Content Macros',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Admin tools &rarr; Content &rarr; Macros`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.contentMenu,
    [EntityPermissionModelKeys.title]: 'Content Menu',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Admin tools &rarr; Content &rarr; Menu`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.contentPages,
    [EntityPermissionModelKeys.title]: 'Content Pages',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Admin tools &rarr; Content &rarr; Pages`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.contentSplashScreens,
    [EntityPermissionModelKeys.title]: 'Content Splash Screens',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Admin tools &rarr; Content &rarr; Splash Screens`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.contentVideos,
    [EntityPermissionModelKeys.title]: 'Content Videos',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Admin tools &rarr; Content &rarr; Videos`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.storeOrders,
    [EntityPermissionModelKeys.title]: 'Store Orders',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Admin tools &rarr; Store &rarr; Orders`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.storeProducts,
    [EntityPermissionModelKeys.title]: 'Store Products',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Admin tools &rarr; Store &rarr; Products`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.storeCoupons,
    [EntityPermissionModelKeys.title]: 'Store Coupons',
    [EntityPermissionModelKeys.parentId]: Entity.adminToolsMenuParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Admin tools &rarr; Store &rarr; Coupons`,
  },
  /* Conference Wizard Portal SubEntities */
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceWizard,
    [EntityPermissionModelKeys.title]: 'Overall Conference Wizard',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open personal menu. Select <b>My Conferences</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceWizardRSVP,
    [EntityPermissionModelKeys.title]: 'RSVP',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Conferences &rarr; <b>RSVP</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceWizardPersonalInfo,
    [EntityPermissionModelKeys.title]: 'Personal Information',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Conferences &rarr; <b>Personal Information</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceWizardGuestRegistration,
    [EntityPermissionModelKeys.title]: 'Guest Registration',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Conferences &rarr; <b>Guest Registration</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceWizardHotelReservation,
    [EntityPermissionModelKeys.title]: 'Hotel Reservation',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Conferences &rarr; <b>Hotel Reservation</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceWizardFlightInformation,
    [EntityPermissionModelKeys.title]: 'Flight Information',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Conferences &rarr; <b>Flight Information</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceWizardExcursions,
    [EntityPermissionModelKeys.title]: 'Excursions',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Conferences &rarr; <b>Excursions</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceWizardConfirmation,
    [EntityPermissionModelKeys.title]: 'Confirmation',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Conferences &rarr; <b>Confirmation</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceWizardPayment,
    [EntityPermissionModelKeys.title]: 'Payment',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Conferences &rarr; <b>Payment</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceWizardRegistrationSummary,
    [EntityPermissionModelKeys.title]: 'Registration Summary',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Conferences &rarr; <b>Registration Summary</b>`,
  },
  // Campaign Wizard Portal SubEntities
  {
    [EntityPermissionModelKeys.id]: Entity.campaignWizard,
    [EntityPermissionModelKeys.title]: 'Overall Campaign Wizard',
    [EntityPermissionModelKeys.parentId]: Entity.campaignWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Open personal menu. Select <b>My Campaign</b> menu item.`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignWizardPickYourWebsite,
    [EntityPermissionModelKeys.title]: 'Pick Your Website',
    [EntityPermissionModelKeys.parentId]: Entity.campaignWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Campaign &rarr; <b>Pick Your Website</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignWizardAddYourContactInfo,
    [EntityPermissionModelKeys.title]: 'Add Your Contact Info',
    [EntityPermissionModelKeys.parentId]: Entity.campaignWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Campaign &rarr; <b>Add Your Contact Info</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignWizardAddYourBio,
    [EntityPermissionModelKeys.title]: 'Add Your Bio',
    [EntityPermissionModelKeys.parentId]: Entity.campaignWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Campaign &rarr; <b>Add Your Bio</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignWizardAddSocialMedia,
    [EntityPermissionModelKeys.title]: 'Add Social Media',
    [EntityPermissionModelKeys.parentId]: Entity.campaignWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Campaign &rarr; <b>Add Social Media</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignWizardAddYourLogoHeadshot,
    [EntityPermissionModelKeys.title]: 'Add Your Logo / Headshot',
    [EntityPermissionModelKeys.parentId]: Entity.campaignWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Campaign &rarr; <b>Add Your Logo / Headshot</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignWizardAddAppointment,
    [EntityPermissionModelKeys.title]: 'Add Appointment',
    [EntityPermissionModelKeys.parentId]: Entity.campaignWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Campaign &rarr; <b>Add Appointment</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignWizardAddExternalLinks,
    [EntityPermissionModelKeys.title]: 'Add External Links',
    [EntityPermissionModelKeys.parentId]: Entity.campaignWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Campaign &rarr; <b>Add External Links</b>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.campaignWizardSummary,
    [EntityPermissionModelKeys.title]: 'Wizard Summary',
    [EntityPermissionModelKeys.parentId]: Entity.campaignWizardParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `Personal menu &rarr; My Campaign &rarr; <b>Summary</b>`,
  },
  // Notifications
  {
    [EntityPermissionModelKeys.id]: Entity.notificationsCRM,
    [EntityPermissionModelKeys.title]: 'Overall Notifications',
    [EntityPermissionModelKeys.parentId]: Entity.notificationsCRMParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.notificationsTemplates,
    [EntityPermissionModelKeys.title]: 'Notifications Templates',
    [EntityPermissionModelKeys.parentId]: Entity.notificationsCRMParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.notificationsChannels,
    [EntityPermissionModelKeys.title]: 'Notifications Channels',
    [EntityPermissionModelKeys.parentId]: Entity.notificationsCRMParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.notificationsScheduler,
    [EntityPermissionModelKeys.title]: 'Notifications Scheduler',
    [EntityPermissionModelKeys.parentId]: Entity.notificationsCRMParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.notificationsBroadcastDelivery,
    [EntityPermissionModelKeys.title]: 'Notifications Broadcast Delivery',
    [EntityPermissionModelKeys.parentId]: Entity.notificationsCRMParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]:
      'Ability to schedule New, see already Scheduled, edit, and delete Broadcast Notifications',
  },
  {
    [EntityPermissionModelKeys.id]: Entity.dynamicListsAndSegmentsGroups,
    [EntityPermissionModelKeys.title]: 'Lists and Segments',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.dynamicSegmentsGroups,
    [EntityPermissionModelKeys.title]: 'Segments',
    [EntityPermissionModelKeys.parentId]: Entity.dynamicListsAndSegmentsGroups,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.dynamicListGroups,
    [EntityPermissionModelKeys.title]: 'Lists',
    [EntityPermissionModelKeys.parentId]: Entity.dynamicListsAndSegmentsGroups,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },
  // Conferences => Attendee List
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceRegistrants,
    [EntityPermissionModelKeys.title]: 'Attendees List',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceRegistrantsParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<div>User with this permission can see and manipulate with the list of event attendees.<br>Access to manipulation with particular attendee’s data is controlled via “Attendee Details” permission.</div>`,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.eventAttendeesOverallExcelReport,
    [EntityPermissionModelKeys.title]: 'Attendees Overall Excel Report',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceRegistrantsParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<div>Only <u>"Update"</u> permission gives option to generate Excel report with attendees list if enabled. All other permissions don't make any difference.</div>`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.read,
      EntityPermissionActivityKeys.delete,
      EntityPermissionActivityKeys.create,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.eventAttendeesCustomReports,
    [EntityPermissionModelKeys.title]: 'Attendees Custom Reports',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceRegistrantsParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<div>Only <u>"Update"</u> permission gives option to generate custom reports if enabled. All other permissions don't make any difference.</div>`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.read,
      EntityPermissionActivityKeys.delete,
      EntityPermissionActivityKeys.create,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.eventAttendeesMassMailing,
    [EntityPermissionModelKeys.title]: 'Attendees Mass Mailing',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceRegistrantsParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<div>Only <u>"Update"</u> permission gives option to send mass emails to attendees if enabled. All other permissions don't make any difference.</div>`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.read,
      EntityPermissionActivityKeys.delete,
      EntityPermissionActivityKeys.create,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.eventAttendeesSendEmailToQualifier,
    [EntityPermissionModelKeys.title]: 'Send email to qualifier',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceRegistrantsParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: `<div>Only <u>"Update"</u> permission gives option to send event info via email to particular qualifier if enabled. All other permissions don't make any difference.</div>`,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.read,
      EntityPermissionActivityKeys.delete,
      EntityPermissionActivityKeys.create,
    ],
  },
  // Conferences => Attendee Details
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceAttendeeDetails,
    [EntityPermissionModelKeys.title]: 'Attendee Details',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceRegistrantsParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceChangeLogs,
    [EntityPermissionModelKeys.title]: 'Change Logs',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceRegistrantsParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
      EntityPermissionActivityKeys.write,
    ],
  },
  // Events => Add/Edit Event
  {
    [EntityPermissionModelKeys.id]: Entity.conferencePrimaryInfo,
    [EntityPermissionModelKeys.title]: 'Event Primary Info',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceEmailSenderConfiguration,
    [EntityPermissionModelKeys.title]: 'Event Email Sender Configuration',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceWelcomeInfo,
    [EntityPermissionModelKeys.title]: 'Event Welcome Info',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceStepsConfiguration,
    [EntityPermissionModelKeys.title]: 'Event Steps Configuration',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceHotelInfo,
    [EntityPermissionModelKeys.title]: 'Event Hotel Info',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceGuestsInfo,
    [EntityPermissionModelKeys.title]: 'Event Guests Info',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
    ],
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferenceExcursionsInfo,
    [EntityPermissionModelKeys.title]: 'Event Excursions',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },
  {
    [EntityPermissionModelKeys.id]: Entity.conferencePermissionsInfo,
    [EntityPermissionModelKeys.title]: 'Event Permissions',
    [EntityPermissionModelKeys.parentId]: Entity.conferenceParent,
    [EntityPermissionModelKeys.appId]: EntityApplications.crm,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: ``,
  },

  {
    [EntityPermissionModelKeys.id]: Entity.impersonateAdminLevel,
    [EntityPermissionModelKeys.title]: 'Impersonate Admin Level',
    [EntityPermissionModelKeys.parentId]: null,
    [EntityPermissionModelKeys.appId]: EntityApplications.portal,
    [EntityPermissionModelKeys.isEnable]: true,
    [EntityPermissionModelKeys.type]: PermissionType.entity,
    [EntityPermissionModelKeys.description]: 'Allow to see list of all registered agents to impersonate.',
    [EntityPermissionModelKeys.disabledActivities]: [
      EntityPermissionActivityKeys.read,
      EntityPermissionActivityKeys.create,
      EntityPermissionActivityKeys.delete,
    ],
  },
];

export const PermissionEntitiesMap = PermissionEntitiesList.reduce((map, entity) => {
  const entityId = entity?.[EntityPermissionModelKeys.id];
  map.set(entityId, entity);
  return map;
}, new Map());

export const PermissionChildrenEntitiesMap: Map<string, EntityItem[]> = PermissionEntitiesList.reduce((map, entity) => {
  const parentId = entity?.[EntityPermissionModelKeys.parentId] ?? null;
  if (!map?.has(parentId)) {
    map.set(parentId, [entity]);
    return map;
  }
  map.get(parentId).push(entity);
  return map;
}, new Map());
