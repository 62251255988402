import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectPhoneNumberComponent } from './select-phone-number.component';
import { SharedModule } from '../../../shared/shared.module';
import { PhoneNumberFormModule } from '../phone-number-form/phone-number-form.module';
import { IsNewPhoneNumberSelectedPipe } from './is-new-phone-number-selected.pipe';
import { SelectPhoneNumberService } from './select-phone-number.service';

@NgModule({
  declarations: [SelectPhoneNumberComponent, IsNewPhoneNumberSelectedPipe],
  imports: [CommonModule, SharedModule, PhoneNumberFormModule],
  exports: [SelectPhoneNumberComponent],
})
export class SelectPhoneNumberModule {}
