import { LookupId } from '../../lists/lookups.enum';

export enum SocialKeys {
  id = 'id',
  url = 'url',
  socialMedia = 'social_media',
  socialType = 'social_type',
}

export class Social {
  [SocialKeys.id]?: string;
  [SocialKeys.url]?: string;
  [SocialKeys.socialMedia]?: LookupId<'AgencySocialMedia'> | LookupId<'SocialMedia'>;
  [SocialKeys.socialType]?: LookupId<'AgencySocialTypes'> | LookupId<'SocialTypes'>;
}
