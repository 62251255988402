<ng-container *ngIf="selectedExcursionsDataSource?.length">
  <dx-data-grid
    class="excursions-info-table mb-3"
    [dataSource]="selectedExcursionsDataSource"
    [showBorders]="true"
    [renderAsync]="true"
    [columnAutoWidth]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [wordWrapEnabled]="false"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowValidating)="onRowValidating($event)"
    height="100%"
  >
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    <dxo-filter-row [visible]="false"></dxo-filter-row>
    <dxo-header-filter [visible]="false"></dxo-header-filter>
    <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxo-editing
      mode="row"
      [allowUpdating]="canEdit"
      [allowAdding]="false"
      [allowDeleting]="false"
      [useIcons]="true"
    ></dxo-editing>

    <dxo-toolbar>
      <dxi-item location="before" *ngIf="caption">
        <div *dxTemplate>
          <span class="dx-widget dx-form-group-caption">{{ caption }}</span>
        </div>
      </dxi-item>

      <dxi-item location="before">
        <div *dxTemplate>
          <ng-content></ng-content>
        </div>
      </dxi-item>

      <dxi-item location="after" *ngIf="canModify">
        <div *dxTemplate>
          <dx-button text="Modify" (onClick)="goToExcursionSelection.emit()"></dx-button>
        </div>
      </dxi-item>
    </dxo-toolbar>

    <dxi-column
      [dataField]="ExcursionInfoTableDataKeys.excursionDate"
      [caption]="'Date'"
      width="auto"
      [groupIndex]="0"
      [sortIndex]="0"
      [sortOrder]="'asc'"
      groupCellTemplate="groupDateCellTemplate"
      [allowEditing]="false"
      [formItem]="{ visible: false }"
    >
    </dxi-column>

    <dxi-column
      [dataField]="ExcursionInfoTableDataKeys.excursionId"
      [visible]="false"
      [allowEditing]="false"
      [formItem]="{ visible: false }"
    ></dxi-column>

    <dxi-column
      [dataField]="ExcursionInfoTableDataKeys.fullName"
      [caption]="'Attendee'"
      width="auto"
      [allowEditing]="false"
      [formItem]="{ visible: false }"
    ></dxi-column>

    <dxi-column
      [dataField]="ExcursionInfoTableDataKeys.excursionName"
      [caption]="'Excursion'"
      width="auto"
      [minWidth]="180"
      [setCellValue]="setExcursionCellValue"
      editCellTemplate="excursionsNameTemplate"
    >
      <dxi-validation-rule type="required" [message]="requiredText"></dxi-validation-rule>
    </dxi-column>
    <div class="m-2" *dxTemplate="let cellData of 'excursionsNameTemplate'">
      <dx-select-box
        #selectBoxRef
        [items]="
          conferenceExcursions
            | excursionsSource
              : cellData.data[ExcursionInfoTableDataKeys.excursionDate]
              : cellData.data[ExcursionInfoTableDataKeys.attendeeDob]
        "
        [displayExpr]="ExcursionKeys.name"
        [valueExpr]="ExcursionKeys.id"
        [value]="cellData.data.excursionId"
        (onValueChanged)="cellData.setValue(selectBoxRef.selectedItem)"
        label="Excursion Name"
        labelMode="floating"
      >
        <div *dxTemplate="let data of 'item'">
          <div>{{ data?.[ExcursionKeys.name] }}</div>
          <div *ngIf="data?.[ExcursionKeys.id] | excursionSeatsRemain: excursionStatisticsMap as excursionStats">
            <ng-container *var="excursionStats?.[ExcursionStatisticKeys.seatsLeft] as seatsLeft">
              <em
                class="conference-registration-excursions-selection-item__title seats-left"
                *ngIf="seatsLeft <= 3 && seatsLeft > 0"
              >
                {{ seatsLeft }} seats left
              </em>
              <span class="excursions-info-table__sold-out" *ngIf="seatsLeft < 1">Sold Out </span>
            </ng-container>
          </div>
        </div>
      </dx-select-box>
    </div>

    <dxi-column
      [dataField]="ExcursionInfoTableDataKeys.selectedPreferences"
      [calculateCellValue]="calculateSelectedPreferencesCellValue"
      [caption]="'Preferences'"
      width="auto"
      [minWidth]="250"
      cellTemplate="selectedPreferencesCellTemplate"
      editCellTemplate="selectedPreferencesEditCellTemplate"
      [setCellValue]="setExcursionPreferenceCellValue"
    >
    </dxi-column>
    <div *dxTemplate="let cellData of 'selectedPreferencesEditCellTemplate'">
      <div
        *ngFor="
          let preference of cellData.data?.[ExcursionInfoTableDataKeys.excursionId]
            | excursionPreferences: conferenceExcursions
        "
      >
        <div class="m-2" *var="preference?.[ExcursionPreferenceKeys.label] as label">
          <dx-select-box
            #preferenceSelectBox
            [items]="preference?.[ExcursionPreferenceKeys.items]"
            [valueExpr]="ExcursionOptionKey.name"
            [displayExpr]="ExcursionOptionKey.name"
            [value]="cellData?.data?.[ExcursionInfoTableDataKeys.selectedPreferences]?.[label]?.name"
            (onValueChanged)="cellData.setValue([label, preferenceSelectBox.selectedItem])"
            [label]="label"
            labelMode="floating"
            itemTemplate="excursionPreferenceItemTmp"
            fieldTemplate="excursionPreferenceFieldTmp"
          >
            <div *dxTemplate="let data of 'excursionPreferenceFieldTmp'" class="d-flex align-items-center">
              <dx-text-box
                [value]="data && data?.[ExcursionOptionKey.name]"
                [inputAttr]="{ size: data?.length ? data?.length + 2 : 13, 'aria-label': 'Name' }"
                [readOnly]="true"
              ></dx-text-box>

              <span *ngIf="data?.[ExcursionOptionKey.cost] as cost" class="excursions-info-table__price ms-auto me-2">
                {{ cost | usdCurrency }}
              </span>
            </div>

            <div *dxTemplate="let data of 'excursionPreferenceItemTmp'" class="d-flex align-items-center">
              <span>{{ data?.[ExcursionOptionKey.name] }}</span>
              <span *ngIf="data?.[ExcursionOptionKey.cost] as cost" class="excursions-info-table__price ms-auto">
                {{ cost | usdCurrency }}
              </span>
            </div>
          </dx-select-box>
        </div>
      </div>
    </div>

    <dxi-column
      [dataField]="ExcursionInfoTableDataKeys.excursionType"
      [caption]="'Excursion Type'"
      width="auto"
      [visible]="false"
      [allowEditing]="false"
      [formItem]="{ visible: false }"
      [showInColumnChooser]="false"
    ></dxi-column>

    <dxi-column
      [dataField]="ExcursionInfoTableDataKeys.configurations"
      [calculateCellValue]="calculateConfigurationsCellValue"
      [caption]="'Configurations'"
      width="auto"
      [minWidth]="250"
      cellTemplate="selectedConfigurationsCellTemplate"
      editCellTemplate="selectedConfigurationsEditCellTemplate"
      [setCellValue]="setExcursionConfigurationsCellValue"
    >
    </dxi-column>
    <div *dxTemplate="let cellData of 'selectedConfigurationsEditCellTemplate'">
      <div
        *ngFor="
          let configuration of cellData.data?.[ExcursionInfoTableDataKeys.excursionId]
            | excursionConfigurations: conferenceExcursions
        "
      >
        <div class="m-2" *var="configuration?.[ExcursionConfigurationKeys.label] as label">
          <ng-container *var="createConfigurationPropertyName(label) as property">
            <ng-container [ngSwitch]="configuration?.[ExcursionConfigurationKeys.dataType]">
              <ng-container *ngSwitchCase="ExcursionDataType.time">
                <dx-date-box
                  #timeBoxRef
                  [value]="cellData?.data?.[ExcursionInfoTableDataKeys.configurations]?.[property]?.value ?? null"
                  [label]="label"
                  labelMode="floating"
                  type="time"
                  [interval]="15"
                  [useMaskBehavior]="true"
                  [displayFormat]="'hh:mm a'"
                  [dateSerializationFormat]="MILITARY_TIME_FORMAT"
                  (onValueChanged)="cellData.setValue([property, timeBoxRef.value, ExcursionDataType.time])"
                >
                </dx-date-box>
              </ng-container>

              <ng-container *ngSwitchCase="ExcursionDataType.string">
                <dx-text-box
                  #textBoxRef
                  [value]="cellData?.data?.[ExcursionInfoTableDataKeys.configurations]?.[property]?.value"
                  [width]="'100%'"
                  [label]="label"
                  labelMode="floating"
                  [showClearButton]="false"
                  (onValueChanged)="cellData.setValue([property, textBoxRef.value, ExcursionDataType.string])"
                >
                </dx-text-box>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div *dxTemplate="let data of 'selectedConfigurationsCellTemplate'">
      <div *ngFor="let item of data.value">
        <span class="text-capitalize">{{ item?.title }}</span>
      </div>
    </div>

    <dxi-column
      [dataField]="ExcursionInfoTableDataKeys.isPaid"
      [caption]="'Is Paid'"
      width="auto"
      [minWidth]="250"
      [visible]="isEditable"
    >
      <dxo-lookup
        [dataSource]="TRUE_FALSE_LOOKUP"
        [allowClearing]="false"
        [displayExpr]="'description'"
        [valueExpr]="'value'"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="ExcursionInfoTableDataKeys.isAdminSelected"
      [caption]="'Is Admin Selected'"
      width="auto"
      [allowEditing]="false"
      [visible]="isEditable"
    >
      <dxo-lookup
        [dataSource]="TRUE_FALSE_LOOKUP"
        [allowClearing]="false"
        [displayExpr]="'description'"
        [valueExpr]="'value'"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="ExcursionInfoTableDataKeys.bookingDate"
      [caption]="'Booking Date'"
      dataType="datetime"
      [allowEditing]="false"
      width="auto"
      [visible]="isEditable"
    >
    </dxi-column>

    <dxi-column
      [dataField]="ExcursionInfoTableDataKeys.excursionCost"
      [calculateCellValue]="calculateExcursionCostCellValue"
      [caption]="'Excursions Cost'"
      alignment="left"
      [dataType]="'number'"
      [format]="DX_USD_CURRENCY_FORMAT"
      [alignment]="'right'"
      [editorOptions]="{ format: DX_USD_CURRENCY_FORMAT }"
      width="auto"
      [minWidth]="150"
      [allowEditing]="false"
      [formItem]="{ visible: false }"
      [visible]="includeTotal"
    >
    </dxi-column>

    <dxi-column type="buttons" [visible]="isEditable && canEdit">
      <dxi-button name="edit"></dxi-button>
    </dxi-column>

    <dxo-summary
      *ngIf="includeTotal"
      [calculateCustomSummary]="calculateCustomSummary"
      [recalculateWhileEditing]="true"
    >
      <dxi-group-item
        [showInColumn]="ExcursionInfoTableDataKeys.excursionCost"
        [name]="ExcursionInfoTableDataKeys.excursionCost"
        summaryType="custom"
        [valueFormat]="DX_USD_CURRENCY_FORMAT"
        [showInGroupFooter]="false"
        [alignByColumn]="true"
        displayFormat="Day Total: {0}"
      >
      </dxi-group-item>

      <dxi-total-item
        [showInColumn]="ExcursionInfoTableDataKeys.excursionCost"
        [name]="ExcursionInfoTableDataKeys.excursionCost"
        summaryType="custom"
        [valueFormat]="DX_USD_CURRENCY_FORMAT"
        cssClass="excursions-info-table__total"
        displayFormat="Total Cost: {0}"
      >
      </dxi-total-item>

      <dxi-total-item
        [column]="ExcursionInfoTableDataKeys.excursionCost"
        summaryType="custom"
        [valueFormat]="DX_USD_CURRENCY_FORMAT"
        name="discount"
        cssClass="excursions-info-table__discount"
        displayFormat="Credit: -{0}"
      >
      </dxi-total-item>

      <dxi-total-item
        [showInColumn]="ExcursionInfoTableDataKeys.excursionCost"
        summaryType="custom"
        [valueFormat]="DX_USD_CURRENCY_FORMAT"
        name="totalCharge"
        cssClass="excursions-info-table__total-charge"
        displayFormat="Total Charge: {0}"
      >
      </dxi-total-item>
    </dxo-summary>

    <div *dxTemplate="let data of 'selectedPreferencesCellTemplate'">
      <div *ngFor="let item of data.value">
        <span>{{ item?.title }}</span>

        <ng-container *ngIf="item?.cost">
          (<span class="excursions-info-table__price"> {{ item?.cost | usdCurrency }} </span>)
        </ng-container>
      </div>
    </div>

    <div *dxTemplate="let data of 'groupDateCellTemplate'">
      <div>{{ data.value | date: "EEEE, LLLL d, yyyy" }}</div>
    </div>
  </dx-data-grid>
</ng-container>
