import { LookupBase } from '../models/utils/lookup-base.model';

export enum CampaignsManagementStepStatus {
  updated = 'updated',
  notUpdated = 'notUpdated',
}

export const CAMPAIGNS_MANAGEMENT_STEP_STATUS_LOOKUP: LookupBase[] = [
  { value: CampaignsManagementStepStatus.updated, description: 'Updated' },
  { value: CampaignsManagementStepStatus.notUpdated, description: 'Not Updated' },
];
