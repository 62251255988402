import { Pipe, PipeTransform } from '@angular/core';
import { Excursion, ExcursionKeys, ExcursionPreference } from '@ag-common-lib/public-api';

@Pipe({ name: 'excursionPreferences' })
export class ExcursionPreferencesPipe implements PipeTransform {
  transform(excursionId: string, conferenceExcursions: Excursion[]): ExcursionPreference[] {
    const selectedExcursion = conferenceExcursions?.find(excursion => excursion?.[ExcursionKeys.id] === excursionId);

    return selectedExcursion?.[ExcursionKeys.preferences];
  }
}
