import { Address, AddressModelKeys } from '@ag-common-lib/public-api';
import * as uuid from 'uuid';

export const getAddressKeyExpression = (address: Address) => {
  if (!address) {
    return null;
  }
  const keyItems = [
    address?.address1,
    address?.address2,
    address?.city,
    // address?.state,
    // address?.zip,
    // address?.country,
    // address?.county,
  ];

  if (!keyItems.some(Boolean)) {
    return null;
  }

  return keyItems.join(',');
};

export const normalizeAddresses = (addressToEdit: Address, addresses: Address[]) => {
  if (!addressToEdit) {
    return addresses;
  }

  const hasPrimaryBillingAddresses =
    !!addresses?.length && addresses?.some(address => address?.[AddressModelKeys.isPrimaryBilling]);
  const hasPrimaryShippingAddresses =
    !!addresses?.length && addresses?.some(address => address?.[AddressModelKeys.isPrimaryShipping]);

  if (!hasPrimaryBillingAddresses) {
    addressToEdit[AddressModelKeys.isPrimaryBilling] = true;
  }
  if (!hasPrimaryShippingAddresses) {
    addressToEdit[AddressModelKeys.isPrimaryShipping] = true;
  }

  const isPrimaryBilling = addressToEdit?.[AddressModelKeys.isPrimaryBilling];
  const isPrimaryShipping = addressToEdit?.[AddressModelKeys.isPrimaryShipping];
  const isPhysicalLocation = addressToEdit?.[AddressModelKeys.isPhysicalLocation];

  let isUpdated = false;

  const normalizedAddresses = addresses.map(address => {
    if (addressToEdit?.[AddressModelKeys.id] === address?.[AddressModelKeys.id]) {
      debugger;
      isUpdated = true;
      return addressToEdit;
    }

    const normalizedAddress = Object.assign({}, address);
    if (isPrimaryBilling) {
      Object.assign(normalizedAddress, { [AddressModelKeys.isPrimaryBilling]: false });
    }
    if (isPrimaryShipping) {
      Object.assign(normalizedAddress, { [AddressModelKeys.isPrimaryShipping]: false });
    }
    if (isPhysicalLocation) {
      Object.assign(normalizedAddress, { [AddressModelKeys.isPhysicalLocation]: false });
    }

    return normalizedAddress;
  });

  if (!isUpdated) {
    normalizedAddresses.push(Object.assign({}, addressToEdit));
  }

  return normalizedAddresses;
};

export const normalizeAddressesFromFirestore = (addresses: Address[]) => {
  if (!addresses?.length) {
    return [];
  }
  const idsSet = new Set();

  const normalizedAddresses = addresses?.map(address => {
    const normalizedAddress = Object.assign({}, new Address(), address);
    let addressId = normalizedAddress?.[AddressModelKeys.id];

    if (!addressId || idsSet.has(addressId)) {
      addressId = uuid.v4();
      normalizedAddress[AddressModelKeys.id] = addressId;
    }

    idsSet.add(addressId);

    return normalizedAddress;
  });

  return normalizedAddresses;
};
