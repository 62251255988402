export enum GuestTypes {
  infant = 'infant',
  childSmall = 'childSmall',
  childOlder = 'childOlder',
  adult = 'adult',
}

export enum MonthYearSelection {
  month = 'month',
  years = 'years',
}

export const MonthYearSelectionLookup = [
  { value: MonthYearSelection.month, description: 'Months' },
  { value: MonthYearSelection.years, description: 'Years' },
];

export enum GuestRateKeys {
  guestRateId = 'guestRateId',
  guestTypeName = 'guestTypeName',
  startAgeRange = 'startAgeRange',
  endAgeRange = 'endAgeRange',
  budget = 'budget',
  monthYearSelection = 'monthYearSelection',
}

export class GuestRate {
  [GuestRateKeys.guestRateId]: string;
  [GuestRateKeys.guestTypeName]?: string;
  [GuestRateKeys.startAgeRange]?: number;
  [GuestRateKeys.endAgeRange]?: number;
  [GuestRateKeys.budget]?: number;
  [GuestRateKeys.monthYearSelection]?: MonthYearSelection;
}
