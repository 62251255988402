<div class="mt-2">
  <ng-container *ngTemplateOutlet="grid; context: { isEdit: false }"></ng-container>
</div>

<ag-shr-modal-window
  #socialsEditorModalRef
  [title]="'Add / Edit Socials'"
  [showSaveButton]="false"
  [inProgress]="inProgress$ | async"
>
  <ng-container *ngTemplateOutlet="grid; context: { isEdit: true }"></ng-container>
</ag-shr-modal-window>

<ng-template #grid let-isEdit="isEdit">
  <ag-shr-socials-grid
    [isEdit]="isEdit"
    [socials]="socials"
    [socialMediaLookup]="socialMediaLookup"
    [socialTypesLookup]="socialTypesLookup"
    [allowAdding]="canCreate"
    [allowDeleting]="canDelete"
    [allowUpdating]="canEdit"
    [saveSocials]="saveSocials"
    (handleShowSocialsEditorModal)="showSocialsEditorModal()"
  ></ag-shr-socials-grid>
</ng-template>
