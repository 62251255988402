export enum RegisterUserKeys {
  firstName = 'firstName',
  lastName = 'lastName',
  mobileNumber = 'mobileNumber',
  upline = 'upline',
  email = 'email',
  password = 'password',
}

export interface RegisterUser {
  [RegisterUserKeys.firstName]: string;
  [RegisterUserKeys.lastName]: string;
  [RegisterUserKeys.mobileNumber]: string;
  [RegisterUserKeys.upline]: string;
  [RegisterUserKeys.email]: string;
  [RegisterUserKeys.password]: string;
}
