import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'excursionConfigurationDisplayName' })
export class ExcursionConfigurationDisplayNamePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    const capitalize = item => item.charAt(0).toUpperCase() + item.slice(1);
    return capitalize(value)
      .split(/(?=[A-Z])/)
      .join(' ');
  }
}
