import { Inject, Injectable } from '@angular/core';
import {
  AgentDocumentCollections,
  AgentFileDirectory,
  AgentFileDirectoryKeys,
  AGENTS_COLLECTION_NAME,
} from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { CommonFireStoreDao, QueryParam } from '../dao/CommonFireStoreDao.dao';
import { FIREBASE_APP } from '../injections/firebase-app';

@Injectable({
  providedIn: 'root',
})
export class AgentFileDirectoriesService {
  public readonly fsDao: CommonFireStoreDao<AgentFileDirectory>;
  private readonly agentCollectionPath = AGENTS_COLLECTION_NAME;
  private readonly fileDirectoryCollectionPath = AgentDocumentCollections.fileDirectories;

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp, private toastrService: ToastrService) {
    this.fsDao = new CommonFireStoreDao<AgentFileDirectory>(fireBaseApp, null, null);
  }

  public getList(agentId: string, qp: QueryParam[] = []) {
    const table = this.getCollectionPath(agentId);

    return this.fsDao.getList(table, qp);
  }

  public async create(agentId: string, data: AgentFileDirectory, silent = false) {
    const table = this.getCollectionPath(agentId);
    const media = await this.fsDao
      .create(Object.assign(data, { [AgentFileDirectoryKeys.agentDbId]: agentId }), table)
      .catch(e => {
        console.log('e', e);
      });

    !silent && this.toastrService.success('Agent File Directory Successfully Created!');

    return media;
  }

  public async update(agentId: string, documentId: any, updates: Partial<AgentFileDirectory>, silent = false) {
    const table = this.getCollectionPath(agentId);

    const media = await this.fsDao.updateFields(updates, documentId, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success('Agent File Directory Successfully Updated!');

    return media;
  }

  public delete(agentId: string, documentId: any) {
    const table = this.getCollectionPath(agentId);

    return this.fsDao.delete(documentId, table).then(response => {
      this.toastrService.success('Agent File Directory Removed!');
      return response;
    });
  }

  private getCollectionPath(agentId: string) {
    return [this.agentCollectionPath, agentId, this.fileDirectoryCollectionPath].join('/');
  }
}
