<div class="mt-2">
  <ag-shr-editor-toolbar
    [title]="'Carriers'"
    [isEditVisible]="canEdit"
    (clickEdit)="carriersEditorModalComponent.showModal()"
  ></ag-shr-editor-toolbar>

  <ag-shr-carriers-grid
    [canEdit]="false"
    [canCreate]="false"
    [canDelete]="false"
    [carriers]="agent[AgentKeys.agent_carriers]"
    [isEditable]="false"
  ></ag-shr-carriers-grid>
</div>

<ag-shr-modal-window
  #carriersEditorModalRef
  [title]="'Add / Edit Carriers'"
  [showSaveButton]="false"
  [width]="'75%'"
  [height]="'auto'"
>
  <ag-shr-carriers-grid
    [canEdit]="canEdit"
    [canCreate]="canCreate"
    [canDelete]="canDelete"
    [carriers]="agent[AgentKeys.agent_carriers]"
    [agentId]="agent[BaseModelKeys.dbId]"
    (carriersChange)="onCarriersChange($event)"
  ></ag-shr-carriers-grid>
</ag-shr-modal-window>
