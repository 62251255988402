import { CallOutcomeKeys } from './call-outcomes.enum';

export const CALL_OUTCOMES_LOOKUP = [
  { value: CallOutcomeKeys.busy, description: 'Busy' },
  { value: CallOutcomeKeys.connected, description: 'Connected' },
  { value: CallOutcomeKeys.leftLiveMessage, description: 'Left Live Message' },
  { value: CallOutcomeKeys.leftVoiceMessage, description: 'Left Voicemail' },
  { value: CallOutcomeKeys.noAnswer, description: 'No Answer' },
  { value: CallOutcomeKeys.notInService, description: 'Not in Service' },
  { value: CallOutcomeKeys.schedules, description: 'Scheduled' },
  { value: CallOutcomeKeys.wrongNumber, description: 'Wrong Number' },
];
