export enum UpdateUserLoginEmailKeys {
  firebaseUID = 'uid',
  otp = 'otp',
  agentDbId = 'agentDbId',
  emailAddressDbId = 'emailAddressDbId',
}

export interface UpdateUserLoginEmail {
  [UpdateUserLoginEmailKeys.firebaseUID]: string;
  [UpdateUserLoginEmailKeys.agentDbId]: string;
  [UpdateUserLoginEmailKeys.emailAddressDbId]: string;
  [UpdateUserLoginEmailKeys.otp]: string;
}
