import { Component, HostBinding, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AGMediaKeys, CampaignLogoHeadshot } from '@ag-common-lib/public-api';
import { Observable } from 'rxjs';
import { AgentCampaignLogoHeadshotService } from './agent-campaign-logo-headshot.service';
import { ImageCropperKeys } from '../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/image-cropper.model';

@UntilDestroy()
@Component({
  selector: 'ag-shr-agent-campaign-logo-headshot',
  templateUrl: './agent-campaign-logo-headshot.component.html',
  styleUrls: ['./agent-campaign-logo-headshot.component.scss'],
})
export class AgentCampaignLogoHeadshotComponent {
  @HostBinding('class') className = 'agent-campaign-logo-headshot agent-campaign-wizard__content-container';

  @Input() hasStepIssues: boolean;
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;

  inProgress$: Observable<boolean>;
  hasFormChanges$: Observable<boolean>;
  logoHeadshotFormData: Partial<CampaignLogoHeadshot> = {};

  protected readonly AGMediaKeys = AGMediaKeys;

  constructor(private agentCampaignLogoHeadshotService: AgentCampaignLogoHeadshotService) {
    this.inProgress$ = agentCampaignLogoHeadshotService.inProgress$;
    this.hasFormChanges$ = agentCampaignLogoHeadshotService.hasFormChanges$;

    agentCampaignLogoHeadshotService.formData$.pipe(untilDestroyed(this)).subscribe(formData => {
      this.logoHeadshotFormData = formData;
    });
  }

  nextStep = () => {
    this.agentCampaignLogoHeadshotService.handleNextStepClick();
  };

  onMediaChanged(e, key) {
    this.logoHeadshotFormData[key] = e;
  }

  onMediaCropped(e, key) {
    this.logoHeadshotFormData[key] = Object.assign(
      { ...this.logoHeadshotFormData[key] },
      {
        [ImageCropperKeys.imageCropperPayload]: e,
      },
    );
  }

  resetChanges = () => {
    this.agentCampaignLogoHeadshotService.revertAllChanges();
  };
}
