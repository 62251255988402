<ag-shr-modal-window
  #attendeeManualRefundModalRef
  [title]="'Manual Refund'"
  [width]="'50vw'"
  [height]="'590px'"
  [actionTitle]="'REFUND'"
  [inProgress]="inProgress$ | async"
  [useScrollView]="false"
  (onSaveClick)="onSaveRefunds($event)"
>
  <div
    *ngIf="attendeeManualRefundModalRef?.popupComponent?.visible ?? false"
    class="attendee-manual-refund-modal__container"
  >
    <div class="attendee-manual-refund-modal__grid-container">
      <dx-data-grid
        [dataSource]="formData?.[StripeTransactionKeys.details] ?? []"
        (dataSourceChange)="onDetailsChanged($event)"
        (onInitNewRow)="onInitNewRefundDetailRow($event)"
        [showBorders]="true"
        height="100%"
      >
        <dxo-editing
          mode="row"
          [useIcons]="true"
          [allowUpdating]="true"
          [allowDeleting]="true"
          [allowAdding]="true"
          [newRowPosition]="'last'"
        >
        </dxo-editing>

        <dxo-toolbar>
          <dxi-item location="before">
            <div *dxTemplate>
              <dx-text-box
                [(value)]="formData && formData[StripeTransactionKeys.parentStripeId]"
                label="Transaction ID to Refund"
                labelMode="floating"
                [width]="200"
              >
                <dx-validator [validationGroup]="validationGroup">
                  <dxi-validation-rule
                    type="required"
                    message="Transaction ID is required to refund"
                  ></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </dxi-item>

          <dxi-item location="before">
            <dx-text-box
              [(value)]="formData && formData[StripeTransactionKeys.description]"
              label="Refunds Description"
              labelMode="floating"
              [width]="250"
            >
              <dx-validator [validationGroup]="validationGroup">
                <dxi-validation-rule type="required" message="Refunds Description is required"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </dxi-item>

          <dxi-item
            [visible]="true"
            name="addRowButton"
            [disabled]="!formData?.[StripeTransactionKeys.parentStripeId]"
          ></dxi-item>
        </dxo-toolbar>

        <dxi-column [dataField]="AgStripeSubtotalKeys.description" caption="Description">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column
          [dataField]="AgStripeSubtotalKeys.amount"
          [dataType]="'number'"
          [format]="DX_USD_CURRENCY_FORMAT"
          [alignment]="'right'"
          [editorOptions]="{ format: DX_USD_CURRENCY_FORMAT }"
          width="auto"
          [minWidth]="150"
        >
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxo-summary [recalculateWhileEditing]="true">
          <dxi-total-item
            [column]="AgStripeSubtotalKeys.amount"
            [name]="AgStripeSubtotalKeys.amount"
            summaryType="sum"
            [valueFormat]="DX_USD_CURRENCY_FORMAT"
            displayFormat="Total: {0}"
          >
          </dxi-total-item>
        </dxo-summary>
      </dx-data-grid>
    </div>
  </div>
</ag-shr-modal-window>
