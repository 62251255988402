import { PhoneNumber } from '@ag-common-lib/public-api';

export const matcher = number => {
  if (!number) {
    return '';
  }
  const matcher = number.match(/(\d{3})(\d{3})(\d{4})/);
  return matcher ? `(${matcher[1]}) ${matcher[2]} - ${matcher[3]}` : number;
};

export const getPhoneNumber = (phoneNumber: PhoneNumber, showExtension: boolean = true): string => {
  if (!phoneNumber || !phoneNumber?.number) {
    return '';
  }

  return `${matcher(phoneNumber?.number)} ${
    showExtension && phoneNumber?.extension ? ` (ext. ${phoneNumber?.extension})` : ''
  }`;
};
