import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import {
  HTML_EDITOR_ITEMS,
  Messages,
  FONT_HTML_EDITOR_ITEMS,
  FONT_STYLE_HTML_EDITOR_ITEMS,
  HEADER_STYLE_HTML_EDITOR_ITEMS,
  HtmlEditorTypeKeys,
  IMAGE_STYLE_HTML_EDITOR_ITEMS,
  LINK_STYLE_HTML_EDITOR_ITEMS,
  PARAGRAPH_STYLE_HTML_EDITOR_ITEMS,
  VARIABLE_STYLE_HTML_EDITOR_ITEMS,
} from '@ag-common-lib/public-api';
import { CloudFunctionsService } from 'ag-common-svc/lib/services/cloud-functions.service';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';
import {
  DxDateBoxComponent,
  DxHtmlEditorComponent,
  DxSelectBoxComponent,
  DxTagBoxComponent,
  DxTextBoxComponent,
  DxValidatorComponent,
} from 'devextreme-angular';
import { InitializedEvent, OptionChangedEvent } from 'devextreme/ui/file_uploader';
import { fileToUIBase64 } from '../ag-media-uploader/ag-media-uploader.utils';
import * as uuid from 'uuid';
import { DxButtonTypes } from 'devextreme-angular/ui/button';

export type FormEditors =
  | DxHtmlEditorComponent
  | DxTextBoxComponent
  | DxSelectBoxComponent
  | DxDateBoxComponent
  | DxTagBoxComponent;

export interface SaveActionPayload {
  component: FormEditors;
  validator?: DxValidatorComponent;
}

@Component({
  selector: 'shr-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
})
export class HtmlEditorComponent {
  @ViewChild('editorRef') editorComponent: DxHtmlEditorComponent;
  @ViewChild('testRef') testRefComp: any;
  @HostBinding('class') className = 'html-editor';
  @Input() value?: string;
  @Input() label?: string;
  @Input() validationGroup?: string;
  @Input() name: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter();
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() useFiledEditing = false;
  @Input() hasChanges = false;
  @Input() inProgress = false;
  @Input() fileDirectory: string = 'html-editor';
  @Input() allowSoftLineBreak?: boolean = false;

  @Input() set toolbarItemType(types: HtmlEditorTypeKeys[]) {
    if (!types) {
      return;
    }
    this.generateToolbarItems(types);
  }

  @Input() variables: string[];

  @Output() shortFilePath = new EventEmitter<string>();
  @Output() onShowPreview = new EventEmitter<string>();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSaveChanges: EventEmitter<SaveActionPayload> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onRevertChanges: EventEmitter<DxHtmlEditorComponent> = new EventEmitter();

  constructor(private cloudFunctions: CloudFunctionsService) {}

  toolbarButtonOptions: DxButtonTypes.Properties = {
    text: 'Preview',
    icon: 'eyeopen',
    hint: 'Preview',
    stylingMode: 'text',
    onClick: () => this.onShowPreview.emit(this.value),
  };

  protected isFocused: boolean;

  protected readonly Messages = Messages;
  protected editorItems = HTML_EDITOR_ITEMS;

  public handleValueChange = e => {
    this.valueChange.emit(e?.value);
  };

  public handleRevertClick = () => {
    this.onRevertChanges.emit(this.editorComponent);
  };

  public handleSaveChangesClick = async () => {
    const isValid = await validateDxGroups(this.validationGroup);
    if (isValid) {
      this.onSaveChanges.emit({ component: this.editorComponent });
    }
  };

  protected uploadFile = async (file: File) => {
    const fileBase64 = (await fileToUIBase64(file))?.split(',')[1];
    this.shortFilePath.emit(`${this.fileDirectory}/${file.name}`);

    return this.cloudFunctions.uploadMedia({
      fileId: file.name,
      filePath: this.fileDirectory,
      mediaData: fileBase64,
      contentType: file.type,
      withThumbnail: false,
      metadata: {
        fileName: file.name,
      },
    });
  };

  onInitialized = (e: InitializedEvent) => {
    e.component.on('optionChanged', this.onOptionChanged);
  };

  onOptionChanged = (e: OptionChangedEvent) => {
    if (e.name === 'value') {
      e.component.off('optionChanged', this.onOptionChanged);
      const [file] = e.value;

      e.component?.option('value', [new File([file], uuid.v4(), { type: file?.type })]);

      setTimeout(() => {
        e.component.on('optionChanged', this.onOptionChanged);
      }, 0);
    }
  };

  private generateToolbarItems = types => {
    const itemTypes = types ?? [
      HtmlEditorTypeKeys.font,
      HtmlEditorTypeKeys.fontStyle,
      HtmlEditorTypeKeys.paragraph,
      HtmlEditorTypeKeys.header,
      HtmlEditorTypeKeys.link,
      HtmlEditorTypeKeys.image,
    ];
    this.editorItems = [];

    itemTypes.forEach(type => {
      switch (type) {
        case HtmlEditorTypeKeys.font:
          this.editorItems.push(...FONT_HTML_EDITOR_ITEMS);
          break;

        case HtmlEditorTypeKeys.fontStyle:
          this.editorItems.push(...FONT_STYLE_HTML_EDITOR_ITEMS);
          break;

        case HtmlEditorTypeKeys.paragraph:
          this.editorItems.push(...PARAGRAPH_STYLE_HTML_EDITOR_ITEMS);
          break;

        case HtmlEditorTypeKeys.header:
          this.editorItems.push(...HEADER_STYLE_HTML_EDITOR_ITEMS);
          break;

        case HtmlEditorTypeKeys.link:
          this.editorItems.push(...LINK_STYLE_HTML_EDITOR_ITEMS);
          break;

        case HtmlEditorTypeKeys.variable:
          this.editorItems.push(...VARIABLE_STYLE_HTML_EDITOR_ITEMS);
          break;

        case HtmlEditorTypeKeys.image:
          this.editorItems.push(...IMAGE_STYLE_HTML_EDITOR_ITEMS);
          break;

        case HtmlEditorTypeKeys.preview:
          const PREVIEW_HTML_EDITOR_ITEMS = { widget: 'dxButton', options: this.toolbarButtonOptions, name: 'eyeopen' };
          this.editorItems.push(PREVIEW_HTML_EDITOR_ITEMS);
          break;

        default:
          this.editorItems.push(...HTML_EDITOR_ITEMS);
          break;
      }
    });
  };
}
