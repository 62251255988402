import { BaseModel } from '../base.model';

export enum DynamicListsGroupKeys {
    description = 'description',
    title = 'title',
    recipientsDbIds = 'recipientsDbIds',
}

export class DynamicListsGroup extends BaseModel {
    [DynamicListsGroupKeys.title]: string;
    [DynamicListsGroupKeys.description]?: string;
    [DynamicListsGroupKeys.recipientsDbIds]: string[] = [];
}
