import { Inject, Injectable } from '@angular/core';
import {
  AssociationKeys,
  BaseModelKeys,
  Conference,
  ConferenceKeys,
  ConferenceStepsConfigurationObject,
  ExcursionKeys,
  ExcursionStatistic,
  ExcursionStatisticKeys,
  GuestData,
  HotelRegion,
  RegistrantData,
  RegistrantKeys,
  RegistrantModelKeys,
  SelectedExcursionsKeys,
} from '@ag-common-lib/public-api';
import { DataService } from '../services/data.service';
import { FirebaseApp } from 'firebase/app';
import { catchError, combineLatest, map, Observable, of, shareReplay, startWith, Subject, switchMap } from 'rxjs';
import { addMinutes, differenceInMilliseconds, endOfDay, isBefore, isDate, isFuture } from 'date-fns';
import { FIREBASE_APP } from '../injections/firebase-app';
import { ConferenceGuestsService } from './conference-guests.service';
import { ConferenceRegistrantsService } from './conference-registrants/conference-registrants.service';

export const CONFERENCES_COLLECTION_PATH = 'conferences';
@Injectable()
export class ConferenceService extends DataService<Conference> {
  private readonly conferencesCollectionPath = CONFERENCES_COLLECTION_PATH;

  upcomingConferences$: Observable<Conference[]>;

  private readonly _statisticResetTrigger$ = new Subject<void>();
  private _resetStatisticTimeouts: { [conferenceDbId: string]: NodeJS.Timeout } = {};

  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,
    private registrantService: ConferenceRegistrantsService,
    private guestsService: ConferenceGuestsService,
  ) {
    super(fireBaseApp, ConferenceService.fromFirestore);
    this.collection = this.conferencesCollectionPath;

    this.upcomingConferences$ = this.getUpcomingConferences();
  }

  static readonly fromFirestore = (data): any => {
    const result = Object.assign({}, data, {
      [ConferenceKeys.hotelRegion]: data?.[ConferenceKeys.hotelRegion] || HotelRegion.domestic,
    });

    const stepsConfiguration = data?.[ConferenceKeys.stepsConfiguration];
    const stepsConfigurationObject =
      Array.isArray(stepsConfiguration) && stepsConfiguration?.length
        ? stepsConfiguration.reduce(
            (acc, stepConfiguration) => Object.assign(acc, { [stepConfiguration?.stepName]: stepConfiguration }),
            {},
          )
        : new ConferenceStepsConfigurationObject();

    Object.assign(result, {
      [ConferenceKeys.stepsConfigurationObject]: stepsConfigurationObject,
    });
    return result;
  };

  getDocumentData(conferenceDbId: string): Observable<Conference> {
    return this.fsDao.getDocument(this.conferencesCollectionPath, conferenceDbId).pipe(
      map(snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          return data;
        }
        return null;
      }),
    );
  }

  private getUpcomingConferences() {
    return this.getList().pipe(
      map(conferences => {
        return conferences?.filter(conference => {
          const registrationStartDate = conference?.[ConferenceKeys.registrationStartDate];
          const registrationStartDateEndOfDay = endOfDay(registrationStartDate);

          if (isFuture(registrationStartDateEndOfDay)) {
            return false;
          }

          const conferenceEndDate = conference?.[ConferenceKeys.endDate];
          const conferenceEndDateEndOfDay = endOfDay(conferenceEndDate);

          return isFuture(conferenceEndDateEndOfDay);
        });
      }),
      shareReplay(1),
    );
  }

  getExcursionStatistics(conferenceDbId: string): Observable<Map<string, ExcursionStatistic>> {
    return combineLatest({
      conference: this.getDocumentData(conferenceDbId),
      _statisticResetTrigger: this._statisticResetTrigger$.pipe(startWith(null)),
    }).pipe(
      map(({ conference }) => conference),
      switchMap(conference => {
        return this.registrantService.getRegistrantsByConferenceId(conferenceDbId, 'last_name').pipe(
          switchMap(registrants => {
            const guestRequests = registrants.map(registrant =>
              this.guestsService.getList(conferenceDbId, registrant[BaseModelKeys.dbId]),
            );

            return combineLatest(guestRequests).pipe(
              map(guestsPerRegistrant => {
                // Flattens the array of arrays into a single array
                const allGuests = guestsPerRegistrant.flatMap(guests => guests);

                const participants = [
                  ...registrants.map(registrant => registrant[RegistrantModelKeys.data]),
                  ...allGuests,
                ];

                // Initialize excursion statistics based on the current list of excursions
                const excursions = conference[ConferenceKeys.excursions];
                const excursionsBookingTime = conference[ConferenceKeys.excursionsBookingTime];

                const initialStatistics: Map<string, ExcursionStatistic> = excursions.reduce((map, excursion) => {
                  map.set(excursion[ExcursionKeys.id], {
                    [ExcursionStatisticKeys.excursionId]: excursion[ExcursionKeys.id],
                    [ExcursionStatisticKeys.seatsLeft]: Number(excursion[ExcursionKeys.capacity]),
                    [ExcursionStatisticKeys.excursionName]: excursion[ExcursionKeys.name],
                    [ExcursionStatisticKeys.capacity]: Number(excursion[ExcursionKeys.capacity]),
                    [ExcursionStatisticKeys.date]: excursion[ExcursionKeys.date],
                  });
                  return map;
                }, new Map());

                return this.calculateExcursionStatistics(
                  conferenceDbId,
                  initialStatistics,
                  participants,
                  excursionsBookingTime,
                );
              }),
              catchError(error => {
                console.error('Error fetching guests:', error);
                // Return the initial statistics if there's an error fetching guests
                return of(null);
              }),
            );
          }),
        );
      }),
    );
  }

  private calculateExcursionStatistics(
    conferenceDbId: string,
    excursionStats: Map<string, ExcursionStatistic>,
    participants: (GuestData | RegistrantData)[],
    excursionsBookingTime: number,
  ): Map<string, ExcursionStatistic> {
    clearTimeout(this._resetStatisticTimeouts?.[conferenceDbId]);

    let nearByBookingExpirationDate;
    const tookSeat = (excursionId: string) => {
      const statisticItem = excursionStats.get(excursionId);
      if (statisticItem) {
        statisticItem[ExcursionStatisticKeys.seatsLeft]--;
        excursionStats.set(excursionId, statisticItem);
      }
    };
    participants.forEach(participant => {
      Object.entries(participant?.[RegistrantKeys.selectedExcursions] ?? {}).forEach(([excursionId, data]) => {
        const isPaid = data?.[SelectedExcursionsKeys.isPaid];
        const isAdminSelected = data?.[SelectedExcursionsKeys.isAdminSelected];

        if (isPaid || isAdminSelected) {
          tookSeat(excursionId);
          return;
        }

        const bookingDate = new Date(data?.[SelectedExcursionsKeys.bookingDate]);
        const isBookingDateValid = isDate(bookingDate);

        if (!isBookingDateValid) {
          tookSeat(excursionId);
          return;
        }

        const bookingExpirationDate = addMinutes(bookingDate, excursionsBookingTime);
        const isBookingExpirationDateInFeature = isFuture(bookingExpirationDate);

        if (!isBookingExpirationDateInFeature) {
          return;
        }

        if (!nearByBookingExpirationDate || isBefore(bookingExpirationDate, nearByBookingExpirationDate)) {
          nearByBookingExpirationDate = bookingExpirationDate;
        }

        tookSeat(excursionId);
      });
    });

    if (nearByBookingExpirationDate) {
      const timeout = differenceInMilliseconds(nearByBookingExpirationDate + 1000, new Date());

      this._resetStatisticTimeouts[conferenceDbId] = setTimeout(() => {
        this._statisticResetTrigger$.next();
      }, timeout);
    }

    return excursionStats;
  }
}
