import { Pipe, PipeTransform } from '@angular/core';
import { ConferenceStepsTemplateArgument } from '@ag-common-lib/public-api';

@Pipe({ name: 'conferenceTemplateWithArguments' })
export class ConferenceTemplateWithArgumentsPipe implements PipeTransform {
  transform(template: string, templateArguments: ConferenceStepsTemplateArgument[]): string {
    if (!template || !templateArguments?.length) {
      return '';
    }
    return template.replace(/{{.*?}}/g, match => {
      return templateArguments.find(item => item.id.toString() === match?.replace(/[{}]/g, ''))?.value ?? match;
    });
  }
}
