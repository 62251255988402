<ag-shr-modal-window
  #attendeeRefundTransactionModalRef
  [title]="'Refund Payment'"
  [width]="500"
  [height]="'auto'"
  [actionTitle]="'REFUND'"
  [inProgress]="inProgress$ | async"
  [useScrollView]="false"
  (onSaveClick)="onSaveRefund($event)"
>
  <div
    *ngIf="attendeeRefundTransactionModalRef?.popupComponent?.visible ?? false"
    class="attendee-refund-payment-modal__container"
  >
    <p>
      Refunds take 5-10 days to appear on a customer's statement. Stripe's fees for the original payment won't be
      returned, but there are no additional fees for the refund.
    </p>

    <dx-form class="mt-2" [labelMode]="'floating'" [(formData)]="formData" [validationGroup]="validationGroup">
      <dxi-item
        [dataField]="ProcessRefundPayloadKeys.amount"
        [label]="{ text: 'Amount' }"
        editorType="dxNumberBox"
        [editorOptions]="{ format: DX_USD_CURRENCY_FORMAT }"
        *var="totalAmountToRefund$ | async as totalAmount"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule type="range" [min]="0.50" message="The minimum amount is $0.50 US"></dxi-validation-rule>
        <dxi-validation-rule type="range" [max]="totalAmount" [message]="'The maximum amount is ' + (totalAmount | currency) +' US'"></dxi-validation-rule>
      </dxi-item>

      <dxi-item
        editorType="dxSelectBox"
        [dataField]="ProcessRefundPayloadKeys.reason"
        [label]="{ text: 'Reason' }"
        [editorOptions]="{
          items: reasonTypesLookup,
          valueExpr: LookupKeys.value,
          displayExpr: LookupKeys.description,
          searchEnabled: true,
          placeholder: 'Select a reason'
        }"
      ></dxi-item>

      <dxi-item [dataField]="ProcessRefundPayloadKeys.description" [label]="{ text: 'Description' }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
    </dx-form>
  </div>
</ag-shr-modal-window>
