import { Injectable } from '@angular/core';
import { DietaryConsiderationKeys, LookupKeys, TShirtSizesKeys, YES_NO_TYPE } from '@ag-common-lib/public-api';
import { updateDoc } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DietaryConsiderationsFormService {
  private _selectedDietaryConsiderationType$ = new BehaviorSubject(null);
  selectedDietaryConsiderationType$ = this._selectedDietaryConsiderationType$.asObservable();

  constructor() {}

  lockLookups = () => {
    const assignedLookups = [this._selectedDietaryConsiderationType$.value];
    const promises = assignedLookups
      .filter(lookup => lookup && !lookup?.isAssigned)
      .map(lookup => updateDoc(lookup?.reference, { [LookupKeys.isAssigned]: true }));

    return Promise.all(promises);
  };

  getFormData = initialData => {
    const formData = new Proxy(initialData ?? {}, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        if (value !== prevValue) {
          Reflect.set(target, prop, value, receiver);
          switch (prop) {
            case DietaryConsiderationKeys.hasDietaryOrPersonalConsideration:
              if (prevValue === YES_NO_TYPE.Yes && value === YES_NO_TYPE.No) {
                Object.assign(initialData, { [DietaryConsiderationKeys.dietaryConsiderationType]: null });
                Object.assign(initialData, { [DietaryConsiderationKeys.dietaryConsideration]: null });
              }
              break;
          }
        }
        return true;
      },
    });

    return formData;
  };

  handleDietaryConsiderationTypeSelect = item => {
    this._selectedDietaryConsiderationType$.next(item);
  };
}
