import { TASK_PRIORITY } from '../../lists/task-priority.enum';
import { TASK_STATUS } from '../../lists/task-status.enum';
import { TaskCategory, TaskSubcategory } from '../../lists/task.category.enum';
import { ActivityBase } from './activity-base.model';
import { Agent } from '../domain/agent.model';
import { DocumentReference } from 'firebase/firestore';

export enum TaskKeys {
  title = 'title',
  assignedId = 'assignedId',
  assignedRef = 'assignedRef',
  _assignedRefId = 'assignedRef.id',
  status = 'status',
  priority = 'priority',
  startAtDate = 'startAtDate',
  startAtTime = 'startAtTime',
  completedOnDate = 'completedOnDate',
  completedOnTime = 'completedOnTime',
  dueDate = 'dueDate',
  dueTime = 'dueTime',
  details = 'details',
  category = 'category',
  categoryRef = 'categoryRef',
  _categoryRefId = 'categoryRef.id',
  subcategory = 'subcategory',
  subcategoryRef = 'subcategoryRef',
  _subcategoryRefId = 'subcategoryRef.id',
  followers = 'followers',
  // /
  listId = 'listId',
  parentId = 'parentId',
  order = 'order',
  duration = 'duration',
}

export class Task extends ActivityBase {
  [TaskKeys.title]: string; // it was subject
  [TaskKeys.assignedRef]?: DocumentReference<Agent>;
  [TaskKeys.categoryRef]?: DocumentReference<TaskCategory>;
  [TaskKeys.subcategoryRef]?: DocumentReference<TaskSubcategory>;
  [TaskKeys.status]: TASK_STATUS;
  [TaskKeys.priority]: TASK_PRIORITY;
  [TaskKeys.startAtDate]: Date;
  [TaskKeys.startAtTime]?: string;
  [TaskKeys.completedOnDate]: Date;
  [TaskKeys.completedOnTime]?: string;
  [TaskKeys.dueDate]: Date;
  [TaskKeys.dueTime]?: string;
  [TaskKeys.details]?: string;
  [TaskKeys.followers]?: string[];
  //
  [TaskKeys.listId]?: string;
  [TaskKeys.parentId]?: string;
  [TaskKeys.order]?: number;

  /**
   * @deprecated Use assignedRef instead
   */
  [TaskKeys.assignedId]: string;
  /**
   * @deprecated Use categoryRef instead
   */
  [TaskKeys.category]: TaskCategory;
  /**
   * @deprecated Use subcategoryRef instead
   */
  [TaskKeys.subcategory]: TaskSubcategory;
}

export class TaskTemplate extends Task {
  [TaskKeys.duration]: number;
}
