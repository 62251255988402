import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationsListRouting } from './notifications-list-routing.module';
import { NotificationsListComponent } from './notifications-list.component';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { NotificationCampaignModalModule } from '../notification-campaign-modal/notification-campaign-modal.module';
import { NotificationRecipientsGridModule } from '../notification-recipients-grid/notification-recipients-grid.module';
import { DxSchedulerModule } from 'devextreme-angular';
import { NotificationAddRecipientsModalModule } from '../notification-add-recipients-modal/notification-add-recipients-modal.module';

@NgModule({
  declarations: [NotificationsListComponent],
  imports: [
    CommonModule,
    SharedModule,
    NotificationsListRouting,
    NotificationCampaignModalModule,
    NotificationRecipientsGridModule,
    DxSchedulerModule,
    NotificationAddRecipientsModalModule,
  ],
})
export class NotificationsListModule {}
