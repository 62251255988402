import { BaseModel } from '../base.model';

export class LogMessage extends BaseModel {
  constructor(type: string, source: string, message: string, error_code: string, data?: any) {
    super();
    this.type = type;
    this.error_code = error_code;
    this.archived = false;
    this.message = message;
    this.source = source;

    if (data) {
      this.data = data ? JSON.stringify(data) : '';
    }
  }

  type: string;
  source: string;
  message: string;
  data: any;
  error_code: string;
  archived: boolean;
  notes: string;
}
