import { NgModule } from '@angular/core';
import { AgentCampaignSocialMediaComponent } from './agent-campaign-social-media.component';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ButtonWithIndicatorModule } from '../../button-with-indicator/button-with-indicator.module';
import { SocialsModule } from '../../socials/socials.module';
import { AgentCampaignSocialMediaService } from './agent-campaign-social-media.service';
import { AgentCampaignSocialMediaDataSourcePipe } from './agent-campaign-social-media-data-source.pipe';
import { NgIf } from '@angular/common';

@NgModule({
  declarations: [AgentCampaignSocialMediaComponent, AgentCampaignSocialMediaDataSourcePipe],
    imports: [ SharedModule, ButtonWithIndicatorModule, SocialsModule, NgIf ],
  exports: [AgentCampaignSocialMediaComponent],
  providers: [AgentCampaignSocialMediaService],
})
export class AgentCampaignSocialMediaModule {}
