import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  AgencyCarrier,
  AgencyCarrierModelKeys,
  BaseModelKeys,
  CARRIER_LINE_OF_BUSINESS_LOOKUP,
  CARRIER_ORGANIZATION_LOOKUP,
  CarrierModelKeys,
  Constants,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  Messages,
} from 'ag-common-lib/public-api';
import { CarrierService } from '../../../../../services/carrier.service';
import { ToastrService } from 'ngx-toastr';
import { DxDataGridComponent } from 'devextreme-angular';
import { AgencyCarriersModalComponent } from '../carriers-modal/agency-carriers-modal.component';
import { PortalDataService } from '../../../../../services/portal.service';
import * as _ from 'lodash';
import { AgencyCarriersModalService } from '../agency-carriers-modal.service';

@Component({
  selector: 'ag-shr-agency-carriers-grid',
  templateUrl: './agency-carriers-grid.component.html',
  styleUrls: ['./agency-carriers-grid.component.scss'],
  providers: [CarrierService, ToastrService],
})
export class AgencyCarriersGridComponent {
  @ViewChild('agencyCarriersGridRef', { static: false }) agencyCarriersGridComponent: DxDataGridComponent;
  @ViewChild('agencyCarrierModalRef') agencyCarrierModalComponent: AgencyCarriersModalComponent;
  @Input() carriers: AgencyCarrier[] = [];
  @Input() agencyId: string;
  @Input() isEditable: boolean = true;
  @Output() carriersChange = new EventEmitter<AgencyCarrier[]>();

  carriersLookup$ = this.carrierService.getList();

  protected readonly CarrierModelKeys = CarrierModelKeys;
  protected readonly AgencyCarrierModelKeys = AgencyCarrierModelKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly Messages = Messages;
  protected readonly carrierLineOfBusinessLookup = CARRIER_LINE_OF_BUSINESS_LOOKUP;
  protected readonly carrierOrganizationLookup = CARRIER_ORGANIZATION_LOOKUP;
  readonly emptyMessage = 'No Agency Carriers Currently Exist';
  readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(
    private carrierService: CarrierService,
    private portalDataService: PortalDataService,
    private agencyCarriersModalService: AgencyCarriersModalService,
  ) {}

  onRowRemoving = e => {
    const carriers = this.carriers.filter(carrier => carrier.id !== e.key.id);
    e.cancel = this.updateCarriers(carriers);
  };

  showAddPopup = (): void => {
    const initialData: AgencyCarrier = {
      id: this.portalDataService.getUUID(),
      is_active: !this.carriers.length,
    };
    this.agencyCarrierModalComponent.showModal(this.agencyId, initialData);
  };

  showEditPopup = ({ row: { data } }): void => {
    this.agencyCarrierModalComponent.showModal(this.agencyId, data);
  };

  carrierChange(agencyCarrier: AgencyCarrier) {
    const carriers: AgencyCarrier[] = this.normalizeCarriers(agencyCarrier);
    if (!carriers.length || !_.find(carriers, { id: agencyCarrier.id })) {
      carriers.push(Object.assign({}, agencyCarrier)); // to add
    }
    this.updateCarriers(carriers);
  }

  private normalizeCarriers = data => {
    const isActive = !!data?.is_active;
    return this.carriers.map(carrier => {
      if (data && carrier.id === data.id) {
        return Object.assign({}, data);
      }
      return Object.assign({}, carrier, { is_active: isActive });
    });
  };

  private updateCarriers = (carriers: AgencyCarrier[]) => {
    return this.agencyCarriersModalService.updateCarriers(this.agencyId, carriers).then(() => {
      this.carriers = carriers;
      this.carriersChange.emit(carriers);
    });
  };
  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
}
