import { Inject, Injectable } from '@angular/core';
import { FIREBASE_APP } from '../injections/firebase-app';
import { FirebaseApp } from 'firebase/app';
import { dateFromTimestamp } from '../utils/date-from-timestamp';
import { firstValueFrom, map, Observable } from 'rxjs';
import {
  AGENT_CAMPAIGNS_COLLECTION_NAME,
  CampaignManagementTaskLogs,
  CAMPAIGNS_MANAGEMENT_TASKS_COLLECTION_NAME,
  CAMPAIGNS_MANAGEMENT_TASKS_LOGS_COLLECTION_NAME,
  CampaignsManagementTaskIssues,
  CampaignsManagementTaskLogsAction,
  CampaignsManagementTaskLogsKeys,
  CampaignsManagementTasks,
  RelatedCampaignsManagementTaskLogs,
  AgentCampaignStepName,
} from '@ag-common-lib/public-api';
import { CommonFireStoreDao, QueryParam } from '../dao/CommonFireStoreDao.dao';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AgentCampaignsManagementTaskLogsService {
  public readonly fsDao: CommonFireStoreDao<CampaignManagementTaskLogs>;
  private readonly agentCampaignsCollectionPath = AGENT_CAMPAIGNS_COLLECTION_NAME;
  private readonly campaignManagementTasksCollectionPath = CAMPAIGNS_MANAGEMENT_TASKS_COLLECTION_NAME;
  private readonly campaignManagementTaskLogsCollectionPath = CAMPAIGNS_MANAGEMENT_TASKS_LOGS_COLLECTION_NAME;

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    this.fsDao = new CommonFireStoreDao<CampaignManagementTaskLogs>(
      fireBaseApp,
      AgentCampaignsManagementTaskLogsService.fromFirestore,
      AgentCampaignsManagementTaskLogsService.toFirestore,
    );
  }

  static readonly fromFirestore = (data): CampaignManagementTaskLogs => {
    if (!!data?.created_date) {
      data.created_date = dateFromTimestamp(data?.created_date);
    }
    if (!!data?.taskChanges) {
      if (!!data?.taskChanges?.updatedStatusAt) {
        data.taskChanges.updatedStatusAt = dateFromTimestamp(data?.taskChanges?.updatedStatusAt);
      }
      if (!!data?.taskChanges?.assignedAt) {
        data.taskChanges.assignedAt = dateFromTimestamp(data?.taskChanges?.assignedAt);
      }
      if (!!data?.taskChanges?.updated_date) {
        data.taskChanges.updated_date = dateFromTimestamp(data?.taskChanges?.updated_date);
      }
    }
    if (!!data?.issuesChanges) {
      if (!!data?.issuesChanges?.assignedAt) {
        data.issuesChanges.assignedAt = dateFromTimestamp(data?.issuesChanges?.assignedAt);
      }
      if (!!data?.issuesChanges?.updatedStatusAt) {
        data.issuesChanges.updatedStatusAt = dateFromTimestamp(data?.issuesChanges?.updatedStatusAt);
      }
      if (!!data?.issuesChanges?.updated_date) {
        data.issuesChanges.updated_date = dateFromTimestamp(data?.issuesChanges?.updated_date);
      }
    }

    return Object.assign({}, data);
  };

  static readonly toFirestore = (data): CampaignManagementTaskLogs => {
    return data;
  };

  getLogs$(queryParams: QueryParam[] = []): Observable<CampaignManagementTaskLogs[]> {
    return this.fsDao
      .getCollectionGroupSnapshot(this.campaignManagementTaskLogsCollectionPath, queryParams, true)
      .pipe(map(snapshot => snapshot.docs.map(doc => (!doc.exists() ? null : doc.data()))));
  }

  getLogsByTaskId(taskId: string): Observable<RelatedCampaignsManagementTaskLogs[]> {
    return this.fsDao.getCollectionGroupSnapshot(this.campaignManagementTaskLogsCollectionPath).pipe(
      map(snapshot => {
        return snapshot.docs.map(doc => {
          if (!doc.exists()) {
            return null;
          }
          const data = doc.data();
          const parentAgent = doc?.ref?.parent?.parent;
          const parentDbId = parentAgent?.id;

          return { data, parentDbId };
        });
      }),
    );
  }

  saveLogs(
    agentCampaignsId: string,
    taskId: string,
    taskChanges: Partial<CampaignsManagementTasks>,
    issuesChanges: Partial<CampaignsManagementTaskIssues>,
    action: CampaignsManagementTaskLogsAction,
  ): void {
    this.getLogs(taskChanges, issuesChanges, action)
      .then(logs => {
        this.createLogs(agentCampaignsId, taskId, logs);
      })
      .catch(() => console.log('Logs Error!'));
  }

  async createLogs(
    agentCampaignsId: string,
    taskId: string,
    updates: CampaignManagementTaskLogs,
  ): Promise<CampaignManagementTaskLogs | void> {
    const table = this.getCollectionPath(agentCampaignsId, taskId);

    const agentCampaignsLogs = await this.fsDao.create(updates, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    return agentCampaignsLogs;
  }

  private getCollectionPath(agentCampaignsId: string, taskId: string): string {
    return [
      this.agentCampaignsCollectionPath,
      agentCampaignsId,
      this.campaignManagementTasksCollectionPath,
      taskId,
      this.campaignManagementTaskLogsCollectionPath,
    ].join('/');
  }

  private async getLogs(
    taskChanges: Partial<CampaignsManagementTasks>,
    issuesChanges: Partial<CampaignsManagementTaskIssues>,
    action: CampaignsManagementTaskLogsAction,
  ): Promise<CampaignManagementTaskLogs> {
    return Object.assign(
      {},
      {
        [CampaignsManagementTaskLogsKeys.action]: action,
        // [CampaignsManagementTaskLogsKeys.agentDbId]: loggedInUserDbId,
        [CampaignsManagementTaskLogsKeys.taskDbId]: taskChanges?.dbId ?? issuesChanges?.taskDbId,
        [CampaignsManagementTaskLogsKeys.taskChanges]: taskChanges,
        [CampaignsManagementTaskLogsKeys.issuesChanges]: issuesChanges,
      },
    );
  }
}
