import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ag-shr-agent-campaign-summary-list-item',
  templateUrl: './agent-campaign-summary-list-item.component.html',
  styleUrls: ['./agent-campaign-summary-list-item.component.scss'],
})
export class AgentCampaignSummaryListItemComponent {
  @HostBinding('class') className = 'agent-campaign-summary-list-item';

  @Input() title: string;

  constructor() {}
}
