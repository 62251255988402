import { ActivityBase } from './activity-base.model';

export enum EmailLogKeys {
  title = 'title',
  sender = 'sender',
  date = 'date',
  time = 'time',
  to = 'to',
  cc = 'cc',
  bcc = 'bcc',
  externalContacts = 'externalContacts',
  detailsAgenda = 'detailsAgenda',
}

export enum EmailParticipantKeys {
  targetDbId = 'targetDbId',
  email = 'email',
}

export interface EmailParticipant {
  [EmailParticipantKeys.targetDbId]: string;
  [EmailParticipantKeys.email]: string;
}

export class EmailLog extends ActivityBase {
  [EmailLogKeys.title]: string;
  /**
   * Presented string of "email".
   */
  [EmailLogKeys.sender]: string;
  [EmailLogKeys.date]: Date;
  [EmailLogKeys.time]?: string;
  /**
   * Presented list is array of strings of  "email".
   */
  [EmailLogKeys.to]?: string[];
  /**
   * Presented list is array of strings of  "email".
   */
  [EmailLogKeys.cc]?: string[];
  /**
   * Presented list is array of strings of  "email".
   */
  [EmailLogKeys.bcc]?: string[];
  [EmailLogKeys.externalContacts]?: string[];
  [EmailLogKeys.detailsAgenda]?: string;
}
