<ag-shr-modal-window
  #notificationAddRecipientsModalRef
  [width]="'80vw'"
  [height]="'80vh'"
  [title]="title"
  [actionTitle]="actionTitle"
  (onSaveClick)="addNotificationRecipients()"
>
  <ng-container *ngIf="notificationAddRecipientsModalRef?.popupComponent?.visible ?? false">
    <ag-shr-notification-recipients-grid
      #recipientsGridRef
      [mode]="'add'"
      [dataSource]="dataSource"
    ></ag-shr-notification-recipients-grid>
  </ng-container>
</ag-shr-modal-window>
