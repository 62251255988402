import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { AgentCampaignWizardComponent } from './agent-campaign-wizard.component';
import { AgentCampaignAddressInfoModule } from './agent-campaign-address-info/agent-campaign-address-info.module';
import { AgentCampaignPhoneNumberInfoModule } from './agent-campaign-phone-number-info/agent-campaign-phone-number-info.module';
import { AgentCampaignEmailAddressInfoModule } from './agent-campaign-email-address-info/agent-campaign-email-address-info.module';
import { AgentCampaignBioModule } from './agent-campaign-bio/agent-campaign-bio.module';
import { AgentCampaignSocialMediaModule } from './agent-campaign-social-media/agent-campaign-social-media.module';
import { AgentCampaignLogoHeadshotModule } from './agent-campaign-logo-headshot/agent-campaign-logo-headshot.module';
import { AgentCampaignWebSiteModule } from './agent-campaign-web-site/agent-campaign-web-site.module';
import { AgentCampaignAppointmentModule } from './agent-campaign-appointment/agent-campaign-appointment.module';
import { AgentCampaignExtraModule } from './agent-campaign-extra/agent-campaign-extra.module';
import { AgentCampaignWizardService } from './agent-campaign-wizard.service';
import { DxLoadPanelModule } from 'devextreme-angular';
import { ButtonWithIndicatorModule } from '../button-with-indicator/button-with-indicator.module';
import { AgentCampaignSummaryModule } from './agent-campaign-summary/agent-campaign-summary.module';
import { AgentCampaignAgentProfileDataService } from './agent-campaign-agent-profile-data.service';
import { AgentImagesService } from '../../services/agent-images.service';
import { WizardStepperModule } from '../wizard-stepper/wizard-stepper.module';

@NgModule({
  declarations: [AgentCampaignWizardComponent],
  imports: [
    SharedModule,
    WizardStepperModule,
    AgentCampaignAddressInfoModule,
    AgentCampaignEmailAddressInfoModule,
    AgentCampaignPhoneNumberInfoModule,
    AgentCampaignBioModule,
    AgentCampaignSocialMediaModule,
    AgentCampaignLogoHeadshotModule,
    AgentCampaignWebSiteModule,
    AgentCampaignAppointmentModule,
    AgentCampaignExtraModule,
    DxLoadPanelModule,
    ButtonWithIndicatorModule,
    AgentCampaignSummaryModule,
  ],
  providers: [AgentCampaignWizardService, AgentCampaignAgentProfileDataService, AgentImagesService],
  exports: [AgentCampaignWizardComponent],
})
export class AgentCampaignWizardModule {}
