<dx-select-box
  [name]="name"
  [dataSource]="associationTypeLookup$ | async"
  [(value)]="value"
  (valueChange)="valueChange.emit($event)"
  (selectedItemChange)="selectedItemChange.emit($event)"
  [readOnly]="readOnly"
  [disabled]="disabled"
  [label]="label"
  [labelMode]="labelMode"
  [placeholder]="placeholder"
  [displayExpr]="LookupKeys.description"
  [valueExpr]="BaseModelKeys.dbId"
  [searchEnabled]="true"
  [showClearButton]="true"
>
  <dx-validator [validationGroup]="validationGroup">
    <dxi-validation-rule *ngIf="isRequired" type="required" [message]="label + ' is required'"></dxi-validation-rule>
  </dx-validator>
</dx-select-box>
