import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ModalWindowModule } from '../modal-window/modal-window.module';
import { AddAgentToDynamicListModalComponent } from './add-agent-to-dynamic-list-modal.component';
import { DxDropDownBoxModule, DxValidatorModule } from 'devextreme-angular';
import { AddAgentToDynamicListModalService } from './add-agent-to-dynamic-list-modal.service';
import { DynamicListsGroupService } from 'ag-common-svc/public-api';

@NgModule({
  imports: [CommonModule, SharedModule, ModalWindowModule, DxDropDownBoxModule, DxValidatorModule],
  declarations: [AddAgentToDynamicListModalComponent],
  exports: [AddAgentToDynamicListModalComponent],
  providers: [AddAgentToDynamicListModalService, DynamicListsGroupService],
})
export class AddAgentToDynamicListModalModule {}
