import { BaseModel } from '../base.model';

export enum SystemFileDirectories {
  Documents = 'Documents',
  Images = 'Images',
}

export enum AgentFileDirectoryCollections {
  files = 'files',
}

export enum AgentFileDirectoryKeys {
  agentDbId = 'agentDbId',
  name = 'name',
  isSystem = 'isSystem',
}

export class AgentFileDirectory extends BaseModel {
  [AgentFileDirectoryKeys.agentDbId]: string;
  [AgentFileDirectoryKeys.name]: string;
  [AgentFileDirectoryKeys.isSystem]: boolean;
}

export const AGENT_SYSTEM_DIRECTORY_NAMES: string[] = [SystemFileDirectories.Images, SystemFileDirectories.Documents];
