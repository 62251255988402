export enum GetImageContentByUrlKeys {
  url = 'url',
  contentType = 'contentType',
  base64 = 'base64',
}

export interface GetImageContentByUrlPayload {
  [GetImageContentByUrlKeys.url]: string;
}

export interface GetImageContentByUrlResponse {
  [GetImageContentByUrlKeys.base64]: string;
  [GetImageContentByUrlKeys.contentType]?: string;
}
