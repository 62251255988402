import { MappingProperty } from '@elastic/elasticsearch/lib/api/types';
import { PhoneNumberType } from '../../lists/phone_number_type.enum';
import { SupportType } from '../../lists/support-type.enum';
import { LookupKeys } from '../crm/lookup.model';

export enum PhoneNumberKeys {
  number = 'number',
  extension = 'extension',
  phone_type = 'phone_type',
  is_primary = 'is_primary',
  is_whatsapp = 'is_whatsapp',
  supportType = 'support_type',
}
export class PhoneNumber {
  [PhoneNumberKeys.number]?: string;
  [PhoneNumberKeys.extension]?: string;
  [PhoneNumberKeys.phone_type]?: PhoneNumberType = PhoneNumberType.Mobile;
  [PhoneNumberKeys.is_primary]?: boolean = false;
  [PhoneNumberKeys.is_whatsapp]?: boolean = false;
  [PhoneNumberKeys.supportType]?: SupportType;
}

export const PHONE_NUMBER_KEYS_LOOKUP = [
  { [LookupKeys.value]: PhoneNumberKeys.number, [LookupKeys.description]: 'Phone Number' },
  { [LookupKeys.value]: PhoneNumberKeys.extension, [LookupKeys.description]: 'Extension' },
  { [LookupKeys.value]: PhoneNumberKeys.phone_type, [LookupKeys.description]: 'Phone Type' },
  { [LookupKeys.value]: PhoneNumberKeys.is_primary, [LookupKeys.description]: 'Is Primary' },
  { [LookupKeys.value]: PhoneNumberKeys.is_whatsapp, [LookupKeys.description]: 'Is Whatsapp' },
  { [LookupKeys.value]: PhoneNumberKeys.supportType, [LookupKeys.description]: 'Support Type' },
];

export const PhoneNumberKeyMapping: {
  [key: string]: MappingProperty;
} = {
  [PhoneNumberKeys.number]: { type: 'keyword' },
  [PhoneNumberKeys.extension]: { type: 'keyword' },
  [PhoneNumberKeys.phone_type]: { type: 'text' },
  [PhoneNumberKeys.is_primary]: { type: 'boolean' },
  [PhoneNumberKeys.is_whatsapp]: { type: 'boolean' },
  [PhoneNumberKeys.supportType]: { type: 'text' },
};
