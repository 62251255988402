<ag-shr-modal-window
  #modalRef
  [title]="caption"
  [width]="'600px'"
  [height]="'auto'"
  [actionTitle]="'ADD'"
  [useScrollView]="false"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="handleSave()"
  [onCloseModal]="handleClosePopup"
  [saveButtonDisabled]="!formData?.[AddAgentToDynamicListKeys.dynamicLists]?.length"
>
  <ng-container *ngIf="modalRef?.popupComponent?.visible ?? false">
    <dx-form [formData]="formData" labelMode="floating">
      <dxi-item
        *var="dynamicLists$ | async as dynamicLists"
        [dataField]="AddAgentToDynamicListKeys.dynamicLists"
        editorType="dxTagBox"
        [label]="{ text: 'Select Dynamic Lists' }"
        [isRequired]="true"
        [editorOptions]="{
          valueChangeEvent: 'input',
          items: dynamicLists,
          displayExpr: DynamicListsGroupKeys.title,
          searchEnabled: true,
          showSelectionControls: true,
          applyValueMode: 'useButtons',
          placeholder: '',
          noDataText: 'No Lists To Display',
        }"
      ></dxi-item>
    </dx-form>
  </ng-container>
</ag-shr-modal-window>

<ag-shr-modal-window
  #infoModalRef
  [title]="caption"
  [width]="'600px'"
  [height]="'auto'"
  [useScrollView]="false"
  [showSaveButton]="false"
  [cancelTitle]="'OK'"
>
  <div *ngIf="infoModalRef?.popupComponent?.visible ?? false">
    <ng-container *var="agentIncludedLists$ | async as includedLists">
      <ng-container *ngTemplateOutlet="listsTmp; context: { lists: includedLists, success: true }"></ng-container>
    </ng-container>

    <ng-container *var="agentNotAddedLists$ | async as notAddedLists">
      <ng-container *ngTemplateOutlet="listsTmp; context: { lists: notAddedLists, success: false }"></ng-container>
    </ng-container>

    <ng-template #listsTmp let-lists="lists" let-success="success">
      <p class="mb-3" [ngClass]="{ 'text-danger': !success }" *ngIf="lists?.length as listsCount">
        Agent was <span *ngIf="!success">not</span> added to the <b>{{ lists | arrayToString }}</b>
        <span [ngPlural]="listsCount">
          <ng-template ngPluralCase="=1"> list</ng-template>
          <ng-template ngPluralCase="other"> lists</ng-template>
        </span>.
      </p>
    </ng-template>
  </div>
</ag-shr-modal-window>
