import { Pipe, PipeTransform } from '@angular/core';
import { MMDDYYYY_DATE_FORMAT } from '@ag-common-lib/public-api';
import { format, isValid, toDate } from 'date-fns';

@Pipe({ name: 'datesRange' })
export class DatesRangePipe implements PipeTransform {
  transform(arrivalDate: string, departureDate: string): string {
    if (!isValid(toDate(arrivalDate)) || !isValid(toDate(departureDate))) {
      return '';
    }
    return [format(arrivalDate, MMDDYYYY_DATE_FORMAT), '-', format(departureDate, MMDDYYYY_DATE_FORMAT)].join(' ');
  }
}
