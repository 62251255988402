import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AgMediaUploadModel } from '../ag-media-uploader.models';
import { AgMediaUploaderService, ImageData } from '../ag-media-uploader.service';
import { ValueChangedEvent } from 'devextreme/ui/text_box';
import { GetImageContentByUrlResponse } from '@ag-common-lib/public-api';
import { CloudFunctionsService } from '../../../services/cloud-functions.service';
import { buildUIBase64 } from '../ag-media-uploader.utils';

@Component({
  selector: 'ag-shr-upload-from-url-tab',
  templateUrl: './upload-from-url-tab.component.html',
  styleUrls: ['./upload-from-url-tab.component.scss'],
})
export class UploadFromUrlTabComponent {
  @HostBinding('class') className = 'upload-from-disk-tab';
  imageUrlBlob: string | null = null;

  fileName: string;
  imagePreviewUrl: string;

  private _response: GetImageContentByUrlResponse;

  @Output() mediaUrlChange = new EventEmitter<AgMediaUploadModel | null>();

  _isImageValid$ = new BehaviorSubject(true);

  constructor(
    private agMediaUploaderService: AgMediaUploaderService,
    private cloudFunctionsService: CloudFunctionsService,
  ) {}

  handleIsImageValid = async isValid => {
    this._isImageValid$.next(isValid);

    if (isValid) {
      const imageData = Object.assign({}, this._response as Partial<ImageData>, { fileName: '' }) as ImageData;
      this.agMediaUploaderService.setImage(imageData);
      return;
    }
  };

  imageUrlToBase64 = async (event: ValueChangedEvent) => {
    if (!event.value) {
      return;
    }
    const response = await this.cloudFunctionsService.getImageContentByUrl({ url: event.value }).catch(() => {
      this._isImageValid$.next(false);
      return null;
    });

    if (!response) {
      this.agMediaUploaderService.setImage(null);
      this.imagePreviewUrl = null;
      return;
    }

    this._response = response;
    this.imagePreviewUrl = buildUIBase64(response?.base64, response?.contentType);
  };

  clear() {
    this.imagePreviewUrl = null;
    this.fileName = null;
    this._response = null;
  }
}
