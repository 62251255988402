import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Agent, AgentCarrier, AgentKeys, BaseModelKeys } from '@ag-common-lib/public-api';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import {
  Entity
} from "../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/lists/entity-permission.enum";
import {
  EntityPermissionModelKeys
} from "../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/entity-permission.model";

@Component({
  selector: 'ag-shr-carriers',
  templateUrl: './agent-carriers.component.html',
  styleUrls: ['./agent-carriers.component.scss'],
})
export class AgentCarriersComponent implements OnInit {
  @ViewChild('carriersEditorModalRef') carriersEditorModalComponent: ModalWindowComponent;
  @Input() agent: Agent;
  @Input() canEdit: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() canCreate: boolean = true;
  @Output() carriersChange = new EventEmitter<AgentCarrier[]>();

  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly AgentKeys = AgentKeys;

  constructor() {}

  ngOnInit(): void {
    this.agent[AgentKeys.agent_carriers] = this.agent[AgentKeys.agent_carriers]
      ? this.agent[AgentKeys.agent_carriers]
      : [];
  }

  onCarriersChange(carriers: AgentCarrier[]): void {
    this.agent[AgentKeys.agent_carriers] = carriers;
    this.carriersChange.emit(carriers);
  }

  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
}
