import { Component, HostBinding, Input } from '@angular/core';
import { LookupBase } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-clipboard-button',
  templateUrl: './clipboard-button.component.html',
  styleUrls: ['./clipboard-button.component.scss'],
})
export class ClipboardButtonComponent {
  @HostBinding('class') className = 'clipboard-button';

  @Input() text?: string;
  @Input() stylingMode?: 'text' | 'outlined' | 'contained' = 'text';
  @Input() clipboardValue: string;
  @Input() clipboardValues: LookupBase[];
  @Input() valueExpr: string = 'value';
  @Input() displayExpr: string = 'description';

  popoverVisible = false;
  popoverTarget: Element;
  constructor() {}

  copyToClipBoard(data: string): void {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(data)
        .then(() => {
          this.displayPopover();
        })
        .catch(error => {
          console.error('Error', error);
        });
    }
  }

  private displayPopover = () => {
    this.popoverVisible = true;
    setTimeout(() => {
      this.popoverVisible = false;
    }, 2000);
  };
}
