export const fileToUIBase64 = (file): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

export const buildUIBase64 = (base64: string, contentType: string = 'application/octet-stream') => {
  return `data:${contentType};base64,${base64}`;
};
