import { BaseModel } from '../base.model';

export enum AgencyFileDirectoryCollections {
  files = 'files',
}

export enum AgencyFileDirectoryKeys {
  agencyDbId = 'agencyDbId',
  name = 'name',
  isSystem = 'isSystem',
}

export class AgencyFileDirectory extends BaseModel {
  [AgencyFileDirectoryKeys.agencyDbId]: string;
  [AgencyFileDirectoryKeys.name]: string;
  [AgencyFileDirectoryKeys.isSystem]: boolean;
}

export const AGENCY_SYSTEM_DIRECTORY_NAMES: string[] = ['Images', 'Documents'];
