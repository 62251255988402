export enum SupportType {
  MainOffice = 'Main Office',
  Contracting = 'Contracting',
  Support = 'Support',
  NewBusiness = 'New Business',
}

export const SupportTypeDescriptionMap: Map<SupportType, string> = new Map([
  [SupportType.MainOffice, 'Main Office'],
  [SupportType.Contracting, 'Contracting'],
  [SupportType.Support, 'Support'],
  [SupportType.NewBusiness, 'New Business'],
]);
