import { Inject, Injectable } from '@angular/core';
import { CommonFireStoreDao, QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';

import { FIREBASE_APP } from '../injections/firebase-app';
import { FirebaseApp } from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { dateFromTimestamp } from '../utils/date-from-timestamp';
import { map, mergeAll, mergeMap, Observable, of, shareReplay, tap, zip } from 'rxjs';
import {
  AGENT_CAMPAIGNS_COLLECTION_NAME,
  CAMPAIGNS_MANAGEMENT_ISSUES_COLLECTION_NAME,
  CAMPAIGNS_MANAGEMENT_TASKS_COLLECTION_NAME,
  CampaignsManagementIssuesStatus,
  CampaignsManagementIssuesType,
  CampaignsManagementTaskIssues,
  CampaignsManagementTaskIssuesKeys,
  RelatedCampaignsManagementTaskIssues,
} from '@ag-common-lib/public-api';
import { setDoc } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class AgentCampaignsManagementTaskIssuesService {
  public readonly fsDao: CommonFireStoreDao<CampaignsManagementTaskIssues>;
  private readonly agentCampaignsCollectionPath = AGENT_CAMPAIGNS_COLLECTION_NAME;
  private readonly campaignManagementTasksCollectionPath = CAMPAIGNS_MANAGEMENT_TASKS_COLLECTION_NAME;
  private readonly campaignManagementTaskIssuesCollectionPath = CAMPAIGNS_MANAGEMENT_ISSUES_COLLECTION_NAME;

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp, private toastrService: ToastrService) {
    this.fsDao = new CommonFireStoreDao<CampaignsManagementTaskIssues>(
      fireBaseApp,
      AgentCampaignsManagementTaskIssuesService.fromFirestore,
      AgentCampaignsManagementTaskIssuesService.toFirestore,
    );
  }

  static readonly fromFirestore = (data): CampaignsManagementTaskIssues => {
    if (!!data?.updated_date) {
      data.updated_date = dateFromTimestamp(data?.updated_date);
    }
    if (!!data?.updatedStatusAt) {
      data.updatedStatusAt = dateFromTimestamp(data?.updatedStatusAt);
    }
    if (!!data?.assignedAt) {
      data.assignedAt = dateFromTimestamp(data?.assignedAt);
    }
    return Object.assign({}, data);
  };

  static readonly toFirestore = (data): CampaignsManagementTaskIssues => {
    return data;
  };

  getIssuesByTaskId(
    taskDbId: string,
    queryParams: QueryParam[] = [],
  ): Observable<RelatedCampaignsManagementTaskIssues[]> {
    const qp: QueryParam[] = [
      new QueryParam(CampaignsManagementTaskIssuesKeys.taskDbId, WhereFilterOperandKeys.equal, taskDbId),
      ...queryParams,
    ];

    return this.fsDao.getCollectionGroupSnapshot(this.campaignManagementTaskIssuesCollectionPath, qp).pipe(
      map(snapshot => {
        return snapshot.docs.map(doc => {
          if (!doc.exists()) {
            return null;
          }
          const data = doc.data();
          const parentAgent = doc?.ref?.parent?.parent;
          const parentDbId = parentAgent?.id;

          return { data, parentDbId };
        });
      }),
    );
  }

  async createIssues(
    agentCampaignsId: string,
    taskId: string,
    updates: Partial<CampaignsManagementTaskIssues>,
    silent = false,
  ): Promise<void | CampaignsManagementTaskIssues> {
    const table = this.getCollectionPath(agentCampaignsId, taskId);

    const agentCampaigns = await this.fsDao.create(updates, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success('Campaigns Issues Successfully Created!');

    return agentCampaigns;
  }

  async updateIssues(
    agentCampaignsId: string,
    taskId: string,
    documentId: string,
    updates: Partial<CampaignsManagementTaskIssues>,
    silent = false,
  ): Promise<void | CampaignsManagementTaskIssues> {
    const table = this.getCollectionPath(agentCampaignsId, taskId);

    const agentCampaigns = await this.fsDao.updateFields(updates, documentId, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success('Campaigns Issues Successfully Updated!');

    return agentCampaigns;
  }

  getAllCampaignActiveContentIssues(agentCampaignsId: string): Observable<RelatedCampaignsManagementTaskIssues[]> {
    const agentCampaignTasksTable = this.getCampaignTaskCollectionPath(agentCampaignsId);
    return this.fsDao.getCollectionSnapshot(agentCampaignTasksTable).pipe(
      mergeMap(snapshot => {
        if (snapshot.empty) {
          return of([]);
        }

        return zip(
          snapshot?.docs?.map(doc => {
            const qp = [
              new QueryParam(
                CampaignsManagementTaskIssuesKeys.issueType,
                WhereFilterOperandKeys.equal,
                CampaignsManagementIssuesType.content,
              ),
              new QueryParam(CampaignsManagementTaskIssuesKeys.issueStatus, WhereFilterOperandKeys.in, [
                CampaignsManagementIssuesStatus.posted,
                CampaignsManagementIssuesStatus.reopened,
              ]),
            ];

            return this.fsDao
              .getList(`${doc.ref.path}/${this.campaignManagementTaskIssuesCollectionPath}`, qp, {
                includeRef: true,
              })
              .pipe(
                map((issues): RelatedCampaignsManagementTaskIssues[] =>
                  issues.map(data => {
                    return { data, parentDbId: doc.id };
                  }),
                ),
              );
          }),
        );
      }),

      map(items => items?.flat(1)),
    );
  }

  resolve(ref) {
    return setDoc(
      ref,
      { [CampaignsManagementTaskIssuesKeys.issueStatus]: CampaignsManagementIssuesStatus.resolved },
      { merge: true },
    );
  }

  private getCampaignCollectionPath(agentCampaignsId: string): string {
    return [this.agentCampaignsCollectionPath, agentCampaignsId].join('/');
  }

  private getCampaignTaskCollectionPath(agentCampaignsId: string): string {
    return [this.agentCampaignsCollectionPath, agentCampaignsId, this.campaignManagementTasksCollectionPath].join('/');
  }

  private getCollectionPath(agentCampaignsId: string, taskId: string): string {
    return [
      this.agentCampaignsCollectionPath,
      agentCampaignsId,
      this.campaignManagementTasksCollectionPath,
      taskId,
      this.campaignManagementTaskIssuesCollectionPath,
    ].join('/');
  }
}
