<ag-shr-image-with-loader
  class="agent-campaign-picture__image"
  [wasabiPath]="mediaItem?.[AGMediaKeys.wasabiPath]"
  [mediaSize]="MediaSize.large"
  [cropperPayload]="mediaItem?.[ImageCropperKeys.imageCropperPayload]"
  [withBorder]="true"
  [borderRounded]="true"
></ag-shr-image-with-loader>

<div class="agent-campaign-picture__button-container" *ngIf="canEdit">
  <dx-drop-down-button
    class="agent-campaign-picture__button"
    [showArrowIcon]="false"
    [dropDownOptions]="{ width: 230 }"
    icon="edit"
    stylingMode="text"
    [items]="editOptions"
  >
  </dx-drop-down-button>
</div>

<ag-shr-media-uploader
  [mediaPathPrefix]="mediaPathPrefix$ | async"
  (mediaChanged)="onMediaChanged($event)"
  [mediaItems]="agentMediaDataSource$ | async"
></ag-shr-media-uploader>

<ag-shr-image-cropper-modal
  [onImageCropped]="onImageCropped"
  [roundCropper]="false"
  [maintainAspectRatio]="false"
  [title]="'Edit Thumbnail'"
></ag-shr-image-cropper-modal>
