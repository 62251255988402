export enum TaskCategory {
  agent = 'Agent',
  allianceGroup = 'Alliance Group',
  carrier = 'Carrier',
  conference = 'Conference',
  other = 'Other',
  prospect = 'Prospect',
}

export enum TaskSubcategory {
  compliance = 'Compliance',
  contracting = 'Contracting',
  marketing = 'Marketing',
  onboarding = 'Onboarding',
  training = 'Training',
  opportunity = 'Opportunity',
  video = 'Video',
  locations = 'Locations',
  other = 'Other',
  initialInquiry = 'Initial Inquiry',
  investigation = 'Investigation',
  routing = 'Routing',
  followUp = 'Follow-up',
}
