<div
  class="website-input__content"
  [ngClass]="{
    'website-input__content--has-error': !isValid
  }"
>
  <label
    for="htmlEditor"
    class="website-input__label dx-widget"
    [ngClass]="{
      'website-input__label--top': !!value || isFocused,
      'website-input__label--has-error': !isValid
    }"
  >
    {{ label }}
  </label>

  <div class="website-input__editor-container">
    <dx-text-box
      [value]="value.subdomain"
      [name]="name"
      (onValueChanged)="handleValueChange($event)"
      class="website-input__text-box"
      placeholder="my-subdomain"
      [inputAttr]="{ size: value?.length ? value?.length + 2 : 13 }"
    ></dx-text-box>

    <span>.</span>

    <dx-select-box
      [value]="value.domain"
      [name]="name"
      (onValueChanged)="handleValueChange($event)"
      placeholder="domain.com"
      class="website-input__select-box"
    >
      <dx-validator>
        <!-- <dxi-validation-rule
          type="pattern"
          [pattern]="subdomainPattern"
          [message]="Messages.INVALID_SUBDOMAIN"
        ></dxi-validation-rule>

        <dxi-validation-rule
          type="custom"
          [validationCallback]="validateUrlWithOrWithoutProtocol"
          [message]="Messages.INVALID_URL_FORMAT_TEXT"
        >
        </dxi-validation-rule>

        <dxi-validation-rule
          type="custom"
          [validationCallback]="validateDomain"
          [message]="Messages.REQUIRED_TEXT"
        >
        </dxi-validation-rule> -->
      </dx-validator>

    </dx-select-box>

  </div>
</div>
