import { InjectionToken } from '@angular/core';
import { ActivityBaseKeys, EmailAddress, TARGET_TYPE } from 'ag-common-lib/public-api';
import { Observable } from 'rxjs';

export enum AgentEventTemplates {
  meetingTmp = 'meetingTmp',
  callLogTmp = 'callLogTmp',
  emailLogTmp = 'emailLogTmp',
  noteLogTmp = 'noteLogTmp',
  taskTmp = 'taskTmp',
}

export interface ActivityConfig {
  [ActivityBaseKeys.targetId]: 'string';
  [ActivityBaseKeys.targetType]: TARGET_TYPE;
}

export const ACTIVITY_CONFIG = new InjectionToken<Observable<ActivityConfig>>('activityConfig');

export const TARGET_CONTACT_EMAILS = new InjectionToken<Observable<EmailAddress[]>>('targetContactEmails');

export const SHOULD_FETCH_TARGET_ACTIVITIES_SET = new Set([
  TARGET_TYPE.AGENT,
  TARGET_TYPE.AGENCY,
  TARGET_TYPE.CARRIER,
  TARGET_TYPE.PROSPECT,
]);
