import { Pipe, PipeTransform } from '@angular/core';
import { Conference, ConferenceKeys } from '@ag-common-lib/public-api';
import { ConferenceService } from 'ag-common-svc/public-api';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

@Pipe({ name: 'conferenceNameByDbId' })
export class ConferenceNameByDbIdPipe implements PipeTransform {
  private _conferenceDbId$ = new BehaviorSubject(null);
  agentFullName$ = this._conferenceDbId$.pipe(
    filter(Boolean),
    mergeMap(conferenceDbId => {
      if (!conferenceDbId) {
        return '';
      }
      return this.conferenceService.getDocument(conferenceDbId).pipe(
        map(agentSnapshot => {
          if (agentSnapshot.exists()) {
            const data = agentSnapshot.data();
            return [data?.[ConferenceKeys.eventName]].filter(Boolean).join(' ');
          }
          return '';
        }),
      );
    }),
  );

  constructor(private conferenceService: ConferenceService) {}

  transform(conferenceDbId: string, converter?: (conference: Conference) => string): Observable<string> {
    this._conferenceDbId$.next(conferenceDbId);

    return this.agentFullName$;
  }
}
