import { AgencyRelationship, AgencyRelationshipDescriptionMap } from './agency-relationship.enum';

export const AGENCY_RELATIONSHIP_LOOKUP = [
  {
    value: AgencyRelationship.NBA,
    description: AgencyRelationshipDescriptionMap.get(AgencyRelationship.NBA),
  },
  {
    value: AgencyRelationship.STROMSOE,
    description: AgencyRelationshipDescriptionMap.get(AgencyRelationship.STROMSOE),
  },
];
