<div *ngIf="!isReadonly">
  <dx-list
    #listRef
    [items]="emailAddressesDataSource$ | async"
    [selectionMode]="'single'"
    [showSelectionControls]="true"
    [keyExpr]="emailAddressKeyExpr"
    (onInitialized)="onInitialized($event)"
    [(selectedItemKeys)]="selectedEmailAddressesKeys"
  >
    <div *dxTemplate="let emailAddress of 'item'">
      <ng-container *ngIf="emailAddress?.address; else useNewAddress">
        <div>{{ emailAddress?.address }}</div>
      </ng-container>

      <ng-template #useNewAddress>
        <div>Add new Email Address</div>
      </ng-template>
    </div>
  </dx-list>
</div>

<dx-form
  #formRef
  class="mt-2"
  *var="formData$ | async as formData"
  [formData]="formData"
  labelMode="floating"
  [validationGroup]="validationGroup"
  [readOnly]="!!formData?.dbId || isReadonly"
  [screenByWidth]="formSize$ | async | formScreenByWidth : screenLimits"
  (onInitialized)="onFormInitialized($event)"
>
  <dxi-item itemType="group" [colCountByScreen]="colCountByScreen">
    <dxi-item
      [colSpan]="3"
      [dataField]="EmailAddressKeys.address"
      [label]="{ text: label }"
      [isRequired]="isRequired"
      [editorOptions]="{
        name: [pathPrefix, EmailAddressKeys.address] | path
      }"
    >
      <dxi-validation-rule type="email" [message]="Messages.INVALID_EMAIL_FORMAT_TEXT"></dxi-validation-rule>

      <dxi-validation-rule *ngIf="isRequired" type="required" [message]="Messages.EMAIL_REQUIRED"></dxi-validation-rule>
    </dxi-item>
  </dxi-item>
</dx-form>
