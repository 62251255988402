import { Pipe, PipeTransform } from '@angular/core';
import { SOCIAL_MEDIA_LOOKUP_VALUE, Social } from '@ag-common-lib/public-api';

@Pipe({ name: 'agentCampaignSocialMediaDataSource' })
export class AgentCampaignSocialMediaDataSourcePipe implements PipeTransform {
  constructor() {}

  transform(currentStep: Social[], socialMedia: SOCIAL_MEDIA_LOOKUP_VALUE): Social[] {
    return currentStep.filter(social => social.social_media === socialMedia);
  }
}
