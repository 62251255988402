import { MeetingLocationTypeKeys } from '../../lists/meeting-location-types.enum';
import { MeetingOutcomeKeys } from '../../lists/meeting-outcomes.enum';
import { ActivityBase } from './activity-base.model';
import { PhysicalLocation } from './physical-location.model';

export enum MeetingLogKeys {
  title = 'title',
  hostOwnerId = 'hostOwnerId',
  meetingOutcome = 'meetingOutcome',
  date = 'date',
  time = 'time',
  duration = 'duration',
  isRecurring = 'isRecurring',
  locationType = 'locationType',
  locationDetails = 'locationDetails',
  attendees = 'attendees',
  externalAttendees = 'externalAttendees',
  detailsAgenda = 'detailsAgenda',
  physicalLocation = 'physicalLocation',
}

export class MeetingLog extends ActivityBase {
  [MeetingLogKeys.title]: string;
  [MeetingLogKeys.hostOwnerId]: string;
  [MeetingLogKeys.meetingOutcome]: MeetingOutcomeKeys;
  [MeetingLogKeys.date]: Date;
  [MeetingLogKeys.time]?: string;
  [MeetingLogKeys.duration]?: string;
  [MeetingLogKeys.isRecurring]?: boolean;
  [MeetingLogKeys.locationType]: MeetingLocationTypeKeys;
  [MeetingLogKeys.physicalLocation]?: PhysicalLocation;
  [MeetingLogKeys.locationDetails]?: string;
  [MeetingLogKeys.attendees]?: string[];
  [MeetingLogKeys.externalAttendees]?: string[];
  [MeetingLogKeys.detailsAgenda]?: string;
}
