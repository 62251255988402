import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Messages } from '@ag-common-lib/public-api';
import { DxTextAreaComponent } from 'devextreme-angular';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { IAspectRatio } from './drop-zone.model';

@Component({
  selector: 'ag-shr-drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.scss'],
})
export class DropZoneComponent {
  @ViewChild('urlTextBoxRef', { static: true }) urlTextBoxComponent: DxTextAreaComponent;
  @ViewChild('imageCropModalRef', { static: true }) imageCropModalModalComponent: ModalWindowComponent;
  @ViewChild('fileInput', { static: false }) fileInputComponent: ElementRef<HTMLInputElement>;
  @Input() set pictureUrl(data: string) {
    this._isImageValid$.next(!!data);
    this.imagePreviewUrl = data;
  }
  @Input() textBoxLabel: string = 'Profile Picture Url';
  @Input() imageCroperTitle: string = 'Crop Profile Picture';
  @Input() roundCropper = true;
  @Input() ratio: IAspectRatio = { w: 3, h: 4 };
  @Input() showToolbar = true;
  @Input() showURL = true;
  @Input()
  set containWithinAspectRatio(v: boolean) {
    this.byContainWithinAspectRatio = v || false;
  }
  @Output() pictureUrlChange = new EventEmitter<string | null>();

  imagePreviewUrl: string;
  imageChangedEvent: any = '';
  imageCroppedEvent: ImageCroppedEvent;
  byContainWithinAspectRatio = false;
  scale = 1;
  transform: ImageTransform = {};

  isImageValid$: Observable<boolean>;
  readonly Messages = Messages;
  private _isImageValid$ = new BehaviorSubject(true);

  constructor() {
    this.isImageValid$ = this._isImageValid$.asObservable();
  }

  // todo: always false
  customValidationCallback = (): boolean => {
    return false;
  };

  onImgError = (): void => {
    this._isImageValid$.next(false);
    this.urlTextBoxComponent?.instance.option('isValid', false);
  };

  onImgLoaded = (): void => {
    this._isImageValid$.next(true);
    this.urlTextBoxComponent?.instance.option('isValid', true);
  };

  onUrlValueChanged = (e): void => {
    this.pictureUrlChange.emit(e?.value ?? null);
    this.urlTextBoxComponent?.instance.option('isValid', true);
  };

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.imageCroppedEvent = null;
    this.imageCropModalModalComponent.showModal();
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.imageCroppedEvent = event;
  }

  handleApplyCrop = (): void => {
    this.imagePreviewUrl = this.imageCroppedEvent.base64;
    this.imageCropModalModalComponent.hideModal();
  };

  handleCancelCrop = (): void => {
    this.fileInputComponent.nativeElement.value = null;
    this.imageChangedEvent = null;
    this.imageCroppedEvent = null;
  };

  toggleContainWithinAspectRatio(): void {
    this.byContainWithinAspectRatio = !this.byContainWithinAspectRatio;
  }

  resetImage(): void {
    this.scale = 1;
    this.transform = {};
    this.byContainWithinAspectRatio = false;
  }

  zoomOut(): void {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn(): void {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }
}
