import { MMDDYYYY_DATE_FORMAT } from '@ag-common-lib/public-api';
import { addDays, format, fromUnixTime, isDate, isValid, parse } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

export const dateFromTimestamp = item => {
  if (!item) {
    return null;
  }

  if (isDate(item)) {
    return item;
  }

  if (typeof item === 'string') {
    return parseStringDate(item);
  }

  let normalizedDate;

  if (item?.seconds) {
    normalizedDate = fromUnixTime(item.seconds);
  }

  return isValid(normalizedDate) ? normalizedDate : null;
};

export const localDateTimeFromTimestamp = (item, formatString: string = "yyyy-MM-dd'T'HH:mm:ss") => {
  if (!item) {
    return null;
  }

  if (isDate(item)) {
    return item;
  }

  if (typeof item === 'string') {
    return item;
  }

  if (item?.seconds) {
    const timestampDate = (item as Timestamp).toDate();
    const utcFullYear = timestampDate.getUTCFullYear();
    const utcMonth = timestampDate.getUTCMonth();
    const utcDate = timestampDate.getUTCDate();
    const utcHours = timestampDate.getUTCHours();
    const utcMinutes = timestampDate.getUTCMinutes();
    const localDate = new Date(utcFullYear, utcMonth, utcDate, utcHours, utcMinutes);

    return format(utcHours > 11 ? addDays(localDate, 1) : localDate, formatString);
  }

  return null;
};

const parseStringDate = (dateString: string): null | Date => {
  //console.warn('Wrong Date format detected: all dates must be stored as Timestamp in DB', dateString);

  if (!dateString) {
    return null;
  }
  if (dateString.match(/dd\/dd\/dddd/)) {
    const date = parse(dateString, MMDDYYYY_DATE_FORMAT, new Date());
    return isValid(date) ? date : null;
  }
  //console.warn('Wrong date format detected: unsupported string format', dateString);
  return dateString as any;
};
