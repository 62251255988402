import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { NotificationCampaignModalComponent } from './notification-campaign-modal.component';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { HtmlEditorModule } from '../../../html-editor/html-editor.module';

@NgModule({
  declarations: [NotificationCampaignModalComponent],
  imports: [CommonModule, SharedModule, ModalWindowModule, HtmlEditorModule],
  exports: [NotificationCampaignModalComponent],
})
export class NotificationCampaignModalModule {}
