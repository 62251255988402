import { NgModule } from '@angular/core';
import { AgentCampaignEmailAddressInfoComponent } from './agent-campaign-email-address-info.component';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ButtonWithIndicatorModule } from '../../button-with-indicator/button-with-indicator.module';
import { StateSelectBoxModule } from '../../state-select-box/state-select-box.module';
import { AgentCampaignEmailAddressInfoService } from './agent-campaign-email-address-info.service';
import { DxListModule, DxRadioGroupModule } from 'devextreme-angular';
import { SelectEmailAddressModule } from '../../select-email-address/select-email-address.module';

@NgModule({
  declarations: [AgentCampaignEmailAddressInfoComponent],
  imports: [SharedModule, ButtonWithIndicatorModule, SelectEmailAddressModule],
  exports: [AgentCampaignEmailAddressInfoComponent],
  providers: [AgentCampaignEmailAddressInfoService],
})
export class AgentCampaignEmailAddressInfoModule {}
