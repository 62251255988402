import { LookupKeys } from '../crm/lookup.model';
import { BaseModel } from '../base.model';

export enum PaymentIntentPayloadKeys {
  amount = 'amount',
}

export interface PaymentIntentPayload {
  [PaymentIntentPayloadKeys.amount]: number;
}
export enum ProcessPaymentPayloadKeys {
  token = 'token',
  amount = 'amount', //Amount in cents
  currency = 'currency', // Three-letter ISO currency code , in lowercase. Must be a supported currency.
  description = 'description',
}

export interface ProcessPaymentPayload {
  [ProcessPaymentPayloadKeys.token]: string;
  [ProcessPaymentPayloadKeys.amount]: number;
  [ProcessPaymentPayloadKeys.currency]: string;
  [ProcessPaymentPayloadKeys.description]: string;
}

export enum RefundReasonKeys {
  duplicate = 'duplicate',
  fraudulent = 'fraudulent',
  requestedByCustomer = 'requested_by_customer',
}

export const REFUND_REASON_LOOKUP = [
  {
    [LookupKeys.value]: RefundReasonKeys.duplicate,
    [LookupKeys.description]: 'Duplicate',
  },
  {
    [LookupKeys.value]: RefundReasonKeys.fraudulent,
    [LookupKeys.description]: 'Fraudulent',
  },
  {
    [LookupKeys.value]: RefundReasonKeys.requestedByCustomer,
    [LookupKeys.description]: 'Requested By Customer',
  },
];

export enum ProcessRefundPayloadKeys {
  paymentIntent = 'payment_intent',
  amount = 'amount',
  reason = 'reason',
  charge = 'charge',
  isFullRefund = 'isFullRefund',
  description = 'description',
}

export class ProcessRefundPayload extends BaseModel {
  [ProcessRefundPayloadKeys.paymentIntent]: string; // The identifier of the PaymentIntent to refund.
  [ProcessRefundPayloadKeys.amount]?: number; // how much of this charge to refund
  [ProcessRefundPayloadKeys.reason]?: RefundReasonKeys;
  [ProcessRefundPayloadKeys.description]?: string;
}
