import { Component, Input } from '@angular/core';
import { AgMediaUploaderService } from '../../ag-media-uploader/ag-media-uploader.service';
import { AGMedia } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-select-from-gallery',
  templateUrl: './select-from-gallery.component.html',
  styleUrls: ['./select-from-gallery.component.scss'],
})
export class SelectFromGalleryComponent {
  @Input() mediaItems: AGMedia[];

  constructor(private mediaUploaderService: AgMediaUploaderService) {}

  selectedItemsChange([selectedItem]: AGMedia[]) {
    this.mediaUploaderService.setSelectedFromGallery(selectedItem);
  }
}
