<div class="row my-2 px-3">
  <div class="col-12 text-start editToolbar">
    <dx-toolbar>
      <dxi-item location="before" widget="dxButton" locateInMenu="never">
        <div>
          <div class="toolbar-label">
            <b>{{ title }}</b>
          </div>
        </div>
      </dxi-item>
      <dxi-item location="after" locateInMenu="never">
        <div>
          <dx-button [visible]="isEditVisible" [icon]="'edit'" [stylingMode]="'text'" (onClick)="clickEdit.emit(true)"></dx-button>
        </div>
      </dxi-item>
    </dx-toolbar>
  </div>
</div>
