export enum ConferenceStepsTemplateArgumentName {
  excursionBudget = 'Excursion_Budget',
  flightAgentName = 'Flight_Agent_Name',
  primaryArrivalDate = 'Primary_Arrival_Date',
  primaryDepartureDate = 'Primary_Departure_Date',
}

export enum ConferenceStepsTemplateArgumentKey {
  id = 'id',
  value = 'value',
}

export interface ConferenceStepsTemplateArgument {
  [ConferenceStepsTemplateArgumentKey.id]: ConferenceStepsTemplateArgumentName;
  [ConferenceStepsTemplateArgumentKey.value]: string;
}

export const ConferenceStepsArgumentVariables: ConferenceStepsTemplateArgumentName[] = [
  ConferenceStepsTemplateArgumentName.excursionBudget,
  ConferenceStepsTemplateArgumentName.flightAgentName,
  ConferenceStepsTemplateArgumentName.primaryArrivalDate,
  ConferenceStepsTemplateArgumentName.primaryDepartureDate,
];
