import { Inject, Injectable } from '@angular/core';
import { AttendeeKeys, BaseModelKeys, GuestData, GuestKeys } from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';
import { FIREBASE_APP } from '../injections/firebase-app';
import { CommonFireStoreDao, localDateTimeFromTimestamp, QueryParam, WhereFilterOperandKeys } from '../../public-api';
import { CONFERENCES_COLLECTION_PATH } from './conference.service';
import { ToastrService } from 'ngx-toastr';
import { CONFERENCE_REGISTRANTS_COLLECTION_PATH } from './conference-registrants/conference-registrants.service';
import { catchError, map, Observable, throwError } from 'rxjs';
import { compareAsc } from 'date-fns';
import { normalizeFlightData } from '../utils/flight-data.utils';
import { normalizeSelectedExcursions } from '../utils/excursions-data.utils';

@Injectable({
  providedIn: 'root',
})
export class ConferenceGuestsService {
  readonly fsDao: CommonFireStoreDao<GuestData>;
  private readonly conferenceCollectionPath = CONFERENCES_COLLECTION_PATH;
  private readonly registrantsCollectionPath = CONFERENCE_REGISTRANTS_COLLECTION_PATH;
  private readonly registrantGuestsCollectionPath = 'guests';

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp, private toastrService: ToastrService) {
    this.fsDao = new CommonFireStoreDao<GuestData>(fireBaseApp, ConferenceGuestsService.fromFirestore, null);
  }

  static readonly fromFirestore = (data): GuestData => {
    return Object.assign({}, data, {
      [AttendeeKeys.dob]: localDateTimeFromTimestamp(data?.dob),

      [GuestKeys.selectedExcursions]: normalizeSelectedExcursions(data?.[GuestKeys.selectedExcursions]),
      [GuestKeys.flightInformation]: normalizeFlightData(data?.[GuestKeys.flightInformation] || {}),
    });
  };

  getList(conferenceDbId: string, registrantDbId: string, qp: QueryParam[] = []) {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    return this.fsDao.getList(table, qp).pipe(
      map(guests => {
        guests.sort((left, right) => {
          const isLeftComplimentary = left?.[GuestKeys.isComplimentary];
          const isRightComplimentary = right?.[GuestKeys.isComplimentary];
          const isLeftCreatedDate = left?.[BaseModelKeys.createdDate];
          const isRightCreatedDate = right?.[BaseModelKeys.createdDate];
          if ((isLeftComplimentary && isRightComplimentary) || (!isLeftComplimentary && !isRightComplimentary)) {
            return compareAsc(isLeftCreatedDate, isRightCreatedDate);
          }

          if (isLeftComplimentary) {
            return -1;
          }

          return 1;
        });

        return guests;
      }),
    );
  }

  getConferenceGuestsByConferenceId(conferenceDbId: string): Observable<GuestData[]> {
    let qp: QueryParam[] = [];
    qp.push(new QueryParam(GuestKeys.conferenceDbId, WhereFilterOperandKeys.equal, conferenceDbId));

    return this.fsDao.getCollectionGroupSnapshot(this.registrantGuestsCollectionPath, qp).pipe(
      map(snapshot => {
        return snapshot.docs.map(doc => {
          if (!doc.exists()) {
            return null;
          }
          const data = doc.data();

          return data;
        });
      }),
    );
  }

  getGuestsByDbIdAndConferenceId(guestDbId: string, conferenceDbId: string): Observable<GuestData[]> {
    const qp: QueryParam[] = [];
    qp.push(new QueryParam(GuestKeys.conferenceDbId, WhereFilterOperandKeys.equal, conferenceDbId));
    qp.push(new QueryParam(BaseModelKeys.dbId, WhereFilterOperandKeys.equal, guestDbId));

    return this.fsDao.getCollectionGroupSnapshot(this.registrantGuestsCollectionPath, qp).pipe(
      map(snapshot => {
        return snapshot.docs
          .map(doc => {
            if (!doc.exists()) {
              return null;
            }
            const data = doc.data();
            return data;
          })
          .filter(doc => doc !== null); // Filter out null values
      }),
      catchError(error => {
        console.error('Error fetching guests:', error);
        // Handle your error appropriately
        return throwError(() => new Error('Error fetching guests.'));
      }),
    );
  }

  getDocumentData(conferenceDbId: string, registrantDbId: string, documentId: string): Observable<GuestData> {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    return this.fsDao.getDocument(table, documentId).pipe(
      map(snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          return data;
        }
        return null;
      }),
    );
  }

  async create(conferenceDbId: string, registrantDbId: string, data: GuestData, silent = false) {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    Object.assign(data, {
      [GuestKeys.conferenceDbId]: conferenceDbId,
      [GuestKeys.registrantDbId]: registrantDbId,
    });

    const registrant = await this.fsDao.create(data, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success('Conference Registration Successfully Created!');

    return registrant;
  }

  async update(
    conferenceDbId: string,
    registrantDbId: string,
    documentId: string,
    updates: Partial<GuestData>,
    silent = false,
  ) {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    const registrant = await this.fsDao.updateFields(updates, documentId, table).catch(e => {
      console.log('e', e);
      this.toastrService.error('Conference Registration is Not Updated! Please try again later!');
      throw new Error(e);
    });

    !silent && this.toastrService.success('Conference Registration Successfully Updated!');

    return registrant;
  }

  delete(conferenceDbId: string, registrantDbId: string, documentId: string, silent = false) {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    return this.fsDao.delete(documentId, table).then(response => {
      !silent && this.toastrService.success('Agent Email Address Removed!');
      return response;
    });
  }

  private getCollectionPath(conferenceId: string, registrantDbId: string) {
    return [
      this.conferenceCollectionPath,
      conferenceId,
      this.registrantsCollectionPath,
      registrantDbId,
      this.registrantGuestsCollectionPath,
    ].join('/');
  }
}
