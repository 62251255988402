import { BaseModel } from '../base.model';
import { ACTIVITY_TYPE } from '../engagement/types/activity-types.enum';
import { TARGET_TYPE } from '../engagement/types/target-types.enum';

export enum ActivityBaseKeys {
  author = 'author',
  ownerId = 'owner',
  targetId = 'target',
  targetType = 'target_type',
  activityType = 'activity_type',
  comments = 'comments',
  activityTemplate = 'activityTemplate',
}

export class ActivityBase extends BaseModel {
  [ActivityBaseKeys.author]?: string;
  [ActivityBaseKeys.ownerId]?: string;
  [ActivityBaseKeys.targetId]: string;
  [ActivityBaseKeys.targetType]: TARGET_TYPE;
  [ActivityBaseKeys.activityType]?: ACTIVITY_TYPE;
  [ActivityBaseKeys.comments]?: Comment[] = [];
}
