import { Pipe, PipeTransform } from '@angular/core';
import { OfficeEmailAddress } from '@ag-common-lib/public-api';

@Pipe({ name: 'AgencyPrimaryEmailAddress' })
export class AgencyPrimaryEmailAddressPipe implements PipeTransform {
  transform(emails: OfficeEmailAddress[]): string {
    const emailAddress = Array.isArray(emails)
      ? emails.find(emailAddress => {
          return emailAddress?.is_primary;
        }) ?? null
      : null;

    return emailAddress?.address;
  }
}
