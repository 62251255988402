<div class="transaction-details-container">
  <dx-data-grid
    class="transaction-details-table mb-3"
    [dataSource]="transactionDetails"
    [showBorders]="true"
    [showColumnLines]="true"
    [renderAsync]="true"
    [columnAutoWidth]="true"
    [allowColumnReordering]="false"
    [allowColumnResizing]="true"
    width="100%"
    height="auto"
  >
    <dxo-filter-row [visible]="false"></dxo-filter-row>
    <dxo-header-filter [visible]="false"></dxo-header-filter>
    <dxo-paging [enabled]="false"></dxo-paging>
    <!-- <dxo-sorting [mode]="false"></dxo-sorting> -->

    <dxo-toolbar>
      <dxi-item location="before">
        <b *dxTemplate class="master-detail-caption ms-2">Transactions to Pay :</b>
      </dxi-item>
    </dxo-toolbar>

    <dxi-column [dataField]="AgStripeSubtotalKeys.subtotalType">
      <dxo-lookup
        [dataSource]="SUBTOTAL_TYPE_LOOKUP"
        [displayExpr]="LookupKeys.description"
        [valueExpr]="LookupKeys.value"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="AgStripeSubtotalKeys.description"></dxi-column>

    <dxi-column
      [dataField]="AgStripeSubtotalKeys.amount"
      [dataType]="'number'"
      [format]="DX_USD_CURRENCY_FORMAT"
      [alignment]="'right'"
      [editorOptions]="{ format: DX_USD_CURRENCY_FORMAT }"
      width="auto"
      [minWidth]="150"
    ></dxi-column>

    <dxo-summary [calculateCustomSummary]="calculateCustomSummary">
      <dxi-total-item
        *ngIf="!!excursionsBudget"
        [showInColumn]="AgStripeSubtotalKeys.amount"
        summaryType="custom"
        [valueFormat]="DX_USD_CURRENCY_FORMAT"
        name="total"
        cssClass="transaction-details-table__total"
        displayFormat="Total Cost: {0}"
      >
      </dxi-total-item>

      <dxi-total-item
        *ngIf="!!excursionsBudget"
        [showInColumn]="AgStripeSubtotalKeys.amount"
        summaryType="custom"
        [valueFormat]="DX_USD_CURRENCY_FORMAT"
        name="discount"
        cssClass="transaction-details-table__discount"
        displayFormat="Credit: -{0}"
      >
      </dxi-total-item>

      <dxi-total-item
        [showInColumn]="AgStripeSubtotalKeys.amount"
        summaryType="custom"
        [valueFormat]="DX_USD_CURRENCY_FORMAT"
        name="totalCharge"
        cssClass="transaction-details-table__total-charge"
        displayFormat="Total Charge: {0}"
      >
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>
