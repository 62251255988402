import { MappingProperty } from '@elastic/elasticsearch/lib/api/types';

export const DocumentIdsKeyMapping: {
  [key: string]: MappingProperty;
} = {
  conferenceDbId: { type: 'keyword' },
  registrantDbId: { type: 'keyword' },
};

export const DocumentWriteLogKeyMapping: {
  [key: string]: MappingProperty;
} = {
  wasabiPath: { type: 'keyword' },
  date: { type: 'keyword' },
  updatedBy: { type: 'keyword' },
  updatedFields: { type: 'object' }, // for string[]
  collection: { type: 'text' },
  action: { type: 'text' },
  documentIds: { type: 'nested', properties: DocumentIdsKeyMapping },
};
