import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { WizardStep, ConferenceRegistrationLog } from '@ag-common-lib/public-api';
import { GetSupportModalComponent } from '../get-support-modal/get-support-modal.component';
import { DxScrollViewComponent } from 'devextreme-angular';

@Component({
  selector: 'ag-shr-wizard-stepper',
  templateUrl: './wizard-stepper.component.html',
  styleUrls: ['./wizard-stepper.component.scss'],
})
export class WizardStepperComponent<T> implements AfterViewInit {
  @HostBinding('class') className = 'wizard-stepper';
  @ViewChildren('stepRef') stepRefs: QueryList<ElementRef<HTMLDivElement>>;
  @ViewChild('scrollViewRef', { static: true }) dxScrollViewComponent: DxScrollViewComponent;
  @ViewChild('getSupportModalRef', { static: true }) getSupportModalComponent: GetSupportModalComponent;

  @Input() withSupportButton = true;
  @Input() steps: WizardStep<T>[];
  @Input() currentStepName: string;
  @Output() setCurrentStep = new EventEmitter<WizardStep<T>>();
  @Input() onGetSupport: (details: string) => Promise<void>;
  @Input() supportTickets: ConferenceRegistrationLog[];
  @Input() supportEmail: string;
  @Input() supportPhone: string;

  popupTarget = null;

  ngAfterViewInit(): void {
    this.scrollToCurrentStep();
  }

  scrollToCurrentStep() {
    const stepElements = this.stepRefs.toArray();
    const currentStep = stepElements.find(element =>
      element.nativeElement.classList.contains('wizard-stepper__step--current'),
    );

    if (currentStep) {
      currentStep.nativeElement.scrollIntoView({ block: 'start' });

      setTimeout(() => {
        this.dxScrollViewComponent.instance.update();
      }, 0);
    }
  }

  onSetCurrentStep = (step: WizardStep<T>) => {
    if (!step?.steps?.length || step.hasView) {
      this.setCurrentStep.emit(step);
      return;
    }
    this.setCurrentStep.emit(step?.steps[0]);
  };

  openInstructionsPopup = (popupTarget: string) => {
    this.popupTarget = popupTarget;
  };

  closeInstructionsPopup = () => {
    this.popupTarget = null;
  };

  showGetSupportModal = () => {
    this.getSupportModalComponent.showPopup();
  };
}
