import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  AgencyCarrier,
  AgencyCarrierModelKeys,
  BaseModelKeys,
  Carrier,
  CARRIER_LINE_OF_BUSINESS_LOOKUP,
  CARRIER_ORGANIZATION_LOOKUP,
  CarrierModelKeys,
} from '@ag-common-lib/public-api';
import { ModalWindowComponent } from '../../../../modal-window/modal-window.component';
import { DxFormComponent } from 'devextreme-angular';
import { AgencyCarriersModalService } from '../agency-carriers-modal.service';

@Component({
  selector: 'ag-shr-agency-carriers-modal',
  templateUrl: './agency-carriers-modal.component.html',
  styleUrls: ['./agency-carriers-modal.component.scss'],
})
export class AgencyCarriersModalComponent {
  @ViewChild('agencyCarrierFormRef', { static: false }) agencyCarrierFormComponent: DxFormComponent;
  @ViewChild('agencyCarrierFormModalRef', { static: true }) agencyCarrierFormModalComponent: ModalWindowComponent;
  @Input() carriersLookup: Carrier[];
  @Output() carrierChange = new EventEmitter<AgencyCarrier>();

  agencyCarrierFormData: AgencyCarrier;
  agencyId: string;
  readonly inProgress$ = this.agencyCarriersModalService.inProgress$;
  readonly AgencyCarrierModelKeys = AgencyCarrierModelKeys;
  readonly BaseModelKeys = BaseModelKeys;
  readonly CarrierModelKeys = CarrierModelKeys;
  protected readonly carrierLineOfBusinessLookup = CARRIER_LINE_OF_BUSINESS_LOOKUP;
  protected readonly carrierOrganizationLookup = CARRIER_ORGANIZATION_LOOKUP;

  constructor(private agencyCarriersModalService: AgencyCarriersModalService) {}

  handleSaveAgencyCarrier = e => {
    const validationResults = this.agencyCarrierFormComponent.instance.validate();
    if (validationResults.isValid) {
      this.carrierChange.emit(this.agencyCarrierFormData);
      e.component.instance.hide();
    }
  };

  onAfterRevertChanges = (): void => {
    this.agencyCarrierFormComponent.instance.repaint();
  };

  handleClosePopup = () => this.agencyCarriersModalService.onCancelEdit.call(this, this.onAfterRevertChanges);

  showModal = async (agencyId: string, data?: AgencyCarrier): Promise<void> => {
    this.agencyId = agencyId;
    this.agencyCarrierFormData = await this.agencyCarriersModalService.getFormData(data);
    this.agencyCarrierFormModalComponent?.showModal();
  };
}
