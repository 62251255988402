import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import {
  Agent,
  APPROVE_DENY_REASON_VISIBILITY_LEVEL_LOOKUP,
  ApproveDenyReason,
  ApproveDenyReasonKeys,
  BaseModelKeys,
  Lookup,
  LookupKeys,
  MMDDYYYY_DATE_TIME_FORMAT,
} from '@ag-common-lib/public-api';
import { DxFormComponent } from 'devextreme-angular';
import { map } from 'rxjs/operators';
import { ApproveDenyReasonModalService } from './approve-deny-reasons-modal.service';
import { AgentService } from '../../../../../services/agent.service/agent.service';
import { ModalWindowComponent } from '../../../../modal-window/modal-window.component';

@Component({
  selector: 'ag-shr-approve-deny-reasons-modal',
  templateUrl: './approve-deny-reasons-modal.component.html',
  styleUrls: ['./approve-deny-reasons-modal.component.scss'],
  providers: [ApproveDenyReasonModalService],
})
export class ApproveDenyReasonsModalComponent {
  @HostBinding('class') className = 'approve-deny-reasons-modal';
  @ViewChild('approveDenyReasonModalRef', { static: true }) approveDenyReasonModalComponent: ModalWindowComponent;
  @ViewChild('approveDenyReasonFormRef', { static: false }) approveDenyReasonFormComponent: DxFormComponent;
  @Input() title: string;
  @Input() isVisibilityTypeLocked = false;

  inProgress$ = this.approveDenyReasonModalService.inProgress$;
  agentsDataSource$ = this.agentService.getList().pipe(
    map((response): Partial<Lookup>[] =>
      Array.isArray(response)
        ? response.map((agent: Agent) => {
            const description = [agent?.p_agent_first_name, agent?.p_agent_last_name].filter(Boolean).join(' ');
            const value = agent?.[BaseModelKeys.dbId];

            return {
              key: agent?.dbId,
              [LookupKeys.value]: value,
              [LookupKeys.description]: description,
            };
          })
        : [],
    ),
  );

  readonly BaseModelKeys = BaseModelKeys;
  readonly ApproveDenyReasonKeys = ApproveDenyReasonKeys;
  readonly approveDenyReasonVisibilityLevelLookup = APPROVE_DENY_REASON_VISIBILITY_LEVEL_LOOKUP;
  readonly dateFormat = MMDDYYYY_DATE_TIME_FORMAT;
  approveDenyReasonFormData: ApproveDenyReason;

  private agentId: string;

  constructor(
    private agentService: AgentService,
    private approveDenyReasonModalService: ApproveDenyReasonModalService,
  ) {}

  showModal = async (agentId: string, data?: ApproveDenyReason): Promise<void> => {
    this.agentId = agentId;
    this.approveDenyReasonFormData = await this.approveDenyReasonModalService.getFormData(data);
    this.approveDenyReasonModalComponent?.showModal();
  };

  handleSaveApproveDenyReason = e => {
    const validationResults = this.approveDenyReasonFormComponent.instance.validate();
    if (validationResults.isValid) {
      this.approveDenyReasonModalService.saveApproveDenyReason(this.agentId).then(() => {
        e.component.instance.hide();
      });
    }
  };

  handleApproveDenyReasonFormPopupClose = this.approveDenyReasonModalService.onCancelEditApproveDenyReason;
}
