import { Injectable } from '@angular/core';
import { Lookup, LookupKeys, Social } from '@ag-common-lib/public-api';
import { BehaviorSubject } from 'rxjs';
import { BaseFormService } from '../../utils/base-form-service';
import { updateDoc } from 'firebase/firestore';

@Injectable()
export class SocialsService extends BaseFormService<Social> {
  selectedSocialType$ = new BehaviorSubject(null);
  selectedSocialMedia$ = new BehaviorSubject(null);

  constructor() {
    super();
  }

  async onUpdateSocials(): Promise<void> {
    const selectedSocialType = await this.selectedSocialType$?.value;
    const selectedSocialMedia = await this.selectedSocialMedia$?.value;
    if (selectedSocialType && !selectedSocialType?.isAssigned) {
      updateDoc(selectedSocialType?.reference, { [LookupKeys.isAssigned]: true }).then();
    }
    if (selectedSocialMedia && !selectedSocialMedia?.isAssigned) {
      updateDoc(selectedSocialMedia?.reference, { [LookupKeys.isAssigned]: true }).then();
    }
  }

  setSelectedSocialTypeChange(lookup: Lookup): void {
    this.selectedSocialType$.next(lookup || null);
  }

  setSelectedSocialMedia(lookup: Lookup): void {
    this.selectedSocialMedia$.next(lookup || null);
  }
}
