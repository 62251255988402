<div class="agent-campaign-wizard__content">
  <ng-content></ng-content>

  <dx-scroll-view height="100%">
    <div class="agent-campaign-logo-headshot__container">
      <div class="agent-campaign-logo-headshot__item-content">
        <span class="agent-campaign-logo-headshot__title dx-form-group-caption">Logo Image</span>
        <ag-shr-agent-campaign-picture
          class="agent-campaign-logo-headshot__picture"
          [media]="logoHeadshotFormData?.logo"
          [canEdit]="canEdit"
          (mediaChanged)="onMediaChanged($event, 'logo')"
          (mediaCropped)="onMediaCropped($event, 'logo')"
        ></ag-shr-agent-campaign-picture>
      </div>

      <div class="agent-campaign-logo-headshot__item-content">
        <span class=" agent-campaign-logo-headshot__title dx-form-group-caption">Headshot Image</span>
        <ag-shr-agent-campaign-picture
          class="agent-campaign-logo-headshot__picture"
          [media]="logoHeadshotFormData?.headshot"
          [canEdit]="canEdit"
          (mediaChanged)="onMediaChanged($event, 'headshot')"
          (mediaCropped)="onMediaCropped($event, 'headshot')"
        ></ag-shr-agent-campaign-picture>
      </div>

      <div class="agent-campaign-logo-headshot__item-content">
        <span class="agent-campaign-logo-headshot__title dx-form-group-caption">Favicon</span>
        <ag-shr-agent-campaign-picture
          class="agent-campaign-logo-headshot__picture"
          [media]="logoHeadshotFormData?.favicon"
          [canEdit]="canEdit"
          (mediaChanged)="onMediaChanged($event, 'favicon')"
          (mediaCropped)="onMediaCropped($event, 'favicon')"
        ></ag-shr-agent-campaign-picture>
      </div>

      <div class="agent-campaign-logo-headshot__item-content">
        <span class="agent-campaign-logo-headshot__title dx-form-group-caption">Social Sharing Image</span>
        <ag-shr-agent-campaign-picture
          class="agent-campaign-logo-headshot__picture"
          [media]="logoHeadshotFormData?.socialSharingImage"
          [canEdit]="canEdit"
          (mediaChanged)="onMediaChanged($event, 'socialSharingImage')"
          (mediaCropped)="onMediaCropped($event, 'socialSharingImage')"
        ></ag-shr-agent-campaign-picture>
      </div>

    </div>

  </dx-scroll-view>
</div>

<div class="agent-campaign-wizard__controls-container" *ngIf="canEdit">
  <dx-button
    class="modal-window__toolbar-button"
    text="CANCEL CHANGES"
    [disabled]="!(hasFormChanges$ | async)"
    (onClick)="resetChanges()"
  ></dx-button>

  <shr-button-with-indicator
    [title]="hasStepIssues ? 'RESOLVE ISSUE' :'NEXT'"
    [disabled]="hasStepIssues && !(hasFormChanges$ | async)"
    [cssClass]="'modal-window__toolbar-button'"
    [isInProgress]="inProgress$ | async"
    (onClick)="nextStep()"
  ></shr-button-with-indicator>
</div>
