<ag-shr-agent-campaign-summary-list-item [title]="'Bio'">
  <ng-container agCampaignsActions>
    <ng-content select="[agCampaignsControls]"></ng-content>
  </ng-container>

  <div *ngFor="let item of list">

    <ag-shr-agent-campaign-summary-list-data
      [title]="item?.title"
      [hasCurrentData]="!!item?.currentStepData"
      [hasIncomingData]="!!item?.incomingStepData"
    >
      <ng-template
        #dataTmp
        let-data="data"
      >

        <div class="agent-campaign-summary-bio__data">
          <dx-scroll-view
            direction="vertical"
            class="agent-campaign-summary-bio__scroll-view"
          >
            {{data}}
          </dx-scroll-view>
        </div>

      </ng-template>

      <ng-container
        currentDataTmp
        *ngTemplateOutlet="dataTmp; context: { data: item?.currentStepData }"
      >
      </ng-container>

      <ng-container
        incomingDataTmp
        *ngTemplateOutlet="dataTmp; context: { data: item?.incomingStepData}"
      >
      </ng-container>

      <ng-container
        controls
        *var="item?.incomingStepData as data"
      >
        <ag-shr-clipboard-button [clipboardValue]="data"></ag-shr-clipboard-button>
      </ng-container>

    </ag-shr-agent-campaign-summary-list-data>
  </div>

</ag-shr-agent-campaign-summary-list-item>
