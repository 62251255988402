import { Options } from 'nodemailer/lib/mailer';
import { EmailTemplates } from './mailer.model';

export enum EmailToSendConfigKeys {
  mailOptions = 'mailOptions',
  template = 'template',
  context = 'context',
}

export interface EmailToSendConfig {
  [EmailToSendConfigKeys.mailOptions]: Options;
  [EmailToSendConfigKeys.template]: EmailTemplates;
  [EmailToSendConfigKeys.context]: any;
}

export interface BulkEmailSendingResponse {
  successful: string[];
  failed: string[];
}
