export interface WizardStep<T, K = null> {
  name: T;
  title: string;
  icon?: string;
  stepState?: WizardStepState;
  hasView?: boolean;
  steps?: WizardStep<T, K>[];
  data?: K;
}

export enum WizardStepState {
  initial = 'initial',
  locked = 'locked',
  skipped = 'skipped',
  changed = 'changed',
  issues = 'issues',
  done = 'done',
}

export const WizardStepStateMap = new Map([
  [WizardStepState.initial, 'Initial'],
  [WizardStepState.locked, 'Locked'],
  [WizardStepState.skipped, 'Skipped'],
  [WizardStepState.changed, 'Changed'],
  [WizardStepState.issues, 'Issues'],
  [WizardStepState.done, 'Done'],
]);
