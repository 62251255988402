import { BaseModel } from '../base.model';

export const PUSH_NOTIFICATIONS_CHANNELS_COLLECTION = 'push-notifications-channels';

export enum PushNotificationChannelsKeys {
  name = 'name',
  description = 'description',
  isDefault = 'isDefault',
  parentChannelDbId = 'parentChannelDbId',
}

export class PushNotificationChannel extends BaseModel {
  [PushNotificationChannelsKeys.name]?: string;
  [PushNotificationChannelsKeys.description]?: string;
  [PushNotificationChannelsKeys.isDefault]?: boolean = false;
  [PushNotificationChannelsKeys.parentChannelDbId]?: string = null;
}
