import { Address, AddressModelKeys } from '@ag-common-lib/public-api';
import { Injectable } from '@angular/core';
import { BaseFormService } from '../../utils/base-form-service';
import { FormChangesDetector } from 'ag-common-svc/shared/utils';
import * as uuid from 'uuid';
@Injectable()
export class AddressFormService extends BaseFormService<Address> {
  readonly validationChangesDetector: FormChangesDetector = new FormChangesDetector();

  constructor() {
    super();
  }
  getFormData = (address: Partial<Address>) => {
    if (address && !address[AddressModelKeys.id]) {
      address[AddressModelKeys.id] = uuid.v4();
    }
    this.formData = new Proxy(address, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);

        switch (prop) {
          case AddressModelKeys.address1:
          case AddressModelKeys.address2:
          case AddressModelKeys.city:
          case AddressModelKeys.state:
          case AddressModelKeys.zip:
          case AddressModelKeys.country:
          case AddressModelKeys.county:
            this.validationChangesDetector.handleChange(prop, value, prevValue);
            const validationResponse = target?.[AddressModelKeys.validationResponse];
            if (validationResponse?.result) {
              this.formData[AddressModelKeys.validationResponse] = {
                responseId: validationResponse?.responseId,
                result: null,
              };
            }
            break;
        }

        return true;
      },
    });

    return this.formData;
  };
}
