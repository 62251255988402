import { Component, HostBinding, Input } from '@angular/core';
import { ScreenLimits, TShirtSizes, TShirtSizesKeys } from '@ag-common-lib/public-api';
import { TShirtSeizesFormService } from './t-shirt-seizes-form.service';
import { InitializedEvent } from 'devextreme/ui/form';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ag-shr-t-shirt-seizes-form',
  templateUrl: './t-shirt-seizes-form.component.html',
  styleUrls: ['./t-shirt-seizes-form.component.scss'],
})
export class TShirtSeizesFormComponent {
  @HostBinding('class') protected className = 't-shirt-seizes-form';
  @Input() set tShirtSizes(data: Partial<TShirtSizes>) {
    this._formData = this.tShirtSeizesFormService.getFormData(data);
  }

  get tShirtSizes() {
    return this._formData;
  }

  @Input() isReadonly: boolean = false;
  @Input() pathPrefix: string;
  @Input() validationGroup: string;
  @Input() useFullWith: boolean = false;
  @Input() containerColCount: number = 1;
  @Input() colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 4,
  };
  @Input() screenLimits: ScreenLimits;
  @Input() isTShortSizeFieldVisible = true;
  @Input() isUnisexTShortSizeFieldVisible = true;
  @Input() isUnisexTShirtSizeFieldRequired = false;

  protected formWidth$ = new BehaviorSubject<number>(0);
  protected _formData: Partial<TShirtSizes>;

  protected selectedTShortSize$ = this.tShirtSeizesFormService.selectedTShortSize$;
  protected selectedUnisexTShortSize$ = this.tShirtSeizesFormService.selectedUnisexTShortSize$;
  protected readonly TShirtSizesKeys = TShirtSizesKeys;

  constructor(private tShirtSeizesFormService: TShirtSeizesFormService) {}

  protected handleTShortSizeSelect = this.tShirtSeizesFormService.handleTShortSizeSelect;

  protected handleUnisexTShortSizeSelect = this.tShirtSeizesFormService.handleUnisexTShortSizeSelect;

  protected onInit = (e: InitializedEvent) => {
    const nativeElement = e.component.$element()?.[0];

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === nativeElement) {
          const width = entry.contentRect.width;

          this.formWidth$.next(width);
        }
      }
    });

    resizeObserver.observe(nativeElement);
  };
}
