import { BaseModel } from '../base.model';
import { Agency } from './agency.model';

export enum CarrierModelKeys {
  carrierColor = 'carrier_color',
  carrierName = 'carrier_name',
  carrierImage = 'carrier_image',
  agencyList = 'agencyList',
  contactFirstName = 'contact_first_name',
  contactLastName = 'contact_last_name',
  contactEmail = 'contact_email',
  contactPhone = 'contact_phone',
}

export class Carrier extends BaseModel {
  [CarrierModelKeys.carrierColor]?: string;
  [CarrierModelKeys.carrierName]?: string;
  [CarrierModelKeys.carrierImage]?: string;
  [CarrierModelKeys.agencyList]?: Agency[] = [];
  [CarrierModelKeys.contactFirstName]?: string;
  [CarrierModelKeys.contactLastName]?: string;
  [CarrierModelKeys.contactEmail]?: string;
  [CarrierModelKeys.contactPhone]?: string;
}
