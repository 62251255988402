import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ImageWithLoaderComponent } from './image-with-loader.component';
import { SharedModule } from '../../../shared/shared.module';
import { DxLoadIndicatorModule } from 'devextreme-angular';
import { ImageLoadingStateDirective } from './image-loading-state.directive';

@NgModule({
  declarations: [ImageWithLoaderComponent, ImageLoadingStateDirective],
  imports: [CommonModule, SharedModule, NgOptimizedImage, DxLoadIndicatorModule],
  exports: [ImageWithLoaderComponent],
})
export class ImageWithLoaderModule {}
