import { AG_APPLICATIONS } from '../../lists/ag-applications.enum';
import { BaseModel } from '../base.model';

export enum UserPermissionKeys {
  owner = 'owner',
  application = 'application',
  isEnabled = 'is_enabled',
  role = 'role',
}
export class UserPermission extends BaseModel {
  [UserPermissionKeys.owner]: string;
  [UserPermissionKeys.application]: AG_APPLICATIONS;
  [UserPermissionKeys.isEnabled]: boolean = false;
  [UserPermissionKeys.role]: string[] = [];
}
