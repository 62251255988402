import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectEmailAddressComponent } from './select-email-address.component';
import { SharedModule } from '../../../shared/shared.module';
import { SelectEmailAddressService } from './select-email-address.service';

@NgModule({
  declarations: [SelectEmailAddressComponent],
  imports: [CommonModule, SharedModule],
  exports: [SelectEmailAddressComponent],
})
export class SelectEmailAddressModule {}
