import { Pipe, PipeTransform } from '@angular/core';
import { AGENCY_STATUS } from 'ag-common-lib/lib/lists/agency-status.enum';

@Pipe({ name: 'agencyStatusThumbsIcon' })
export class AgencyStatusThumbsIconPipe implements PipeTransform {
  transform(agencyStatus: string): string {
    const cssClassName = {
      [AGENCY_STATUS.APPROVED]: 'success',
      [AGENCY_STATUS.DENIED]: 'danger',
    };
    return cssClassName[agencyStatus] || 'normal';
  }
}
