<dx-text-box
  #urlTextBoxRef
  [visible]="showURL"
  [width]="'100%'"
  [(value)]="imagePreviewUrl"
  (onValueChanged)="onUrlValueChanged($event)"
  [label]="textBoxLabel"
  [labelMode]="'floating'"
  [showClearButton]="true"
>
  <dx-validator>
    <dxi-validation-rule
      type="custom"
      [reevaluate]="true"
      [message]="Messages.BROKEN_IMAGE_TEXT"
      [validationCallback]="customValidationCallback"
    >
    </dxi-validation-rule>
  </dx-validator>
</dx-text-box>
<div class="drop-zone">
  <div
    class="drop-zone__container"
    [class._not-rounded]="!roundCropper"
    [ngStyle]="{ '--dz-base-image-ratio-width': this.ratio.w, '--dz-base-image-ratio-height': this.ratio.h }"
  >
    <input #fileInput class="drop-zone__input" type="file" (change)="fileChangeEvent($event)" accept=".jpg,.png,.gif" />

    <img
      class="drop-zone__image"
      [src]="imagePreviewUrl"
      *ngIf="!!imagePreviewUrl; else imagePlaceholder"
      (load)="onImgLoaded()"
      (error)="onImgError()"
    />

    <ng-template #imagePlaceholder>
      <p class="drop-zone__image-placeholder">Click or Drag a Image</p>
    </ng-template>
  </div>
</div>

<p class="drop-zone__note">Note: Preferred images in png format with aspect ratio {{ ratio.w }} / {{ ratio.h }}</p>

<ag-shr-modal-window
  #imageCropModalRef
  [title]="imageCroperTitle"
  [width]="'100vw'"
  [height]="'100vh'"
  [isFullScreen]="true"
  [showFullScreenButton]="false"
  (onSaveClick)="handleApplyCrop()"
  (onPopupClose)="handleCancelCrop()"
>
  <div class="drop-zone__toolbar" *ngIf="showToolbar">
    <dx-button type="default" stylingMode="outlined" icon="minus" hint="Zoom out" (onClick)="zoomOut()"></dx-button>

    <dx-button type="default" stylingMode="outlined" icon="add" hint="Zoom in" (onClick)="zoomIn()"></dx-button>

    <dx-button
      type="default"
      [text]="byContainWithinAspectRatio ? 'Fill Aspect Ratio' : 'Contain Within Aspect Ratio'"
      (onClick)="toggleContainWithinAspectRatio()"
    ></dx-button>

    <dx-button type="normal" text="Reset image" (onClick)="resetImage()"></dx-button>
  </div>

  <div class="drop-zone__crop-popup-content">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [resizeToHeight]="500"
      [aspectRatio]="ratio.w / ratio.h"
      [containWithinAspectRatio]="byContainWithinAspectRatio"
      format="png"
      (imageCropped)="imageCropped($event)"
      [onlyScaleDown]="true"
      [roundCropper]="roundCropper"
      [transform]="transform"
    ></image-cropper>
  </div>
</ag-shr-modal-window>
