import { AGMedia, MediaContentType } from './ag-media.model';

export enum AgentMediaKeys {
  agentDbId = 'agentDbId',
}

export class AgentMedia extends AGMedia {
  [AgentMediaKeys.agentDbId]: string;

  constructor(mediaType: MediaContentType, agentDbId: string) {
    super(mediaType);
    this[AgentMediaKeys.agentDbId] = agentDbId;
  }
}
