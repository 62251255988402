import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import {
  Constants,
  Messages,
  PHONE_NUMBER_TYPE_LOOKUP,
  Patterns,
  PhoneNumber,
  PhoneNumberKeys,
} from '@ag-common-lib/public-api';
import { DxListComponent } from 'devextreme-angular';
import { InitializedEvent, SelectionChangedEvent } from 'devextreme/ui/list';
import { SelectPhoneNumberService, getPhoneNumberKeyExpression } from './select-phone-number.service';

@Component({
  selector: 'ag-shr-select-phone-number',
  templateUrl: './select-phone-number.component.html',
  styleUrls: ['./select-phone-number.component.scss'],
  providers: [SelectPhoneNumberService],
})
export class SelectPhoneNumberComponent {
  @HostBinding('class') protected className = 'select-phone-number';
  @ViewChild('phoneNumbersListRef') protected listComponent: DxListComponent;

  @Input() pathPrefix: string;
  @Input() validationGroup: string;
  @Input() set phoneNumber(data: Partial<PhoneNumber>) {
    this.selectPhoneNumberService.setFormData(data);
  }
  @Input() set phoneNumbers(data: Partial<PhoneNumber>[]) {
    this.selectPhoneNumberService.setPhoneNumbers(data);
  }
  @Input() isReadonly: boolean = false;
  @Input() selectorHidden: boolean = false;
  @Input() isNumberFieldRequired: boolean = true;

  protected selectedPhoneNumbersKeys: string[];
  protected readonly totalPhoneDigits: number = Constants.TOTAL_PHONE_DIGITS;
  protected readonly phoneMask: string = Constants.PHONE_MASK;
  protected readonly phonePattern = Patterns.PHONE_NUMBER;
  protected readonly Messages = Messages;
  protected readonly PhoneNumberKeys = PhoneNumberKeys;
  protected readonly PHONE_NUMBER_TYPE_LOOKUP = PHONE_NUMBER_TYPE_LOOKUP;

  protected formData$ = this.selectPhoneNumberService.formData$;

  protected agentPhoneNumbersDataSource$ = this.selectPhoneNumberService.agentPhoneNumbersDataSource$;

  constructor(protected selectPhoneNumberService: SelectPhoneNumberService) {
    this.selectPhoneNumberService.selectedPhoneNumbersKeys$.subscribe(selectedPhoneNumbersKeys => {
      this.listComponent?.instance?.off('selectionChanged');
      this.selectedPhoneNumbersKeys = selectedPhoneNumbersKeys;

      setTimeout(() => {
        this.listComponent?.instance?.on('selectionChanged', this.onSelectionChanged);
      }, 0);
    });
  }

  protected onInitialized = (e: InitializedEvent) => {
    if (this.onSelectionChanged) {
      e.component.instance().on('selectionChanged', this.onSelectionChanged);
    }
  };

  protected onSelectionChanged = (e: SelectionChangedEvent) => {
    const phoneNumber = e.addedItems[0];
    this.selectPhoneNumberService.setPhoneNumber(phoneNumber);
  };

  protected addCopyingPhoneNumber(e, phoneNumber: PhoneNumber): void {
    this.listComponent.instance.off('selectionChanged');
    this.selectedPhoneNumbersKeys = [null];
    this.selectPhoneNumberService.setPhoneNumber(phoneNumber);

    setTimeout(() => {
      this.listComponent.instance.on('selectionChanged', this.onSelectionChanged);
    }, 0);

    e.event.stopPropagation();
  }

  protected phoneNumberKeyExpression = getPhoneNumberKeyExpression;
}
