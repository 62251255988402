import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import {
  ActiveLookup,
  AddressModelKeys,
  Association,
  AssociationKeys,
  BaseModelKeys,
  Constants,
  COUNTRIES,
  DATE_NOW,
  DietaryConsiderationKeys,
  EditorOptions,
  LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
  Lookup,
  LookupKeys,
  Messages,
  MIN_DATE,
  Patterns,
  TShirtSizesKeys,
  YES_NO_TYPE,
} from '@ag-common-lib/public-api';
import { BehaviorSubject, Observable } from 'rxjs';
import { AgentAssociationsService } from '../../../../services/agent-associations.service';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { AssociationFormService } from './association-form.service';
import { DxFormComponent } from 'devextreme-angular';
import { LookupsService } from '../../../../services/lookups.service';
import { format, subYears } from 'date-fns';
import { matcher } from '../../../../utils/phone-number-data.utils';

@Component({
  selector: 'ag-shr-associations',
  templateUrl: './associations.component.html',
  styleUrls: ['./associations.component.scss'],
  providers: [AssociationFormService],
})
export class AssociationsComponent {
  @HostBinding('class') className = 'associations';
  @Input() set agentId(value) {
    this.agentId$.next(value);
  }
  @Input() canEdit: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() canCreate: boolean = true;
  @ViewChild('associationModalRef', { static: true }) associationModalComponent: ModalWindowComponent;
  @ViewChild('associationFormRef', { static: false }) associationFormComponent: DxFormComponent;
  @ViewChild('editAssociationModalRef', { static: true }) editAssociationModalComponent: ModalWindowComponent;

  readonly Messages = Messages;
  readonly totalPhoneDigits: number = Constants.TOTAL_PHONE_DIGITS;
  readonly phoneMask: string = Constants.PHONE_MASK;

  inProgress$: Observable<boolean>;
  readonly validationGroup = 'associationsValidationGroup';
  readonly BaseModelKeys = BaseModelKeys;
  readonly LookupKeys = LookupKeys;
  readonly countries = COUNTRIES;
  readonly yesNoType = YES_NO_TYPE;
  associations$: Observable<DataSource>;
  associationFormData: Association;
  readonly TShirtSizesKeys = TShirtSizesKeys;
  readonly DietaryConsiderationKeys = DietaryConsiderationKeys;
  readonly AssociationKeys = AssociationKeys;
  readonly AddressModelKeys = AddressModelKeys;
  relationshipTypeLookup$: Observable<ActiveLookup[]>;
  selectedRelationshipType$: BehaviorSubject<ActiveLookup>;
  selectedGender$: BehaviorSubject<Lookup>;

  readonly emptyMessage = 'No Associations Currently Exist';
  readonly maxDate: Date = subYears(DATE_NOW, 1);
  readonly minDate: Date = MIN_DATE;
  readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  readonly dateValidation = Patterns.DATE_PATTERN_MMDDYYYY;
  readonly dateEditorOptions = {
    ...EditorOptions.DATE,
    min: this.minDate,
    max: this.maxDate,
    dateSerializationFormat: LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
    dateOutOfRangeMessage: `Please enter a date between ${format(this.minDate, Constants.DISPLAY_DATE_FORMAT)} and
      ${format(this.maxDate, Constants.DISPLAY_DATE_FORMAT)}`,
  };

  private readonly agentId$ = new BehaviorSubject<string>(undefined);

  constructor(
    private readonly lookupsService: LookupsService,
    private readonly associationFormService: AssociationFormService,
    private readonly agentAssociationsService: AgentAssociationsService,
  ) {
    this.selectedRelationshipType$ = associationFormService.selectedRelationshipType$;
    this.relationshipTypeLookup$ = this.lookupsService.associationTypeLookup$;
    this.selectedGender$ = associationFormService.selectedGender$;
    this.inProgress$ = this.associationFormService.inProgress$;

    this.associations$ = this.agentId$.pipe(
      filter(Boolean),
      switchMap((agentId: string) => this.agentAssociationsService.getList(agentId)),
      map(associations => {
        return new DataSource({
          store: new ArrayStore({
            key: 'dbId',
            data: Array.isArray(associations) ? associations : [],
          }),
        });
      }),
      shareReplay(1),
    );
  }

  showListPopup = (): void => {
    this.associationModalComponent.showModal();
  };

  showAddAssociationPopup = (): void => {
    this.associationFormData = this.associationFormService.getFormData();
    this.editAssociationModalComponent.showModal();
  };

  showEditPopup = ({ row: { data } }): void => {
    this.associationFormData = this.associationFormService.getFormData(data);
    this.editAssociationModalComponent.showModal();
  };

  onRowRemoving = (e): void => {
    e.cancel = this.agentAssociationsService.delete(this.agentId$.value, e.data[BaseModelKeys.dbId]);
  };

  onRelationshipTypeSelectionChanged = (item): void => {
    this.selectedRelationshipType$.next(item);
  };

  handleSaveAssociation = (e): void => {
    const validationResults = this.associationFormComponent.instance.validate();
    if (validationResults.isValid) {
      this.associationFormService.saveAssociation(this.agentId$.value).then(() => {
        e.component.instance.hide();
      });
    }
  };

  handleAssociationFormPopupClose = this.associationFormService.onCancelEdit;

  setState = (state): void => {
    Object.assign(this.associationFormData.address, { state });
  };

  calculateContactNumberDisplayValue = (association: Association) => matcher(association?.contact_number);

  handleGenderSelect = (item): void => {
    this.selectedGender$.next(item);
  };

  calculateNameDisplayValue = (association: Association) => {
    return [association?.[AssociationKeys.firstName], association?.[AssociationKeys.lastName]]
      .filter(Boolean)
      .join(' ');
  };
}
