import { LookupBase } from '../models/utils/lookup-base.model';

export enum RegistrantIssuesStatus {
  posted = 'posted',
  reopened = 'reopened',
  resolved = 'resolved',
  closed = 'closed',
}

export const REGISTRANT_ISSUES_STATUS_LOOKUP: LookupBase[] = [
  { value: RegistrantIssuesStatus.posted, description: 'Posted', cssClass: 'bg-warning text-dark' },
  { value: RegistrantIssuesStatus.reopened, description: 'Reopened', cssClass: 'bg-secondary' },
  { value: RegistrantIssuesStatus.resolved, description: 'Resolved', cssClass: 'bg-success' },
  { value: RegistrantIssuesStatus.closed, description: 'Closed', cssClass: 'bg-danger' },
];
