import { Injectable } from '@angular/core';
import { ProcessRefundPayload } from 'ag-common-lib/public-api';
import { BaseFormService } from '../../utils/base-form-service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AttendeeRefundPaymentModalService extends BaseFormService<ProcessRefundPayload> {
  totalAmountToRefund$ = new BehaviorSubject<number>(0);

  constructor() {
    super();
  }

  getFormData = (refundPayment?: Partial<ProcessRefundPayload>) => {
    this.totalAmountToRefund$.next(refundPayment?.amount ?? 0);
    const initialData = Object.assign({}, refundPayment);
    this.formData = new Proxy(Object.assign({}, new ProcessRefundPayload(), initialData), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }
        return true;
      },
    });
    return this.formData;
  };
}
