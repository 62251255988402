<dx-form
  #formRef
  [formData]="tShirtSizes"
  labelMode="floating"
  [validationGroup]="validationGroup"
  [colCount]="containerColCount"
  (onInitialized)="onInit($event)"
  [readOnly]="isReadonly"
  [screenByWidth]="formWidth$ | async | formScreenByWidth : screenLimits"
>
  <dxi-item
    itemType="group"
    [colCount]="useFullWith && (selectedTShortSize$ | async | otherSize) ? 2 : 1"
    [colCountByScreen]="!useFullWith && colCountByScreen"
    [visible]="isTShortSizeFieldVisible"
  >
    <dxi-item>
      <div *dxTemplate>
        <ag-shr-t-short-size-select-box
          [name]="[pathPrefix, TShirtSizesKeys.tShirtSize] | path"
          [readOnly]="isReadonly"
          [label]="'T-Shirt Size'"
          [(value)]="tShirtSizes && tShirtSizes[TShirtSizesKeys.tShirtSize]"
          (selectedItemChange)="handleTShortSizeSelect($event)"
        ></ag-shr-t-short-size-select-box>
      </div>
    </dxi-item>

    <dxi-item
      *var="!!(selectedTShortSize$ | async | otherSize) as isOtherTShirtSize"
      [dataField]="TShirtSizesKeys.tShirtSizeOther"
      [isRequired]="isOtherTShirtSize"
      [visible]="isOtherTShirtSize"
      [editorOptions]="{
        name: [pathPrefix, TShirtSizesKeys.tShirtSizeOther] | path
      }"
    >
      <dxo-label [text]="'T-Shirt Size Other'"></dxo-label>
    </dxi-item>
  </dxi-item>

  <dxi-item
    itemType="group"
    [colCount]="useFullWith && (selectedUnisexTShortSize$ | async | otherSize) ? 2 : 1"
    [colCountByScreen]="!useFullWith && colCountByScreen"
    [visible]="isUnisexTShortSizeFieldVisible"
  >
    <dxi-item>
      <div *dxTemplate>
        <ag-shr-t-short-size-select-box
          [name]="[pathPrefix, TShirtSizesKeys.unisexTShirtSize] | path"
          [readOnly]="isReadonly"
          [validationGroup]="validationGroup"
          [isRequired]="isUnisexTShirtSizeFieldRequired"
          [label]="'Unisex T-Shirt Size'"
          [(value)]="tShirtSizes && tShirtSizes[TShirtSizesKeys.unisexTShirtSize]"
          (selectedItemChange)="handleUnisexTShortSizeSelect($event)"
        ></ag-shr-t-short-size-select-box>
      </div>
    </dxi-item>

    <dxi-item
      *var="!!(selectedUnisexTShortSize$ | async | otherSize) as isOtherUnisexTShirtSize"
      [dataField]="TShirtSizesKeys.unisexTShirtSizeOther"
      [isRequired]="isOtherUnisexTShirtSize"
      [visible]="isOtherUnisexTShirtSize"
      [editorOptions]="{
        name: [pathPrefix, TShirtSizesKeys.unisexTShirtSizeOther] | path
      }"
    >
      <dxo-label [text]="'Unisex T-Shirt Size Other'"></dxo-label>
    </dxi-item>
  </dxi-item>
</dx-form>
