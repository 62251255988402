import { LocalDateTimeString } from '../utils/local-date-time-string.model';
import { RegistrationType } from './conference-registration-type.model';

export enum ConferencePossibleStayPeriodKey {
  registrationType = 'registrationType',
  primaryArrivalDate = 'primaryArrivalDate',
  primaryDepartureDate = 'primaryDepartureDate',
  shoulderArrivalDate = 'shoulderArrivalDate',
  shoulderDepartureDate = 'shoulderDepartureDate',
}
export interface ConferencePossibleStayPeriod {
  [ConferencePossibleStayPeriodKey.registrationType]: RegistrationType;
  [ConferencePossibleStayPeriodKey.primaryArrivalDate]?: LocalDateTimeString;
  [ConferencePossibleStayPeriodKey.primaryDepartureDate]?: LocalDateTimeString;
  [ConferencePossibleStayPeriodKey.shoulderArrivalDate]?: LocalDateTimeString;
  [ConferencePossibleStayPeriodKey.shoulderDepartureDate]?: LocalDateTimeString;
}
