import { Injectable } from '@angular/core';
import { Agency, Lookup, LookupKeys, PhoneNumber } from '@ag-common-lib/public-api';
import { BehaviorSubject } from 'rxjs';
import { AgencyService } from '../../../../services/agency.service';
import { DocumentReference, updateDoc } from 'firebase/firestore';
import { BaseFormService } from '../../../../utils/base-form-service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class OfficePhoneNumberService extends BaseFormService<PhoneNumber[]> {
  selectedAgencyPhoneType$ = new BehaviorSubject<Lookup>(null);

  constructor(private agencyService: AgencyService, private toastrService: ToastrService) {
    super();
  }

  setSelectedAgencyPhoneType(item): void {
    this.selectedAgencyPhoneType$.next(item);
  }

  updatePhoneNumbers(agencyId: string, officePhoneNumbers: PhoneNumber[]): Promise<Agency> {
    this.startProgress();
    return this.agencyService
      .updateFields(agencyId, { officePhoneNumbers })
      .then(response => {
        const selectedAgencyPhoneType = this.selectedAgencyPhoneType$?.value;
        if (selectedAgencyPhoneType && !selectedAgencyPhoneType?.isAssigned) {
          updateDoc(selectedAgencyPhoneType?.reference as DocumentReference<Lookup>, {
            [LookupKeys.isAssigned]: true,
          }).then();
        }
        return response;
      })
      .catch(err => {
        this.toastrService.error('Carriers are not updated!');
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  }
}
