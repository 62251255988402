import { NgModule } from '@angular/core';
import { DxDropDownButtonModule, DxValidatorModule } from 'devextreme-angular';
import { SharedModule } from '../../../../../shared/shared.module';
import { DropZoneModule } from '../../../drop-zone/drop-zone.module';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { PrefixSelectBoxModule } from '../../../prefix-select-box/prefix-select-box.module';
import { SuffixSelectBoxModule } from '../../../suffix-select-box/suffix-select-box.module';
import { AgencyHeaderService } from './agency-header.service';
import { ImageWithLoaderModule } from '../../../image-with-loader/image-with-loader.module';
import { AgMediaUploaderModule } from '../../../ag-media-uploader/ag-media-uploader.module';
import { ImageCropperModalModule } from '../../../image-cropper-modal/image-cropper-modal.module';
import { AgencyHeaderComponent } from './agency-header.component';

@NgModule({
  imports: [
    SharedModule,
    ModalWindowModule,
    DxValidatorModule,
    SuffixSelectBoxModule,
    PrefixSelectBoxModule,
    DropZoneModule,
    ImageWithLoaderModule,
    DxDropDownButtonModule,
    AgMediaUploaderModule,
    ImageCropperModalModule,
  ],
  declarations: [AgencyHeaderComponent],
  exports: [AgencyHeaderComponent],
  providers: [AgencyHeaderService],
})
export class AgencyHeaderModule {}
