import { CallDirectionKeys } from '../../lists/call-direction.enum';
import { CallOutcomeKeys } from '../../lists/call-outcomes.enum';
import { ActivityBase } from './activity-base.model';

export enum CallLogKeys {
  title = 'title',
  hostOwnerId = 'hostOwnerId',
  callOutcome = 'callOutcome',
  date = 'date',
  time = 'time',
  callDirection = 'callDirection',
  callDetails = 'callDetails',
  attendees = 'attendees',
  externalAttendees = 'externalAttendees',
  detailsAgenda = 'detailsAgenda',
}

export class CallLog extends ActivityBase {
  [CallLogKeys.title]: string;
  [CallLogKeys.hostOwnerId]: string;
  [CallLogKeys.callOutcome]: CallOutcomeKeys;
  [CallLogKeys.date]: Date;
  [CallLogKeys.time]?: string;
  [CallLogKeys.callDirection]: CallDirectionKeys;
  [CallLogKeys.callDetails]?: string;
  [CallLogKeys.attendees]?: string[];
  [CallLogKeys.externalAttendees]?: string[];
  [CallLogKeys.detailsAgenda]?: string;
}
