export interface IAspectRatio {
  w: number;
  h: number;
}

export interface ImageCropperPayload {
  relativeCropperPosition: {
    relativeX1: string;
    relativeX2: string;
    relativeY1: string;
    relativeY2: string;
  };
}

export enum ImageCropperKeys {
  imageCropperPayload = 'imageCropperPayload',
}

export class ImageCropper {
  [ImageCropperKeys.imageCropperPayload]?: ImageCropperPayload;
}
