import { Inject, Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { PUSH_NOTIFICATIONS_CHANNELS_COLLECTION, PushNotificationChannel } from '@ag-common-lib/lib';
import { DataService } from '../data.service';
import { FIREBASE_APP } from 'ag-common-svc/public-api';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsChannelsService extends DataService<PushNotificationChannel> {
  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, null, null);
    this.collection = PUSH_NOTIFICATIONS_CHANNELS_COLLECTION;
  }
}
