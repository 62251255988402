export enum GetStatsType {
  pushNotifications = 'pushNotifications',
}

export enum GetStatsPayloadKeys {
  type = 'type',
  payload = 'payload',
}

export interface PushNotificationsStatsPayload {
  agentDbId: string;
}

export interface PushNotificationsStatsResponse {
  total: number;
  notReded: number;
}

type GetStatsPayloadMap = {
  [GetStatsType.pushNotifications]: PushNotificationsStatsPayload;
};

type GetStatsResponseMap = {
  [GetStatsType.pushNotifications]: PushNotificationsStatsResponse;
};

type GetStatsFilter<T> = {
  [K in keyof T]: {
    type: K;
    payload: T[K];
  };
}[keyof T];

export type GetStatsPayload = GetStatsFilter<GetStatsPayloadMap>;

export type GetStatsResponse = GetStatsResponseMap[keyof GetStatsResponseMap];
