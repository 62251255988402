import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumber } from '@ag-common-lib/public-api';
import { getPhoneNumber } from '../../lib/utils/phone-number-data.utils';

@Pipe({ name: 'phoneNumberMask' })
export class PhoneNumberMaskPipe implements PipeTransform {
  transform(phoneNumber: PhoneNumber, showExtension: boolean = true): string {
    return getPhoneNumber(phoneNumber, showExtension);
  }
}
