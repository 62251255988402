<div class="agent-campaign-wizard__content">
  <ng-content></ng-content>
  <dx-scroll-view
    height="100%"
    [useNative]="false"
    [showScrollbar]="'always'"
  >

    <dx-form
      #extrasFormRef
      [formData]="extrasFormData"
      labelMode="floating"
      [colCount]="2"
      [readOnly]="!canEdit"
    >
      <dxi-item
        *ngFor="let item of [].constructor(4); let index='index'"
        itemType="group"
        caption="Link {{index + 1}}"
      >

        <dxi-item
          dataField="title{{index}}"
          [label]="{ text: 'Title' }"
        >

          <dxi-validation-rule
            type="custom"
            [validationCallback]="validateIsTitleRequired.bind(this, extrasFormData?.['url' + index])"
            [message]="'Title is Required When Url Provided'"
          >
          </dxi-validation-rule>
        </dxi-item>

        <dxi-item
          dataField="url{{index}}"
          [label]="{ text: 'Url' }"
        >

          <dxi-validation-rule
            type="custom"
            [validationCallback]="validateIsUrlRequired.bind(this, extrasFormData?.['title' + index])"
            [message]="'Url is Required When Title Provided'"
          >
          </dxi-validation-rule>

          <dxi-validation-rule
            type="custom"
            [validationCallback]="validateUrlWithOrWithoutProtocol"
            [message]="Messages.INVALID_URL_FORMAT_TEXT"
          >
          </dxi-validation-rule>
        </dxi-item>
      </dxi-item>

    </dx-form>
  </dx-scroll-view>

</div>

<div class="agent-campaign-wizard__controls-container" *ngIf="canEdit">
  <dx-button
    class="modal-window__toolbar-button"
    text="CANCEL CHANGES"
    [disabled]="!(hasFormChanges$ | async)"
    (onClick)="resetChanges()"
  ></dx-button>

  <shr-button-with-indicator
    [title]="hasStepIssues ? 'RESOLVE ISSUE' :'NEXT'"
    [disabled]="hasStepIssues && !(hasFormChanges$ | async)"
    [cssClass]="'modal-window__toolbar-button'"
    [isInProgress]="inProgress$ | async"
    (onClick)="nextStep()"
  ></shr-button-with-indicator>
</div>
