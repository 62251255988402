import { LookupId } from '../../lists/lookups.enum';
import { YES_NO_TYPE } from '../../lists/yes-no.enum';
import { LookupKeys } from '../crm/lookup.model';

export enum DietaryConsiderationKeys {
  hasDietaryOrPersonalConsideration = 'hasDietaryOrPersonalConsideration',
  dietaryConsiderationType = 'dietaryConsiderationType',
  dietaryConsideration = 'dietaryConsideration',
}

export const DietaryConsiderationMap = {
  [DietaryConsiderationKeys.hasDietaryOrPersonalConsideration]: 'Has Dietary Consideration(s)?',
  [DietaryConsiderationKeys.dietaryConsiderationType]: 'Dietary Consideration Type',
  [DietaryConsiderationKeys.dietaryConsideration]: 'Dietary Consideration',
};

export class DietaryConsideration {
  [DietaryConsiderationKeys.hasDietaryOrPersonalConsideration]?: YES_NO_TYPE = YES_NO_TYPE.No;
  [DietaryConsiderationKeys.dietaryConsiderationType]?: LookupId<'DietaryConsiderationType'> = null;
  [DietaryConsiderationKeys.dietaryConsideration]?: string = null;
}

export const DIETARY_CONSIDERATION_LOOKUP = [
  {
    [LookupKeys.value]: DietaryConsiderationKeys.hasDietaryOrPersonalConsideration,
    [LookupKeys.description]: 'Has Dietary Or Personal Consideration',
  },
  {
    [LookupKeys.value]: DietaryConsiderationKeys.dietaryConsiderationType,
    [LookupKeys.description]: 'Dietary Consideration Type',
  },
  {
    [LookupKeys.value]: DietaryConsiderationKeys.dietaryConsideration,
    [LookupKeys.description]: 'Dietary Consideration',
  },
];
