import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { Observable, map } from 'rxjs';
import { CampaignWebSite, Messages, Patterns, RelatedCampaignsManagementTaskIssues } from '@ag-common-lib/public-api';
import { validateUrlWithOrWithoutProtocol } from 'ag-common-svc/lib/utils/validation';
import { AgentCampaignWebSiteService } from './agent-campaign-web-site.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import ValidationEngine from 'devextreme/ui/validation_engine';

@UntilDestroy()
@Component({
  selector: 'ag-shr-agent-campaign-web-site',
  templateUrl: './agent-campaign-web-site.component.html',
  styleUrls: ['./agent-campaign-web-site.component.scss'],
})
export class AgentCampaignWebSiteComponent implements OnInit {
  @HostBinding('class') className = 'agent-campaign-web-site agent-campaign-wizard__content-container';
  @ViewChild('webSiteFormRef') webSiteFormComponent: DxFormComponent;

  @Input() hasStepIssues: boolean;
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;

  isValid = true;
  inProgress$: Observable<boolean>;
  webSiteLocked$: Observable<boolean>;
  hasFormChanges$: Observable<boolean>;
  webSiteFormData: Partial<CampaignWebSite> = {};
  domains = [
    { value: 'mylivingbenefits.com', description: 'mylivingbenefits.com' },
    { value: 'yourplanningpro.com', description: 'yourplanningpro.com' },
    { value: 'lifetaxfree.com', description: 'lifetaxfree.com' },
    { value: '__custom__', description: 'My Domain' },
  ];

  readonly subdomainPattern = Patterns.SUBDOMAIN;
  readonly Messages = Messages;
  readonly webSiteFormValidationGroup = 'WEB_SITE_FORM_VALIDATION_GROUP';

  constructor(private agentCampaignWebSiteService: AgentCampaignWebSiteService) {
    this.inProgress$ = agentCampaignWebSiteService.inProgress$;
    this.hasFormChanges$ = agentCampaignWebSiteService.hasFormChanges$;
    this.webSiteLocked$ = agentCampaignWebSiteService.webSiteLocked$;
  }

  ngOnInit(): void {
    this.agentCampaignWebSiteService.formData$.pipe(untilDestroyed(this)).subscribe(formData => {
      this.webSiteFormData = formData;
    });
  }

  validateUrlWithOrWithoutProtocol = e => validateUrlWithOrWithoutProtocol(e?.value);

  checkDomain = () => {
    return !!this.webSiteFormData?.domainOption || this.webSiteFormData?.domainOption === null;
  };

  checkSubdomain = () => {
    return !!this.webSiteFormData?.subdomain;
  };

  asyncUniqWebSiteValidation = async (): Promise<boolean> => {
    return this.agentCampaignWebSiteService.asyncUniqWebSiteValidation();
  };

  resetChanges = () => {
    this.agentCampaignWebSiteService.resetChanges();
  };

  nextStep = async () => {
    const validationResults = ValidationEngine.validateGroup(this.webSiteFormValidationGroup);
    let asyncValidatorValid = !validationResults?.complete;

    if (validationResults?.complete) {
      const asyncValidationResults = await validationResults?.complete;
      asyncValidatorValid = asyncValidationResults?.isValid;
    }

    if (!validationResults.isValid || !asyncValidatorValid) {
      return;
    }

    this.agentCampaignWebSiteService.handleNextStepClick();
  };
}
