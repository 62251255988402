import { BaseModel } from '../base.model';
import { CampaignManagementTaskLogs } from './campaign-management-task-logs.model';
import { CampaignsManagementTaskIssues } from './campaign-management-task-issues.model';
import { CampaignsManagementTasksStatus } from '../../lists/campaign-management-tasks-status.enum';
import { AgentCampaignChanges, AgentCampaignStepName } from './agent-campaign-wizard.model';
import { AgentCampaignStatisticChanges } from './agent-campaign-statistic.model';

export const CAMPAIGNS_MANAGEMENT_TASKS_COLLECTION_NAME = 'campaign-management-tasks';

export const contactInfoKeys: AgentCampaignStepName[] = [
  AgentCampaignStepName.contactInfoAddress,
  AgentCampaignStepName.contactInfoPhoneNumber,
  AgentCampaignStepName.contactInfoEmailAddress,
];

export interface RelatedCampaignsManagementTasks {
  data: CampaignsManagementTasks;
  parentDbId: string;
}

export enum CampaignsManagementTasksKeys {
  agentDbId = 'agentDbId',
  agentCampaignDbId = 'agentCampaignDbId',
  taskStatus = 'taskStatus',
  isStepDone = 'isStepDone',
  assignedAt = 'assignedAt',
  assignedTo = 'assignedTo',
  note = 'note',
  updatedStatusAt = 'updatedStatusAt',
  details = 'details',
  statistic = 'statistic',
  campaignManagementTaskIssues = 'campaign-management-task-issues',
  campaignManagementTaskLogs = 'campaign-management-task-logs',
}

export class CampaignsManagementTasks extends BaseModel {
  [CampaignsManagementTasksKeys.agentDbId]: string;
  [CampaignsManagementTasksKeys.agentCampaignDbId]: string;
  [CampaignsManagementTasksKeys.taskStatus]: CampaignsManagementTasksStatus = CampaignsManagementTasksStatus.newTasks;
  /* when task was assigned at [date and time] */
  [CampaignsManagementTasksKeys.assignedAt]?: Date;
  /* who was given the task [id] */
  [CampaignsManagementTasksKeys.assignedTo]?: string = '';
  [CampaignsManagementTasksKeys.note]?: string = '';
  [CampaignsManagementTasksKeys.updatedStatusAt]?: Date = new Date();
  [CampaignsManagementTasksKeys.campaignManagementTaskIssues]?: CampaignsManagementTaskIssues[];
  [CampaignsManagementTasksKeys.campaignManagementTaskLogs]?: CampaignManagementTaskLogs[];
  [CampaignsManagementTasksKeys.details]?: AgentCampaignChanges;
  [CampaignsManagementTasksKeys.statistic]?: AgentCampaignStatisticChanges;

  constructor(agentDbId: string, details: AgentCampaignChanges) {
    super();

    this[CampaignsManagementTasksKeys.agentDbId] = agentDbId;
    this[CampaignsManagementTasksKeys.details] = details;
  }
}
