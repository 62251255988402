<dx-button
  *ngIf="!!clipboardValue"
  #popoverRef
  class="clipboard-button__button"
  icon="copy"
  hint="Copy To Clipboard"
  [stylingMode]="stylingMode"
  [text]="text"
  (onClick)="copyToClipBoard(clipboardValue)"
  (onContentReady)="popoverTarget = $event.element"
>
</dx-button>

<dx-drop-down-button
  *ngIf="!!clipboardValues"
  #popoverRef
  class="clipboard-button__button"
  [showArrowIcon]="false"
  [dropDownOptions]="{ width: 230 }"
  [text]="text"
  icon="copy"
  hint="Copy To Clipboard"
  [stylingMode]="stylingMode"
  [items]="clipboardValues"
  (onItemClick)="copyToClipBoard($event?.itemData?.value)"
  [keyExpr]="valueExpr"
  [displayExpr]="displayExpr"
  (onContentReady)="popoverTarget = $event.element"
>
</dx-drop-down-button>

<dx-popover
  *ngIf="popoverVisible"
  [target]="popoverTarget"
  position="top"
  [width]="'auto'"
  [height]="'auto'"
  [(visible)]="popoverVisible"
  [showTitle]="false"
  titleTemplate="titleTemplate"
  [wrapperAttr]="{ class: 'clipboard-button__popover-wrapper' }"
>
  <div *dxTemplate="let data = model; of: 'content'">Copied!</div>
</dx-popover>
