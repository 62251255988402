import { Inject, Injectable } from '@angular/core';
import { CommonFireStoreDao, QueryParam } from '../dao/CommonFireStoreDao.dao';
import {
  AGENCIES_COLLECTION_NAME,
  AgencyFileDirectoryCollections,
  AgencyMedia,
  AgencyMediaKeys,
  AgencyDocumentCollections,
} from '@ag-common-lib/public-api';
import { FIREBASE_APP } from '../injections/firebase-app';
import { FirebaseApp } from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgencyFilesMediaService {
  readonly fsDao: CommonFireStoreDao<AgencyMedia>;
  private readonly agenciesCollectionPath = AGENCIES_COLLECTION_NAME;
  private readonly fileDirectoryCollectionPath = AgencyDocumentCollections.fileDirectories;
  private readonly mediaCollectionPath = AgencyFileDirectoryCollections.files;

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp, private toastrService: ToastrService) {
    this.fsDao = new CommonFireStoreDao<AgencyMedia>(fireBaseApp, null, null);
  }

  getList(agencyId: string, fileDirectoryId: string, qp: QueryParam[] = []): Observable<AgencyMedia[]> {
    const table = this.getCollectionPath(agencyId, fileDirectoryId);

    return this.fsDao.getList(table, qp);
  }

  async create(agencyId: string, fileDirectoryId: string, data: AgencyMedia, silent = false) {
    const table = this.getCollectionPath(agencyId, fileDirectoryId);
    const media = await this.fsDao
      .create(Object.assign(data, { [AgencyMediaKeys.agencyDbId]: agencyId }), table)
      .catch(e => {
        console.log('e', e);
      });

    !silent && this.toastrService.success('Agency Media Successfully Created!');

    return media;
  }

  async update(
    agencyId: string,
    fileDirectoryId: string,
    documentId: any,
    updates: Partial<AgencyMedia>,
    silent = false,
  ) {
    const table = this.getCollectionPath(agencyId, fileDirectoryId);

    const media = await this.fsDao.updateFields(updates, documentId, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success('Agency Media Successfully Updated!');

    return media;
  }

  delete(agencyId: string, fileDirectoryId: string, documentId: any) {
    const table = this.getCollectionPath(agencyId, fileDirectoryId);

    return this.fsDao.delete(documentId, table).then(response => {
      this.toastrService.success('Agency Media Removed!');
      return response;
    });
  }

  private getCollectionPath(agencyId: string, fileDirectoryId: string) {
    return [
      this.agenciesCollectionPath,
      agencyId,
      this.fileDirectoryCollectionPath,
      fileDirectoryId,
      this.mediaCollectionPath,
    ].join('/');
  }
}
