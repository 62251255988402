export enum FavoriteKeys {
  pageId = 'page_id',
  pageName = 'page_name',
  pageRoute = 'page_route',
}

export class Favorite {
  [FavoriteKeys.pageId]: String;
  [FavoriteKeys.pageName]: String;
  [FavoriteKeys.pageRoute]?: String;

  constructor(pageId: string, pageName: string, pageRoute?: string) {
    this[FavoriteKeys.pageId] = pageId;
    this[FavoriteKeys.pageName] = pageName;
    this[FavoriteKeys.pageRoute] = pageRoute;
  }
}
