import { NgModule } from '@angular/core';
import { AgentCampaignBioComponent } from './agent-campaign-bio.component';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ButtonWithIndicatorModule } from '../../button-with-indicator/button-with-indicator.module';
import { AgentCampaignBioService } from './agent-campaign-bio.service';

@NgModule({
  declarations: [AgentCampaignBioComponent],
  imports: [SharedModule, ButtonWithIndicatorModule],
  exports: [AgentCampaignBioComponent],
  providers: [AgentCampaignBioService],
})
export class AgentCampaignBioModule {}
