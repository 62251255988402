<ag-shr-agent-campaign-summary-list-item [title]="title">
  <div class="agent-campaign-summary-logo-headshot__container">
    <div
      class="agent-campaign-summary-logo-headshot__image-container"
      *var="stepData?.currentStepData?.[imageKey] as currentImage"
    >
      <ag-shr-image-with-loader
        class="agent-campaign-summary-logo-headshot__image"
        [wasabiPath]="currentImage?.wasabiPath"
        [cropperPayload]="currentImage?.[ImageCropperKeys.imageCropperPayload]"
        [mediaSize]="MediaSize.small"
      ></ag-shr-image-with-loader>

      <dx-drop-down-button
        *ngIf="canEdit"
        class="agent-campaign-summary-logo-headshot__link"
        [showArrowIcon]="false"
        [dropDownOptions]="{ width: 230 }"
        (onItemClick)="logAction($event, currentImage)"
        icon="download"
        stylingMode="text"
        [items]="editOptions"
      >
      </dx-drop-down-button>
    </div>

    <ng-container *ngIf="stepData?.incomingStepData?.[imageKey] as incomingImage">
      <i class="dx-icon dx-icon-arrowright"></i>

      <div class="agent-campaign-summary-logo-headshot__image-container">
        <ag-shr-image-with-loader
          class="agent-campaign-summary-logo-headshot__image"
          [wasabiPath]="incomingImage?.wasabiPath"
          [cropperPayload]="incomingImage?.[ImageCropperKeys.imageCropperPayload]"
          [mediaSize]="MediaSize.small"
        ></ag-shr-image-with-loader>

        <dx-drop-down-button
          *ngIf="canEdit"
          class="agent-campaign-summary-logo-headshot__link"
          [showArrowIcon]="false"
          [dropDownOptions]="{ width: 230 }"
          (onItemClick)="logAction($event, incomingImage)"
          icon="download"
          stylingMode="text"
          [items]="editOptions"
        >
        </dx-drop-down-button>
        <!--          <i class="agent-campaign-summary-logo-headshot__icon dx-icon-download"></i>-->
      </div>
    </ng-container>
  </div>
</ag-shr-agent-campaign-summary-list-item>
