<dx-data-grid
  #dataGridRef
  class="socials-grid__data-grid"
  [class.socials-grid__data-grid--view]="!isEdit"
  [dataSource]="socials"
  [showRowLines]="isEdit"
  [showBorders]="isEdit"
  [showColumnLines]="isEdit"
  [showColumnHeaders]="isEdit"
  [columnAutoWidth]="true"
  [wordWrapEnabled]="true"
  [rowAlternationEnabled]="false"
  [noDataText]="emptyMessage"
  [selectedRowKeys]="selectedRowKeys"
  (selectedRowKeysChange)="selectedRowKeysChange.emit($event)"
  (onInitNewRow)="onInitNewRow($event)"
  (onRowInserting)="onRowInserting($event)"
  (onRowUpdating)="onRowUpdating($event)"
  (onRowRemoving)="onRowRemoving($event)"
  height="100%"
>
  <dxo-toolbar>
    <dxi-item [visible]="!isEdit" location="before">
      <div *dxTemplate>
        <div class="toolbar-label">
          <b>Socials</b>
        </div>
      </div>
    </dxi-item>

    <dxi-item
      [visible]="!isEdit && allowUpdating"
      location="after"
      widget="dxButton"
      [options]="{ icon: 'edit', stylingMode: 'text', onClick: showSocialsEditorModal }"
    ></dxi-item>

    <dxi-item [visible]="isEdit && allowAdding" name="addRowButton"></dxi-item>
  </dxo-toolbar>

  <dxo-editing
    mode="row"
    [useIcons]="true"
    [allowUpdating]="allowUpdating"
    [allowDeleting]="allowDeleting"
    [allowAdding]="allowAdding"
  >
  </dxo-editing>

  <dxo-selection [selectAllMode]="'allPages'" [showCheckBoxesMode]="'always'" [mode]="selectionMode"></dxo-selection>

  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>

  <dxi-column [dataField]="SocialKeys.url" [width]="'50%'">
    <dxi-validation-rule type="required" [message]="Messages.REQUIRED_URL_TEXT"></dxi-validation-rule>
    <dxi-validation-rule
      type="custom"
      [validationCallback]="validateUrlWithOrWithoutProtocol"
      [message]="Messages.INVALID_URL_FORMAT_TEXT"
    >
    </dxi-validation-rule>
  </dxi-column>

  <dxi-column
    [dataField]="SocialKeys.socialType"
    [caption]="'Type'"
    [editorOptions]="{ onSelectionChanged: onSocialTypeChanged }"
  >
    <dxo-lookup
      [dataSource]="socialTypesLookup"
      [displayExpr]="LookupKeys.description"
      [valueExpr]="BaseModelKeys.dbId"
    ></dxo-lookup>
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    [dataField]="SocialKeys.socialMedia"
    [caption]="'Social Media'"
    [editorOptions]="{ onSelectionChanged: onSocialMediaChanged }"
  >
    <dxo-lookup
      [dataSource]="socialMediaLookup"
      [displayExpr]="LookupKeys.description"
      [valueExpr]="BaseModelKeys.dbId"
    ></dxo-lookup>
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column type="buttons" [visible]="isEdit">
    <dxi-button name="edit"></dxi-button>
    <dxi-button name="delete"></dxi-button>
  </dxi-column>
</dx-data-grid>
