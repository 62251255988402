import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  DxAutocompleteModule,
  DxButtonModule,
  DxDropDownBoxModule,
  DxListModule,
  DxLoadIndicatorModule,
  DxTextBoxModule,
  DxTreeViewModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { AddressAutocompleteComponent } from './address-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxLoadIndicatorModule,
    DxDropDownBoxModule,
    DxTextBoxModule,
    DxTreeViewModule,
    DxValidatorModule,
    DxAutocompleteModule,
    DxListModule,
  ],
  declarations: [AddressAutocompleteComponent],
  exports: [AddressAutocompleteComponent],
})
export class AddressAutocompleteModule {}
