import { ValidationResults } from '@ag-common-lib/lib';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'isAddressFormReadonly' })
export class IsAddressFormReadonlyPipe implements PipeTransform {
  constructor() {}

  transform(keys: string[], validationResults: ValidationResults): boolean {
    const verdict = validationResults?.verdict;
    if (verdict && !verdict?.addressComplete) {
      return false;
    }

    if (verdict?.hasUnconfirmedComponents) {
      return false;
    }

    if (keys?.some(Boolean)) {
      return true;
    }

    return false;
  }
}
