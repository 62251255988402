import { Address } from '@ag-common-lib/public-api';

export const getAddressItems = (address: Address) => [
  address?.address1,
  address?.address2,
  address?.city,
  address?.state,
  address?.zip,
];

export const compareAddressesIsDifferent = (leftAddress: Address, rightAddress: Address): boolean => {
  const leftAddressItems = getAddressItems(leftAddress);
  const rightAddressItems = getAddressItems(rightAddress);

  return leftAddressItems.some((item, index) => {
    const comparedItem = rightAddressItems[index];
    const isSame =
      !item ||
      !comparedItem ||
      item?.replace(/\W|_/g, '')?.toLocaleLowerCase() === comparedItem?.replace(/\W|_/g, '')?.toLocaleLowerCase();

    return !isSame;
  });
};

export const getFullAddress = (address: Address) => (address ? getAddressItems(address).filter(Boolean).join(' ') : '');
