import { ActivityBase } from './activity-base.model';

export enum NoteLogKeys {
  title = 'title',
  hostOwnerId = 'hostOwnerId',
  date = 'date',
  time = 'time',
  detailsAgenda = 'detailsAgenda',
}

export class NoteLog extends ActivityBase {
  [NoteLogKeys.title]: string;
  [NoteLogKeys.hostOwnerId]: string;
  [NoteLogKeys.date]: Date;
  [NoteLogKeys.time]?: string;
  [NoteLogKeys.detailsAgenda]?: string;
}
