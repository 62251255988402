import { AgencyCampaignsStatus } from './agent-campaigns-status.enum';
import { LookupKeys } from '../models/crm/lookup.model';

export const AGENT_CAMPAIGNS_STATUS_LOOKUP = [
  { [LookupKeys.value]: AgencyCampaignsStatus.Registered, [LookupKeys.description]: 'Registered' },
  { [LookupKeys.value]: AgencyCampaignsStatus.ProcessingSetup, [LookupKeys.description]: 'Processing Setup' },
  { [LookupKeys.value]: AgencyCampaignsStatus.Active, [LookupKeys.description]: 'Active' },
  { [LookupKeys.value]: AgencyCampaignsStatus.Inactive, [LookupKeys.description]: 'Inactive' },
  { [LookupKeys.value]: AgencyCampaignsStatus.Denied, [LookupKeys.description]: 'Denied' },
];
