import { BaseModel } from '../base.model';
import { NotificationOptInGroupType } from './push-notification-campaign.model';
import { FilterGroup } from '@ag-common-lib/lib';

export enum DynamicSegmentsGroupKeys {
  description = 'description',
  title = 'title',
  recipientsCollection = 'recipientsCollection',
  filterExpression = 'filterExpression',
  collectionEventDbId = 'collectionEventDbId',
}

export class DynamicSegmentsGroup extends BaseModel {
  [DynamicSegmentsGroupKeys.title]: string;
  [DynamicSegmentsGroupKeys.description]?: string;
  [DynamicSegmentsGroupKeys.recipientsCollection]?: NotificationOptInGroupType;
  [DynamicSegmentsGroupKeys.collectionEventDbId]?: string;
  [DynamicSegmentsGroupKeys.filterExpression]?: FilterGroup;
}
