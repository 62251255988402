import { Inject, Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { DataService } from './data.service';
import { FIREBASE_APP, QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/public-api';
import { DynamicListsGroup, DynamicListsGroupKeys } from '@ag-common-lib/lib';
import { Observable } from 'rxjs';

@Injectable()
export class DynamicListsGroupService extends DataService<DynamicListsGroup> {
  private readonly dynamicListsGroupServicesCollectionPath = 'dynamic-lists-group';

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, DynamicListsGroupService.fromFirestore);
    super.setCollection(this.dynamicListsGroupServicesCollectionPath);
  }

  static readonly fromFirestore = (data): any => {
    return Object.assign({}, data);
  };

  getDynamicListsByAgentDbId(agentDbId: string, sortField?: string, qp: QueryParam[] = []): Observable<DynamicListsGroup[]> {
    qp.push(new QueryParam(DynamicListsGroupKeys.recipientsDbIds, WhereFilterOperandKeys.notIn, [agentDbId]));

    return this.fsDao.getList(this.dynamicListsGroupServicesCollectionPath, qp, { sortField });
  }
}
