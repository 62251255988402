import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { AgMediaUploadModel } from '../ag-media-uploader.models';
import { fileToUIBase64 } from '../ag-media-uploader.utils';
import { BehaviorSubject } from 'rxjs';
import { AgMediaUploaderService } from '../ag-media-uploader.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ag-shr-upload-from-disk-tab',
  templateUrl: './upload-from-disk-tab.component.html',
  styleUrls: ['./upload-from-disk-tab.component.scss'],
})
export class UploadFromDiskTabComponent {
  @HostBinding('class') className = 'upload-from-disk-tab';

  @ViewChild('fileUploader') fileUploader!: DxFileUploaderComponent;

  @Input() accept = 'image/*';
  @Output() mediaUrlChange = new EventEmitter<AgMediaUploadModel | null>();

  imagePreviewUrl: string;
  fileName: string;

  private _file: File;
  private _isImageValid$ = new BehaviorSubject(true);

  constructor(private agMediaUploaderService: AgMediaUploaderService, private toastrService: ToastrService) {}

  handleIsImageValid = (isValid): void => {
    this._isImageValid$.next(isValid);

    if (isValid) {
      this.fileName = this._file.name;
      this.agMediaUploaderService.setImage({
        contentType: this._file.type,
        fileName: this._file.name,
        base64: this.imagePreviewUrl.split(',')[1],
      });
    }
  };

  async fileChangeEvent(event: Event) {
    this.imagePreviewUrl = null;
    const file: File = (event?.target as HTMLInputElement)?.files[0];

    if (!file) {
      return;
    }

    const isFileValid = this.validateFile(file);
    if (!isFileValid) {
      return;
    }

    this._file = file;

    const fileBase64 = await fileToUIBase64(file).catch(() => {
      this._isImageValid$.next(false);
    });

    if (fileBase64) {
      this.imagePreviewUrl = fileBase64;
    }
  }

  clear() {
    this.imagePreviewUrl = null;
    this.fileName = null;
    this._file = null;
  }

  private validateFile(file: File): boolean {
    const { type, size } = file;
    return this.validateSize(size) && this.isImage(type);
  }

  private isImage(mimeType: string): boolean {
    if (mimeType.match(/image\/*/) === null) {
      this.toastrService.error('Only image files are allowed!');
      return false;
    }
    return true;
  }

  private validateSize(fileSize: number): boolean {
    const allowedSizeMb = 10;
    const maxAllowedSize = allowedSizeMb * 1024 * 1024; // MB => bytes
    if (fileSize > maxAllowedSize) {
      this.toastrService.error(
        `File is too big! Max allowed size is ${allowedSizeMb}MB. Image 1920 px width or height is recommended.`,
      );
      return false;
    }
    return true;
  }
}
