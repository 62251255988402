import { Component, HostBinding, Input } from '@angular/core';
import { AgentCampaignChange, CampaignBio } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-agent-campaign-summary-bio',
  templateUrl: './agent-campaign-summary-bio.component.html',
  styleUrls: ['./agent-campaign-summary-bio.component.scss'],
})
export class AgentCampaignSummaryBioComponent {
  @HostBinding('class') className = 'agent-campaign-summary-bio';

  @Input() set stepData(data: AgentCampaignChange<CampaignBio>) {
    const currentStepData = data?.currentStepData;
    const incomingStepData = data?.incomingStepData;

    this.list = [
      {
        title: 'Title',
        currentStepData: currentStepData?.title ?? null,
        incomingStepData: incomingStepData?.title ?? null,
      },
      {
        title: 'About Us',
        currentStepData: currentStepData?.aboutUs ?? null,
        incomingStepData: incomingStepData?.aboutUs ?? null,
      },
    ];
  }

  list: any[];
  constructor() {}
}
