import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { HTML_EDITOR_ITEMS } from '@ag-common-lib/public-api';
import {
  DxDateBoxComponent,
  DxHtmlEditorComponent,
  DxSelectBoxComponent,
  DxTagBoxComponent,
  DxTextBoxComponent,
  DxValidatorComponent,
} from 'devextreme-angular';

export type FormEditors =
  | DxHtmlEditorComponent
  | DxTextBoxComponent
  | DxSelectBoxComponent
  | DxDateBoxComponent
  | DxTagBoxComponent;

export interface SaveActionPayload {
  component: FormEditors;
  validator?: DxValidatorComponent;
}

@Component({
  selector: 'shr-website-input',
  templateUrl: './website-input.component.html',
  styleUrls: ['./website-input.component.scss'],
})
export class WebSiteInputComponent {
  @ViewChild('editorRef') editorComponent: DxHtmlEditorComponent;
  @HostBinding('class') className = 'website-input';
  @Input() value?: any;
  @Input() label?: string;
  @Input() name: string;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() useFiledEditing = false;
  @Input() hasChanges = false;
  @Input() inProgress = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSaveChanges: EventEmitter<SaveActionPayload> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onRevertChanges: EventEmitter<DxHtmlEditorComponent> = new EventEmitter();

  public isValid: boolean = true;
  public isFocused: boolean;
  public HTML_EDITOR_ITEMS = HTML_EDITOR_ITEMS;

  public handleValueChange = e => {
    this.valueChange.emit(e?.value);

    if (!this.isValid) {
      this.validate();
    }
  };

  public validate = () => {
    this.isValid = !!this.value;

    return { isValid: this.isValid };
  };

  public handleRevertClick = () => {
    this.onRevertChanges.emit(this.editorComponent);
  };

  public handleSaveChangesClick = () => {
    const { isValid } = this.validate();
    if (isValid) {
      this.onSaveChanges.emit({ component: this.editorComponent });
    }
  };
}
