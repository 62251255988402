import { BaseModel } from '../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/base.model';

export enum AssignedOwnerDetailsKeys {
  assignedTo = 'assignedTo',
  assignedToNote = 'assignedToNote',
}

export class AssignedOwnerDetails extends BaseModel {
  [AssignedOwnerDetailsKeys.assignedTo]?: string;
  [AssignedOwnerDetailsKeys.assignedToNote]?: string;
}
