export enum PhysicalLocationKeys {
  address = 'address',
  secondAddress = 'secondAddress',
  city = 'city',
  state = 'state',
  zip = 'zip',
}

export class PhysicalLocation {
  [PhysicalLocationKeys.address]: string;
  [PhysicalLocationKeys.secondAddress]?: string;
  [PhysicalLocationKeys.city]?: string;
  [PhysicalLocationKeys.state]?: string;
  [PhysicalLocationKeys.zip]?: string;
}
