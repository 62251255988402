import { BaseModel } from '../base.model';
import { LookupKeys } from '../crm/lookup.model';

export enum ConferenceRegistrationLogType {
  support = 'support',
  specialRequest = 'specialRequest',
  other = 'other',
}

export const REGISTRATION_LOG_TYPE_LOOKUP = [
  { [LookupKeys.value]: ConferenceRegistrationLogType.support, [LookupKeys.description]: 'Support' },
  { [LookupKeys.value]: ConferenceRegistrationLogType.specialRequest, [LookupKeys.description]: 'Special Request' },
  // { [LookupKeys.value]: ConferenceRegistrationLogType.additionalGuest, [LookupKeys.description]: 'Additional Guest' },
  // { [LookupKeys.value]: ConferenceRegistrationLogType.additionalRoom, [LookupKeys.description]: 'Additional Room' },
  // {
  //   [LookupKeys.value]: ConferenceRegistrationLogType.travelDatesDiffer,
  //   [LookupKeys.description]: 'Travel Dates Differ',
  // },
  {
    [LookupKeys.value]: ConferenceRegistrationLogType.other,
    [LookupKeys.description]: 'Other',
  },
];

export enum ConferenceRegistrationSupportLogKey {
  title = 'title',
  details = 'details',
  owner = 'owner',
  date = 'date',
}

export enum ConferenceRegistrationRequestLogKey {
  status = 'status',
}

export class ConferenceRegistrationSupportLog {
  [ConferenceRegistrationSupportLogKey.details]?: string;
  [ConferenceRegistrationSupportLogKey.title]?: string;
  [ConferenceRegistrationSupportLogKey.owner]?: string;
  [ConferenceRegistrationSupportLogKey.date]?: Date;
}

export enum RequestLogStatus {
  approved = 'approved',
  rejected = 'rejected`',
}

export const REGISTRATION_LOG_STATUS_LOOKUP = [
  { [LookupKeys.value]: RequestLogStatus.approved, [LookupKeys.description]: 'Approved' },
  { [LookupKeys.value]: RequestLogStatus.rejected, [LookupKeys.description]: 'Rejected' },
];

export class ConferenceRegistrationRequestLog extends ConferenceRegistrationSupportLog {
  [ConferenceRegistrationRequestLogKey.status]?: RequestLogStatus;
}

type ConferenceRegistrationLogTypeMap = {
  [ConferenceRegistrationLogType.support]: ConferenceRegistrationSupportLog;
  [ConferenceRegistrationLogType.specialRequest]: ConferenceRegistrationRequestLog;
  [ConferenceRegistrationLogType.other]: null;
};

type ConferenceRegistrationLogData<T extends ConferenceRegistrationLogType> = ConferenceRegistrationLogTypeMap[T];

export enum ConferenceRegistrationLogKeys {
  conferenceDbId = 'conferenceDbId',
  registrantDbId = 'registrantDbId',
  type = 'type',
  data = 'data',
  isArchived = 'isArchived',
}

export class ConferenceRegistrationLog extends BaseModel {
  [ConferenceRegistrationLogKeys.conferenceDbId]?: string;
  [ConferenceRegistrationLogKeys.registrantDbId]?: string;
  [ConferenceRegistrationLogKeys.type]: ConferenceRegistrationLogType;
  [ConferenceRegistrationLogKeys.data]: ConferenceRegistrationLogData<ConferenceRegistrationLogType>;
  [ConferenceRegistrationLogKeys.isArchived]?: boolean;
}
