import { Pipe, PipeTransform } from '@angular/core';
import { ActiveLookup, LookupId } from '@ag-common-lib/public-api';

@Pipe({ name: 'organizationAffiliationsDescription' })
export class AgencyOrganizationAffiliationsDescriptionPipe implements PipeTransform {
  transform(lookupIds: LookupId<'AgencyOrganizationAffiliations'>, lookup: ActiveLookup[]): string {
    if (!lookupIds) {
      return '';
    }
    return lookup
      ?.filter(item => item && lookupIds.includes(item.dbId))
      .map(({ description }) => description)
      .filter(Boolean)
      .join(', ');
  }
}
