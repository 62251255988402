import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { AgEditorToolbarModule } from '../../../ag-editor-toolbar/ag-editor-toolbar.module';
import { AgencyRelationshipComponent } from './agency-relationship.component';
import { AgencyRelationshipService } from './agency-relationship.service';
import { AgencyOrganizationAffiliationsDescriptionPipe } from './agency-organization-affiliations-description.pipe';

@NgModule({
  imports: [SharedModule, ModalWindowModule, AgEditorToolbarModule],
  declarations: [AgencyRelationshipComponent, AgencyOrganizationAffiliationsDescriptionPipe],
  exports: [ AgencyRelationshipComponent, AgencyOrganizationAffiliationsDescriptionPipe ],
  providers: [AgencyRelationshipService],
})
export class AgencyRelationshipModule {}
