<ag-shr-agent-campaign-summary-list-item [title]="'Web Site Data'">

  <ng-container agCampaignsActions>
    <ng-content select="[agCampaignsControls]"></ng-content>
  </ng-container>

  <ag-shr-agent-campaign-summary-list-data
    [hasCurrentData]="!!stepData?.currentStepData"
    [hasIncomingData]="!!stepData?.incomingStepData"
  >
    <ng-template
      #websiteDataTmp
      let-data="data"
    >
      <ng-container *ngIf="data?.domainOption !== '__custom__'; else userDomainTmp">
        {{data?.subdomain}}
        .{{data?.domainOption}}
      </ng-container>

      <ng-template #userDomainTmp>Custom Domain: {{data?.subdomain}}</ng-template>
    </ng-template>

    <ng-container
      currentDataTmp
      *ngTemplateOutlet="websiteDataTmp; context: { data: stepData?.currentStepData }"
    >
    </ng-container>

    <ng-container
      incomingDataTmp
      *ngTemplateOutlet="websiteDataTmp; context: { data: stepData?.incomingStepData}"
    >
    </ng-container>

    <ng-container
      controls
      *var="stepData?.incomingStepData as data"
    >
      <ag-shr-clipboard-button
        *ngIf="data?.domainOption !== '__custom__'; else copySubdomainTmp"
        [valueExpr]="'value'"
        [displayExpr]="'description'"
        [clipboardValues]=" [{value: data?.subdomain + '.' +data?.domainOption, description: 'Copy Full Url' }, {value: data?.subdomain, description: 'Copy Subdomain' }, {value: data?.domainOption, description: 'Copy Domain' }]"
      ></ag-shr-clipboard-button>

      <ng-template #copySubdomainTmp>
        <ag-shr-clipboard-button [clipboardValue]="data?.subdomain"></ag-shr-clipboard-button>
      </ng-template>
    </ng-container>

  </ag-shr-agent-campaign-summary-list-data>
</ag-shr-agent-campaign-summary-list-item>
