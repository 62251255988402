import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@ag-common-lib/public-api';
import { getFullAddress } from '../utils/full-address';

@Pipe({ name: 'fullAddress' })
export class FullAddressPipe implements PipeTransform {
  transform(address: Address, limit?: number, partAddress: 'full' | 'street' | 'other' = 'full'): string {
    let addressText = '';
    if (partAddress === 'full') {
      const fullAddress = getFullAddress(address);
      if (!fullAddress) {
        return '';
      }
      addressText = fullAddress;
    }

    if (partAddress === 'street') {
      addressText = [address?.address1, address?.address2].filter(Boolean).join(' ');
    }

    if (partAddress === 'other') {
      addressText = [address?.city, address?.state, address?.zip].filter(Boolean).join(' ');
    }

    if (limit && limit < addressText.length) {
      return addressText.slice(0, limit) + '...';
    }

    return addressText || '';
  }
}
