import { Component, ViewChild } from '@angular/core';
import {
  AgStripeSubtotal,
  AgStripeSubtotalKeys,
  DX_USD_CURRENCY_FORMAT,
  LookupKeys,
  REFUND_FAILURE_REASON_LOOKUP,
  REFUND_REASON_LOOKUP,
  SUBTOTAL_TYPE_LOOKUP,
  SubtotalType,
  TransactionType,
} from 'ag-common-lib/public-api';
import { ModalWindowComponent } from '../modal-window/modal-window.component';

@Component({
  selector: 'ag-crm-attendee-transaction-details-modal',
  templateUrl: './attendee-transaction-details-modal.component.html',
  styleUrls: ['./attendee-transaction-details-modal.component.scss'],
})
export class AttendeeTransactionDetailsModalComponent {
  @ViewChild('attendeeTransactionDetailsModalRef', { static: true })
  transactionDetailsModalComponent: ModalWindowComponent;

  transactionDetails: Partial<AgStripeSubtotal<SubtotalType>[]> = [];
  transactionType: TransactionType;

  protected readonly TransactionType = TransactionType;
  protected readonly AgStripeSubtotalKeys = AgStripeSubtotalKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;
  protected readonly reasonReasonLookup = REFUND_REASON_LOOKUP;
  protected readonly refundFailureReasonLookup = REFUND_FAILURE_REASON_LOOKUP;
  protected readonly SUBTOTAL_TYPE_LOOKUP = SUBTOTAL_TYPE_LOOKUP;

  showModal = async (data: Partial<AgStripeSubtotal<SubtotalType>[]>, type: TransactionType) => {
    this.transactionDetails = data;
    this.transactionType = type;
    this.transactionDetailsModalComponent.showModal();
  };
}
