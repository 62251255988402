import { LookupBase } from '../models/utils/lookup-base.model';

export enum PermissionType {
  root = 'root',
  entity = 'entity',
}

export const PERMISSION_TYPE_LOOKUP: LookupBase[] = [
  { value: PermissionType.root, description: 'Root' },
  { value: PermissionType.entity, description: 'Entity' },
];

export const PERMISSION_USER_DESCRIPTION_LOOKUP: LookupBase[] = [
  { value: PermissionType.root, description: 'System' },
  { value: PermissionType.entity, description: 'Entity' },
];
