import { LookupBase } from '../models/utils/lookup-base.model';

export enum CampaignsManagementTaskLogsAction {
  taskCreating = 'taskCreating',
  taskUpdating = 'taskUpdating',
  issueCreating = 'issueCreating',
  issueUpdating = 'issueUpdating',
  assignOwner = 'assignOwner',
}

export const CAMPAIGNS_MANAGEMENT_LOGS_ACTION_LOOKUP: LookupBase[] = [
  { value: CampaignsManagementTaskLogsAction.taskCreating, description: 'New Task was Created' },
  { value: CampaignsManagementTaskLogsAction.taskUpdating, description: 'Task was Updated' },
  { value: CampaignsManagementTaskLogsAction.issueCreating, description: 'New Issue was Created' },
  { value: CampaignsManagementTaskLogsAction.issueUpdating, description: 'Issue was Updated' },
  { value: CampaignsManagementTaskLogsAction.assignOwner, description: 'The owner was Added/Updated' },
];
