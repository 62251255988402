import { Injectable } from '@angular/core';
import { AgencyCarrier } from '@ag-common-lib/public-api';
import { BaseFormService } from '../../../../utils/base-form-service';
import { AgencyService } from '../../../../services/agency.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AgencyCarriersModalService extends BaseFormService<AgencyCarrier> {
  constructor(private toastrService: ToastrService, private agencyService: AgencyService) {
    super();
  }

  getFormData = async (carrier?: Partial<AgencyCarrier>): Promise<AgencyCarrier> => {
    const initialData = Object.assign({}, carrier);

    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);

        return true;
      },
    });

    return this.formData;
  };

  updateCarriers(dbId: string, carriers: AgencyCarrier[]): Promise<AgencyCarrier[]> {
    this.startProgress();
    this.formChangesDetector.clear();
    return this.agencyService
      .updateFields(dbId, { carriers })
      .then(() => carriers)
      .catch(err => {
        this.toastrService.error('Carriers are not updated!');
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  }
}
