export enum AgentReviewLevel {
  AllianceGroupLevel = 'AllianceGroupLevel',
  InternalLevel = 'InternalLevel',
  ExternalLevel = 'ExternalLevel',
}

export const AgentReviewLevelDescriptionMap = new Map([
  [AgentReviewLevel.AllianceGroupLevel, 'In Review: Initial'],
  [AgentReviewLevel.InternalLevel, 'In Review: Internal'],
  [AgentReviewLevel.ExternalLevel, 'In Review: External'],
]);
