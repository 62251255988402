import { StripeTransaction } from '../stripe/stripe-transaction.model';

export enum RegistrationTransactionKeys {
  conferenceDbId = 'conferenceDbId',
  registrantDbId = 'registrantDbId',
}

export class RegistrationTransaction extends StripeTransaction {
  [RegistrationTransactionKeys.conferenceDbId]?: string;
  [RegistrationTransactionKeys.registrantDbId]?: string;
}
