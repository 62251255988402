import { Directive, Input, OnInit, ElementRef } from '@angular/core';
import * as ics from 'ics';

@Directive({
  selector: '[shrAddToCalendar]',
})
export class AddToCalendarDirective implements OnInit {
  @Input() shrAddToCalendar: ics.EventAttributes; // Input property to receive event details

  constructor(private el: ElementRef) {}

  ngOnInit() {
    // Check if the input property has a value
    if (this.shrAddToCalendar) {
      // Generate and set the iCalendar data when the directive initializes
      this.generateICalData();
    }
  }

  generateICalData() {
    // Use the event details from the input property
    const event = this.shrAddToCalendar;

    // Generate the iCalendar data
    const { error, value } = ics.createEvent(event);

    if (!error) {
      // Create a Blob with the iCalendar data
      const blob = new Blob([value], { type: 'text/calendar;charset=utf-8' });

      // Set the Blob as the href, triggering the download when the link is clicked
      this.el.nativeElement.href = window.URL.createObjectURL(blob);
    }
  }
}
