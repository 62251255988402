import { Injectable } from '@angular/core';
import { AgencyRelationship, Lookup, LookupKeys } from '@ag-common-lib/public-api';
import { BaseFormService } from '../../../../utils/base-form-service';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from '../../../../services/agency.service';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { BehaviorSubject } from 'rxjs';
import { DocumentReference, updateDoc } from 'firebase/firestore';

@Injectable()
export class AgencyRelationshipService extends BaseFormService<AgencyRelationship> {
  selectedOrganizationAffiliations$ = new BehaviorSubject<Lookup[]>([]);

  constructor(private toastrService: ToastrService, private agencyService: AgencyService) {
    super();
  }

  getFormData = (relationship?: AgencyRelationship) => {
    const initialData = Object.assign({}, relationship);
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);
        return true;
      },
    });

    return this.formData;
  };

  saveAgencyRelashionship = (agencyId: string, modalWindowComponent: ModalWindowComponent) => {
    const hasChanges = this.formChangesDetector.hasChanges;

    if (hasChanges) {
      const relationshipWithAG = {};
      const changes = this.formChangesDetector.getAllChanges();
      changes.forEach(([key]) => {
        const update = this.formData[key] ?? null;
        Object.assign(relationshipWithAG, { [key]: update });
      });
      this.startProgress();
      return this.agencyService
        .updateFields(agencyId, { relationshipWithAG })
        .then(() => {
          const selectedOrganizationAffiliations = this.selectedOrganizationAffiliations$?.value;
          if (selectedOrganizationAffiliations.length) {
            selectedOrganizationAffiliations.map(lookup => {
              if (!lookup?.isAssigned) {
                updateDoc(lookup?.reference as DocumentReference<Lookup>, {
                  [LookupKeys.isAssigned]: true,
                }).then();
              }
            });
          }
          this.toastrService.success('Agency Relationship Successfully Updated');
          this.formChangesDetector.clear();
          modalWindowComponent?.hideModal();
          return { agencyId, relationshipWithAG };
        })
        .finally(() => {
          this.stopProgress();
        });
    }
    return null;
  };

  setSelectedOrganizationAffiliations(item): void {
    this.selectedOrganizationAffiliations$.next(item);
  }
}
