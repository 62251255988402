import { LookupId } from '../../lists/lookups.enum';
import { BaseModel } from '../base.model';
import { SupportType } from '../../lists/support-type.enum';
import { MappingProperty } from '@elastic/elasticsearch/lib/api/types';
import { LookupKeys } from '../crm/lookup.model';

export enum EmailAddressKeys {
  address = 'address',
  rawEmailType = 'raw_email_type',
  agentDbId = 'agent_db_id',
  emailType = 'email_type',
  isLogin = 'is_login',
  supportType = 'support_type',
  agencyId = 'agencyId',
}

export const EMAIL_ADDRESS_KEYS_LOOKUP = [
  { [LookupKeys.value]: EmailAddressKeys.address, [LookupKeys.description]: 'Email Address' },
  { [LookupKeys.value]: EmailAddressKeys.emailType, [LookupKeys.description]: 'Email Type' },
  { [LookupKeys.value]: EmailAddressKeys.isLogin, [LookupKeys.description]: 'Is Login' },
  { [LookupKeys.value]: EmailAddressKeys.supportType, [LookupKeys.description]: 'Support Type' },
];

export interface EmailRecipient {
  name: string;
  address: string;
}

export class RawEmailAddress {
  [EmailAddressKeys.address]: string;
  [EmailAddressKeys.agentDbId]: string;
  [EmailAddressKeys.rawEmailType]?: string;
  [EmailAddressKeys.isLogin]?: boolean = false;
}

export class EmailAddress extends BaseModel {
  [EmailAddressKeys.address]: string;
  [EmailAddressKeys.agentDbId]: string;
  [EmailAddressKeys.emailType]?: LookupId<'EmailTypes'>;
  [EmailAddressKeys.isLogin]?: boolean = false;
}

export const EmailAddressKeyMapping: {
  [key: string]: MappingProperty;
} = {
  [EmailAddressKeys.address]: { type: 'keyword' },
  [EmailAddressKeys.emailType]: { type: 'text' },
  [EmailAddressKeys.isLogin]: { type: 'boolean' },
};

export interface RelatedEmailAddress {
  data: EmailAddress;
  parentDbId: string;
}

export class OfficeEmailAddress extends BaseModel {
  is_primary: boolean;
  [EmailAddressKeys.emailType]?: LookupId<'AgencyEmailTypes'>;
  [EmailAddressKeys.address]: string;
  [EmailAddressKeys.agencyId]: string;
  [EmailAddressKeys.supportType]: SupportType = SupportType.MainOffice;
}
