import { Inject, Injectable } from '@angular/core';
import { CommonFireStoreDao, QueryParam } from '../dao/CommonFireStoreDao.dao';
import { AGENCIES_COLLECTION_NAME, AgencyDocumentCollections, AgencyFileDirectory } from '@ag-common-lib/public-api';
import { FIREBASE_APP } from '../injections/firebase-app';
import { FirebaseApp } from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { AgencyFileDirectoryKeys } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/agency-file-directory.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgencyFileDirectoriesService {
  readonly fsDao: CommonFireStoreDao<AgencyFileDirectory>;
  private readonly agenciesCollectionPath = AGENCIES_COLLECTION_NAME;
  private readonly fileDirectoryCollectionPath = AgencyDocumentCollections.fileDirectories;

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp, private toastrService: ToastrService) {
    this.fsDao = new CommonFireStoreDao<AgencyFileDirectory>(fireBaseApp, null, null);
  }

  getList(agencyId: string, qp: QueryParam[] = []): Observable<AgencyFileDirectory[]> {
    const table = this.getCollectionPath(agencyId);

    return this.fsDao.getList(table, qp);
  }

  async create(agencyId: string, data: AgencyFileDirectory, silent = false): Promise<void | AgencyFileDirectory> {
    const table = this.getCollectionPath(agencyId);
    const media = await this.fsDao
      .create(Object.assign(data, { [AgencyFileDirectoryKeys.agencyDbId]: agencyId }), table)
      .catch(e => {
        console.log('e', e);
      });

    !silent && this.toastrService.success(`Agency File Directory "${data.name}" Successfully Created!`);

    return media;
  }

  async update(
    agencyId: string,
    documentId: any,
    updates: Partial<AgencyFileDirectory>,
    silent = false,
  ): Promise<void | AgencyFileDirectory> {
    const table = this.getCollectionPath(agencyId);

    const media = await this.fsDao.updateFields(updates, documentId, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success(`Agency File Directory "${updates.name}" Successfully Updated!`);

    return media;
  }

  delete(agencyId: string, documentId: any): Promise<void> {
    const table = this.getCollectionPath(agencyId);

    return this.fsDao.delete(documentId, table).then(response => {
      this.toastrService.success('Agency File Directory Removed!');
      return response;
    });
  }

  private getCollectionPath(agencyId: string): string {
    return [this.agenciesCollectionPath, agencyId, this.fileDirectoryCollectionPath].join('/');
  }
}
