export enum UpdateUserPasswordKeys {
  password = 'password',
  email = 'email',
  otp = 'otp'
}

export interface UpdateUserPassword {
  [UpdateUserPasswordKeys.password]: string;
  [UpdateUserPasswordKeys.email]: string;
  [UpdateUserPasswordKeys.otp]: string;
}
