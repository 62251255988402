import { NeedToKnowVisibilityLevel } from '../../lists/need-to-know-visibility-level.enum';
import { BaseModel } from '../base.model';

export enum NeedToKnowKeys {
  needToKnow = 'needToKnow',
  visibilityLevel = 'visibilityLevel',
}

export class NeedToKnow extends BaseModel {
  [NeedToKnowKeys.needToKnow]?: string;
  [NeedToKnowKeys.visibilityLevel]: NeedToKnowVisibilityLevel;
}

export class LegacyNeedToKnow extends BaseModel {
  reason?: string;
}
