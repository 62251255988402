import { LookupKeys } from '../crm/lookup.model';

export enum RegistrationType {
  mga = 'mga',
  adMgaArrival = 'adMgaArrival',
  allianceStaff = 'allianceStaff',
  carrierPartner = 'carrierPartner',
  agent = 'agent',
  ad = 'ad',
  vendor = 'vendor',
  travelStaff = 'travelStaff',
}

export const RegistrationTypeMap = new Map([
  [RegistrationType.mga, 'MGA'],
  [RegistrationType.adMgaArrival, 'Agency Director MGA Arrival'],
  [RegistrationType.allianceStaff, 'Alliance Staff'],
  [RegistrationType.carrierPartner, 'Carrier Partner'],
  [RegistrationType.agent, 'Agent'],
  [RegistrationType.ad, 'Agency Director'],
  [RegistrationType.vendor, 'Vendor'],
  [RegistrationType.travelStaff, 'Travel Staff'],
]);

export const REGISTRATION_TYPE_LOOKUP = [
  RegistrationType.mga,
  RegistrationType.adMgaArrival,
  RegistrationType.allianceStaff,
  RegistrationType.carrierPartner,
  RegistrationType.agent,
  RegistrationType.ad,
  RegistrationType.vendor,
  RegistrationType.travelStaff,
].map(value => ({
  [LookupKeys.value]: value,
  [LookupKeys.description]: RegistrationTypeMap.get(value),
}));
