export enum SupportTaskEmailToAgentKeys {
  urlToSupportTask = 'urlToSupportTask',
  inviteeEmail = 'inviteeEmail',
  fullName = 'fullName',
}

export interface SupportTaskEmailToAgent {
  [SupportTaskEmailToAgentKeys.urlToSupportTask]: string;
  [SupportTaskEmailToAgentKeys.inviteeEmail]: string;
  [SupportTaskEmailToAgentKeys.fullName]: string;
}
