import {
  ConferenceStepsTemplateName,
  ConferenceStepsTemplateNameMap,
} from '../models/registration/conference-steps-configuration.model';

export enum StepsTemplateKey {
  description = 'description',
  value = 'value',
  htmlContent = 'htmlContent',
}

export interface StepsTemplate {
  [StepsTemplateKey.description]: string;
  [StepsTemplateKey.value]: ConferenceStepsTemplateName;
  [StepsTemplateKey.htmlContent]: string | null;
}

export const STEPS_TEMPLATE: StepsTemplate[] = [
  {
    description: ConferenceStepsTemplateNameMap.get(ConferenceStepsTemplateName.noTemplate),
    value: ConferenceStepsTemplateName.noTemplate,
    htmlContent: null,
  },
  {
    description: ConferenceStepsTemplateNameMap.get(ConferenceStepsTemplateName.customTemplate),
    value: ConferenceStepsTemplateName.customTemplate,
    htmlContent: null,
  },
];

export const SUMMARY_STEPS_TEMPLATE: StepsTemplate[] = [
  ...STEPS_TEMPLATE,
  {
    description: ConferenceStepsTemplateNameMap.get(
      ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    ),
    value: ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    htmlContent:
      'We look forward to seeing you at our 18th Annual Alliance Group Leaders Conference! ' +
      'Please keep an eye out for emails from our team with your flight itinerary for approval, as well as ' +
      'requests for any additional info we made need from you!',
  },
];

export const DECLINED_SUMMARY_STEPS_TEMPLATE: StepsTemplate[] = [
  ...STEPS_TEMPLATE,
  {
    description: ConferenceStepsTemplateNameMap.get(
      ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    ),
    value: ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    htmlContent:
      '<span>' +
      "Thanks for your response, we're sorry to hear that you won't be able to join us in Cap Cana. But mark your " +
      'calendars for next year! Our 19th Annual Alliance Group Leaders Conference will take place April 13 - 17, 2025 in ' +
      'Scottsdale, AZ at the Arizona Baltimore, A Waldorf Astoria Resort.' +
      '</span>',
  },
];

export const GUEST_STEPS_TEMPLATE: StepsTemplate[] = [
  ...STEPS_TEMPLATE,
  {
    description: ConferenceStepsTemplateNameMap.get(
      ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    ),
    value: ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    htmlContent:
      '<span>' +
      'As a qualifier, you are able to register for yourself and one complimentary guest for the 18th Annual Alliance Group Leaders Conference!' +
      '<br/><br/>' +
      'At your expense, you may choose to buy-in for up to two additional guests to attend the trip with you. Buy-in fees, which are an additional personal expense, cover the cost of the additional guest(s) staying in the same room and arrival and departure transportation during group dates. This expense will also include food and beverages during main group events. Airfare for your additional guests is not included in this fee and will be an additional personal expense.' +
      '<br/><br/>' +
      'These rates cover:' +
      '<br/>' +
      '- Ground Transportation' +
      '<br/>' +
      '- Alliance Group sponsored meals and receptions' +
      '<br/>' +
      '- Hyatt Ziva Cap Cana all-inclusive amenities including dining, beverages, room service, entertainment.' +
      '<br/><br/>' +
      'These rates do not cover:' +
      '<br/>' +
      '- Airfare will be a personal expense in addition to the buy-in rate ' +
      '</span>',
  },
];

export const HOTEL_STEPS_TEMPLATE: StepsTemplate[] = [
  ...STEPS_TEMPLATE,
  {
    description: ConferenceStepsTemplateNameMap.get(
      ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    ),
    value: ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    htmlContent:
      '<span>' +
      'To assist us in making your hotel reservation, please complete the form below with your details. ' +
      'Base on your qualification, your check-in date is ' +
      '<span class="dx-variable" data-var-start-esc-char="{{" data-var-end-esc-char="}}" data-var-value="Primary_Arrival_Date"><span contenteditable="false">{{Primary_Arrival_Date}}</span></span>' +
      ' and your check-out date is ' +
      '<span class="dx-variable" data-var-start-esc-char="{{" data-var-end-esc-char="}}" data-var-value="Primary_Departure_Date"><span contenteditable="false">{{Primary_Departure_Date}}</span></span>' +
      '. If you would like to request and early arrival date or late departure date, at your own expense, select those dates below. ' +
      '</span>',
  },
];

export const FLIGHT_INFO_STEPS_TEMPLATE: StepsTemplate[] = [
  ...STEPS_TEMPLATE,
  {
    description: ConferenceStepsTemplateNameMap.get(
      ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    ),
    value: ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    htmlContent:
      '<span>' +
      'Our travel department will begin making the airline arrangements for your trip upon completion of your ' +
      'online registration. An agent from ' +
      '<span class="dx-variable" data-var-start-esc-char="{{" data-var-end-esc-char="}}" data-var-value="Flight_Agent_Name"><span contenteditable="false">{{Flight_Agent_Name}}</span></span>' +
      ' will contact you within 5-7 business days of completing ' +
      'your registration. Upon receipt of the email, you will be required to approve your air itinerary within ' +
      '24 hours before your final ticket is issued. No ticket will be issued until you have reviewed the itinerary ' +
      'and approve of the schedule via email. Flights will be selected for you based on program guidelines and in ' +
      "accordance with the program's designated airfares." +
      '</span>',
  },
];

export const GUEST_FLIGHT_INFO_STEPS_TEMPLATE: StepsTemplate[] = [
  ...STEPS_TEMPLATE,
  {
    description: ConferenceStepsTemplateNameMap.get(
      ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    ),
    value: ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    htmlContent:
      '<span>' +
      'Our travel department will begin making the airline arrangements for your trip upon completion of your ' +
      'online registration. An agent from ' +
      '<span class="dx-variable" data-var-start-esc-char="{{" data-var-end-esc-char="}}" data-var-value="Flight_Agent_Name"><span contenteditable="false">{{Flight_Agent_Name}}</span></span>' +
      ' will contact you within 5-7 business days of completing ' +
      'your registration. Upon receipt of the email, you will be required to approve your air itinerary within ' +
      '24 hours before your final ticket is issued. No ticket will be issued until you have reviewed the itinerary ' +
      'and approve of the schedule via email. Flights will be selected for you based on program guidelines and in ' +
      "accordance with the program's designated airfares." +
      '</span>',
  },
];
export const EXCURSIONS_INFO_STEPS_TEMPLATE: StepsTemplate[] = [
  ...STEPS_TEMPLATE,
  {
    description: ConferenceStepsTemplateNameMap.get(
      ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    ),
    value: ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    htmlContent:
      '<span>' +
      'We are offering a selection of fun and interesting activities for you and your guests to enjoy in the DR. ' +
      'As a qualifier, you have been allocated a ' +
      '<span class="dx-variable" data-var-start-esc-char="{{" data-var-end-esc-char="}}" data-var-value="Excursion_Budget"><span contenteditable="false">{{Excursion_Budget}}</span></span>' +
      ' credit to be applied toward these excursions. If you would like to participate in excursions that total more than ' +
      '<span class="dx-variable" data-var-start-esc-char="{{" data-var-end-esc-char="}}" data-var-value="Excursion_Budget"><span contenteditable="false">{{Excursion_Budget}}</span></span>' +
      ', you will be responsible for the difference. If you do not use the full ' +
      '<span class="dx-variable" data-var-start-esc-char="{{" data-var-end-esc-char="}}" data-var-value="Excursion_Budget"><span contenteditable="false">{{Excursion_Budget}}</span></span>' +
      ' credit, the remaining value may not be applied to any other part of the ' +
      'trip. If you don\'t wish to schedule an activity, select "at leisure".' +
      '</span>',
  },
];

export const YOUR_EXCURSIONS_INFO_STEPS_TEMPLATE: StepsTemplate[] = [
  ...STEPS_TEMPLATE,
  {
    description: ConferenceStepsTemplateNameMap.get(
      ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    ),
    value: ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    htmlContent:
      '<span>' +
      'We are offering a selection of fun and interesting activities for you and your guests to enjoy in the DR. ' +
      'As a qualifier, you have been allocated a ' +
      '<span class="dx-variable" data-var-start-esc-char="{{" data-var-end-esc-char="}}" data-var-value="Excursion_Budget"><span contenteditable="false">{{Excursion_Budget}}</span></span>' +
      ' credit to be applied toward these excursions. If you would like to participate in excursions that total more than ' +
      '<span class="dx-variable" data-var-start-esc-char="{{" data-var-end-esc-char="}}" data-var-value="Excursion_Budget"><span contenteditable="false">{{Excursion_Budget}}</span></span>' +
      ', you will be responsible for the difference. If you do not use the full ' +
      '<span class="dx-variable" data-var-start-esc-char="{{" data-var-end-esc-char="}}" data-var-value="Excursion_Budget"><span contenteditable="false">{{Excursion_Budget}}</span></span>' +
      " credit, the remaining value may not be applied to any other part of the trip. If you don't wish to schedule an " +
      'activity, select "at leisure". Spots are limited and are first come, first serve!' +
      '</span>',
  },
];

export const CONFIRMATION_STEPS_TEMPLATE: StepsTemplate[] = [
  ...STEPS_TEMPLATE,
  {
    description: ConferenceStepsTemplateNameMap.get(
      ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    ),
    value: ConferenceStepsTemplateName.eighteenthAnnualAllianceGroupLeadersConference,
    htmlContent: 'Please take a moment to carefully review the information below before submitting your registration.',
  },
];
