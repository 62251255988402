<dx-form
  #formRef
  [formData]="phoneNumber"
  labelMode="floating"
  [validationGroup]="validationGroup"
  [colCountByScreen]="colCountByScreen"
  [screenByWidth]="formSize$ | async | formScreenByWidth"
  (onInitialized)="onFormInitialized($event)"
  (onFieldDataChanged)="formRef?.instance?.validate()"
>
  <dxi-item
    [dataField]="PhoneNumberKeys.number"
    [label]="{ text: 'Number' }"
    [colSpan]="6"
    [editorOptions]="{
      name: [pathPrefix, PhoneNumberKeys.number] | path,
      mask: phoneMask,
      readOnly: isNumberReadonly,
      inputAttr: { autocomplete: 'disabled' }
    }"
  >
    <dxi-validation-rule
      type="pattern"
      [pattern]="phonePattern"
      [message]="Messages.INVALID_PHONE_DIGITS_TEXT"
    ></dxi-validation-rule>

    <dxi-validation-rule
      type="custom"
      [message]="'Number field are required for Phone Number'"
      [reevaluate]="true"
      [validationCallback]="numberValidationCallback"
    ></dxi-validation-rule>

    <dxi-validation-rule
      type="required"
      [message]="Messages.REQUIRED_PHONE_TEXT"
      *ngIf="isNumberFieldRequired"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    [dataField]="PhoneNumberKeys.extension"
    [label]="{ text: 'Extension' }"
    [colSpan]="2"
    [editorOptions]="{ name: [pathPrefix, PhoneNumberKeys.extension] | path, readOnly: isExtensionReadonly }"
  ></dxi-item>

  <dxi-item
    [dataField]="PhoneNumberKeys.phone_type"
    [label]="{ text: 'Type' }"
    editorType="dxSelectBox"
    [editorOptions]="{
      name: [pathPrefix, PhoneNumberKeys.phone_type] | path,
      acceptCustomValue: true,
      items: PHONE_NUMBER_TYPE_LOOKUP,
      valueExpr: 'value',
      displayExpr: 'description',
      searchEnabled: true,
      placeholder: '',
      readOnly: isTypeReadonly
    }"
    [colSpan]="2"
  >
    <dxi-validation-rule
      *ngIf="!!phoneNumber?.number && isNumberFieldRequired"
      type="required"
      [message]="'Phone Number Type is required'"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    [dataField]="PhoneNumberKeys.is_whatsapp"
    [alignItemLabels]="true"
    [colSpan]="2"
    [label]="{ text: 'WhatsApp' }"
    editorType="dxSwitch"
    [width]="200"
    cssClass="min-width-min-content"
    [editorOptions]="{
      name: [pathPrefix, PhoneNumberKeys.is_whatsapp] | path,
      minWidth: '200',
      switchedOffText: 'No',
      switchedOnText: 'Yes',
      readOnly: isWhatsappReadonly
    }"
  ></dxi-item>
</dx-form>
