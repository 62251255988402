import { Injectable } from '@angular/core';
import { Lookup, StateLicense } from '@ag-common-lib/public-api';
import { BehaviorSubject } from 'rxjs';
import { AgencyService } from '../../../../services/agency.service';
import { BaseFormService } from '../../../../utils/base-form-service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class StatesLicensedInService extends BaseFormService<StateLicense[]> {
  selectedAgencyPhoneType$ = new BehaviorSubject<Lookup>(null);

  constructor(private agencyService: AgencyService, private toastrService: ToastrService) {
    super();
  }

  updateStateLicenseChange(agencyId: string, license: StateLicense[]): Promise<StateLicense[]> {
    this.startProgress();
    return this.agencyService
      .updateFields(agencyId, { stateLicenses: license })
      .then(() => license)
      .catch(err => {
        this.toastrService.error('Carriers are not updated!');
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  }
}
