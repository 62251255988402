import { NgModule } from '@angular/core';
import { AgentCampaignPhoneNumberInfoComponent } from './agent-campaign-phone-number-info.component';
import { SharedModule } from '../../../../shared/shared.module';
import { ButtonWithIndicatorModule } from '../../button-with-indicator/button-with-indicator.module';
import { AgentCampaignPhoneNumberInfoService } from './agent-campaign-phone-number-info.service';
import { SelectPhoneNumberModule } from '../../select-phone-number/select-phone-number.module';

@NgModule({
  declarations: [AgentCampaignPhoneNumberInfoComponent],
  imports: [SharedModule, ButtonWithIndicatorModule, SelectPhoneNumberModule],
  exports: [AgentCampaignPhoneNumberInfoComponent],
  providers: [AgentCampaignPhoneNumberInfoService],
})
export class AgentCampaignPhoneNumberInfoModule {}
