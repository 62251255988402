<ag-shr-modal-window
  #agencyCarrierFormModalRef
  [title]="'Agency Carrier'"
  [width]="'85%'"
  [height]="'auto'"
  [inProgress]="inProgress$ | async"
  [showSaveButton]="true"
  [onCloseModal]="handleClosePopup"
  (onSaveClick)="handleSaveAgencyCarrier($event)"
>
  <dx-form
    #agencyCarrierFormRef
    *ngIf="agencyCarrierFormModalRef?.popupComponent?.visible ?? false"
    [(formData)]="agencyCarrierFormData"
    [colCount]="12"
    labelMode="floating"
  >
    <dxi-item
      [dataField]="AgencyCarrierModelKeys.carrier"
      [colSpan]="6"
      editorType="dxSelectBox"
      [editorOptions]="{
        items: carriersLookup,
        displayExpr: CarrierModelKeys.carrierName,
        valueExpr: BaseModelKeys.dbId
      }"
    >
    </dxi-item>

    <dxi-item [dataField]="AgencyCarrierModelKeys.name" [colSpan]="6"></dxi-item>

    <dxi-item [dataField]="AgencyCarrierModelKeys.writingNumber" [colSpan]="6"></dxi-item>

    <dxi-item [dataField]="AgencyCarrierModelKeys.emailAddress" [colSpan]="6"></dxi-item>

    <dxi-item
      [dataField]="AgencyCarrierModelKeys.individualOrCorp"
      [colSpan]="6"
      [label]="{ text: 'Individual / Corp' }"
      editorType="dxSelectBox"
      [editorOptions]="{
        items: carrierOrganizationLookup,
        valueExpr: 'value',
        displayExpr: 'description',
        placeholder: ''
      }"
    >
    </dxi-item>

    <dxi-item
      [dataField]="AgencyCarrierModelKeys.lineOfBusiness"
      [colSpan]="6"
      [label]="{ text: 'Line of Business' }"
      editorType="dxSelectBox"
      [editorOptions]="{
        items: carrierLineOfBusinessLookup,
        valueExpr: 'value',
        displayExpr: 'description',
        placeholder: ''
      }"
    ></dxi-item>

    <dxi-item [dataField]="AgencyCarrierModelKeys.firstContracted" editorType="dxDateBox" [colSpan]="6"></dxi-item>

    <dxi-item
      [dataField]="AgencyCarrierModelKeys.isActive"
      [alignItemLabels]="true"
      [colSpan]="4"
      editorType="dxSwitch"
      [editorOptions]="{ switchedOffText: 'false', switchedOnText: 'true' }"
      [label]="{ text: 'Active' }"
    ></dxi-item>
  </dx-form>
</ag-shr-modal-window>
