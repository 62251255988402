import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxButtonModule,
  DxHtmlEditorModule,
  DxLoadIndicatorModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { WebSiteInputComponent } from './website-input.component';

@NgModule({
  imports: [
    CommonModule,
    DxHtmlEditorModule,
    DxToolbarModule,
    DxScrollViewModule,
    DxButtonModule,
    DxLoadIndicatorModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    DxValidatorModule,
  ],
  declarations: [WebSiteInputComponent],
  exports: [WebSiteInputComponent],
  providers: [],
})
export class WebSiteInputModule {}
