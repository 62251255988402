<div>
  <dx-list
    #phoneNumbersListRef
    [items]="agentPhoneNumbersDataSource$ | async"
    [selectionMode]="'single'"
    [showSelectionControls]="true"
    [keyExpr]="phoneNumberKeyExpression"
    (onInitialized)="onInitialized($event)"
    [(selectedItemKeys)]="selectedPhoneNumbersKeys"
    [disabled]="isReadonly"
    [visible]="!selectorHidden"
  >
    <div *dxTemplate="let phoneNumber of 'item'">
      <ng-container *ngIf="phoneNumber?.number; else useNewAddress">
        <div>{{ phoneNumber | phoneNumberMask }}</div>

        <dx-button
          class="clipboard-button"
          icon="copy"
          hint="Add as New Phone Number"
          stylingMode="text"
          (onClick)="addCopyingPhoneNumber($event, phoneNumber)"
        >
        </dx-button>
      </ng-container>

      <ng-template #useNewAddress>
        <div>Add new Phone Number</div>
      </ng-template>
    </div>
  </dx-list>
</div>

<ag-shr-phone-number-form
  class="mt-2"
  *var="selectedPhoneNumbersKeys | isNewPhoneNumberSelected as isNewPhoneNumberSelected"
  [phoneNumber]="formData$ | async"
  [pathPrefix]="pathPrefix"
  [validationGroup]="validationGroup"
  [isNumberReadonly]="isReadonly || !isNewPhoneNumberSelected"
  [isExtensionReadonly]="isReadonly || !isNewPhoneNumberSelected"
  [isTypeReadonly]="isReadonly || !isNewPhoneNumberSelected"
  [isWhatsappReadonly]="isReadonly"
  [isNumberFieldRequired]="isNumberFieldRequired"
  [screenLimits]="{ md: 850 }"
></ag-shr-phone-number-form>
