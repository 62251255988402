import { NgModule } from '@angular/core';
import { TShirtSeizesFormModule } from 'ag-common-svc/lib/components/t-shirt-seizes-form/t-shirt-seizes-form.module';

import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { SizesComponent } from './sizes.component';
import { ModalWindowModule } from 'ag-common-svc/lib/components/modal-window/modal-window.module';
import { ButtonWithIndicatorModule } from 'ag-common-svc/lib/components/button-with-indicator/button-with-indicator.module';
import { TShortSizeSelectBoxModule } from 'ag-common-svc/lib/components/t-short-size-select-box/t-short-size-select-box.module';
import { AgEditorToolbarModule } from 'ag-common-svc/lib/components/ag-editor-toolbar/ag-editor-toolbar.module';

@NgModule({
  imports: [
    SharedModule,
    ModalWindowModule,
    ButtonWithIndicatorModule,
    TShortSizeSelectBoxModule,
    AgEditorToolbarModule,
    TShirtSeizesFormModule,
  ],
  declarations: [SizesComponent],
  exports: [SizesComponent],
})
export class SizesModule {}
