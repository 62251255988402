import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AgentKeys, MediaSize, EntityApplications, Entity } from '@ag-common-lib/public-api';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import { DxFormComponent } from 'devextreme-angular';
import { ModalWindowComponent } from '../../modal-window/modal-window.component';
import { AssignedOwnerDetails, AssignedOwnerDetailsKeys } from '../config/ag-assign-owner.config';
import { AgAssignOwnerFormService } from './ag-assign-owner-form.service';
import { LoadOptions } from 'devextreme/data';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'ag-shr-assign-owner-modal',
  templateUrl: './ag-assign-owner-modal.component.html',
  styleUrls: ['./ag-assign-owner-modal.component.scss'],
  providers: [AgentElasticSearchService],
})
export class AgAssignOwnerModalComponent {
  @ViewChild('assignOwnerModalRef', { static: true }) assignOwnerModalComponent: ModalWindowComponent;
  @ViewChild('assignOwnerFormRef', { static: false }) assignOwnerFormComponent: DxFormComponent;
  @Output() updatedOwner = new EventEmitter<AssignedOwnerDetails>();

  @Input() set listLoadOptions(data: LoadOptions<any>) {
    this.agentsDataSource = this.agentElasticSearchService.getDataSource(data);
  }
  @Input() set assignOwnerList(data: DataSource) {
    this.agentsDataSource = data;
  }
  protected assignOwnerFormData: Partial<AssignedOwnerDetails>;

  protected inProgress$ = this.assignOwnerFormService.inProgress$;
  protected readonly AssignedOwnerDetailsKeys = AssignedOwnerDetailsKeys;
  protected readonly AgentKeys = AgentKeys;
  protected readonly MediaSize = MediaSize;
  agentsDataSource: DataSource;

  constructor(
    private agentElasticSearchService: AgentElasticSearchService,
    private assignOwnerFormService: AgAssignOwnerFormService,
  ) {}

  showModal = async (owner: AssignedOwnerDetails): Promise<void> => {
    this.assignOwnerFormData = await this.assignOwnerFormService.getOwnerFormData(owner);
    this.assignOwnerModalComponent?.showModal();
  };

  hideModal = (): void => {
    this.assignOwnerModalComponent.hideModal();
  };

  onAfterRevertChanges = (): void => {
    this.assignOwnerFormComponent.instance.repaint();
  };

  handleClosePopup = () => this.assignOwnerFormService.onCancelEdit.call(this, this.onAfterRevertChanges);

  handleAssignRegistrantOwnerClick = (): void => {
    const formData = this.assignOwnerFormData;
    const update = {
      [AssignedOwnerDetailsKeys.assignedTo]: formData?.assignedTo ?? '',
      [AssignedOwnerDetailsKeys.assignedToNote]: formData?.assignedToNote ?? '',
    };
    this.updatedOwner.emit(update);
  };
}
