import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, take } from 'rxjs';

export enum HtmlTemplates {
  notificationsPrompt = 'notifications-prompt',
}

@Injectable()
export class HtmlTemplateService {
  constructor(private http: HttpClient) {}

  getTemplateContent(template: HtmlTemplates): Promise<string> {
    const request = this.http.get(`assets/html/${template}.html`, { responseType: 'text' });

    return lastValueFrom(request.pipe(take(1)));
  }
}
