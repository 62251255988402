import { Pipe, PipeTransform } from '@angular/core';
import { FunctionsNames } from '@ag-common-lib/public-api';
import { CloudFunctionsService } from '../../lib/services/cloud-functions.service';

@Pipe({
  name: 'wasabiFile',
})
export class WasabiFile implements PipeTransform {
  constructor(private cloudFunctionsService: CloudFunctionsService) {}
  transform(path: string): string {
    if (!path) {
      return null;
    }

    console.log('path', path);

    let url = `${this.cloudFunctionsService.getFunctionUrl(FunctionsNames.getMedia)}/${path}`;

    return url;
  }
}
