<p class="editor__data-grid--empty" *ngIf="!isEditable && !carriers?.length; else dataGridTmp">
  {{ emptyMessage }}
</p>

<ng-template #dataGridTmp>
  <dx-data-grid
    #carriersGridRef
    class="editor editor__data-grid"
    [class.editor__data-grid--view]="!isEditable"
    [dataSource]="carriers"
    [showRowLines]="isEditable"
    [showBorders]="isEditable"
    [showColumnLines]="isEditable"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="isEditable"
    [rowAlternationEnabled]="false"
    [noDataText]="emptyMessage"
    (onInitNewRow)="onInitNewRow($event)"
    (onRowInserting)="onRowInserting($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event)"
    height="100%"
  >
    <dxo-toolbar [visible]="canCreate">
      <dxi-button name="add"></dxi-button>
    </dxo-toolbar>

    <dxo-editing
      mode="popup"
      [useIcons]="true"
      [allowUpdating]="canEdit"
      [allowDeleting]="canDelete"
      [allowAdding]="canCreate"
    >
      <dxo-popup title="Carrier" [showTitle]="true" [width]="'75%'" [height]="'auto'">
        <dxi-toolbar-item
          widget="dxButton"
          location="after"
          toolbar="bottom"
          *ngIf="!!carriersGridRef.instance"
          [options]="{
            text: 'CANCEL',
            onClick: onCancel,
            elementAttr: {
              class: 'modal-window__toolbar-button'
            }
          }"
        >
        </dxi-toolbar-item>

        <dxi-toolbar-item widget="dxButton" location="after" toolbar="bottom">
          <div *dxTemplate>
            <shr-button-with-indicator
              [isInProgress]="inProgress$ | async"
              (onClick)="carriersGridRef.instance.saveEditData()"
            ></shr-button-with-indicator>
          </div>
        </dxi-toolbar-item>
      </dxo-popup>

      <dxo-form [colCount]="12" [labelMode]="'floating'">
        <dxi-item
          [dataField]="AgentCarrierModelKeys.carrier"
          [colSpan]="6"
          editorType="dxSelectBox"
          [editorOptions]="{
            items: carriersLookup$ | async,
            displayExpr: CarrierModelKeys.carrierName,
            valueExpr: BaseModelKeys.dbId
          }"
        >
        </dxi-item>

        <dxi-item [dataField]="AgentCarrierModelKeys.name" [colSpan]="6"></dxi-item>

        <dxi-item [dataField]="AgentCarrierModelKeys.writingNumber" [colSpan]="6"></dxi-item>

        <dxi-item [dataField]="AgentCarrierModelKeys.emailAddress" [colSpan]="6"></dxi-item>

        <dxi-item [dataField]="AgentCarrierModelKeys.individualOrCorp" [colSpan]="6"></dxi-item>

        <dxi-item [dataField]="AgentCarrierModelKeys.lineOfBusiness" [colSpan]="6"></dxi-item>

        <dxi-item
          [dataField]="AgentCarrierModelKeys.isActive"
          [alignItemLabels]="true"
          [colSpan]="4"
          editorType="dxSwitch"
          [editorOptions]="{ switchedOffText: 'false', switchedOnText: 'true' }"
        ></dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>

    <dxi-column [dataField]="AgentCarrierModelKeys.carrier" caption="Carrier">
      <dxo-lookup
        [dataSource]="carriersLookup$ | async"
        [displayExpr]="CarrierModelKeys.carrierName"
        [valueExpr]="BaseModelKeys.dbId"
      ></dxo-lookup>

      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_TEXT"></dxi-validation-rule>
    </dxi-column>

    <dxi-column [dataField]="AgentCarrierModelKeys.name" caption="Name"></dxi-column>

    <dxi-column [dataField]="AgentCarrierModelKeys.writingNumber" caption="Writing Number"></dxi-column>

    <dxi-column [dataField]="AgentCarrierModelKeys.emailAddress" caption="Email Address">
      <dxi-validation-rule type="email" [message]="Messages.INVALID_EMAIL_FORMAT_TEXT"></dxi-validation-rule>
    </dxi-column>

    <dxi-column [dataField]="AgentCarrierModelKeys.individualOrCorp" caption="Individual / Corp">
      <dxo-lookup
        [dataSource]="carrierOrganizationLookup"
        [displayExpr]="'description'"
        [valueExpr]="'value'"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="AgentCarrierModelKeys.lineOfBusiness" caption="Line Of Business">
      <dxo-lookup
        [dataSource]="carrierLineOfBusinessLookup"
        [displayExpr]="'description'"
        [valueExpr]="'value'"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="AgentCarrierModelKeys.isActive" dataType="boolean" caption="Active"></dxi-column>

    <dxi-column type="buttons" [visible]="canEdit || canDelete">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</ng-template>
