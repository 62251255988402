import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { AgentCarriersComponent } from './agent-carriers.component';
import { CarriersGridComponent } from './carriers-grid/carriers-grid.component';
import { ButtonWithIndicatorModule } from '../../../button-with-indicator/button-with-indicator.module';
import { AgEditorToolbarModule } from '../../../ag-editor-toolbar/ag-editor-toolbar.module';

@NgModule({
  imports: [SharedModule, ModalWindowModule, ButtonWithIndicatorModule, AgEditorToolbarModule],
  declarations: [AgentCarriersComponent, CarriersGridComponent],
  exports: [AgentCarriersComponent],
})
export class AgentCarriersModule {}
