import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'ag-shr-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent {
  @HostBinding('class') className = 'image-preview';

  @Input() src: string;
  @Input() placeholder: string = 'Click or Drag a Image';

  @Input() isValid: boolean;
  @Output() isValidChange = new EventEmitter<boolean>();

  constructor() {}

  onImgError = (): void => {
    this.isValid = false;
    this.isValidChange.emit(false);
  };

  onImgLoaded = (): void => {
    this.isValid = true;
    this.isValidChange.emit(true);
  };
}
