import { Inject, Injectable } from '@angular/core';
import {
  AGENTS_COLLECTION_NAME,
  AgentDocumentCollections,
  Association,
  AssociationKeys,
} from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';
import { Timestamp } from 'firebase/firestore';
import { ToastrService } from 'ngx-toastr';
import { CommonFireStoreDao, QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';
import { FIREBASE_APP } from '../injections/firebase-app';
import { dateFromTimestamp, localDateTimeFromTimestamp } from '../utils/date-from-timestamp';

@Injectable({
  providedIn: 'root',
})
export class AgentAssociationsService {
  public readonly fsDao: CommonFireStoreDao<Association>;
  private readonly agentCollectionPath = AGENTS_COLLECTION_NAME;
  private readonly associationCollectionPath = AgentDocumentCollections.associations;

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp, private toastrService: ToastrService) {
    this.fsDao = new CommonFireStoreDao<Association>(fireBaseApp, AgentAssociationsService.fromFirestore, null);
  }

  getList(agentId: string) {
    const table = this.getCollectionPath(agentId);

    return this.fsDao.getList(table);
  }

  getEmergencyContactsList(agentId: string) {
    const table = this.getCollectionPath(agentId);
    const qp = [new QueryParam(AssociationKeys.isEmergencyContact, WhereFilterOperandKeys.equal, true)];

    return this.fsDao.getList(table, qp);
  }

  getAll(agentId: string): Promise<Association[]> {
    const table = this.getCollectionPath(agentId);

    return this.fsDao.getAll(table);
  }

  create(agentId: string, data: Association, silent = false) {
    const table = this.getCollectionPath(agentId);

    return this.fsDao
      .create(data, table)
      .then(response => {
        !silent && this.toastrService.success('Agent Association Successfully Created!');

        return response;
      })

      .catch(e => {
        console.log('e', e, agentId, data);
      });
  }

  update(agentId: string, documentId: any, updates: Partial<Association>, silent = false) {
    const table = this.getCollectionPath(agentId);

    return this.fsDao
      .updateFields(updates, documentId, table)
      .then(response => {
        !silent && this.toastrService.success('Agent Association Successfully Updated!');

        return response;
      })

      .catch(e => {
        console.log('e', e);
      });
  }

  delete(agentId: string, documentId: any) {
    const table = this.getCollectionPath(agentId);

    return this.fsDao.delete(documentId, table).then(response => {
      this.toastrService.success('Agent Association Removed!');
      return response;
    });
  }

  private getCollectionPath(agentId: string) {
    return [this.agentCollectionPath, agentId, this.associationCollectionPath].join('/');
  }

  static readonly fromFirestore = (data): Association => {
    return Object.assign({}, data, {
      dob: localDateTimeFromTimestamp(data?.dob as Timestamp),
    });
  };
}
