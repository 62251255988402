import { DocumentReference } from 'firebase/firestore';
import { TaskCategory, TaskSubcategory } from '../../lists/task.category.enum';
import { BaseModel } from '../base.model';

export enum TaskWorkflowKeys {
  title = 'title',
  category = 'category',
  categoryRef = 'categoryRef',
  _categoryRefId = 'categoryRef.id',
  subcategory = 'subcategory',
  subcategoryRef = 'subcategoryRef',
  _subcategoryRefId = 'subcategoryRef.id',
}
export class TaskWorkflow extends BaseModel {
  [TaskWorkflowKeys.title]: string;
  [TaskWorkflowKeys.category]: TaskCategory;
  [TaskWorkflowKeys.categoryRef]?: DocumentReference<TaskCategory>;
  [TaskWorkflowKeys.subcategory]: TaskSubcategory;
  [TaskWorkflowKeys.subcategoryRef]?: DocumentReference<TaskSubcategory>;
}
