import { NgModule } from '@angular/core';
import { ExcursionsInfoTableComponent } from './excursions-info-table.component';
import {
  DxiColumnModule,
  DxiGroupItemModule,
  DxiTotalItemModule,
  DxoSummaryModule,
} from 'devextreme-angular/ui/nested';
import { SharedModule } from '../../../shared/shared.module';
import { ModalWindowModule } from '../modal-window/modal-window.module';
import { DxRadioGroupModule, DxValidatorModule } from 'devextreme-angular';
import { ExcursionSourcePipe } from './excursions-source.pipe';
import { ExcursionPreferencesPipe } from './excursion-preferences.pipe';
import { AgAssignOwnerViewerModule } from '../../../lib/components';
import { ExcursionSeatsRemainPipe } from './excursion-seats-remain.pipe';
import { ExcursionConfigurationsPipe } from './excursion-configurations.pipe';

@NgModule({
    imports: [
        SharedModule,
        DxiGroupItemModule,
        DxoSummaryModule,
        DxiColumnModule,
        DxiTotalItemModule,
        ModalWindowModule,
        DxRadioGroupModule,
        AgAssignOwnerViewerModule,
        DxValidatorModule,
    ],
  declarations: [
    ExcursionsInfoTableComponent,
    ExcursionSourcePipe,
    ExcursionPreferencesPipe,
    ExcursionSeatsRemainPipe,
    ExcursionConfigurationsPipe,
  ],
  exports: [ExcursionsInfoTableComponent],
})
export class ExcursionsInfoTableModule {}
