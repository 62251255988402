import { LookupKeys } from '../crm/lookup.model';

export enum QualifiedAs {
  agtMoreThan75 = 'agtMoreThat75',
  agtMoreThat115 = 'agtMoreThat115',
  mgaMoreThat750 = 'mgaMoreThat750',
  adMoreThat1500 = 'adMoreThat1500',
  nonProduction = 'nonProduction',
}

export const QualifiedAsMap = new Map([
  [QualifiedAs.agtMoreThan75, 'Qualifying AGT > $75K'],
  [QualifiedAs.agtMoreThat115, 'Qualifying AGT > $115K'],
  [QualifiedAs.mgaMoreThat750, 'Qualifying MGA > $750K'],
  [QualifiedAs.adMoreThat1500, 'Qualifying AD > $1.5M'],
  [QualifiedAs.nonProduction, 'Non Production'],
]);

export const QUALIFIED_AS_LOOKUP = [
  QualifiedAs.agtMoreThan75,
  QualifiedAs.agtMoreThat115,
  QualifiedAs.mgaMoreThat750,
  QualifiedAs.adMoreThat1500,
  QualifiedAs.nonProduction,
].map(value => ({
  [LookupKeys.value]: value,
  [LookupKeys.description]: QualifiedAsMap.get(value),
}));
