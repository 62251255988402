import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StripePaymentElementComponent } from 'ngx-stripe';
import { StripeElementsOptions, StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { AGStripeService } from './ag-stripe.service';
import { elementsOptions, paymentElementOptions } from './ag-stripe-modal.models';
import { map } from 'rxjs';
import { StripePaymentDetails } from '@ag-common-lib/public-api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { UsdCurrencyPipe } from 'ag-common-svc/shared/pipes/usd-currency.pipe';

@UntilDestroy()
@Component({
  selector: 'ag-shr-stripe-modal',
  templateUrl: './ag-stripe-modal.component.html',
  styleUrls: ['./ag-stripe-modal.component.scss'],
  providers: [UsdCurrencyPipe, AGStripeService],
})
export class AGStripeModalComponent implements OnInit {
  @HostBinding('class') className = 'ag-stripe-modal';

  @ViewChild('modalWindowRef') modalWindowComponent: ModalWindowComponent;
  @ViewChild('payment') paymentElementComponent: StripePaymentElementComponent;

  @Input() onHandlePaymentStarted: () => Promise<boolean> = () => Promise.resolve(true);

  @Output() handleSuccessfulPayment = new EventEmitter<StripePaymentDetails>();

  protected stripe = this.agStripeService.stripe;
  protected elementsOptions: StripeElementsOptions;
  protected readonly paymentElementOptions = paymentElementOptions;

  protected actionTitle$ = this.agStripeService.amountToPay$.pipe(
    map(amountToPay => this.usdCurrencyPipe.transform(amountToPay)),
    map(amountToPay => `PAY ${amountToPay}`),
  );
  protected inProgress$ = this.agStripeService.inProgress$;
  protected isSecretLoading$ = this.agStripeService.isSecretLoading$;
  protected isCompleted = false;

  constructor(protected agStripeService: AGStripeService, private usdCurrencyPipe: UsdCurrencyPipe) {}

  ngOnInit(): void {
    this.agStripeService.showModal$.pipe(untilDestroyed(this)).subscribe(clientSecret => {
      this.elementsOptions = Object.assign({}, elementsOptions, { clientSecret });
      this.modalWindowComponent.showModal();
    });
  }

  handlePayment = async () => {
    const paymentAvailable = await this.onHandlePaymentStarted();

    if (paymentAvailable && this.isCompleted) {
      try {
        const paymentDetails = await this.agStripeService.handlePayment(this.paymentElementComponent.elements);

        this.handleSuccessfulPayment.emit(paymentDetails);

        this.modalWindowComponent.forceCloseModal();
      } catch (error) {}
    }
  };

  protected onChange = (e: StripePaymentElementChangeEvent) => {
    this.isCompleted = e.complete;
  };

  protected onCloseModal = this.agStripeService.closeModal;

  showModal = this.agStripeService.showModal;
}
