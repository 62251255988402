import { LookupBase } from '../models/utils/lookup-base.model';

export enum CampaignsManagementTasksStatus {
  newTasks = 'new',
  inProgress = 'in progress',
  contentIssues = 'content issues',
  technicalIssues = 'technical issues',
  done = 'done', // display last week done tasks
}

export const CAMPAIGNS_MANAGEMENT_TASKS_STATUS_LOOKUP: LookupBase[] = [
  { value: CampaignsManagementTasksStatus.newTasks, description: 'New Tasks' },
  { value: CampaignsManagementTasksStatus.inProgress, description: 'In Progress' },
  { value: CampaignsManagementTasksStatus.contentIssues, description: 'Content Issues' },
  { value: CampaignsManagementTasksStatus.technicalIssues, description: 'Technical Issues' },
  { value: CampaignsManagementTasksStatus.done, description: 'Done' },
];
