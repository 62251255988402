import { BaseModel } from '../base.model';
import { CARRIER_LINE_OF_BUSINESS_TYPE, CARRIER_ORGANIZATION_TYPE } from '../../lists/carrier.enum';

export enum AgencyCarrierModelKeys {
  id = 'id',
  carrier = 'carrier',
  name = 'name',
  emailAddress = 'email_address',
  writingNumber = 'writing_number',
  individualOrCorp = 'individualOrCorp',
  lineOfBusiness = 'lineOfBusiness',
  isActive = 'is_active',
  firstContracted = 'first_contracted',
}

export class AgencyCarrier extends BaseModel {
  [AgencyCarrierModelKeys.id]?: string;
  [AgencyCarrierModelKeys.carrier]?: string;
  [AgencyCarrierModelKeys.name]?: string;
  [AgencyCarrierModelKeys.emailAddress]?: string;
  [AgencyCarrierModelKeys.writingNumber]?: string;
  [AgencyCarrierModelKeys.individualOrCorp]?: CARRIER_ORGANIZATION_TYPE;
  [AgencyCarrierModelKeys.lineOfBusiness]?: CARRIER_LINE_OF_BUSINESS_TYPE;
  [AgencyCarrierModelKeys.isActive]?: boolean;
  [AgencyCarrierModelKeys.firstContracted]?: Date;
}
