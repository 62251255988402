import { ConferenceRegistrationCommonTaskStatus } from '../../lists/conference-registrations-tasks-status.enum';

export enum ConferenceRegistrationCategoryName {
  registrantHotelReservationCategory = 'registrantHotelReservationCategory',
  registrantFlightInformationCategory = 'registrantFlightInformationCategory',
  registrantExcursionCategory = 'registrantExcursionCategory',
  reservationCategory = 'reservationCategory',
}

export interface ConferenceRegistrationCommonTask {
  status?: ConferenceRegistrationCommonTaskStatus;
  assignedTo?: string;
  assignedPersonNote?: string;
  isStepDone?: boolean;
}

export interface ConferenceRegistrationRSVPTask extends ConferenceRegistrationCommonTask {}

export interface ConferenceRegistrationTask {
  // [ConferenceRegistrationStepName.rsvpStep]?: ConferenceRegistrationRSVPTask;
  // [ConferenceRegistrationStepName.registrantHotelReservationStep]?: ConferenceRegistrationRSVPTask;
  // [ConferenceRegistrationStepName.registrantFlightInformationStep]?: ConferenceRegistrationRSVPTask;
  // [ConferenceRegistrationStepName.registrantExcursionsStep]?: ConferenceRegistrationRSVPTask;

  [ConferenceRegistrationCategoryName.registrantExcursionCategory]?: ConferenceRegistrationRSVPTask;
  [ConferenceRegistrationCategoryName.reservationCategory]?: ConferenceRegistrationRSVPTask;
  [ConferenceRegistrationCategoryName.registrantHotelReservationCategory]?: ConferenceRegistrationRSVPTask;
  [ConferenceRegistrationCategoryName.registrantFlightInformationCategory]?: ConferenceRegistrationRSVPTask;
}
