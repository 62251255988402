<ag-shr-modal-window
  #attendeeTransactionDetailsModalRef
  [title]="transactionType === TransactionType.refund ? 'Refund Details' : 'Transaction Details'"
  [width]="900"
  [height]="'auto'"
  [useScrollView]="false"
  [showSaveButton]="false"
>
  <div
    *ngIf="attendeeTransactionDetailsModalRef?.popupComponent?.visible ?? false"
    class="attendee-refund-payment-modal__container"
  >
    <dx-data-grid class="attendee-transactions-details-table" [dataSource]="transactionDetails">
      <dxi-column
        [dataField]="AgStripeSubtotalKeys.subtotalType"
        caption="Transaction Point"
        [width]="150"
        [visible]="transactionType !== TransactionType.refund"
      >
        <dxo-lookup
          [dataSource]="SUBTOTAL_TYPE_LOOKUP"
          [displayExpr]="LookupKeys.description"
          [valueExpr]="LookupKeys.value"
        >
        </dxo-lookup>
      </dxi-column>

      <dxi-column [dataField]="AgStripeSubtotalKeys.description" caption="Description"></dxi-column>

      <dxi-column
        [dataField]="AgStripeSubtotalKeys.amount"
        caption="Amount"
        [alignment]="'right'"
        [dataType]="'number'"
        [format]="DX_USD_CURRENCY_FORMAT"
        [width]="100"
      ></dxi-column>

      <dxi-column
        [dataField]="AgStripeSubtotalKeys.refundStatus"
        caption="Refund Status"
        [visible]="transactionType === TransactionType.refund"
        cssClass="text-capitalize"
      ></dxi-column>

      <dxi-column
        [dataField]="AgStripeSubtotalKeys.refundReason"
        caption="Refund Reason"
        [visible]="transactionType === TransactionType.refund"
      >
        <dxo-lookup
          [dataSource]="reasonReasonLookup"
          [displayExpr]="LookupKeys.description"
          [valueExpr]="LookupKeys.value"
        >
        </dxo-lookup>
      </dxi-column>

      <dxi-column
        [dataField]="AgStripeSubtotalKeys.refundFailureReason"
        caption="Refund Failure Reason"
        [visible]="transactionType === TransactionType.refund"
      >
        <dxo-lookup
          [dataSource]="refundFailureReasonLookup"
          [displayExpr]="LookupKeys.description"
          [valueExpr]="LookupKeys.value"
        >
        </dxo-lookup>
      </dxi-column>

      <dxi-column
        [dataField]="AgStripeSubtotalKeys.refundProcessedDate"
        caption="Refund Processed Date"
        dataType="datetime"
        [width]="150"
        [visible]="transactionType === TransactionType.refund"
      ></dxi-column>
    </dx-data-grid>
  </div>
</ag-shr-modal-window>
