<ag-shr-modal-window
  #deleteAgentModalRef
  [title]="'Delete Agent'"
  [width]="'400px'"
  [height]="'auto'"
  [saveButtonDisabled]="!formData?.deleteAgentRecord && !formData?.deleteAuthLogin"
  [inProgress]="inProgress$ | async"
  [actionTitle]="'CONFIRM'"
  (onSaveClick)="handleSave($event)"
>
  <dx-form [formData]="formData" labelMode="floating">
    <dxi-item
      dataField="deleteAuthLogin"
      editorType="dxSwitch"
      cssClass="delete-agent-modal__switch"
      [label]="{ text: 'Delete Auth Login?' }"
    ></dxi-item>

    <dxi-item
      dataField="deleteAgentRecord"
      editorType="dxSwitch"
      cssClass="delete-agent-modal__switch"
      [label]="{ text: 'Delete Agent Account?' }"
    ></dxi-item>
  </dx-form>
</ag-shr-modal-window>
