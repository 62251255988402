import { LookupKeys } from '../models/crm/lookup.model';
import { YES_NO_TYPE } from './yes-no.enum';

export const YES_NO_LOOKUP = [
  { [LookupKeys.value]: YES_NO_TYPE.Yes, [LookupKeys.description]: YES_NO_TYPE.Yes },
  { [LookupKeys.value]: YES_NO_TYPE.No, [LookupKeys.description]: YES_NO_TYPE.No },
];

export const TRUE_FALSE_LOOKUP = [
  { [LookupKeys.value]: true, [LookupKeys.description]: YES_NO_TYPE.Yes },
  { [LookupKeys.value]: false, [LookupKeys.description]: YES_NO_TYPE.No },
];
