<div class="agent-campaign-wizard__content">
  <ng-content></ng-content>
  <dx-form
    #webSiteFormRef
    [formData]="webSiteFormData"
    labelMode="floating"
    [disabled]="!canEdit"
  >

    <dxi-item>
      <div
        *dxTemplate
        class="website-input"
      >
        <div class="website-input__content">
          <label
            for="htmlEditor"
            class="website-input__label dx-widget"
          >
            Website Url
          </label>

          <div class="website-input__editor-container" *var="(webSiteLocked$ | async) && !hasStepIssues && !canEdit as isReadOnlyMode">
            <ng-container *ngIf="webSiteFormData && webSiteFormData['domainOption'] !== '__custom__'">
              <dx-text-box
                [(value)]="webSiteFormData && webSiteFormData.subdomain"
                class="website-input__text-box website-input__text-box--subdomain"
                placeholder="my-subdomain"
                valueChangeEvent="input"
                [inputAttr]="{ size: webSiteFormData?.subdomain?.length ? webSiteFormData?.subdomain?.length : 13 }"
                [tabIndex]="1"
                [readOnly]="(webSiteLocked$ | async) && !hasStepIssues"
              >
                <dx-validator [validationGroup]="webSiteFormValidationGroup">
                  <dxi-validation-rule
                    type="custom"
                    [validationCallback]="checkSubdomain"
                    [message]="Messages.REQUIRED_TEXT"
                  >
                  </dxi-validation-rule>

                  <dxi-validation-rule
                    type="pattern"
                    [pattern]="subdomainPattern"
                    [message]="Messages.INVALID_SUBDOMAIN"
                  ></dxi-validation-rule>

                  <dxi-validation-rule
                    *ngIf="webSiteFormData?.['domainOption']"
                    type="async"
                    [message]="Messages.ALREADY_REGISTERED_CAMPAIGN_WEBSITE_TEXT"
                    [validationCallback]="asyncUniqWebSiteValidation"
                  ></dxi-validation-rule>

                </dx-validator>
              </dx-text-box>

              <span>.</span>
            </ng-container>

            <dx-select-box
              [(value)]="webSiteFormData && webSiteFormData['domainOption']"
              [items]="domains"
              valueExpr="value"
              displayExpr="description"
              placeholder="domain.com"
              class="website-input__select-box"
              [ngClass]="{'website-input__select-box--custom': webSiteFormData && webSiteFormData['domainOption'] === '__custom__'}"
              [tabIndex]="2"
              [dropDownOptions]="{ width: 320 }"
              [readOnly]="(webSiteLocked$ | async) && !hasStepIssues"
            >
              <dx-validator [validationGroup]="webSiteFormValidationGroup">
                <dxi-validation-rule
                  type="custom"
                  [validationCallback]="checkDomain"
                  [message]="Messages.REQUIRED_TEXT"
                >
                </dxi-validation-rule>

              </dx-validator>
            </dx-select-box>

            <ng-container *ngIf="webSiteFormData && webSiteFormData['domainOption'] === '__custom__'">
              <span>:</span>

              <dx-text-box
                [(value)]="webSiteFormData && webSiteFormData.subdomain"
                class="website-input__text-box website-input__text-box--url"
                placeholder="www.my-website.com"
                valueChangeEvent="input"
                [inputAttr]="{ size: webSiteFormData?.subdomain?.length ? webSiteFormData?.subdomain?.length : 13 }"
                [tabIndex]="3"
                [readOnly]="(webSiteLocked$ | async) && !hasStepIssues"
              >
                <dx-validator [validationGroup]="webSiteFormValidationGroup">
                  <dxi-validation-rule
                    type="custom"
                    [validationCallback]="checkSubdomain"
                    [message]="Messages.REQUIRED_TEXT"
                  >
                  </dxi-validation-rule>

                  <dxi-validation-rule
                    type="custom"
                    [validationCallback]="validateUrlWithOrWithoutProtocol"
                    [message]="Messages.INVALID_URL_FORMAT_TEXT"
                  >
                  </dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </ng-container>

          </div>
        </div>
        <div class="dx-field-item-help-text">
          Once selected You'll not able to edit it.
        </div>
        <div
          *ngIf="webSiteFormData?.['domainOption'] === '__custom__'"
          class="dx-field-item-help-text"
        >
          An Administrator will be contacting you directly to provide the domain configuration settings.
        </div>
      </div>

    </dxi-item>
  </dx-form>

</div>

<div class="agent-campaign-wizard__controls-container" *ngIf="canEdit">
  <dx-button
    class="modal-window__toolbar-button"
    text="CANCEL CHANGES"
    [disabled]="!(hasFormChanges$ | async)"
    (onClick)="resetChanges()"
  ></dx-button>

  <shr-button-with-indicator
    [title]="hasStepIssues ? 'RESOLVE ISSUE' :'NEXT'"
    [disabled]="hasStepIssues && !(hasFormChanges$ | async)"
    [cssClass]="'modal-window__toolbar-button'"
    [isInProgress]="inProgress$ | async"
    (onClick)="nextStep()"
  ></shr-button-with-indicator>
</div>
