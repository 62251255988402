export enum RESIDENCY_TYPES {
  Resident = 'Resident',
  Non_Resident = 'Non-Resident',
}

export const ResidencyTypeSDescriptionMap: Map<RESIDENCY_TYPES, string> = new Map([
  [RESIDENCY_TYPES.Resident, 'Resident'],
  [RESIDENCY_TYPES.Non_Resident, 'Non-Resident'],
]);

export const RESIDENCY_TYPES_LOOKUP = [
  {
    value: RESIDENCY_TYPES.Resident,
    description: ResidencyTypeSDescriptionMap.get(RESIDENCY_TYPES.Resident),
  },
  {
    value: RESIDENCY_TYPES.Non_Resident,
    description: ResidencyTypeSDescriptionMap.get(RESIDENCY_TYPES.Non_Resident),
  },
];
