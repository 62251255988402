export enum Lookups {
  States = 'states',
  EmailTypes = 'email-types',
  Genders = 'genders',
  Suffixes = 'suffixes',
  Prefixes = 'prefixes',
  TaskCategory = 'task-category',
  TaskSubcategory = 'task-subcategory',
  AssociationType = 'association-type',
  TShirtSize = 't-shirt-sizes',
  DietaryConsiderationType = 'dietary-consideration-types',
  SupportTypes = 'support-types',
  AgencyStatuses = 'agency-statuses',
  AgencyOrganizationAffiliations = 'agency-organization-affiliations',
  AgencyPhoneTypes = 'agency-phone-types',
  AgencyEmailTypes = 'agency-email-types',
  AgencyAddressTypes = 'agency-address-types',
  SocialTypes = 'social-types',
  SocialMedia = 'social-media',
  WebsiteTypes = 'website-types',
  AgencySocialTypes = 'agency-social-types',
  AgencySocialMedia = 'agency-social-media',
  AgencyWebsiteTypes = 'agency-website-types',

  // Lookups from data
  Carriers = 'carriers',
  MGA = 'mga',
}

export const LookupsNamesMap = new Map([
  [Lookups.DietaryConsiderationType, 'Dietary Consideration Type'],
  [Lookups.States, 'States'],
  [Lookups.EmailTypes, 'Email Types'],
  [Lookups.Suffixes, 'Suffixes'],
  [Lookups.Prefixes, 'Prefixes'],
  [Lookups.Genders, 'Genders'],
  [Lookups.TaskCategory, 'Task Category'],
  [Lookups.TaskSubcategory, 'Task Subcategory'],
  [Lookups.TShirtSize, 'T-Shirt Size'],
  [Lookups.AssociationType, 'Relationship Type'],
  [Lookups.SupportTypes, 'Support Types'],
  [Lookups.AgencyStatuses, 'Agency Statuses'],
  [Lookups.AgencyOrganizationAffiliations, 'Agency Organization Affiliations'],
  [Lookups.AgencyPhoneTypes, 'Agency Phone Types'],
  [Lookups.AgencyEmailTypes, 'Agency Email Types'],
  [Lookups.AgencyAddressTypes, 'Agency Address Types'],
  [Lookups.SocialTypes, 'Social Types'],
  [Lookups.SocialMedia, 'Social Media'],
  [Lookups.WebsiteTypes, 'Website Types'],
  [Lookups.AgencySocialTypes, 'Agency Social Types'],
  [Lookups.AgencySocialMedia, 'Agency Social Media'],
  [Lookups.AgencyWebsiteTypes, 'Agency Website Types'],
]);

export type LookupId<T extends keyof typeof Lookups> = string & { __lookup?: T };
