import { Directive, ElementRef, EventEmitter, Output, Renderer2, Input } from '@angular/core';
import { IMAGE_PLACEHOLDER_PATH } from '@ag-common-lib/public-api';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[imageLoadingState]',
})
export class ImageLoadingStateDirective {
  @Output() imageLoadingState: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isImageLoadingIssuesChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private img: HTMLImageElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
    this.img = this.el.nativeElement as HTMLImageElement;
    this.initListeners();
  }

  private usePlaceholder;
  @Input()
  set src(data) {
    const newSrc = data.src;
    this.usePlaceholder = data.usePlaceholder;

    if (newSrc || this.usePlaceholder) {
      this.img.src = newSrc ?? IMAGE_PLACEHOLDER_PATH;
      this.imageLoadingState.emit(true); // Emit 'true' to indicate image loading start
      this.initListeners(); // Reinitialize event listeners for the new image source
    }
  }

  private initListeners() {
    this.renderer.listen(this.img, 'load', () => {
      this.imageLoadingState.emit(false); // Image loading finished
      this.isImageLoadingIssuesChange.emit(false);

      if (!this.usePlaceholder) {
        this.img.classList.remove('image-with-loader__image--error');
      }
    });

    this.renderer.listen(this.img, 'error', () => {
      this.imageLoadingState.emit(false); // Image loading failed
      this.isImageLoadingIssuesChange.emit(true);

      if (!this.usePlaceholder) {
        this.img.classList.add('image-with-loader__image--error');
        return;
      }

      this.img.src = IMAGE_PLACEHOLDER_PATH;
    });
  }
}
