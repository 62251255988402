import { LookupBase } from '../models/utils/lookup-base.model';

export enum CampaignsManagementIssuesType {
  technical = 'technical',
  content = 'content',
}

export const CAMPAIGNS_MANAGEMENT_ISSUES_TYPE_LOOKUP: LookupBase[] = [
  { value: CampaignsManagementIssuesType.technical, description: 'Technical Issue' },
  { value: CampaignsManagementIssuesType.content, description: 'Content Issue' },
];
