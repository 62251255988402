import { EmailRecipient } from '../utils/email-address.model';
import { EmailTemplates } from './mailer.model';

export enum NotifyEmailKeys {
  imagePath = 'imagePath',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  ccEmail = 'ccEmail',
  bccEmail = 'bccEmail',
  summaryEmailTemplate = 'summaryEmailTemplate',
}

export interface NotifyEmail {
  [NotifyEmailKeys.imagePath]?: string;
  [NotifyEmailKeys.firstName]?: string;
  [NotifyEmailKeys.lastName]?: string;
  [NotifyEmailKeys.email]?: string;
  [NotifyEmailKeys.ccEmail]?: Array<string | EmailRecipient>;
  [NotifyEmailKeys.bccEmail]?: Array<string | EmailRecipient>;
  [NotifyEmailKeys.summaryEmailTemplate]?: EmailTemplates;
}
