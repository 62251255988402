import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AutocompleteService } from './address-autocomplete.model';
import { GoogleMapsService } from 'ag-common-svc/lib/services';

@Injectable()
export class AddressAutocompleteService {
  private service: AutocompleteService = AutocompleteService.google;
  private readonly _inProgress$ = new BehaviorSubject<boolean>(false);
  inProgress$ = this._inProgress$.asObservable();

  constructor(private googleMapsService: GoogleMapsService) {}

  setAutocompleteService = (service: AutocompleteService) => {
    this.service = service;
  };

  getSuggestions = async (searchValue: string): Promise<google.maps.places.AutocompletePrediction[]> => {
    if (!searchValue) {
      return Promise.resolve([]);
    }

    this._inProgress$.next(true);
    switch (this.service) {
      case AutocompleteService.google:
        const googleSuggestions = await this.googleMapsService.getGoogleSuggestions(searchValue);
        this._inProgress$.next(false);
        return googleSuggestions;

      default:
        this._inProgress$.next(false);
        return Promise.resolve([]);
    }
  };

  getDetails = async (placeId: string) => {
    this._inProgress$.next(true);
    switch (this.service) {
      case AutocompleteService.google:
        const details = await this.googleMapsService.getDetails(placeId);
        this._inProgress$.next(false);
        return details;

      default:
        this._inProgress$.next(false);
        return null;
    }
  };
}
