export enum SOCIAL_MEDIA_LOOKUP_VALUE {
  FB = 'FB',
  IG = 'IG',
  LI = 'LI',
  TW = 'TW',
  YT = 'YT',
  AC = 'AC',
}

export const SocialMediaMapping = new Map([
  [SOCIAL_MEDIA_LOOKUP_VALUE.FB, 'Facebook'],
  [SOCIAL_MEDIA_LOOKUP_VALUE.IG, 'Instagram'],
  [SOCIAL_MEDIA_LOOKUP_VALUE.LI, 'Linked In'],
  [SOCIAL_MEDIA_LOOKUP_VALUE.TW, 'Twitter'],
  [SOCIAL_MEDIA_LOOKUP_VALUE.YT, 'YouTube'],
]);

export const SOCIAL_MEDIA_ICON_MAP = {
  FB: 'agi-facebook-f',
  IG: 'agi-instagram',
  LI: 'agi-linkedin-in',
  TW: 'agi-twitter',
  AC: 'assets/ag-logo-sm-blue.svg',
};
