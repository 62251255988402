import { Component, Input } from '@angular/core';
import {
  AgStripeSubtotal,
  AgStripeSubtotalKeys,
  DX_USD_CURRENCY_FORMAT,
  LookupKeys,
  SUBTOTAL_TYPE_LOOKUP,
  SubtotalType,
} from '@ag-common-lib/public-api';
import { SelectedExcursionOptions } from '../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/registration/excursion.model';

@Component({
  selector: 'ag-shr-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss'],
})
export class TransactionDetailsComponent {
  @Input() excursionsEnable = true;
  @Input() totalCharge: number;
  @Input()
  set transactionDetails(details: AgStripeSubtotal<SubtotalType>[]) {
    const transactionDetails = [];
    details.forEach(item => {
      if (item.subtotalType === SubtotalType.ExcursionBudget) {
        this.excursionsBudget = item.amount;
        return;
      }
      transactionDetails.push(item);
    });

    this._transactionDetails = transactionDetails;
  }
  get transactionDetails(): AgStripeSubtotal<SubtotalType>[] {
    return this._transactionDetails;
  }

  excursionsBudget: number;

  private _transactionDetails: AgStripeSubtotal<SubtotalType>[] = [];
  protected readonly AgStripeSubtotalKeys = AgStripeSubtotalKeys;
  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;
  protected readonly SUBTOTAL_TYPE_LOOKUP = SUBTOTAL_TYPE_LOOKUP;
  protected readonly LookupKeys = LookupKeys;

  protected calculateCustomSummary = options => {
    switch (options.name) {
      case 'total':
        if (options.summaryProcess === 'start') {
          options.totalValue = 0;
        }
        if (options.summaryProcess === 'calculate') {
          const excursionCost = options.value?.[AgStripeSubtotalKeys.amount] ?? 0;
          options.totalValue += excursionCost;
        }
        break;
      case 'discount':
        if (options.summaryProcess === 'start') {
          options.totalValue = Math.abs(this.excursionsBudget);
        }
        break;
      case 'totalCharge':
        if (options.summaryProcess === 'start') {
          options.totalValue = this.totalCharge > 0 ? this.totalCharge : 0;
        }
        break;
      default:
        break;
    }
  };
}
