import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import DataSource, { Options as DataSourceOptions } from 'devextreme/data/data_source';
import Store from 'devextreme/data/abstract_store';
import DevExpress from 'devextreme';

@Component({
  selector: 'ag-shr-tabs',
  templateUrl: './ag-tabs.component.html',
  styleUrls: ['./ag-tabs.component.scss'],
})
export class AgTabsComponent {
  @Input() cssClass?: string;
  @Input() width?: string = '100%';
  @Input() dataSource?: DataSource | DataSourceOptions | string | Array<string | any> = [];
  @Input() disabled?: boolean = false;
  @Input() selectedIndex?: number = 0;

  @Output() selectedIndexChange: EventEmitter<any> = new EventEmitter();
  @Output() onItemClick: EventEmitter<any> = new EventEmitter();

  constructor() {}
}
