import { NgModule } from '@angular/core';
import { StatesLicensedInComponent } from './states-licensed-in.component';
import { StatesLicensedInGridComponent } from './grid/states-licensed-in-grid.component';
import { StatesLicensedInService } from './states-licensed-in.service';
import { StateSelectBoxModule } from '../../../../../../src/lib/components/state-select-box/state-select-box.module';
import { AgEditorToolbarModule } from '../../../../../../src/lib/components/ag-editor-toolbar/ag-editor-toolbar.module';
import { ModalWindowModule } from '../../../../../../src/lib/components/modal-window/modal-window.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { ButtonWithIndicatorModule } from '../../../button-with-indicator/button-with-indicator.module';

@NgModule({
  imports: [SharedModule, ModalWindowModule, ButtonWithIndicatorModule, StateSelectBoxModule, AgEditorToolbarModule],
  declarations: [StatesLicensedInComponent, StatesLicensedInGridComponent],
  exports: [StatesLicensedInComponent, StatesLicensedInGridComponent],
  providers: [StatesLicensedInService],
})
export class StatesLicensedInModule {}
