import { Component, Input, ViewChild } from '@angular/core';
import { AttendeeTransactionModalService } from './attendee-transaction-modal.service';
import { AGStripeModalComponent } from 'ag-common-svc/lib/components/ag-stripe-modal/ag-stripe-modal.component';
import {
  StripeTransaction,
  StripeTransactionKeys,
  AgStripeSubtotalKeys,
  SUBTOTAL_TYPE_LOOKUP,
  LookupKeys,
  Messages,
  DX_USD_CURRENCY_FORMAT,
  TransactionType,
  stripeStatusesToTransactionStatusesMap,
} from '@ag-common-lib/public-api';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';
import { DxFormComponent } from 'devextreme-angular';
import { InitNewRowEvent } from 'devextreme/ui/data_grid';
import { StripeModalPayload, StripeModalUserActionType } from '../ag-stripe-modal/ag-stripe-modal.models';
import { PaymentMethodCreateParams } from '@stripe/stripe-js';

@Component({
  selector: 'ag-crm-attendee-transaction-modal',
  templateUrl: './attendee-transaction-modal.component.html',
  styleUrls: ['./attendee-transaction-modal.component.scss'],
})
export class AttendeeTransactionModalComponent {
  @ViewChild('stripeModalRef', { static: false }) agStripeModalComponent: AGStripeModalComponent;
  @ViewChild('attendeeTransactionModalRef', { static: true }) transactionModalComponent: ModalWindowComponent;
  @ViewChild('attendeeTransactionFormRef', { static: false }) transactionFormComponent: DxFormComponent;

  @Input() billingDetails: PaymentMethodCreateParams.BillingDetails;
  @Input() handleSaveTransaction: (transaction: Partial<StripeTransaction>) => Promise<void>;

  protected formData: StripeTransaction;
  protected readonly inProgress$ = this.attendeeTransactionModalService.inProgress$;
  protected readonly validationGroup = 'manualPaymentValidationGroup';
  protected readonly StripeTransactionKeys = StripeTransactionKeys;
  protected readonly AgStripeSubtotalKeys = AgStripeSubtotalKeys;
  protected readonly SUBTOTAL_TYPE_LOOKUP = SUBTOTAL_TYPE_LOOKUP;
  protected readonly LookupKeys = LookupKeys;
  protected readonly Messages = Messages;
  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;
  protected isNoDetails: boolean = false;
  protected actionTitle = 'SAVE';

  constructor(private attendeeTransactionModalService: AttendeeTransactionModalService) {}

  showModal = async (data?: Partial<StripeTransaction>) => {
    this.formData = this.attendeeTransactionModalService.getFormData(data);
    this.isNoDetails = false;
    this.transactionModalComponent?.showModal();
  };

  protected onSaveTransaction = async e => {
    const isValid = await validateDxGroups(this.validationGroup);
    const isNoDetails = !this.formData?.[StripeTransactionKeys.details]?.length;
    this.isNoDetails = isNoDetails;

    if (isNoDetails || !isValid) {
      return;
    }

    try {
      if (this.formData?.[StripeTransactionKeys.type] !== TransactionType.manual) {
        await this.handleSaveTransaction(this.formData);

        e?.component?.instance?.hide();

        return;
      }

      const config: StripeModalPayload = {
        amountToPay: this.formData?.[StripeTransactionKeys.amount],
        billingDetails: this.billingDetails,
      };

      const outcome = await this.agStripeModalComponent.showModal(config);

      if (outcome.type === StripeModalUserActionType.cancel) {
        return;
      }

      const paymentDetails = outcome.payload;
      const status = stripeStatusesToTransactionStatusesMap.get(paymentDetails.status);

      await this.handleSaveTransaction(
        Object.assign({}, this.formData, {
          [StripeTransactionKeys.stripeId]: paymentDetails.id,
          [StripeTransactionKeys.status]: status,
        }),
      );

      e?.component?.instance?.hide();
    } catch (error) {
      debugger;
    }
  };

  protected handleClosePopup = () => this.attendeeTransactionModalService.onCancelEdit;

  protected onInitNewTransactionDetailRow = (e: InitNewRowEvent) => {
    delete e.data.__KEY__;
  };

  protected onDetailsChanged = event => {
    this.isNoDetails = !event?.length;
    this.formData[StripeTransactionKeys.details] = event;
  };
}
