import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { StateSelectBoxModule } from '../state-select-box/state-select-box.module';
import { PhoneNumberFormComponent } from './phone-number-form.component';

@NgModule({
  declarations: [PhoneNumberFormComponent],
  imports: [CommonModule, SharedModule, StateSelectBoxModule],
  exports: [PhoneNumberFormComponent],
})
export class PhoneNumberFormModule {}
