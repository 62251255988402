<ag-shr-agent-campaign-summary-list-item [title]="'Social Media'">
  <ng-container agCampaignsActions>
    <ng-content select="[agCampaignsControls]"></ng-content>
  </ng-container>

  <div *ngFor="let item of list">

    <ag-shr-agent-campaign-summary-list-data
      [title]="item?.title"
      [hasCurrentData]="!!item?.currentStepData"
      [hasIncomingData]="!!item?.incomingStepData"
    >
      <ng-template
        #contactInfoAddressTmp
        let-data="data"
      >
        {{data}}
      </ng-template>

      <ng-container
        currentDataTmp
        *ngTemplateOutlet="contactInfoAddressTmp; context: { data: item?.currentStepData }"
      >
      </ng-container>

      <ng-container
        incomingDataTmp
        *ngTemplateOutlet="contactInfoAddressTmp; context: { data: item?.incomingStepData}"
      >
      </ng-container>

      <ng-container
        controls
        *var="item?.incomingStepData as data"
      >
        <ag-shr-clipboard-button [clipboardValue]="data"></ag-shr-clipboard-button>
      </ng-container>

    </ag-shr-agent-campaign-summary-list-data>
  </div>

</ag-shr-agent-campaign-summary-list-item>
