export enum ExcursionStatisticKeys {
  excursionId = 'excursionId',
  seatsLeft = 'seatsLeft',
  excursionName = 'excursionName',
  capacity = 'capacity',
  date = 'date',
}

export class ExcursionStatistic {
  [ExcursionStatisticKeys.excursionId]: string;
  [ExcursionStatisticKeys.seatsLeft]: number;
  [ExcursionStatisticKeys.excursionName]: string;
  [ExcursionStatisticKeys.capacity]: number;
  [ExcursionStatisticKeys.date]?: string;
}
