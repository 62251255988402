import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { DxValidatorComponent } from 'devextreme-angular';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { BaseModelKeys, ConferenceRegistrationLog, Constants, Messages } from 'ag-common-lib/public-api';
import { SupportModalFormaData } from './get-support-modal.model';
import { ToastrService } from 'ngx-toastr';
import { matcher } from '../../utils/phone-number-data.utils';

@Component({
  selector: 'ag-shr-get-support-modal',
  templateUrl: './get-support-modal.component.html',
  styleUrls: ['./get-support-modal.component.scss'],
})
export class GetSupportModalComponent {
  @HostBinding('class') className = 'get-support-modal';
  @ViewChild('getSupportModalRef', { static: true }) getSupportModalComponent: ModalWindowComponent;
  @ViewChild('validatorRef', { static: false }) validatorComponent: DxValidatorComponent;

  @Input() onGetSupport: (description: string) => Promise<void>;
  @Input() supportTickets: ConferenceRegistrationLog[] = [];
  @Input() supportEmail: string = 'events@alliancegrouplife.com';
  @Input() supportPhone: string = '8153389668';

  public inProgress = false;
  public formData: SupportModalFormaData = { description: '' };

  protected readonly Messages = Messages;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly BaseModelKeys = BaseModelKeys;

  constructor(private toster: ToastrService) {}

  calculatePhoneNumberDisplayValue = phoneNumber => matcher(phoneNumber);

  public showPopup = async (): Promise<void> => {
    this.formData = Object.assign({ description: '' });
    this.validatorComponent.instance.reset();
    this.getSupportModalComponent.showModal();
  };

  public handleGetSupport = async e => {
    const validationResults = this.validatorComponent.instance.validate();
    if (!validationResults?.isValid) {
      return;
    }

    if (!this.onGetSupport) {
      this.getSupportModalComponent.hideModal();
      return;
    }
    this.inProgress = true;

    await this.onGetSupport(this.formData?.description)
      .then(() => {
        this.getSupportModalComponent.hideModal();
        this.toster.success(
          `<p>Your message has been received. We'll be in touch within 24-48 hours to assist you. If you have urgent concerns,
        please feel free to contact us directly via the phone number <a href="tel:8153389668" class="get-support-modal__link">815-338-9668</a>.</p>`,
          'Thank You!',
          { disableTimeOut: true, enableHtml: true },
        );
      })
      .finally(() => {
        this.inProgress = false;
      });
  };
}
