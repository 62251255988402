import { Inject, Injectable } from '@angular/core';
import { FIREBASE_APP } from '../injections/firebase-app';
import { FirebaseApp } from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { CommonFireStoreDao, QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';
import {
  AGENT_CAMPAIGNS_COLLECTION_NAME,
  AgentCampaignStepName,
  BaseModelKeys,
  CAMPAIGNS_MANAGEMENT_TASKS_COLLECTION_NAME,
  CampaignsManagementTaskLogsAction,
  CampaignsManagementTasks,
  CampaignsManagementTasksKeys,
  CampaignsManagementTasksStatus,
} from '@ag-common-lib/public-api';
import { subDays } from 'date-fns';
import { DocumentData } from 'firebase/firestore';
import * as _ from 'lodash';
import { combineLatest, map, Observable } from 'rxjs';
import { dateFromTimestamp } from '../utils/date-from-timestamp';
import { AgentCampaignsManagementTaskLogsService } from './agent-campaigns-management-task-logs.service';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AgentCampaignsManagementTasksService {
  public readonly fsDao: CommonFireStoreDao<CampaignsManagementTasks>;
  private readonly agentCampaignsCollectionPath = AGENT_CAMPAIGNS_COLLECTION_NAME;
  private readonly campaignManagementTasksCollectionPath = CAMPAIGNS_MANAGEMENT_TASKS_COLLECTION_NAME;

  activeTasks$: Observable<CampaignsManagementTasks[]>;

  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,
    private toastrService: ToastrService,
    private taskLogsService: AgentCampaignsManagementTaskLogsService,
  ) {
    this.fsDao = new CommonFireStoreDao<CampaignsManagementTasks>(
      fireBaseApp,
      AgentCampaignsManagementTasksService.fromFirestore,
      AgentCampaignsManagementTasksService.toFirestore,
    );
  }

  static readonly fromFirestore = (data): CampaignsManagementTasks => {
    if (!!data?.updated_date) {
      data.updated_date = dateFromTimestamp(data?.updated_date);
    }
    if (!!data?.updatedStatusAt) {
      data.updatedStatusAt = dateFromTimestamp(data?.updatedStatusAt);
    }
    if (!!data?.assignedAt) {
      data.assignedAt = dateFromTimestamp(data?.assignedAt);
    }
    if (!!data?.details) {
      const details = data.details;
      let isContactInfoStepDone = null;
      Object.keys(details).forEach((key: AgentCampaignStepName) => {
        if (!!details[key].incomingStepData || !!details[key].currentStepData) {
          switch (key) {
            case AgentCampaignStepName.contactInfoAddress:
            case AgentCampaignStepName.contactInfoEmailAddress:
            case AgentCampaignStepName.contactInfoPhoneNumber:
              // set the same isStepDone to each contact info
              isContactInfoStepDone = _.isNull(isContactInfoStepDone)
                ? details[key].isStepDone ?? false
                : isContactInfoStepDone;
              details[key].isStepDone = isContactInfoStepDone;
              break;

            default:
              details[key].isStepDone = details[key].isStepDone ?? false;
              break;
          }
        }
      });
    }
    return Object.assign({}, data);
  };

  static readonly toFirestore = (data: Partial<CampaignsManagementTasks>): DocumentData => {
    if (data?.[CampaignsManagementTasksKeys.taskStatus]) {
      Object.assign(data, { [CampaignsManagementTasksKeys.updatedStatusAt]: new Date() });
    }
    return data;
  };

  getActiveTasks(): Observable<CampaignsManagementTasks[]> {
    // Active tasks === DONE tasks <= 7 days and all other statuses
    const now = new Date();
    const fromDay = new Date(subDays(now, 7));
    const qpForDone: QueryParam[] = [
      new QueryParam(
        CampaignsManagementTasksKeys.taskStatus,
        WhereFilterOperandKeys.equal,
        CampaignsManagementTasksStatus.done,
      ),
      new QueryParam(CampaignsManagementTasksKeys.updatedStatusAt, WhereFilterOperandKeys.moreOrEqual, fromDay),
    ];
    const otherQp: QueryParam[] = [
      new QueryParam(
        CampaignsManagementTasksKeys.taskStatus,
        WhereFilterOperandKeys.notEqual,
        CampaignsManagementTasksStatus.done,
      ),
    ];

    this.activeTasks$ = combineLatest([
      this.fsDao
        .getCollectionGroupSnapshot(this.campaignManagementTasksCollectionPath, qpForDone, true)
        .pipe(map(snapshot => snapshot.docs.map(doc => (!doc.exists() ? null : doc.data())))),
      this.fsDao
        .getCollectionGroupSnapshot(this.campaignManagementTasksCollectionPath, otherQp, true)
        .pipe(map(snapshot => snapshot.docs.map(doc => (!doc.exists() ? null : doc.data())))),
    ]).pipe(
      debounceTime(200),
      map(([activeDoneTasks, activeOtherTasks]) => {
        return [...activeDoneTasks, ...activeOtherTasks];
      }),
    );

    return this.activeTasks$;
  }

  getTasks$(queryParams: QueryParam[] = []): Observable<CampaignsManagementTasks[]> {
    return this.fsDao
      .getCollectionGroupSnapshot(this.campaignManagementTasksCollectionPath, queryParams, true)
      .pipe(map(snapshot => snapshot.docs.map(doc => (!doc.exists() ? null : doc.data()))));
  }

  async create(agentCampaignsId: string, data: CampaignsManagementTasks, silent = false) {
    const table = this.getCollectionPath(agentCampaignsId);

    Object.assign(data, { [CampaignsManagementTasksKeys.agentCampaignDbId]: agentCampaignsId });

    const agentCampaigns = await this.fsDao
      .create(data, table)
      .then(items => {
        this.taskLogsService.saveLogs(
          agentCampaignsId,
          items[BaseModelKeys.dbId],
          items,
          null,
          CampaignsManagementTaskLogsAction.taskCreating,
        );
      })
      .catch(e => {
        console.log('e', e);
      });

    !silent && this.toastrService.success('Agent Campaigns Task Successfully Created!');

    return agentCampaigns;
  }

  async update(
    agentCampaignsId: string,
    documentId: string,
    updates: Partial<CampaignsManagementTasks>,
    action: CampaignsManagementTaskLogsAction = CampaignsManagementTaskLogsAction.taskUpdating,
    silent = false,
  ): Promise<CampaignsManagementTasks | void> {
    const table = this.getCollectionPath(agentCampaignsId);

    const agentCampaigns = await this.fsDao
      .updateFields(updates, documentId, table)
      .then(items => {
        this.taskLogsService.saveLogs(agentCampaignsId, documentId, items, null, action);
      })
      .catch(e => {
        console.log('e', e);
      });

    !silent && this.toastrService.success('Agent Campaigns Task Successfully Updated!');

    return agentCampaigns;
  }

  async correctDataStepIncomingData(
    agentCampaignsId: string,
    documentId: any,
    stepName: AgentCampaignStepName,
    incomingStepData,
  ) {
    return this.update(
      agentCampaignsId,
      documentId,
      {
        details: {
          [stepName]: {
            incomingStepData,
          },
        },
      },
      CampaignsManagementTaskLogsAction.taskUpdating,
      true,
    );
  }

  getTasksByAgentDbId(agentDbId: string, queryParams: QueryParam[] = []) {
    const qp: QueryParam[] = [
      new QueryParam(CampaignsManagementTasksKeys.agentDbId, WhereFilterOperandKeys.equal, agentDbId),
      ...queryParams,
    ];

    return this.fsDao.getCollectionGroupSnapshot(this.campaignManagementTasksCollectionPath, qp);
  }

  getActiveTasksForCampaign(agentCampaignsId: string) {
    const table = this.getCollectionPath(agentCampaignsId);
    const qp: QueryParam[] = [
      new QueryParam(
        CampaignsManagementTasksKeys.taskStatus,
        WhereFilterOperandKeys.notEqual,
        CampaignsManagementTasksStatus.done,
      ),
    ];

    return this.fsDao.getList(table, qp);
  }

  getNotStartedTasksForCampaign(agentCampaignsId: string) {
    const table = this.getCollectionPath(agentCampaignsId);
    const qp: QueryParam[] = [
      new QueryParam(
        CampaignsManagementTasksKeys.taskStatus,
        WhereFilterOperandKeys.equal,
        CampaignsManagementTasksStatus.newTasks,
      ),
    ];

    return this.fsDao
      .getList(table, qp)
      .pipe(
        map(data =>
          data?.filter(item => !item?.[CampaignsManagementTasksKeys.details]?.[AgentCampaignStepName.support]),
        ),
      );
  }

  getList(agentCampaignsId: string, qp: QueryParam[] = []): Observable<CampaignsManagementTasks[]> {
    const table = this.getCollectionPath(agentCampaignsId);

    return this.fsDao.getList(table, qp);
  }

  private getCollectionPath(agentCampaignsId: string): string {
    return [this.agentCampaignsCollectionPath, agentCampaignsId, this.campaignManagementTasksCollectionPath].join('/');
  }
}
