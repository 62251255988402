import { NgModule } from '@angular/core';
import { AgentCampaignAppointmentComponent } from './agent-campaign-appointment.component';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ButtonWithIndicatorModule } from '../../button-with-indicator/button-with-indicator.module';
import { AgentCampaignAppointmentService } from './agent-campaign-appointment.service';

@NgModule({
  declarations: [AgentCampaignAppointmentComponent],
  imports: [SharedModule, ButtonWithIndicatorModule],
  exports: [AgentCampaignAppointmentComponent],
  providers: [AgentCampaignAppointmentService],
})
export class AgentCampaignAppointmentModule {}
