import { ImageCropperPayload } from './image-cropper.model';
import { LookupKeys } from '../crm/lookup.model';

export enum HeadshotKeys {
  wasabiPath = 'wasabiPath',
  imageCropperPayload = 'imageCropperPayload',
}

export const HEADSHOT_LOOKUP = [
  {
    [LookupKeys.value]: HeadshotKeys.wasabiPath,
    [LookupKeys.description]: 'Wasabi Path',
  },
  {
    [LookupKeys.value]: HeadshotKeys.imageCropperPayload,
    [LookupKeys.description]: 'Image Cropper Payload',
  },
];

export interface Headshot {
  [HeadshotKeys.wasabiPath]: string;
  [HeadshotKeys.imageCropperPayload]?: ImageCropperPayload;
}
